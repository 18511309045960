import { useContext, useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Select,
  Collapse,
  Cascader,
  DatePicker,
  Switch,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../../App";
import { DefaultOptionType } from "antd/lib/cascader";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import useApiPost from "../../../../hooks/useApiPost";
import * as S from "./PhotoAudits.styled";

dayjs.extend(customParseFormat);

const { Panel } = Collapse;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

interface ClusterSpecialistsQuestion {
  orderNumber: number;
  text: string;
  description: string;
  type: "radio" | "text" | "bool";
  maxScore: number;
  options: {
    score: number;
    value: string;
    nextQuestion: number;
    isTriggeringRedFlag: boolean;
    redFlagCategory?: string;
    redFlagCategoryId?: string;
    orderNumber: number;
    redFlagReferencedQuestions?: string;
    emailRecipient?: string;
  }[];
}

export const EMPTY: any = {
  validTo: null,
  validFrom: null,
  chain_id: [],
  name: "",
  isActive: false,
};
const PhotoAuditView = () => {
  const appContext = useContext(AppContext);
  const [treeData, setTreeData] = useState<any[]>([]);
  const { projectId } = appContext;
  const { auditId } = useParams<{ auditId: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [form, setForm] = useState(EMPTY);
  const [userRoles, setUserRoles] = useState<any[]>([]);
  const [outletTypes, setOutletTypes] = useState<any[]>([]);
  const { request, setError } = useApiPost();

  const [chainsProjectSpecific, setChainsProjectSpecific] = useState<any[]>([]);

  const [questions, setQuestions] = useState<ClusterSpecialistsQuestion[]>([
    {
      orderNumber: 1,
      text: "",
      description: "",
      type: "radio",
      maxScore: 1,
      options: [
        {
          value: "",
          nextQuestion: 0,
          isTriggeringRedFlag: false,
          orderNumber: 1,
          score: 1,
        },
      ],
    },
  ]);
  useEffect(() => {
    if (!auditId) {
      return;
    }

    const fetchData = async () => {
      // const data = await getPhotoAuditById(auditId, projectId);
      const data = await request(
        `/office/photo-audits/details/${auditId}`,
        "POST",
        {
          projectId,
        }
      );
      const { connectedOutletTypes, connectedRoles } = data;
      const { tree } = await request("/office/settings/red-flags", "POST", {
        projectId,
      });
      const { userRoles, outletTypes } = await request(
        "/office/strategic-audits/options",
        "POST",
        {
          projectId,
        }
      );
      setUserRoles(userRoles);
      setOutletTypes(outletTypes);
      setChainsProjectSpecific(data.chains);
      setTreeData(tree);
      setQuestions(data.questions || []);

      setForm({
        validTo: dayjs(data.validTo),
        validFrom: dayjs(data.validFrom),
        chain_id: data.chain_id,
        name: data.name,
        description: data.description,
        isActive: data.isActive,
        userRoles_id: connectedRoles
          ? connectedRoles.map((u: any) => u.value)
          : [],
        outletType_id: connectedOutletTypes
          ? connectedOutletTypes.map((u: any) => u.value)
          : [],
      });
    };
    fetchData();
  }, [auditId]);
  let navigate = useNavigate();

  const handleComeBack = () => {
    navigate("/admin/photo-audit");
  };
  const availableOptions = questions
    ? questions.map((question, index) => ({
        value: index,
        label: `Question ${index + 1}`,
      }))
    : [];

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1
    );

  const getLabel = (id: string) => {
    let label = "";
    const findLabel = (data: any[]) => {
      data.forEach((item) => {
        if (item.id === id) {
          label = item.title;
        } else if (item.children && item.children.length > 0) {
          findLabel(item.children);
        }
      });
    };
    findLabel(treeData);
    return label;
  };

  const updateForm = (key: string, value: any) => {
    setForm((form: any) => ({
      ...form,
      [key]: value,
    }));
  };

  const [formOptionValues, setFormOptionValues] = useState({
    chain_id: null,
  });

  const generateYesNoOptions = (index: number) => (
    <div style={{ display: "flex" }}>
      <S.Column>
        <Form.Item
          label={questions[index].options[0].value}
          style={{ width: 400 }}
        >
          <Select
            options={availableOptions.filter((option) => option.value > index)}
            value={questions[index].options[0].nextQuestion}
          />
        </Form.Item>
        <Form.Item label="Red Flag">
          <Input
            type="checkbox"
            name={`questions[${index}].options[0].isTriggeringRedFlag`}
            checked={questions[index].options[0].isTriggeringRedFlag}
          />
          <div
            style={{
              marginTop: 20,
            }}
          >
            Score: {questions[index].options[0].score}
          </div>
          {questions[index].options[0].isTriggeringRedFlag && (
            <Form.Item label="Red Flag Category">
              <Cascader
                showSearch={{ filter }}
                options={treeData}
                expandTrigger="hover"
                disabled={true}
                value={[questions[index]?.options[0]?.redFlagCategory || ""]}
                changeOnSelect
              />
            </Form.Item>
          )}
          <div
            style={{
              marginTop: 20,
            }}
          >
            Referenced Questions:
            {questions[index].options[0].redFlagReferencedQuestions}
          </div>
          {questions[index].options[0].emailRecipient && (
            <div
              style={{
                marginTop: 20,
              }}
            >
              Email recipient:
              {questions[index].options[0].emailRecipient}
            </div>
          )}
        </Form.Item>
      </S.Column>
      <S.Column>
        <Form.Item
          label={questions[index].options[1].value}
          style={{ width: 400 }}
        >
          <Select
            options={availableOptions.filter((option) => option.value > index)}
            value={questions[index].options[1].nextQuestion}
          />
        </Form.Item>
        <Form.Item label="Red Flag">
          <Input
            type="checkbox"
            name={`questions[${index}].options[1].isTriggeringRedFlag`}
            checked={questions[index].options[1].isTriggeringRedFlag}
          />
          <div
            style={{
              marginTop: 20,
            }}
          >
            Score: {questions[index].options[1].score}
          </div>
          {questions[index].options[1].isTriggeringRedFlag && (
            <Form.Item label="Red Flag Category">
              <Cascader
                showSearch={{ filter }}
                options={treeData}
                expandTrigger="hover"
                disabled={true}
                value={[questions[index]?.options[1]?.redFlagCategory || ""]}
                changeOnSelect
              />
            </Form.Item>
          )}
          <div
            style={{
              marginTop: 20,
            }}
          >
            Referenced Questions:
            {questions[index].options[1].redFlagReferencedQuestions}
          </div>
          {questions[index].options[1].emailRecipient && (
            <div
              style={{
                marginTop: 20,
              }}
            >
              Email recipient:
              {questions[index].options[1].emailRecipient}
            </div>
          )}
        </Form.Item>
      </S.Column>
    </div>
  );

  const generateText = (index: number) => (
    <Form.Item label="Next Question">
      <Select
        options={availableOptions.filter((option) => option.value > index)}
      />
    </Form.Item>
  );

  const generateRadio = (index: number) => (
    <div>
      {questions[index].options.map((option, optionIndex) => (
        <S.OptionBox key={`option_${optionIndex}`}>
          <Form.Item label="Option">
            <Input type="text" value={option.value} />
            <div
              style={{
                marginTop: 20,
              }}
            >
              Score: {option.score}
            </div>
            <div
              style={{
                marginTop: 20,
              }}
            >
              Referenced Questions:
              {option.redFlagReferencedQuestions}
            </div>
            {option.emailRecipient && (
              <div
                style={{
                  marginTop: 20,
                }}
              >
                Email recipient:
                {option.emailRecipient}
              </div>
            )}
          </Form.Item>
        </S.OptionBox>
      ))}
    </div>
  );

  const submitForm = async () => {
    const data = {
      ...form,
      projectId,
    };

    if (auditId) {
      // await updatePhotoAudit(auditId, data);
      await request(`/office/photo-audits/update/${auditId}`, "POST", {
        ...data,
      });
      handleComeBack();
    }
  };

  if (loading) return <div>Loading...</div>;

  console.log({ questions });
  return (
    <>
      <Typography.Title>Photo Audit</Typography.Title>
      {/* {isAnyQuestionWithRedFlagButNoReferencedQuestions && (
        <Alert
          message="Warning"
          description="There are questions with red flag but no referenced questions. Please reach out to the administrator."
          type="warning"
          showIcon
          style={{ marginBottom: 20 }}
        />
      )} */}
      <Form labelCol={{ span: 4 }} layout="horizontal">
        <Form.Item label="Name">
          <Input
            type="text"
            name="name"
            required
            value={form.name}
            onChange={(e) => updateForm("name", e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Description">
          <Input
            type="text"
            name="description"
            required
            value={form.description}
            onChange={(e) => updateForm("description", e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="Start"
          // name="startDate"
          // getValueProps={(i: any) => ({ value: moment(i) })}
          required
        >
          <DatePicker
            allowClear={false}
            value={form.validFrom}
            // onChange={(e) => setStartDate(e?.toDate())}
            onChange={(e: any) => updateForm("validFrom", e)}
          />
        </Form.Item>
        <Form.Item
          label="End"
          // name="endDate"
          // getValueProps={(i: any) => ({ value: moment(i) })}
          required
          rules={[
            {
              required: true,
              message: "Please select a form end",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const start = new Date(getFieldValue("startDate"));
                const end = new Date(value);

                if (start > end) {
                  return Promise.reject(
                    "End date must be greater than start date"
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <DatePicker
            allowClear={false}
            value={form.validTo}
            // onChange={(e) => setEndDate(e?.toDate())}
            onChange={(e: any) => updateForm("validTo", e)}
          />
        </Form.Item>
        <Form.Item label="User Roles">
          <Select
            loading={loading}
            options={userRoles}
            value={form.userRoles_id}
            mode="multiple"
            onChange={(e) => {
              updateForm("userRoles_id", e);
            }}
            allowClear
          />
        </Form.Item>
        <Form.Item label="Outlet Type">
          <Select
            loading={loading}
            options={outletTypes}
            value={form.outletType_id}
            mode="multiple"
            onChange={(e) => {
              updateForm("outletType_id", e);
            }}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label="Chain"
          hasFeedback
          rules={[{ required: true, message: "State is required to proceed!" }]}
        >
          <Select
            loading={loading}
            options={chainsProjectSpecific}
            value={form.chain_id}
            mode="multiple"
            onChange={(e) => {
              updateForm("chain_id", e);
            }}
          />
        </Form.Item>
        <Form.Item label="Is active" name="isActive">
          <Switch
            defaultChecked={form.isActive}
            checked={form.isActive}
            onChange={(e) => updateForm("isActive", e)}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button type="primary" htmlType="submit" onClick={() => submitForm()}>
            Submit
          </Button>
        </Form.Item>
        <Collapse bordered={false} style={{ padding: 0 }}>
          {questions.map((question, index) => (
            <Panel
              header={`(${index + 1}) ${questions[index].text}`}
              key={`${index}`}
            >
              <S.QuestionBox key={`box_${index}`}>
                <div>
                  <Form.Item label="Description">
                    <Form.Item label="Text">
                      <Input
                        type="text"
                        value={question.description}
                        // onChange={(e) => updateQuestion(index, "text", e)}
                      />
                    </Form.Item>
                    {/* <Select
                      options={availableOptions.filter(
                        (option) => option.value > index
                      )}
                    /> */}
                  </Form.Item>
                </div>
              </S.QuestionBox>
            </Panel>
          ))}
        </Collapse>
      </Form>
    </>
  );
};

export default PhotoAuditView;
