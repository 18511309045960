import { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Typography, Switch, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import useApiPost from "../../../../hooks/useApiPost";
import { toast } from "react-toastify";
import { AppContext } from "../../../../App";

const OutletStateForm = () => {
  const { projectId } = useContext(AppContext);
  const { outletstateId } = useParams<{ outletstateId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(true);
  const navigate = useNavigate();
  const headerText = outletstateId ? "Edit OutletState" : "Add OutletState";
  const { request } = useApiPost();
  const warn = (content: string) => {
    message.warning(content);
  };
  useEffect(() => {
    fetchClientData();
  }, [outletstateId]);

  const handleComeBack = () => navigate("/admin/outletstate");

  const fetchClientData = async () => {
    setIsLoading(true);
    if (!outletstateId) {
      setIsLoading(false);
      return;
    }
    const { data } = await request(`/office/outletStates/list`, "POST", {
      project_id: projectId,
      outletStateId: outletstateId,
    });
    if (!data) {
      navigate(`/admin`);
      return;
    }
    const { outletState, isActive } = data;
    setName(outletState);
    setIsActive(isActive);
    setIsLoading(false);
  };

  const handleInsert = async () => {
    try {
      let pattern = new RegExp(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/);
      if (!name.trim()) return alert("Please enter OutletState name!");
      if (!pattern.test(name))
        return alert("Only Characters & Numbers allowed");
      const res = await request("/office/outletStates/add", "POST", {
        outletStateName: name,
        isActive,
        project_id: projectId,
      });

      if (res.error) {
        alert(res.error);
        setIsLoading(false);
        return;
      }

      if (res?.status == 200) {
        setIsLoading(false);
        toast.info("OutletState has been added successfully.", {
          position: "top-right",
        });
      }
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      if (!outletstateId) return;
      let res = await request("/office/outletStates/toggleActive", "PATCH", {
        outletStateId: outletstateId,
        isActive,
      });
      if (res.status == 202) warn(res.message);

      setIsLoading(false);
      handleComeBack();
      if (res?.status == 400) {
        return toast.info(res?.message, {
          position: "top-right",
        });
      }
      if (res?.status == 200) {
        toast.info("OutletState has been updated successfully.", {
          position: "top-right",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Form labelCol={{ span: 4 }}>
        <Form.Item
          className="form-item"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please input OutletState name!",
            },
            {
              pattern: new RegExp(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/),
              message: "Only Characters & Numbers Allowed",
            },
          ]}
        >
          {outletstateId ? (
            <div>{name}</div>
          ) : (
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          )}
        </Form.Item>
        <Form.Item label="Is active" valuePropName="isActive" name="isActive">
          <Switch defaultChecked={isActive} onChange={(e) => setIsActive(e)} />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            onClick={outletstateId ? handleUpdate : handleInsert}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default OutletStateForm;
