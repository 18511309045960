import { useContext, useState } from "react";
import { Form, Input, Button, Typography, Collapse } from "antd";
import { FormEvent } from "react";
import { AppContext } from "../../../../App";
import * as S from "./PhotoAudits.styled";
import { useNavigate, useParams } from "react-router-dom";
import useApiPost from "../../../../hooks/useApiPost";
const { Panel } = Collapse;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

interface PhotoAuditInput {
  name: string;
  description?: string;
  questions: PhotoAuditQuestion[];
}

interface PhotoAuditQuestion {
  orderNumber: number;
  text: string;
  description: string;
  isOnlyCameraAllowed: boolean;
}

const PhotoAuditForm = () => {
  const appContext = useContext(AppContext);
  const { projectId } = appContext;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState<PhotoAuditQuestion[]>([]);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const { auditId } = useParams<{ auditId: string }>();
  const navigate = useNavigate();
  const { request, setError } = useApiPost();

  const sendForm = async (e: FormEvent) => {
    setLoading(true);

    try {
      const input: PhotoAuditInput = {
        name,
        description,
        questions: questions,
      };
      await request("/office/photo-audits/insert", "POST", {
        projectId,
        data: input,
      });
      await request("/office/photo-audits/insert", "POST", {
        projectId,
        data: input,
      });
      navigate("/admin/photo-audit");

      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  const handleAddNewQuestion = () => {
    setQuestions((questions) => [
      ...questions,
      {
        orderNumber: questions.length + 1,
        text: "",
        description: "",
        isOnlyCameraAllowed: false,
      },
    ]);
  };

  const onChange = (key: string | string[]) => {
    // console.log(key);
  };

  return (
    <>
      <Typography.Title>Photo Audit</Typography.Title>
      <Form
        labelCol={{ span: 4 }}
        // wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={sendForm}
        form={form}
      >
        <Form.Item label="Name">
          <Input
            type="text"
            name="name"
            required
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label="Description">
          <Input
            type="text"
            name="description"
            required
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </Form.Item>
        <Collapse
          bordered={false}
          style={{ padding: 0 }}
          defaultActiveKey={["1", "0"]}
          onChange={onChange}
        >
          {questions.map((question, index) => (
            <Panel
              header={`(${index + 1}) ${questions[index].text}`}
              key={`${index}`}
            >
              <S.QuestionBox key={`box_${index}`}>
                {/* <S.HeaderRow> */}
                <S.Label>Task:</S.Label>
                <Form.Item
                  style={{
                    width: "100%",
                    paddingRight: 10,
                    marginBottom: 12,
                  }}
                >
                  <Input
                    type="text"
                    name={`questions[${index}].text`}
                    required
                    value={question.text}
                    onChange={(e) => {
                      setQuestions((questions) => {
                        return questions.map((question, questionIndex) => {
                          if (questionIndex === index) {
                            return {
                              ...question,
                              text: e.target.value,
                            };
                          }
                          return question;
                        });
                      });
                    }}
                  />
                </Form.Item>
                <div>
                  <Form.Item label="Only camera">
                    <Input
                      type="checkbox"
                      name="isOnlyCameraAllowed"
                      checked={question.isOnlyCameraAllowed}
                      onChange={(e) => {
                        setQuestions((questions) => {
                          return questions.map((question, questionIndex) => {
                            if (questionIndex === index) {
                              return {
                                ...question,
                                isOnlyCameraAllowed: e.target.checked,
                              };
                            }
                            return question;
                          });
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                {/* </S.HeaderRow> */}
                <Button
                  type="primary"
                  danger
                  style={{ maxWidth: "200px", marginTop: 10, margin: "auto" }}
                  onClick={() => {
                    setQuestions((questions) => {
                      return questions.filter(
                        (question, questionIndex) => questionIndex !== index
                      );
                    });
                  }}
                >
                  Delete task
                </Button>
                {/* </Row> */}
              </S.QuestionBox>
            </Panel>
          ))}
        </Collapse>
        <Button type="primary" onClick={() => handleAddNewQuestion()}>
          Add task
        </Button>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default PhotoAuditForm;
