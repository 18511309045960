export const EMPTY_PRODUCT = {
  isActive: false,
  isDeal: false,
  description: "",
  shortDescription: "",
  EAN: "",
  manufacturerCode: "",
  imageURL: "",
  isTest: false,
  size: 1,
  isImageProcessed: false,
  hierarchy_id: null,
};
