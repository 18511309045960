import { useContext, useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  DatePicker,
  Switch,
  message,
} from "antd";
import { Cycle } from "../types";
import { useParams } from "react-router-dom";
import { EMPTY_CYCLE } from "../../../../utils/cycle";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../../App";
// import { addCycle, updateCycle } from "../../../../api/office/cycles";
import useApiPost from "../../../../hooks/useApiPost";
dayjs.extend(customParseFormat);

export const EMPTY = {
  id: "",
  name: "",
  startDate: null,
  endDate: null,
  isActive: false,
};

const CycleDetailsPage = () => {
  const { cycleId } = useParams<{ cycleId: string }>();
  const { projectId } = useContext(AppContext);
  const [messageApi, contextHolder] = message.useMessage();
  const { request, setError } = useApiPost();

  const warn = (content: string) => {
    message.warning(content);
  };
  const [form, setForm] = useState(EMPTY);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const headerText = cycleId ? "Edit Cycle" : "Add Cycle";
  const [initValues, setInitValues] = useState<Cycle>(EMPTY_CYCLE);

  const fetchCycleData = async () => {
    if (!cycleId) {
      return;
    }
    setIsLoading(true);
    const cycleData = await request(`/office/cycles/${cycleId}`);
    const { startDate, endDate, name, isActive } = cycleData;
    setForm({
      ...cycleData,
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
      isActive,
      name,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchCycleData();
  }, [cycleId]);

  const handleComeBack = () => {
    navigate("/admin/cycles");
  };
  const handleInsert = async (values: any) => {
    try {
      const result = await request("/office/cycles/insert", "POST", {
        ...values,
        projectId,
      });

      if (result.error) {
        return warn(result.error);
      }

      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (values: any) => {
    try {

      const result = await request("/office/cycles/update", "POST", {
        ...values,
        ...form,
        projectId,
      });

      if (result.error) {
        return warn(result.error);
      }

      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  const onFinish = async (values: any) => {
    setIsLoading(true);

    if (!form.startDate || !form.endDate || !form.name) {
      setIsLoading(false);
      return warn("Please fill all fields");
    }

    const isEndLowerThanStart = dayjs(form.endDate).isBefore(form.startDate);

    if (isEndLowerThanStart) {
      setIsLoading(false);
      return warn("Start date must be lower than end date");
    }

    if (cycleId) {
      handleUpdate({
        ...form,
        cycleId,
      });
    } else {
      handleInsert(form);
    }
  };

  const updateForm = (key: string, value: any) => {
    setForm((form) => ({
      ...form,
      [key]: value,
    }));
  };
  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      {contextHolder}
      <Typography.Title>{headerText}</Typography.Title>
      <Form
        labelCol={{ span: 2 }}
        wrapperCol={{ span: 14 }}
        onFinish={onFinish}
        initialValues={initValues}
      >
        <Form.Item label="Name" required>
          <Input
            onChange={(e) => updateForm("name", e.target.value)}
            value={form.name}
          />
        </Form.Item>
        <Form.Item label="Start date" required>
          <DatePicker
            allowClear={false}
            value={form.startDate}
            onChange={(e: any) => updateForm("startDate", e)}
          />
        </Form.Item>
        <Form.Item label="End date" required>
          <DatePicker
            allowClear={false}
            value={form.endDate}
            onChange={(e: any) => updateForm("endDate", e)}
          />
        </Form.Item>
        <Form.Item label="Is active" name="isActive">
          <Switch
            defaultChecked={form.isActive}
            onChange={(e) => updateForm("isActive", e)}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default CycleDetailsPage;
