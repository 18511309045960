import { useContext, useRef, useState } from "react";
import { Form, Input, Button, Typography, Select } from "antd";
import { useNavigate } from "react-router-dom";

import TextArea from "antd/es/input/TextArea";
// import { insertSupportTicket } from "../../../../api/office/tickets";
import { getAuth } from "firebase/auth";
import useApiPost from "../../../../hooks/useApiPost";

const SupportTicketForm = () => {
  const auth = getAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const ref = useRef<HTMLInputElement>(null);
  const { request, setError } = useApiPost();

  const [description, setDescription] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [priority, setPriority] = useState<string>("");
  const [file, setFile] = useState<FileList>();

  const navigate = useNavigate();
  const handleComeBack = () => navigate("/admin/support/tickets");

  const onSubmit = async () => {
    setIsLoading(true);

    const uid = auth.currentUser?.uid;

    if (!uid) {
      alert("Can't find user id. Try to relogin.");
      return;
    }

    const formData = new FormData();

    formData.append("description", description);
    formData.append("priority", priority);
    formData.append("type", type);
    formData.append("title", title);
    formData.append("uid", uid || "");
    //TODO: fix this
    // formData.append("projectId", projectId);
    if (file) {
      formData.append("file", file[0]);
    }

    // await insertSupportTicket(formData);

    await request("/office/support/tickets/new", "POST", formData, true);
    setFile(undefined);
    ref.current!.value = "";
    setIsLoading(false);
    handleComeBack();
  };

  const ticketTypes = [
    { label: "Bug", value: "Bug" },
    { label: "Feature", value: "Feature" },
    { label: "Improvement", value: "Improvement" },
    { label: "Task", value: "Task" },
  ];

  const ticketPriority = [
    { label: "Low", value: "low" },
    { label: "Medium", value: "medium" },
    { label: "High", value: "high" },
  ];

  const isSubmitDisabled =
    title.length === 0 ||
    description.length === 0 ||
    type.length === 0 ||
    priority.length === 0;

  const onChangeSingleFile = (e: any) => {
    setFile(e.target.files);
  };

  const isSendEnabled = title.length > 0 || (file && file?.length > 0);

  return (
    <>
      <Typography.Title>Submit a ticket</Typography.Title>
      <Form
        labelCol={{ span: 4 }}
        style={{
          maxWidth: "800px",
        }}
      >
        <Form.Item label="Title" name="title">
          <Input onChange={(e) => setTitle(e.target.value)} />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <TextArea rows={5} onChange={(e) => setDescription(e.target.value)} />
        </Form.Item>
        <Form.Item label="Type" hasFeedback name="ticketType">
          <Select
            options={ticketTypes}
            onChange={(_value, e) => {
              //@ts-ignore
              setType(e.value);
            }}
          />
        </Form.Item>
        <Form.Item label="Priority" name="ticketPriority">
          <Select
            options={ticketPriority}
            onChange={(_value, e) => {
              //@ts-ignore
              setPriority(e.value);
            }}
          />
        </Form.Item>
        <input
          ref={ref}
          type="file"
          name="file"
          onChange={onChangeSingleFile}
          disabled={file && file?.length > 0}
          // accept="image/*"
          style={{
            marginBottom: "10px",
          }}
        />
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={isSubmitDisabled}
            onClick={onSubmit}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default SupportTicketForm;
