import { useEffect, useState } from "react";
import { Form, Input, Button, Typography, Switch } from "antd";
import { useNavigate, useParams } from "react-router-dom";
// import {
//   addNewCity,
//   getCityDetails,
//   updateCity,
// } from "../../../../api/office/cities";
import useApiPost from "../../../../hooks/useApiPost";

const CityForm = () => {
  const { cityId } = useParams<{ cityId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const navigate = useNavigate();
  const headerText = cityId ? "Edit City" : "Add City";
  const { request, setError } = useApiPost();

  const fetchCityData = async () => {
    setIsLoading(true);
    if (!cityId) {
      setIsLoading(false);
      return;
    }
    // const { city } = await getCityDetails(cityId);
    const { city } = await request(`/office/cities/details`, "POST", {
      id: cityId,
    });
    if (!city) {
      navigate(`/admin`);
      return;
    }
    const { name, isActive } = city;
    setName(name);
    setIsActive(isActive);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchCityData();
  }, [cityId]);

  const handleComeBack = () => navigate("/admin/cities");

  const handleInsert = async () => {
    try {
      // const res = await addNewCity(name, isActive);
      const res = await request("/office/cities/insert", "POST", {
        name,
        isActive,
      });
      if (res.error) {
        alert(res.error);
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      if (!cityId) return;
      // await updateCity(cityId, isActive, name);
      await request("/office/cities/update", "POST", {
        id: cityId,
        isActive,
      });
      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Form labelCol={{ span: 4 }}>
        <Form.Item label="Name">
          {cityId ? (
            <div>{name}</div>
          ) : (
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          )}
        </Form.Item>
        <Form.Item label="Is active" valuePropName="isActive" name="isActive">
          <Switch defaultChecked={isActive} onChange={(e) => setIsActive(e)} />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            onClick={cityId ? handleUpdate : handleInsert}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default CityForm;
