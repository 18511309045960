import React, { useContext, useEffect, useState } from "react";
import { Button, Typography } from "antd";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import authCtx from "../../store/auth/AuthContextProvider";
import useApiPost from "../../hooks/useApiPost";
import { toast } from "react-toastify";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function PasswordNotifyContainer() {
  const { authState, globalLogOutDispatch } = useContext(authCtx);
  const { email, forcePassNotify, passNotify, isLoggedIn, name } = authState;
  const [open, setOpen] = useState(false);
  const { noAuthRequest } = useApiPost();

  const handleClose = () => {
    if (!forcePassNotify) setOpen(false);
  };

  useEffect(() => {
    if (passNotify && isLoggedIn) {
      setOpen(true);
    }
  }, [passNotify, isLoggedIn]);

  const handleAction = async () => {
    const data = { email };
    const authResponse = await noAuthRequest(
      "/office/forgotPass/sendForgotPasswordEmail",
      "POST",
      data
    );
    if (authResponse?.status === 200) {
      toast.info(authResponse.message, {
        position: "bottom-center",
      });
      setOpen(false);
      globalLogOutDispatch();
    } else {
      toast.info(authResponse.message, {
        position: "bottom-center",
      });
    }
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <Typography>Password Expiry</Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ></IconButton>
        <DialogContent dividers>
          <Typography>Hi {name}</Typography>
          <br />
          <Typography>
            This is to inform you that your password is set to expire in 90
            days. As part of our security protocols, it is essential to
            periodically update your password to ensure the safety and integrity
            of our systems and your personal information.
          </Typography>
          <br />
          <Typography>
            To avoid any disruptions to your access, please take a moment to
            change your password at your earliest convenience. You can do so by
            following Click on Change Password.
          </Typography>
          <Typography>
            Should you encounter any difficulties or have any questions
            regarding this process, please don't hesitate to reach out to our IT
            support team for assistance.
          </Typography>
        </DialogContent>
        <DialogActions>
          {!forcePassNotify && <Button autoFocus onClick={handleClose}>
            Close
          </Button>}
          <Button
            type="primary"
            htmlType="submit"
            autoFocus
            onClick={handleAction}
          >
            Change Password
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
export default PasswordNotifyContainer;
