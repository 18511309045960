import { useContext, useEffect, useState } from "react";
import { BadgeProps, Button, Modal } from "antd";
import { Badge, Calendar, Card, Space } from "antd";
import type { Dayjs } from "dayjs";
import { AppContext } from "../../../../App";
import moment from "moment";
import * as S from "./coaching-calendar.styled";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import useApiPost from "../../../../hooks/useApiPost";

const getMonthData = (value: Dayjs) => {
  // if (value.month() === 8) {
  //   return 1394;
  // }
  return null;
};

interface IDelegate {
  id: string;
  name: string;
  email: string;
  phone: string;
  role: string;
}

interface IModule {
  id: string;
  name: string;
  description: string;
}

const CoachingCalendar = () => {
  const auth = getAuth();
  const { request, setError } = useApiPost();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { projectId } = useContext(AppContext);
  const [entries, setEntries] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeDate, setActiveDate] = useState<any | null>(null);
  const [activeRegistry, setActiveRegistry] = useState<any | null>(null);
  const [isLoadingCellDetails, setIsLoadingCellDetails] = useState(false);
  const [availableDelegates, setAvailableDelegates] = useState<IDelegate[]>([]);
  const [availabelModules, setAvailableModules] = useState<IModule[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const monthCellRender = (value: Dayjs) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  };

  const getListData = (value: Dayjs) => {
    const filteredEntries = entries.filter((e) =>
      moment(e.start, "YYYY-MM-DD").isSame(value.toDate(), "day")
    );
    return filteredEntries || [];
  };

  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true);
      const userUid = auth.currentUser?.uid;
      const allEntries = await request(
        "/office/training/get-calendar-data",
        "POST",
        {
          projectId,
        }
      );
      const visibleEntries = allEntries.filter(
        (e: any) => e.facilitator_uid === userUid
      );
      setEntries(visibleEntries);
      setIsLoading(false);
    };
    fetchAllData();
  }, []);

  const handleCellClick = (date: any) => {
    // setActiveCell(item)
    setActiveDate(date);
    setIsModalOpen(true);
  };

  const dateCellRender = (value: Dayjs) => {
    const listData = getListData(value);
    return (
      <ul className="events" onClick={() => handleCellClick(value)}>
        {listData.map((item, index) => (
          <li key={index}>
            <Badge
              status={item.type as BadgeProps["status"]}
              text={item.content}
            />
          </li>
        ))}
      </ul>
    );
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getModalContent = () => {
    if (!activeDate) return null;

    if (isLoadingCellDetails) return <div>Loading...</div>;

    if (activeRegistry) {
      return (
        <div>
          <h3>Register for {activeRegistry}</h3>
          <Space direction="vertical">
            <Button type="primary">Register</Button>
          </Space>
        </div>
      );
    }

    const listData = getListData(activeDate);

    const isToday =
      new Date().toDateString() === activeDate.toDate().toDateString();
    const isFuture = new Date() < activeDate.toDate();

    const getActionText = () => {
      if (isToday) return "View Details";
      if (isFuture) return "Edit";
      return "View";
    };

    const handleAction = (id: string) => {
      // if (isToday) {
      const rootPath = location.pathname.split("/")[1];
      return navigate(`/${rootPath}/formal-trainings/view/${id}`);
      // }
      // setActiveRegistry(id);
      // navigate(`/admin/formal-trainings/edit/${id}`);
    };

    return (
      <S.EventsList>
        {listData.map((item, index) => (
          <li key={index}>
            {/* <Badge
              status={item.type as BadgeProps["status"]}
              text={item.content}
            /> */}
            <Badge.Ribbon
              text={item.callTypeName}
              color={item.callTypeName === "Promotional" ? "blue" : "cyan"}
            >
              <Card title={item.content} size="small">
                <Button onClick={() => handleAction(item.id)}>
                  {getActionText()}
                </Button>
              </Card>
            </Badge.Ribbon>
          </li>
        ))}
      </S.EventsList>
    );
  };

  return (
    <>
      <Calendar
        dateCellRender={dateCellRender}
        monthCellRender={monthCellRender}
      />
      <Modal
        title="Scheduled Events"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
        ]}
      >
        {getModalContent()}
      </Modal>
    </>
  );
};

export default CoachingCalendar;
