import { useState, useEffect, useContext } from "react";
import { Button, Typography, Cascader, Input, Form, Modal } from "antd";
import { AppContext } from "../../../../App";
import { DefaultOptionType } from "antd/lib/select";
import useApiPost from "../../../../hooks/useApiPost";

const RedFlagsSettings = () => {
  const { projectId } = useContext(AppContext);
  const { request, setError } = useApiPost();

  const [selectedGrouping, setSelectedGrouping] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedType, setSelectedType] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [treeData, setTreeData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState<string>("");

  const isGroupingActive = selectedGrouping?.length > 0;
  const isTypeActive = selectedType?.length > 0;
  const isCategoryActive = selectedCategory?.length > 0;
  const isAddingEnabled = !isCategoryActive;
  const isDeleteEnabled = isGroupingActive || isTypeActive || isCategoryActive;
  const isSubmitDisabled = name.length === 0;

  useEffect(() => {
    fetchData();
  }, [projectId]);

  const fetchData = async () => {
    setIsLoading(true);
    const { tree } = await request("/office/settings/red-flags", "POST", {
      projectId,
    });
    setTreeData(tree);
    setIsLoading(false);
  };

  const onCreateNew = async () => {
    await request("/office/settings/red-flags/insert", "POST", {
      projectId,
      group_id: selectedGrouping,
      type_id: selectedType,
      category_id: selectedCategory,
      name,
    });
    await fetchData();
  };
  const onDelete = async () => {
    setIsModalOpen(true);
  };

  const onChange = (val: any) => {
    if (!val) {
      setSelectedGrouping("");
      setSelectedType("");
      setSelectedCategory("");
      return;
    }
    setSelectedGrouping(val[0]);
    setSelectedType(val[1]);
    setSelectedCategory(val[2]);
  };

  const filter = (input: string, path: DefaultOptionType[]) =>
    path.some(
      ({ label }: any) => label.toLowerCase().indexOf(input.toLowerCase()) > -1
    );

  const getAddText = () => {
    if (!isGroupingActive && !isTypeActive && !isCategoryActive) {
      return "New Group";
    }
    if (isGroupingActive && !isTypeActive && !isCategoryActive) {
      return "New type";
    }
    if (isGroupingActive && isTypeActive && !isCategoryActive) {
      return "New category";
    }
  };

  const handleModalCancel = () => setIsModalOpen(false);
  const handleConfirmDelete = async () => {
    await request("/office/settings/red-flags/delete", "POST", {
      projectId,
      group_id: selectedGrouping,
      type_id: selectedType,
      category_id: selectedCategory,
    });
    await fetchData();
    setIsModalOpen(false);
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <div style={{ maxWidth: 600 }}>
      <Modal
        title="Confirmation"
        open={isModalOpen}
        onOk={handleConfirmDelete}
        onCancel={handleModalCancel}
        centered
        footer={[
          <Button key="back" onClick={handleModalCancel}>
            Back
          </Button>,
          <Button
            key="submit"
            type="primary"
            danger
            onClick={handleConfirmDelete}
          >
            Confirm Delete
          </Button>,
        ]}
      >
        <div>
          Are you sure you want to delete this item? This action cannot be
          undone.
        </div>
      </Modal>
      <Typography.Title>Red Flags Settings</Typography.Title>
      <Form>
        <Form.Item label="Select item" name="flags">
          <Cascader
            showSearch={{ filter }}
            options={treeData}
            expandTrigger="hover"
            onChange={(val) => onChange(val)}
            changeOnSelect
          />
        </Form.Item>
        {isAddingEnabled && (
          <Form.Item label={getAddText()} name="name">
            <Input
              style={{ width: 400 }}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Item>
        )}
      </Form>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: 260,
        }}
      >
        <Button
          type="primary"
          onClick={() => onCreateNew()}
          disabled={isSubmitDisabled}
        >
          ADD
        </Button>
        <Button
          type="primary"
          danger
          onClick={() => onDelete()}
          disabled={!isDeleteEnabled}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

export default RedFlagsSettings;
