import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useApiPost from "../../../../hooks/useApiPost";
import {
  Form,
  Button,
  Typography,
  DatePicker,
  Table,
  Input,
  Switch,
  Select,
  message,
} from "antd";
import {
  handleEndDateToDateOnly,
  handleStartDateToDateOnly,
} from "../../../../utils/dateTime";
import dayjs from "dayjs";

export const EMPTY_PROJECT = {
  description: "",
  objectives: "",
  startDate: null,
  endDate: null,
  client_id: null,
  isActive: false,
};

const ProjectForm = () => {
  let navigate = useNavigate();
  const { projectId } = useParams<{ projectId: string }>();
  const headerText = projectId ? "Edit Project" : "Add New Project";
  const [isLoading, setIsLoading] = useState(false);
  const { request, setError } = useApiPost();
  const [clients, setClients] = useState<any[]>([]);
  const [form, setForm] = useState(EMPTY_PROJECT);
  const [messageApi, contextHolder] = message.useMessage();

  const warn = (content: string) => {
    message.warning(content);
  };

  useEffect(() => {
    const getAllClients = async () => {
      setIsLoading(true);
      const res = await request(
        "/office/clients/all?isActive=true",
        "GET",
        null,
        undefined,
        "v2"
      );
      setClients(res.data);
      setIsLoading(false);
    };
    projectId && getProjectDetails();
    getAllClients();
  }, []);

  const getProjectDetails = async () => {
    const res = await request(`/office/projects/${projectId}`);

    setForm({
      ...res,
      startDate: dayjs(res.startDate || new Date()),
      endDate: dayjs(res.endDate || new Date()),
    });
  };

  const updateForm = (key: string, value: any) => {
    setForm((form) => ({
      ...form,
      [key]: value,
    }));
  };

  const onFinish = async (values: any) => {
    if (
      !form.description ||
      !form.startDate ||
      !form.objectives ||
      !form.client_id
    ) {
      setIsLoading(false);
      return warn("Please fill all required fields");
    }
    let updateData = {
      startDate: form.startDate,
      endDate: form.endDate,
      isActive: form.isActive,
    };
    projectId ? handleUpdate(updateData) : handleInsert(form);
  };

  const handleInsert = async (values: any) => {
    console.log(values);

    setIsLoading(true);
    try {
      const result = await request("/office/projects/create", "POST", {
        ...values,
      });

      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (values: any) => {
    setIsLoading(true);
    try {
      const result = await request(`/office/projects/${projectId}`, "PATCH", {
        ...values,
      });

      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  const handleComeBack = () => {
    navigate("/admin/projects");
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={onFinish}
      >
        <div>
          <Form.Item label="Name" required>
            <Input
              value={form.description}
              disabled={projectId ? true : false}
              onChange={(e) => updateForm("description", e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Objective" required>
            <Input
              disabled={projectId ? true : false}
              value={form.objectives}
              onChange={(e) => updateForm("objectives", e.target.value)}
            />
          </Form.Item>

          <Form.Item label="Start" required>
            <DatePicker
              allowClear={false}
              value={form.startDate}
              onChange={(e) => updateForm("startDate", e)}
            />
          </Form.Item>
          <Form.Item
            label="End"
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  const start = new Date(getFieldValue("startDate"));
                  const end = new Date(value);

                  if (start > end) {
                    return Promise.reject(
                      "End date must be greater than start date"
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <DatePicker
              allowClear={false}
              value={form.endDate}
              onChange={(e) => updateForm("endDate", e)}
            />
          </Form.Item>

          <Form.Item label="Client" required>
            <Select
              disabled={projectId ? true : false}
              allowClear
              options={clients}
              defaultValue={form.client_id}
              onChange={(e) => updateForm("client_id", e)}
            />
          </Form.Item>

          <Form.Item label="Is active" required>
            <Switch
              checked={form.isActive}
              onChange={(e) => updateForm("isActive", e)}
            />
          </Form.Item>
        </div>

        <>
          <Button type="primary" style={{ margin: 10 }} htmlType="submit">
            {projectId ? "Update" : "Submit"}
          </Button>
        </>
      </Form>
    </>
  );
};

export default ProjectForm;
