export const EMPTY_USER = {
  isActive: true,
  uid: "",
  alias: "",
  firstName: "",
  lastName: "",
  isExternalUser: false,
  isTestAccount: false,
  email: "",
  contactNumber: "",
  role_id: "",
  contactNetwork_id: null,
  hierarchy_id: null,
  project_id: null,
  region_id: null,
  isDisabled: false,
  isC3DUser: false,
  isM2User: false,
  isAxonifyUser: false,
};
