import { useEffect, useState } from "react";
import { Form, Input, Button, Typography, Switch } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import useApiPost from "../../../../hooks/useApiPost";
import { toast } from "react-toastify";

const DropOffReasonForm = () => {
    const { dropOffReasonId } = useParams<{ dropOffReasonId: string }>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [isActive, setIsActive] = useState<boolean>(false);
    const navigate = useNavigate();
    const headerText = dropOffReasonId ? "Edit Drop Off Reason" : "Add Drop Off Reason";
    const { request, setError } = useApiPost();

    const fetchClientData = async () => {
        setIsLoading(true);
        if (!dropOffReasonId) {
            setIsLoading(false);
            return;
        }
        const { data } = await request(`/office/dropoffreasons/${dropOffReasonId}`, "GET", null, undefined, "v2");
        if (!data) {
            navigate(`/admin`);
            return;
        }
        const { dropoffReason, isActive } = data;
        setName(dropoffReason);
        setIsActive(isActive);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchClientData();
    }, [dropOffReasonId]);


    const handleComeBack = () => navigate("/admin/dropoffreason");

    const handleInsert = async () => {
        setIsLoading(true);
        try {
            let pattern = new RegExp(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/)
            if (!name.trim()) return alert("Please enter Drop Off Reason!")
            if (!pattern.test(name)) return alert("Only Characters & Numbers allowed")

            const res = await request("/office/dropoffreasons/add", "POST", {
                name,
                isActive
            }, undefined, "v2");

            setIsLoading(false);

            if (res.error) {
                alert(res.error);
                return;
            }
            handleComeBack();

            toast.info("Drop Off Reason has been added successfully.", {
                position: "top-right",
            });
        } finally {
            setIsLoading(false);
        }
    };



    const handleUpdate = async () => {
        setIsLoading(true);
        try {
            if (!dropOffReasonId) return;
            let res = await request("/office/dropoffreasons/edit", "PATCH", {
                dropOffReasonId,
                isActive,
            }, undefined, "v2");
            setIsLoading(false);
            handleComeBack();
            if (res?.status == 400) {
                return toast.info(res?.message, {
                    position: "top-right",
                });
            }
            toast.info(`Record marked ${isActive?'active':'inactive'} successfully.`, {
                position: "top-right",
            });
        } finally {
            setIsLoading(false);
        }
    };


    if (isLoading) {
        return <p>Loading...</p>;
    }

    return (
        <>
            <Typography.Title>{headerText}</Typography.Title>
            <Form labelCol={{ span: 4 }}>
                <Form.Item label="Name">
                    {dropOffReasonId ? (
                        <div>{name}</div>
                    ) : (
                        <Input value={name} onChange={(e) => setName(e.target.value)} />
                    )}
                </Form.Item>
                <Form.Item label="Is active" valuePropName="isActive" name="isActive">
                    <Switch defaultChecked={isActive} onChange={(e) => setIsActive(e)} />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={dropOffReasonId ? handleUpdate : handleInsert}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default DropOffReasonForm;
