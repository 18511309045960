import { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Typography, Switch, Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
// import {
//   insertPhoneNumber,
//   getPhoneNumberDetails,
//   updatePhoneNumber,
// } from "../../../../api/office/phone-numbers";
import { AppContext } from "../../../../App";
import useApiPost from "../../../../hooks/useApiPost";

const PhoneNumbersForm = () => {
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [number, setNumber] = useState<string>("");
  const [outletType, setOutletType] = useState<string>("Post-Paid");
  const { request, setError } = useApiPost();

  const { projectId } = useContext(AppContext);

  const [isActive, setIsActive] = useState<boolean>(false);
  const navigate = useNavigate();
  const headerText = id ? "Edit Phone Number" : "Add Phone Number";

  const fetchData = async () => {
    setIsLoading(true);
    if (!id) {
      setIsLoading(false);
      return;
    }
    const { phoneNumber } = await request(`/office/phone-numbers/${id}`);
    if (!phoneNumber) {
      navigate(`/admin`);
      return;
    }
    const { name, outletType, number } = phoneNumber;
    setName(name);
    setOutletType(outletType);
    setNumber(number);
    setIsActive(isActive);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleComeBack = () => navigate("/admin/phone-numbers");

  const handleInsert = async () => {
    try {
      const res = await request("/office/phone-numbers/insert", "POST", {
        name,
        number,
        outletType,
        projectId,
      });
      if (res.error) {
        alert(res.error);
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };
  const handleUpdate = async () => {
    try {
      if (!id) return;
      // await updatePhoneNumber({
      //   id,
      //   name,
      //   number,
      //   outletType,
      // });
      await request("/office/phone-numbers/update", "POST", {
        id,
        name,
        number,
        outletType,
      });
      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  const outletTypeOptions = [
    { label: "Pre-Paid", value: "Pre-Paid" },
    { label: "Post-Paid", value: "Post-Paid" },
  ];

  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Form labelCol={{ span: 4 }}>
        <Form.Item label="Name">
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        </Form.Item>
        <Form.Item label="Number">
          <Input value={number} onChange={(e) => setNumber(e.target.value)} />
        </Form.Item>
        <Form.Item label="Outlet Type">
          <Select
            options={outletTypeOptions}
            disabled={isLoading}
            onChange={(e) => setOutletType(e)}
            defaultValue={outletType}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            onClick={id ? handleUpdate : handleInsert}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default PhoneNumbersForm;
