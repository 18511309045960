// import React from "react";
// import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";

// require("dotenv").config();

// const rootEl = document.getElementById("root");

// const renderApp = () => {
//   render(
//     <React.Suspense fallback={<div >loading</div>}>
//       <App />
//     </React.Suspense>,
//     rootEl
//   );
// };

// renderApp();

// // (async () => renderApp(await checkLoggedIn()))();

// import { createRoot } from 'react-dom/client';
// const container = document.getElementById('root') as any;
// const root = createRoot(container); // createRoot(container!) if you use TypeScript
// root.render(<App  />);
// import { render } from 'react-dom';
// import React from "react";
// import { checkLoggedIn } from "./api/utils";

// const rootEl = document.getElementById('root');

// const renderApp = () => {
//   render(
//       <React.Suspense fallback={<div />}>
//         <App state={{}}/>
//       </React.Suspense>,
//     rootEl
//   );
// };

// async () => renderApp()

import { createRoot } from "react-dom/client";
import { AuthContextProvider } from "./store/auth/AuthContextProvider";

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <BrowserRouter>
    <AuthContextProvider>
      <App tab="home" />
    </AuthContextProvider>
  </BrowserRouter>
);
