import { useEffect, useState } from "react";
import { Space, Table, Button, Switch } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import useApiPost from "../../../../hooks/useApiPost";

interface DataType {
  id: string;
  isActive: boolean;
  name: string;
  abbreviation: string;
}

interface ColumnType {
  onUpdate: (id: string) => void;
  onDelete: (id: string) => void;
}
const columns = (props: ColumnType): ColumnsType<DataType> => [
  {
    title: "Active",
    dataIndex: "isActive",
    key: "isActive",
    render: (val) => (
      <div>
        <Switch defaultChecked={val} />
      </div>
    ),
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Abbreviation",
    dataIndex: "abbreviation",
    key: "abbreviation",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    render: (_, record) => (
      <Space size="middle" key={record.id}>
        <Button type="primary" onClick={() => props.onUpdate(record.id)}>
          Update
        </Button>
        <Button type="primary" danger onClick={() => props.onDelete(record.id)}>
          Delete
        </Button>
      </Space>
    ),
  },
];

const ClientsPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  let navigate = useNavigate();
  const [allClients, setAllClients] = useState<DataType[]>([]);
  const { request, setError } = useApiPost();

  useEffect(() => {
    const getAllClients = async () => {
      setLoading(true);
      try {
        const data = await request("/accounts/all");
        setAllClients(data);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    getAllClients();
  }, []);

  const handleUpdate = async (id: string) => {
    navigate(`/admin/accounts/view/${id}`);
  };

  const handleDelete = async (id: string) => {
    await request(`/accounts/${id}`, "DELETE");
  };

  const handleTo = () => {
    navigate("/admin/accounts/new");
  };

  return (
    <div>
      <h3>Clients</h3>
      <br />
      <>
        <Table
          columns={columns({
            onUpdate: handleUpdate,
            onDelete: handleDelete,
          })}
          dataSource={allClients}
          rowKey="key"
        />

        <Button type="primary" onClick={handleTo}>
          Add
        </Button>
      </>
    </div>
  );
};

export default ClientsPage;
