import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import "antd/dist/antd.css";
import { Col, Row, Form, Button, Typography, Select } from "antd";
import { FormEvent } from "react";
import { EMPTY_CALL } from "../../../../utils/call";
import CalendarComponent from "../../../Calendar/Calendar";
import { today, getLocalTimeZone, Time } from "@internationalized/date";
import { TimeField } from "@adobe/react-spectrum";
import { AppContext } from "../../../../App";
import { IOption } from "../types";
import useApiPost from "../../../../hooks/useApiPost";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AddCallForm = () => {
  const appContext = useContext(AppContext);
  const { request, setError } = useApiPost();
  const { projectId } = appContext;
  const [, setIsLoading] = useState(false);
  const [allCallTypes, setAllCallTypes] = useState<any[]>([]);
  const [allOutlets, setAllOutlets] = useState<any[]>([]);
  const [form, setForm] = useState(EMPTY_CALL);
  const [date, setDate] = useState(today(getLocalTimeZone()));
  const [time, setTime] = useState(new Time(10, 0));
  const [assigness, setAssigness] = useState<IOption[]>([]);
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [resultInfo, setResultInfo] = useState<string | null>(null);

  // get id from route
  const { userId: selectedAssigneeId } = useParams<{ userId: string }>();

  useEffect(() => {
    if (date && time) {
      const { hour, minute } = time;
      let selectedDateWithTime = new Date(
        date.toDate(getLocalTimeZone()).setHours(hour, minute)
      );
      return updateForm("planned_at", selectedDateWithTime);
    }
  }, [date, time]);

  let navigate = useNavigate();

  const fetchAssigness = async () => {
    setIsLoading(true);
    const { assigness } = await request(
      "/office/calls/options/assignees",
      "POST",
      {
        projectId,
      }
    );
    setAssigness(assigness);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAssigness();
  }, []);

  const fetchOptions = async () => {
    setIsLoading(true);
    const { callTypes, outlets } = await request(
      "/office/calls/options/creation/call",
      "POST",
      {
        projectId,
        assigneeId: selectedAssigneeId,
      }
    );
    // const response = await getCreationOptions(projectId, selectedAssigneeId);
    setAllCallTypes(callTypes);
    setAllOutlets(outlets);
    setIsLoading(false);
  };

  useEffect(() => {
    if (selectedAssigneeId) {
      fetchOptions();
    }
  }, [selectedAssigneeId]);

  const handleComeBack = () => {
    navigate("/admin/calls");
  };

  const handleAddAnotherCallType = () => {
    setResultInfo(null);
    setForm(EMPTY_CALL);
  };

  const updateForm = (key: string, value: any) => {
    setForm((form) => ({
      ...form,
      [key]: value,
    }));
  };

  const handleSelectDate = (event: any) => {
    updateForm("planned_at", event.toDate());
    setDate(event);
  };

  const sendForm = async (values: FormEvent) => {
    setLoading(true);
    const formattedDate = new Date(form.planned_at);
    //set hours
    formattedDate.setHours(time.hour, time.minute);
    updateForm("planned_at", formattedDate);
    try {
      const data = await request("/office/calls/insert", "POST", {
        ...form,
        projectId,
        assigneeId: selectedAssigneeId,
      });

      setLoading(false);
      setResultInfo(`${data.name} added with ID ${data.id}.`);
      handleComeBack();
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (resultInfo !== null) {
    return (
      <>
        <Row justify="space-between">
          <Col span={18} push={4}>
            <Typography.Title>
              A new call has been successfully added to the database.
            </Typography.Title>
            <Button
              type="primary"
              style={{ margin: 10 }}
              onClick={handleAddAnotherCallType}
            >
              Add another one
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  // const { day, month, year } = date;
  // const { hour, minute } = time;

  const handleGoToUserDiary = (id: string) => {
    const first = location.pathname.split("/")[1];
    navigate(`/${first}/diary/${id}`);
  };

  return (
    <>
      <Typography.Title>Adding a call</Typography.Title>
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={sendForm}
      >
        <Form.Item label="Assignee" name="assignee_id">
          <Select
            // showSearch
            // loading={isLoading}
            options={assigness}
            style={{ width: 200 }}
            defaultValue={selectedAssigneeId}
            onChange={(_value, e) => {
              handleGoToUserDiary(_value);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Call type"
          hasFeedback
          rules={[{ required: true, message: "Required to proceed!" }]}
        >
          <Select
            onChange={(e: any) => updateForm("callTypeId", e)}
            options={allCallTypes}
          // onChange={(_value, e) => {
          //   handleOptionChange("callType_id", e);
          // }}
          />
        </Form.Item>
        <Form.Item
          label="Outlet"
          hasFeedback
          rules={[{ required: true, message: "Required to proceed!" }]}
        >
          <Select
            onChange={(e: any) => updateForm("outletId", e)}
            options={allOutlets}
            showSearch
            filterOption={(input, option) =>
              (option?.label?.toString() ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          label="Call Time"
          name="time"
          hasFeedback
          rules={[{ required: true, message: "Required to proceed!" }]}
        >
          <TimeField
            hourCycle={24}
            hideTimeZone
            value={time}
            onChange={(e: any) => setTime(e)}
            aria-label="Pick time"
            defaultValue={undefined}
          />
        </Form.Item>

        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <CalendarComponent
            defaultValue={date}
            onChange={(e: any) => handleSelectDate(e)}
          />
        </Form.Item>
        {/* Selected: {day}/{month}/{year}, {hour}:{minute} */}
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button type="primary" htmlType="submit">
            ADD
          </Button>
          <Button
            type="primary"
            style={{ margin: 10 }}
            onClick={handleComeBack}
          >
            MENU
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddCallForm;
