/* ui/src/store/auth/AuthContextProvider.tsx */

// Global imports
import React, {
  createContext,
  useReducer,
  useCallback,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";

// Project dependencies
import { AuthActionEnum } from "./authActions";
import authReducer, { AuthState, defaultAuthState } from "./authReducer";
import { getAuth } from "firebase/auth";
import { instance } from "../../api/common-api";

type AuthProviderProps = {
  children: React.ReactElement;
};

export type UserData = {
  uid: string;
  email: string;
  // authToken: string;
  role: string;
  token: string;
  access: string[];
  projectAccess: string[];
  passwordAgeInDays: number;
  forcePassNotify: boolean;
  passNotify: boolean;
  name: string
};

export interface AuthContext {
  authState: AuthState;
  globalLogInDispatch: (props: UserData) => void;
  globalLogOutDispatch: () => void;
  globalTokenDispatch: (props: UserData) => void;
  setLoading: (isLoading: boolean) => void;
}

// Auth context
const authCtx = createContext<AuthContext>({
  authState: defaultAuthState,
  globalLogInDispatch: () => { },
  globalLogOutDispatch: () => { },
  globalTokenDispatch: () => { },
  setLoading: () => { },
});

export const AuthContextProvider = (props: AuthProviderProps) => {
  const { children } = props;

  const [authState, authDispatch] = useReducer(authReducer, defaultAuthState);
  const navigate = useNavigate();

  // Check if user detail is persisted, mostly catering for refreshing of the browser
  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      const userData: UserData = JSON.parse(user);
      authDispatch({ type: AuthActionEnum.LOG_IN, payload: userData });
    }
  }, []);

  const globalLogInDispatch = useCallback(
    (props: UserData) => {
      const {
        uid,
        email,
        role,
        token,
        access,
        projectAccess,
        passwordAgeInDays,
        forcePassNotify,
        passNotify,
        name
      } = props;
      instance.defaults.headers.common["Authorization"] = token;

      authDispatch({
        type: AuthActionEnum.LOG_IN,
        payload: {
          uid,
          email,
          role,
          token,
          access,
          projectAccess,
          passwordAgeInDays,
          forcePassNotify,
          passNotify,
          name
          // authToken
        },
      });
      const isLoginScreen = window.location.pathname === "/login";
      if (isLoginScreen) {
        navigate("/admin");
      }
    },
    [navigate]
  );

  /* 
    THIS FUNCTION WILL BE UPDATE ONLY TOKEN IN REDUX STORE.
    All params will be used for saving data in local Storage.
  */

  const globalTokenDispatch = useCallback(
    (props: UserData) => {
      const {
        uid,
        email,
        role,
        token,
        access,
        projectAccess,
        passwordAgeInDays,
        forcePassNotify,
        passNotify,
      } = props;
      instance.defaults.headers.common["Authorization"] = token;

      authDispatch({
        type: AuthActionEnum.UPDATE_TOKEN,
        payload: {
          uid,
          email,
          role,
          token,
          access,
          projectAccess,
          passwordAgeInDays,
          forcePassNotify,
          passNotify,
          
          // authToken
        },
      });
    },
    []
  );

  const setLoading = useCallback((isLoading: boolean) => {
    authDispatch({
      type: AuthActionEnum.SET_LOADING,
      payload: isLoading,
    });
  }, []);

  const globalLogOutDispatch = useCallback(() => {
   
    localStorage.removeItem("user");
    const auth = getAuth();
    auth.signOut();
    localStorage.setItem('AuthToken', ``);
     authDispatch({ type: AuthActionEnum.LOG_OUT, payload: null });
    navigate("/login");
  }, [navigate]);

  // context values to be passed down to children
  const ctx = {
    authState,
    globalLogInDispatch,
    globalLogOutDispatch,
    globalTokenDispatch,
    setLoading,
  };

  return <authCtx.Provider value={ctx}>{children}</authCtx.Provider>;
};

export default authCtx;
