import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Button,
  Typography,
  DatePicker,
  Table,
  Input,
} from "antd";
import { AppContext } from "../../../../App";
import readXlsxFile from "read-excel-file";
import useApiPost from "../../../../hooks/useApiPost";
import { SheetDataSet } from "./axonify-types";
import {
  handleEndDateToDateOnly,
  handleStartDateToDateOnly,
} from "../../../../utils/dateTime";
import { toast } from "react-toastify";

const AxonifyForm = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [name, setName] = useState<any>(null);
  const { request, setError } = useApiPost();

  const appContext = useContext(AppContext);

  const [newEntries, setNewEntries] = useState<any[]>([]);

  const { projectId } = appContext;
  const headerText = "Import Axonify Data";
  const navigate = useNavigate();

  const handleComeBack = () => {
    navigate("/admin/axonify");
  };

  const handleSave = async () => {
    if (!projectId || !startDate || !endDate) {
      return;
    }
    if(newEntries.length === 0) {
      notifyError();
      return;
    }
    setIsLoading(true);

    await request("/office/axonify/insert", "POST", {
      projectId: projectId,
      name: name,
      validFromDate: startDate,
      validToDate: endDate,
      axonifyData: newEntries,
    });
    notifySuccess();
    setIsLoading(false);
    navigate(`/admin/axonify`);
  };
  const notifyError = () => {
    toast.error("Please choose excel file", {
      position: "bottom-center"
    });
  };

  const notifySuccess = () => {
    toast.info("File uploaded successfully", {
      position: "bottom-center"
    });
  };


  const handleUploadBatchFile = async (e: any) => {
    const file = e.target.files[0];
    await readXlsxFile(file).then(async (rows) => {
      let filteredData: any = [];
      const encounteredCombinations = new Set();

      rows.forEach((item: any, index: number) => {
        if (index > 0) {
          const retailCloudId = item[0]?.toString().trim();
          const topicName = item[1]?.toString().trim();
          const difficultyLevel = item[2]?.toString().trim();
          const topicLevelStatus = item[3]?.toString().trim();
          const sheetDataRow: SheetDataSet = {
            retailCloudId,
            topicName,
            difficultyLevel,
            topicLevelStatus,
          };
          const combination =
            retailCloudId +
            topicName?.toLowerCase() +
            difficultyLevel +
            difficultyLevel;
          if (!encounteredCombinations.has(combination)) {
            filteredData.push(sheetDataRow);
            encounteredCombinations.add(combination);
          }
        }
      });
      if (filteredData.length > 0) {
        setNewEntries(filteredData);
      }
    });
  };
  if (isLoading) {
    return <p>Loading...</p>;
  }
  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={undefined}
      >
        <div>
          <Form.Item label="Name" name="name" required>
            <Input onChange={(e) => setName(e.target.value)} />
          </Form.Item>
          <Form.Item label="Start" name="startDate" required>
            <DatePicker
              allowClear={false}
              onChange={(e) => setStartDate(handleStartDateToDateOnly(e))}
            />
          </Form.Item>
          <Form.Item
            label="End"
            name="endDate"
            required
            rules={[
              {
                required: true,
                message: "Please select a form end",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  const start = new Date(getFieldValue("startDate"));
                  const end = new Date(value);

                  if (start > end) {
                    return Promise.reject(
                      "End date must be greater than start date"
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <DatePicker
              allowClear={false}
              onChange={(e) => setEndDate(handleEndDateToDateOnly(e))}
            />
          </Form.Item>
        </div>
        <Table
          rowKey="order"
          style={{
            fontSize: "12px",
          }}
          pagination={{
            pageSize: 10,
          }}
          dataSource={newEntries}
          columns={[
            {
              title: "Employee ID/Retail Cloud ID",
              dataIndex: "retailCloudId",
              key: "retailCloudId",
            },
            {
              title: "Topic Name",
              dataIndex: "topicName",
              key: "topicName",
            },
            {
              title: "Difficulty Level",
              dataIndex: "difficultyLevel",
              key: "difficultyLevel",
            },
            {
              title: "Topic Level Status",
              dataIndex: "topicLevelStatus",
              key: "topicLevelStatus",
            },
          ]}
        />
        <>
          <div>
            <input
              type="file"
              id="input"
              accept=".xlsx, .xls"
              onChange={(e) => {
                handleUploadBatchFile(e);
                setNewEntries([]);
              }}
            />
          </div>
          <Button
            type="primary"
            style={{ margin: 10 }}
            onClick={handleComeBack}
          >
            Go back
          </Button>

          <Button type="primary" style={{ margin: 10 }} onClick={handleSave}>
            Save
          </Button>
        </>
      </Form>
    </>
  );
};

export default AxonifyForm;
