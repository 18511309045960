import { Input } from "antd";
import * as S from "./AndtInput.styles";

interface IAndInput {
  label?: string;
  type?: string;
  placeholder?: string;
  defaultValue?: string;
  onChange?: (e: any) => void;
  onPressEnter?: (e: any) => void;
}

const styles = {
  borderRadius: "18px",
  padding: "8px",
};

const AndtInput = ({
  label,
  type,
  placeholder,
  defaultValue,
  onChange,
  onPressEnter,
}: IAndInput) => {
  return (
    <S.Wrapper>
      <S.Label>{label}</S.Label>
      <Input
        onPressEnter={onPressEnter}
        size="large"
        type={type}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={onChange}
        style={styles}
      />
    </S.Wrapper>
  );
};

export default AndtInput;
