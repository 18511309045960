import styled from "styled-components";

export const Prop = styled.div`
  display: flex;
`;
export const BoldText = styled.span`
  font-weight: bold;
  margin-right: 10px;
`;

export const Value = styled.span``;

export const Datetext = styled.div`
  font-weight: bold;
  margin-bottom: 20px;
`;

interface IScoreWrapper {
  score: number;
}

export const ScoreWrapper = styled.div<IScoreWrapper>`
  display: flex;
  flex-direction: row;
  text-align: center;
  color: ${(props) => {
    console.log({ props });
    if (props.score < 80) {
      return "#f5222d";
      // } else if (props.score < 90) {
      //   return "#faad14";
    } else {
      return "#52c41a";
    }
  }};
`;

export const AttemptsScores = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  text-align: center;
  width: 300px;
`;

export const AttemptRow = styled.div`
  display: flex;
`;
export const AttemptNumber = styled.div`
  margin-right: 10px;
  font-weight: bold;
`;
