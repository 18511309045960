import { useCallback, useEffect, useRef, useState } from "react";
import { Form, Input, Button, Typography, Switch } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import useApiPost from "../../../../hooks/useApiPost";
export const EMPTY_VENUE: any = {
  latitude: "",
  longitude: "",
  name: "",
};

const containerStyle = {
  width: "100%",
  height: "100%",
};
const TrainingVenueDetails = () => {
  const { venueId } = useParams<{ venueId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const navigate = useNavigate();
  const headerText = venueId ? "Edit Venue" : "Add Venue";
  const mapRef = useRef<any>(null);
  const [currentZoom, setCurrentZoom] = useState<number | undefined>(18);
  const { request, setError } = useApiPost();

  const [form, setForm] = useState(EMPTY_VENUE);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
  });
  const onUnmount = useCallback(function callback(map: any) {}, []);

  const fetchVenuesData = async () => {
    setIsLoading(true);
    if (!venueId) {
      setIsLoading(false);
      return;
    }
    const { venue } = await request("/office/training-venues/details", "POST", {
      id: venueId,
    });

    if (!venue) {
      navigate(`/admin`);
      return;
    }
    const { name, isActive } = venue;
    setForm({ ...venue });
    setName(name);
    setIsActive(isActive);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchVenuesData();
  }, [venueId]);

  const handleComeBack = () => navigate("/admin/training-venues");

  const handleInsert = async () => {
    try {
      const data = {
        name,
        isActive,
        latitude: form.latitude,
        longitude: form.longitude,
      };
      const res = await request("/office/training-venues/insert", "POST", {
        ...data,
      });
      if (res.error) {
        alert(res.error);
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      if (!venueId) return;
      const data = {
        venueId,
        name,
        isActive,
        latitude: form.latitude,
        longitude: form.longitude,
      };
      await request("/office/training-venues/update", "POST", {
        ...data,
      });
      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };
  if (isLoading || !isLoaded) {
    return <p>Loading...</p>;
  }
  const center =
    form.latitude && form.longitude
      ? { lat: Number(form.latitude), lng: Number(form.longitude) }
      : {
          lat: -26.0356601,
          lng: 28.0472975,
        };

  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Form labelCol={{ span: 4 }}>
        <Form.Item label="Name">
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        </Form.Item>
        <Form.Item label="Is active" valuePropName="isActive" name="isActive">
          <Switch defaultChecked={isActive} onChange={(e) => setIsActive(e)} />
        </Form.Item>
        <Form.Item label="Latitude">
          <Input
            type="text"
            value={form.latitude}
            onChange={(e) => {
              setForm({
                ...form,
                latitude: e.target.value,
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Longitude">
          <Input
            type="text"
            value={form.longitude}
            onChange={(e) => {
              setForm({
                ...form,
                longitude: e.target.value,
              });
            }}
          />
        </Form.Item>
        <div
          style={{
            width: 400,
            height: 400,
            marginBottom: 20,
            marginLeft: "10%",
          }}
        >
          <GoogleMap
            ref={mapRef}
            mapContainerStyle={containerStyle}
            center={center}
            onUnmount={onUnmount}
            zoom={currentZoom}
            onZoomChanged={() => {
              try {
                if (!mapRef.current) return;
                if (mapRef.current.getZoom) {
                  setCurrentZoom(mapRef.current.getZoom());
                }
              } catch (error) {
                console.log(error);
              }
            }}
            onClick={(e) => {
              setForm({
                ...form,
                latitude: e.latLng?.lat().toString() || "0",
                longitude: e.latLng?.lng().toString() || "0",
              });
            }}
          >
            <Marker
              position={{
                lat: Number(form.latitude),
                lng: Number(form.longitude),
              }}
            ></Marker>
          </GoogleMap>
        </div>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            onClick={venueId ? handleUpdate : handleInsert}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default TrainingVenueDetails;
