import styled from "styled-components";

export const CategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const SubCategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
`;

export const CategoryName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  margin-left: 4px;
  font-size: 16px;

  &:hover {
    cursor: pointer;
    background-color: grey;
    color: white;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;
