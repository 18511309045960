import styled, { keyframes } from "styled-components";

export const pulsar = keyframes`
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
`;

export const Pulsar = styled.div<{ isOnline: boolean }>`
  border: ${(props: any) => (props.isOnline ? "#62bd19" : "#62bd19")} 3px solid;
  border-radius: 30px;
  height: 20px;
  width: 20px;
  position: absolute;
  opacity: 0;
  animation: ${pulsar} 1s ease-out;
  border: ${(props: any) => (props.isOnline ? "#62bd19" : "#62bd19")} 3px solid;
  animation-iteration-count: ${(props: any) =>
    props.isOnline ? "infinite" : "0"};
`;


export const RedDot = styled.div`
  background-color: #ff0000;
  border-radius: 50%;
  height: 10px;
  width: 10px;
`;

