import styled from "styled-components";

export const MainWrapper = styled.div`
  /* height: 956px; */
`;

export const Navigation = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
`;
export const NavigationText = styled.div``;
export const NavigationItem = styled.div``;
export const ArrowLeft = styled.div``;
export const ArrowRight = styled.div``;

export const Wrapper = styled.div`
  padding: "16px 32px";
  display: flex;
  gap: 20px;
  max-width: 90vw;
  height: 100%;
`;

export const LogoImage = styled.img`
  max-width: 80px;
`;

export const SmallColumn = styled.div`
  flex: 1;
  text-align: center;
`;

export const BasicColumn = styled.div`
  flex: 3;
`;

export const PaddedPieChartContainer = styled.div`
  max-width: 100px;
`;

export const BasicBox = styled.div<{ color: string }>`
  padding: "10px";
  border-radius: "8px";
  padding: 8px;
  border: ${({ color }) => `4px solid ${color}`};
  margin-bottom: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 120px;
  justify-content: center;
`;
export const LargeDoubleBox = styled.div`
  padding: 10px;
  border: 4px solid #e6e8e8;
  display: flex;
  border-radius: 8px;
  margin-bottom: 22px;
  justify-content: space-around;
`;

export const LargeDoubleBoxColumn = styled.div`
  padding: "10px";
`;

export const LargeBox = styled.div`
  padding: "10px";
`;
export const LargeBoxTitle = styled.div``;
export const LargeBoxValue = styled.div`
  text-align: center;
`;

export const BasicBoxTitle = styled.span<{ color: string }>`
  color: ${({ color }) => color};
  text-align: center;
`;

export const BasicBoxValue = styled.span``;

export const FilterBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;

export const DashboardFilter = styled.div`
  display: flex;
  justify-content: space-around;
  background-color: #faf8e4;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid #d5d5d5;
  position: absolute;
  z-index: 2;
  right: 0;
  width: 800px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  flex-direction: column;
`;

export const RegionWrapper = styled.div``;

export const FilterLabel = styled.div``;

export const PeriodWrapper = styled.div``;

export const DatePicker = styled.div``;

export const FilterButton = styled.div`
  position: absolute;
  right: 20px;
  z-index: 1;
`;

export const FilterApplyButton = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const CurrentFilter = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const FilterItem = styled.span`
  margin-right: 10px;
`;

export const StockRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const StockRow2 = styled(StockRow)`
  flex-direction: row;
`;

export const StocksGroup = styled.div`
  display: flex;
`;
