import { useEffect, useRef, useState } from "react";
import { Form, Input, Button, Typography, Select, Alert } from "antd";
import { useNavigate, useParams } from "react-router-dom";

// import {
//   addNewMessage,
//   getTicketDetails,
//   updateTicketStatus,
//   verifyTicketCompletion,
// } from "../../../../api/office/tickets";
import { getAuth } from "firebase/auth";
import * as S from "./SupportTicket.styled";
import { FaDownload } from "react-icons/fa";
import TextArea from "antd/es/input/TextArea";
import { Image } from "antd";
// import { getFile } from "../../../../api/office/downloads";
import useApiPost from "../../../../hooks/useApiPost";

interface IMessage {
  created_at: string;
  message: string;
  imageUrl: string;
  uid: string;
  alias: string;
  fileUrl: string;
}

const statusOptions = [
  { label: "Open", value: "Open" },
  { label: "In Progress", value: "In Progress" },
  { label: "Feedback required", value: "Feedback required" },
  { label: "Answer required", value: "Answer required" },
  { label: "Resolved", value: "Resolved" },
  { label: "Closed", value: "Closed" },
];

const SupportTicketDetails = () => {
  const auth = getAuth();
  const { request, setError } = useApiPost();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const ref = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<FileList>();

  const uid = auth.currentUser?.uid;

  const [description, setDescription] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [estimate, setEstimate] = useState<number>(0);
  const [type, setType] = useState<string>("");
  const [newMessage, setNewMessage] = useState<string>("");
  const [priority, setPriority] = useState<string>("");
  const [messages, setMessages] = useState<IMessage[]>([]);

  const navigate = useNavigate();
  const handleComeBack = () => navigate("/admin/support/tickets");

  const params = useParams();
  const { ticketId } = params;

  const fetchTicketInfo = async () => {
    console.log("hey", ticketId);
    if (!ticketId) return;
    setIsLoading(true);
    // const ticket = await getTicketDetails(ticketId);
    const ticket = await request(`/office/support/tickets/details/${ticketId}`);
    setTitle(ticket.title);
    setStatus(ticket.status);
    setDescription(ticket.description);
    setType(ticket.type);
    setPriority(ticket.priority);
    setMessages(ticket.messages);
    setEstimate(ticket.estimate);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTicketInfo();
  }, [ticketId]);

  const onChangeSingleFile = (e: any) => {
    setFile(e.target.files);
  };
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const handleSendNewMessage = async () => {
    if (!ticketId) return;
    setIsLoading(true);

    const formData = new FormData();

    formData.append("ticket_id", ticketId);
    formData.append("message", newMessage);
    formData.append("uid", uid || "");
    if (file) {
      formData.append("file", file[0]);
    }

    await request(
      "/office/support/tickets/message/new",
      "POST",
      formData,
      true
    );
    setNewMessage("");
    setFile(undefined);
    ref.current!.value = "";
    await fetchTicketInfo();
    setIsLoading(false);
  };
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      block: "end",
    });
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  const isSendEnabled = newMessage.length > 0 || file;

  const isSupport = auth.currentUser?.email?.includes("rc20@publicis.co.za");

  const handleAdminSubmit = async () => {
    if (!ticketId) {
      return alert("no ticket id found");
    }

    await request("/office/support/tickets/admin/update", "POST", {
      status,
      estimate,
      ticket_id: ticketId,
    });
    handleGoBack();
  };

  const handleVerifyTicketCompletion = async (isWorking: boolean) => {
    if (!ticketId) {
      return;
    }
    await request("/office/support/tickets/verify", "POST", {
      ticket_id: ticketId,
      isWorking,
    });
    // await verifyTicketCompletion(ticketId, isWorking);
    handleGoBack();
  };

  const isAwaitingFeedback = status === "Feedback required";

  const handleGoBack = () => {
    navigate("/admin/support/tickets");
  };

  const handleDownload = async (url: string) => {
    // const delegateData = await request(
    //   `/office/presigned-url`,
    //   "POST",
    //   {
    //     key: url,
    //   }
    // );
    // const fileUrl = await getFile(url);
    // console.log({ fileUrl });
  };

  return (
    <>
      <Typography.Title>Ticket details</Typography.Title>
      <Form
        labelCol={{ span: 4 }}
        style={{
          maxWidth: "800px",
        }}
        size="small"
      >
        <Form.Item label="Status">{status}</Form.Item>
        <Form.Item label="Title">{title}</Form.Item>
        <Form.Item label="Description">
          <TextArea readOnly value={description} rows={8} />
        </Form.Item>
        {/* {description} */}
        <Form.Item label="Type">{type}</Form.Item>
        <Form.Item label="Priority">{priority}</Form.Item>
        {isAwaitingFeedback && (
          <Alert
            message="Information"
            description="This issue has been deployed. Please confirm or reject it's completion."
            showIcon
            type="success"
            style={{ marginBottom: 20 }}
          />
        )}
        <div>
          <span
            style={{
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            Chat
          </span>
          <S.ChatBox>
            {isLoading
              ? null
              : messages.map((message, index) => {
                  const isIncoming = message.uid === uid;

                  if (isIncoming)
                    return (
                      <div key={index}>
                        <S.IncomingTicketBox>
                          <div>{isSupport ? "Support" : message.alias}</div>
                          <div
                            style={{
                              fontSize: "10px",
                              color: "#999",
                            }}
                          >
                            {new Date(message.created_at).toLocaleString()}
                          </div>
                          <S.IncomingTicketMessage>
                            <TextArea
                              value={message.message}
                              autoSize
                              style={{
                                backgroundColor: "rgb(223 225 227)",
                                border: "none",
                              }}
                            />
                            {message.fileUrl && (
                              <>
                                <div
                                  style={{
                                    marginBottom: 10,
                                  }}
                                >
                                  <Image src={message.fileUrl} width={200} />
                                </div>
                                <S.DownloadFile
                                  onClick={() =>
                                    handleDownload(message.fileUrl)
                                  }
                                >
                                  <div>
                                    <span>Download file</span>
                                    <FaDownload
                                      size={8}
                                      style={{
                                        marginLeft: "5px",
                                      }}
                                    />
                                  </div>
                                </S.DownloadFile>
                              </>
                            )}
                          </S.IncomingTicketMessage>
                        </S.IncomingTicketBox>
                      </div>
                    );

                  return (
                    <div>
                      <S.OutgoingTicketBox>
                        <div>{isSupport ? "Support" : message.alias}</div>
                        <div
                          style={{
                            fontSize: "10px",
                            color: "#999",
                          }}
                        >
                          {new Date(message.created_at).toLocaleString()}
                        </div>
                        <S.OutgoingTicketMessage>
                          <TextArea
                            value={message.message}
                            autoSize
                            style={{
                              backgroundColor: "#eaf4ff",
                              border: "none",
                            }}
                          />
                          {message.fileUrl && (
                            <>
                              <div
                                style={{
                                  marginBottom: 10,
                                }}
                              >
                                <Image src={message.fileUrl} width={200} />
                              </div>
                              <S.DownloadFile
                                onClick={() => handleDownload(message.fileUrl)}
                              >
                                <div>
                                  <span>Download file</span>
                                  <FaDownload
                                    size={8}
                                    style={{
                                      marginLeft: "5px",
                                    }}
                                  />
                                </div>
                              </S.DownloadFile>
                            </>
                          )}
                        </S.OutgoingTicketMessage>
                      </S.OutgoingTicketBox>
                    </div>
                  );
                })}
            <div ref={messagesEndRef} />
          </S.ChatBox>
          <div>
            <TextArea
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Write a message..."
              rows={4}
              style={{
                marginBottom: "10px",
              }}
            />
            <input
              ref={ref}
              type="file"
              name="file"
              onChange={onChangeSingleFile}
              disabled={file && file?.length > 0}
              // accept="image/*"
              style={{
                marginBottom: "10px",
              }}
            />
            <Button
              onClick={() => handleSendNewMessage()}
              disabled={!isSendEnabled}
            >
              Send
            </Button>
          </div>
        </div>
      </Form>
      {isAwaitingFeedback && (
        <div
          style={{
            marginTop: "20px",
          }}
        >
          <Button onClick={() => handleVerifyTicketCompletion(true)}>
            Confirm
          </Button>
          <Button onClick={() => handleVerifyTicketCompletion(false)}>
            Reject
          </Button>
        </div>
      )}
      <div>
        {isSupport && (
          <div
            style={{
              maxWidth: "300px",
              marginTop: "20px",
            }}
          >
            <div>
              <span>status</span>
              <Select
                style={{
                  width: "100%",
                }}
                onChange={(e: any) => setStatus(e)}
              >
                {statusOptions.map((option, index) => (
                  <Select.Option value={option.value} key={index}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div>
              <span>Allocated time</span>
              <Input
                type="number"
                value={estimate}
                onChange={(e) => setEstimate(Number(e.target.value))}
              />
            </div>
            <Button onClick={handleAdminSubmit}>Submit</Button>
          </div>
        )}
      </div>
    </>
  );
};

export default SupportTicketDetails;
