import axios from "axios";

export const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
export const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
    // authorization: "mZSv7GCUER&4$kIi8TM6oEQm",
  },
  baseURL: BASE_URL,
  withCredentials: true,
});

axios.defaults.withCredentials = true;
