import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB7vvBVpmEM83bnkrqeubXr2dpqkjsvxyU",
  authDomain: "rc20-c7fb7.firebaseapp.com",
  projectId: "rc20-c7fb7",
  storageBucket: "rc20-c7fb7.appspot.com",
  messagingSenderId: "269785622226",
  appId: "1:269785622226:web:4e7bbe936341830c89af0b",
  measurementId: "G-QG46M6JBPN",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;