import { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
// import "antd/dist/antd.css";
import {
  Button,
  Typography,
  Table,
  Switch,
  Space,
  InputRef,
  Input,
} from "antd";
import { AppContext } from "../../../../App";
import useApiPost from "../../../../hooks/useApiPost";
import { ColumnsType, ColumnType } from "antd/lib/table";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import type { FilterConfirmProps } from "antd/es/table/interface";
import { FcExpired } from "react-icons/fc";

interface DataType {
  id: string;
  key: string;
  name: string;
  isDraft: boolean;
}

const StrategicAudit = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  type DataIndex = keyof DataType;
  const searchInput = useRef<InputRef>(null);
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: any) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const exists = record[dataIndex] ? record[dataIndex] : false;

      if (!exists) {
        return false;
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        //@ts-ignore
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = (props: any): ColumnsType<DataType> => [
    {
      title: "Active",
      dataIndex: "isActive",
      render: (val) => (
        <div>
          <Switch checked={val} disabled />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Valid From",
      dataIndex: "validFrom",
      key: "validFrom",
      render: (date) => <div>{`${new Date(date).toDateString()}`}</div>,
    },
    {
      title: "Valid To",
      dataIndex: "validTo",
      key: "validTo",
      render: (date) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>{`${new Date(date).toDateString()}`}</div>
          {new Date(date) < new Date() && (
            <div
              style={{
                display: "flex",
              }}
            >
              <FcExpired size={20} />
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <Space size="middle" key={record.id}>
          <Button
            type="primary"
            onClick={() => {
              record?.isDraft
                ? props.onDraftUpdate(record.id)
                : props.onUpdate(record.id);
            }}
          >
            {record?.isDraft ? "Edit Draft" : "Update"}
          </Button>
        </Space>
      ),
    },
  ];
  const appContext = useContext(AppContext);
  const { projectId } = appContext;
  const [strategicAudits, setStrategicAudits] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const headerText = "Strategic Audits";
  const navigate = useNavigate();
  const { request, setError } = useApiPost();

  const fetchStrategicAudits = async () => {
    setIsLoading(true);
    const response = await request("/office/strategic-audits", "POST", {
      projectId,
    });
    getDraftStrategicAudits(response);
  };

  const getDraftStrategicAudits = async (response: any) => {
    const data = await request(
      `/office/strategic-audits/get_save_draft`,
      "POST",
      {
        projectId,
      }
    );
    if (data) {
      setStrategicAudits([...data, ...response]);
    } else {
      setStrategicAudits(response);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchStrategicAudits();
  }, [projectId]);

  const handleUpdate = async (id: string) => {
    navigate(`/admin/strategic-audit/edit/${id}`);
  };

  const handleDraftUpdate = async (id: string) => {
    navigate(`/admin/strategic-audit/edit/draft/${id}`);
  };

  const onCreateNew = async () => {
    navigate(`/admin/strategic-audit/new`);
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Table
        rowKey="order"
        style={{
          fontSize: "12px",
        }}
        pagination={{
          pageSize: 20,
        }}
        size="small"
        dataSource={strategicAudits}
        columns={columns({
          onUpdate: handleUpdate,
          onDraftUpdate: handleDraftUpdate,
          onDelete: () => {},
        })}
      />
      <Button type="primary" onClick={() => onCreateNew()}>
        ADD
      </Button>
    </>
  );
};

export default StrategicAudit;
