import { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Typography, Switch, Modal, Divider } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import useApiPost from "../../../../hooks/useApiPost";
import { AppContext } from "../../../../App";

const ChannelForm = () => {
  const { channelId } = useParams<{ channelId: string }>();
  const { projectId } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [chainName, setChainName] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const navigate = useNavigate();
  const headerText = channelId ? "Edit channel" : "Add channel";
  const [chains, setChains] = useState<any[]>([]);
  const { request } = useApiPost();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [confirmationText, setConfirmationText] = useState<string>("");
  const [selectedChainId, setSelectedChainId] = useState<string>("");
  const [action, setAction] = useState<"DISABLE_CHAIN" | "GENERIC">();

  const fetchchannelData = async () => {
    setIsLoading(true);
    if (!channelId) {
      setIsLoading(false);
      return;
    }
    const { channel } = await request(
      `/office/locations/channels/details`,
      "POST",
      {
        id: channelId,
        projectId,
      }
    );
    if (!channel) {
      navigate(`/admin`);
      return;
    }
    const { name, isActive, chains = [] } = channel;
    setChains(chains);
    setName(name);
    setIsActive(isActive);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchchannelData();
  }, [channelId]);

  const handleComeBack = () => navigate("/admin/channels");

  const handleInsert = async () => {
    try {
      setIsLoading(true);
      // const res = await addNewchannel(name, isActive);
      const res = await request("/office/locations/channels/insert", "POST", {
        name,
        isActive,
        projectId,
      });
      if (res.error) {
        setErrorMessage(res.error);
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  const handleInsertChain = async () => {
    try {
      const res = await request("/office/locations/chains/insert", "POST", {
        name: chainName,
        channelId,
        projectId,
        parent_id: channelId,
      });
      if (res.error) {
        setErrorMessage(res.error);
        setIsLoading(false);
        return;
      }

      await fetchchannelData();
      setIsLoading(false);
    } finally {
      setChainName("");
      setIsLoading(false);
    }
  };

  const toggleChainActive = async (id: string, isActive: boolean) => {
    let tempChains = [...chains];

    tempChains = tempChains.map((chain) => {
      if (chain.id === id) {
        return {
          ...chain,
          isActive,
        };
      }
      return chain;
    });
    setChains(tempChains);
  };

  const handleUpdate = async () => {
    try {
      if (!channelId) return;
      const res = await request("/office/locations/channels/update", "POST", {
        channelId,
        isActive,
        name,
      });

      if (res.error) {
        if (!isActive) {
          setIsActive(true);
        }
        setErrorMessage(res.error);
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalCancel = () => {
    setErrorMessage("");
    setConfirmationText("");
    if (action === "DISABLE_CHAIN") {
      toggleChainActive(selectedChainId, true);
      setSelectedChainId("");
    }
  };

  const isModalOpen = !!errorMessage || !!confirmationText;

  const handleConfirmOK = async () => {
    if (action === "DISABLE_CHAIN") {
      if (!selectedChainId) return;

      const res = await request(
        "/office/locations/chains/toggleActive",
        "POST",
        {
          chainId: selectedChainId,
          isActive: false,
        }
      );

      if (res.error) {
        setErrorMessage(res.error);
        setIsLoading(false);
        setAction(undefined);
        toggleChainActive(selectedChainId, true);

        return;
      }
    }
    setErrorMessage("");
    setConfirmationText("");
    setAction(undefined);
  };

  const handleChainToggleActive = async (
    id: string,
    isActive: boolean,
    name: string
  ) => {
    setSelectedChainId(id);
    if (isActive) {
      setIsLoading(true);
      await request("/office/locations/chains/toggleActive", "POST", {
        chainId: id,
        isActive: true,
      });
      toggleChainActive(id, true);
      setIsLoading(false);
      return;
    } else {
      toggleChainActive(id, false);
    }
    setConfirmationText(`Are you sure you want to disable ${name}?`);
    setAction("DISABLE_CHAIN");
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Modal
        title="Confirmation"
        open={isModalOpen}
        onOk={handleConfirmOK}
        onCancel={handleModalCancel}
        centered
        footer={[
          <Button key="back" onClick={handleModalCancel}>
            Back
          </Button>,
          <Button key="submit" type="primary" onClick={handleConfirmOK}>
            Confirm
          </Button>,
        ]}
      >
        <div>{errorMessage || confirmationText}</div>
      </Modal>
      <Typography.Title>{headerText}</Typography.Title>
      <Form labelCol={{ span: 4 }}>
        <Form.Item label="Name">
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        </Form.Item>
        <Form.Item label="Is active" valuePropName="isActive" name="isActive">
          <Switch checked={isActive} onChange={(e) => setIsActive(e)} />
        </Form.Item>

        <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              loading={isLoading}
              type="primary"
              htmlType="submit"
              onClick={channelId ? handleUpdate : handleInsert}
            >
              Submit
            </Button>
          </div>
        </Form.Item>

        <Divider />

        {chains && chains.length > 0 && (
          <div
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                marginBottom: "10px",
              }}
            >
              Linked Chains
            </div>
            {chains.map((chain) => (
              <div
                key={chain.id}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div>
                  <Switch
                    checked={chain.isActive}
                    onChange={(e) =>
                      handleChainToggleActive(chain.id, e, chain.name)
                    }
                    style={{
                      marginRight: "10px",
                    }}
                  />
                  {chain.name}
                </div>
              </div>
            ))}
          </div>
        )}
        {channelId && (
          <div
            style={{
              display: "flex",
              marginBottom: "10px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                minWidth: "100px",
              }}
            >
              Add new chain
            </div>
            <Input
              value={chainName}
              onChange={(e) => setChainName(e.target.value)}
              style={{
                marginRight: "10px",
                maxWidth: "400px",
              }}
            />
            <Button
              type="primary"
              onClick={() => {
                handleInsertChain();
              }}
            >
              Add
            </Button>
          </div>
        )}
      </Form>
    </>
  );
};

export default ChannelForm;
