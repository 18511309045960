import { useContext, useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Select,
  InputRef,
  Space,
  DatePicker,
} from "antd";
import { EMPTY_USER } from "../../../../utils/user";
import { AppContext } from "../../../../App";
import { User, IOption } from "./types";
import { DefaultOptionType } from "antd/lib/select";
import { useNavigate, useParams } from "react-router-dom";
import Table, { ColumnsType, ColumnType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import { FilterConfirmProps } from "antd/es/table/interface";
import Highlighter from "react-highlight-words";
import moment from "moment";
import useApiPost from "../../../../hooks/useApiPost";
import { handleStartDate } from "../../../../utils/dateTime";

interface DataType {
  id: string;
  key: string;
  name: string;
  photo: string;
  address1: string;
  latitude: string;
  longitude: string;
  isNewlyPlanned: boolean;
}

type DataIndex = keyof DataType;
// import "antd/dist/antd.css";

const DiaryManagement = () => {
  const { request, setError } = useApiPost();

  const { userId } = useParams<{ userId: string }>();
  const { projectId } = useContext(AppContext);
  const searchInput = useRef<InputRef>(null);
  const [allOutlets, setAllOutlets] = useState<DataType[]>([]);
  const [alreadyScheduledCalls, setAlreadyScheduledCalls] =
    useState<AlreadyScheduledCalls>({});

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [startDate, setStartDate] = useState<any>(null);
  const [initValues, setInitValues] = useState<User>(EMPTY_USER);
  const [assigness, setAssigness] = useState<IOption[]>([]);

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: any) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        //@ts-ignore
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  enum CallStatusOptions {
    None = "None",
    Planned = "Planned",
    Started = "Started",
    Completed = "Completed",
  }

  const getRowStatus = (row: DataType): CallStatusOptions => {
    const isFinished = isCallFinished(row);
    const isPlanned = isCallAlreadyPlanned(row);

    if (isFinished) return CallStatusOptions.Completed;
    if (isPlanned) return CallStatusOptions.Planned;
    return CallStatusOptions.None;
  };

  const getActionText = (rowStatus: CallStatusOptions) => {
    switch (rowStatus) {
      case CallStatusOptions.None:
        return "Plan";
      case CallStatusOptions.Planned:
        return "Planned Already";
      case CallStatusOptions.Started:
        return "Active";
      case CallStatusOptions.Completed:
        return "Finished";
      default:
        return "Plan";
    }
  };

  interface AlreadyScheduledCalls {
    [key: string]: {
      status: string;
      plannedCallStart: string;
      callType_id: string;
      customer_id: string;
    }[];
  }

  const columns = (props: any): ColumnsType<DataType> => [
    {
      title: "Business Site",
      dataIndex: "name",
      key: "name",
      render: (text) => <div>{text}</div>,
      ...getColumnSearchProps("name"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        const rowStatus = getRowStatus(record);

        return (
          <Space size="middle" key={record.id}>
            <Button
              loading={isLoading}
              disabled={
                rowStatus !== CallStatusOptions.None ||
                startDate === null ||
                formOptionValues.assignee_id === null ||
                formOptionValues.callType_id === null ||
                record.isNewlyPlanned
                //  ||  formOptionValues.cycle_id === null
              }
              type="primary"
              onClick={() => handleAction(rowStatus, record)}
            >
              {getActionText(rowStatus)}
            </Button>
            {/* <Button type="primary" danger onClick={() => props.onDelete(record.id)}>
            Delete
          </Button> */}
          </Space>
        );
      },
    },
  ];

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userRolesOptions, setUserRolesOptions] = useState<IOption[]>([]);
  const [userNetworkOptions, setUserNetworkOptions] = useState<IOption[]>([]);
  const [cycles, setCycles] = useState<IOption[]>([]);
  const [callTypes, setCallTypes] = useState<IOption[]>([]);

  const [treeData, setTreeData] = useState<DataType[]>([]);
  const navigate = useNavigate();
  const headerText = "Diary Management";

  const [formOptionValues, setFormOptionValues] = useState({
    hierarchy_id: null,
    contactNetwork_id: null,
    role_id: null,
    cycle_id: null,
    callType_id: null,
    assignee_id: null,
  });

  useEffect(() => {
    const fetchAssigness = async () => {
      setIsLoading(true);
      const { assigness } = await request(
        "/office/calls/options/assignees",
        "POST",
        {
          projectId,
        }
      );
      setAssigness(assigness);
      setIsLoading(false);
    };
    fetchAssigness();
  }, []);

  console.log({ assigness });

  const fetchUserData = async () => {
    setIsLoading(true);
    if (!userId) {
      return;
    }
    const userData = await request(`/office/users/${userId}`, "POST", {
      projectId,
    });
    if (!userData) {
      navigate(`/admin`);
      return;
    }
    setInitValues({
      ...userData,
    });
    setIsLoading(false);
  };

  const isCallFinished = (row: DataType) => {
    // console.log({row})
    return (
      alreadyScheduledCalls[row.id] &&
      alreadyScheduledCalls[row.id].length &&
      alreadyScheduledCalls[row.id].find(
        (plannedCall) =>
          plannedCall.status === "Closed" &&
          plannedCall.callType_id === formOptionValues.callType_id &&
          plannedCall.customer_id === row.id &&
          moment(plannedCall.plannedCallStart, "YYYY-MM-DD").isSame(
            startDate,
            "day"
          )
      )
    );
  };

  const isCallAlreadyPlanned = (row: DataType) => {
    // console.log({row})
    return (
      alreadyScheduledCalls[row.id] &&
      alreadyScheduledCalls[row.id].length &&
      alreadyScheduledCalls[row.id].find(
        (plannedCall) =>
          plannedCall.status === "Open" &&
          plannedCall.callType_id === formOptionValues.callType_id &&
          plannedCall.customer_id === row.id &&
          moment(plannedCall.plannedCallStart, "YYYY-MM-DD").isSame(
            startDate,
            "day"
          )
      )
    );
  };
  // console.log({alreadyScheduledCalls,call: formOptionValues.callType_id})

  const handleAction = async (rowStatus: CallStatusOptions, record: any) => {
    setIsLoading(true);
    switch (rowStatus) {
      case CallStatusOptions.None:
        await handlePlanCall(record);
        break;
      // case CallStatusOptions.Planned:
      //   handleStartCall(record);
      //   break;
      // case CallStatusOptions.Started:
      //   handleContinueCall(record);
      //   break;
      // case CallStatusOptions.Completed:
      //   handleFinishedCall(record);
      //   break;
      default:
        await handlePlanCall(record);
    }
    setIsLoading(false);
  };

  const handlePlanCall = async (record: any) => {
    if (formOptionValues.callType_id === null) {
      alert("Please select call type");
      return;
    }
    if (formOptionValues.assignee_id === null) {
      alert("Please select assignee");
      return;
    }

    const data = {
      projectId,
      outletId: record.id,
      startDate,
      callTypeId: formOptionValues.callType_id,
      assigneeId: formOptionValues.assignee_id,
    };

    const res = await request("/office/training/add-call", "POST", {
      ...data,
    });
    if (res.status === 200) {
      setAlreadyScheduledCalls({
        ...alreadyScheduledCalls,
        [record.id]: [
          ...(alreadyScheduledCalls[record.id] || []),
          {
            id: record.id,
            status: "Open",
            callType_id: formOptionValues.callType_id,
            customer_id: record.id,
            plannedCallStart: startDate,
            isNewlyPlanned: true,
          },
        ],
      });

      setAllOutlets(
        allOutlets.map((outlet) => {
          if (outlet.id === record.id) {
            return {
              ...outlet,
              status: CallStatusOptions.Planned,
            };
          }
          return outlet;
        })
      );
      // const { callTypes, cycles, outlets, allPlannedCalls } =
      // await getTrainingOptions(projectId);
      // setAlreadyScheduledCalls(allPlannedCalls);
      // setCycles(cycles);
      // setCallTypes(callTypes);
      // setAllOutlets(outlets);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [userId, projectId]);

  useEffect(() => {
    form.setFieldsValue(initValues);
  }, [initValues, userId]);

  const handleComeBack = () => navigate("/admin/users");

  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true);
      const { callTypes, cycles, outlets, allPlannedCalls } = await request(
        "/office/training/options",
        "POST",
        {
          projectId,
        }
      );
      setAlreadyScheduledCalls(allPlannedCalls);
      setCycles(cycles);
      setCallTypes(callTypes);
      setAllOutlets(outlets);

      setIsLoading(false);
    };
    fetchAllData();
  }, [projectId]);

  const handleInsert = async (values: any) => {
    try {
      await request("/office/users/insert", "POST", {
        ...values,
      });
      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (values: any) => {
    try {
      // await updateUser(values);
      // setIsLoading(false);
      // handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  const onFinish = async (values: any) => {
    setIsLoading(true);

    const submitData = {
      ...values,
      ...formOptionValues,
      project_id: projectId,
    };

    if (userId) {
      handleUpdate({
        ...submitData,
        userId,
      });
    } else {
      handleInsert(submitData);
    }
  };

  const handleOptionChange = (key: string, event: any) => {
    setFormOptionValues({
      ...formOptionValues,
      [key]: event.value,
    });
  };

  // if (isLoading)  || userRolesOptions.length === 0) {
  //   return <p>Loading...</p>;
  // }

  const displayRender = (labels: string[]) => labels[labels.length - 1];
  const onChange = (key: string, value: any) => {
    const lastValue = Array.isArray(value) ? value[value.length - 1] : value;
    handleOptionChange(key, { value: lastValue });
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1
    );

  // console.log({ allOutlets });
  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Form
        form={form}
        labelCol={{ span: 4 }}
        // wrapperCol={{ span: 10 }}
        onFinish={onFinish}
        initialValues={initValues}
      >
        {/* <Form.Item
          label="Cycle"
          hasFeedback
          name="cycle_id"
          rules={[{ required: true, message: "Cycle is required to proceed!" }]}
        >
          <Select
            loading={isLoading}
            options={cycles}
            defaultValue={formOptionValues.cycle_id}
            onChange={(_value, e) => {
              handleOptionChange("cycle_id", e);
            }}
          />
        </Form.Item> */}
        <Form.Item
          label="Call Type"
          rules={[
            { required: true, message: "Call type is required to proceed!" },
          ]}
        >
          <Select
            loading={isLoading}
            options={callTypes}
            defaultValue={formOptionValues.callType_id}
            onChange={(_value, e) => {
              handleOptionChange("callType_id", e);
            }}
          />
        </Form.Item>
        <Form.Item label="Assignee" name="assignee_id">
          <Select
            // loading={isLoading}
            options={assigness}
            style={{ width: 200 }}
            onChange={(_value, e) => {
              handleOptionChange("assignee_id", e);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Date"
          name="startDate"
          // getValueProps={(i: any) => ({ value: moment(i) })}
          required
        >
          <DatePicker
            allowClear={false}
            onChange={(e) => setStartDate(handleStartDate(e))}
            inputReadOnly
          />
        </Form.Item>
        <Table
          columns={columns({
            onUpdate: handleUpdate,
            // onDelete: handleDelete,
          })}
          dataSource={allOutlets}
          rowKey="id"
          pagination={{
            defaultPageSize: 25,
          }}
          loading={isLoading}
        />

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default DiaryManagement;
