import { Typography, Table } from "antd";
import * as S from "./importInfoTable.styles";

interface IColumn {
  title: string;
  column: string;
}

interface IImportInfoTableProps {
  columns: IColumn[];
  description: string;
  imgSrc?: string;
}
const ImportInfoTable = ({
  columns,
  description,
  imgSrc,
}: IImportInfoTableProps) => (
  <div>
    <Typography.Title level={4}>Important information</Typography.Title>
    <Typography.Paragraph>{description}</Typography.Paragraph>
    <Table
      columns={columns}
      dataSource={[]}
      pagination={false}
      size="small"
      style={{
        marginBottom: "1rem",
      }}
      locale={{
        emptyText: "List of required columns",
      }}
    />
    <S.ImageWrapper>
      <S.StyledImage src={imgSrc} />
    </S.ImageWrapper>
  </div>
);

export default ImportInfoTable;
