// import "antd/dist/antd.css";
import {
    Form,
    Button,
    Typography,
    Table,
    Tag,
  } from "antd";
  import {
    FormEvent,
    useCallback,
    useContext,
    useState,
  } from "react";
  import { useNavigate } from "react-router-dom";
  import { AppContext } from "../../../../App";
  import useApiPost from "../../../../hooks/useApiPost";
  import { SheetDataSetBulkFrequencyCall } from "./outlet-list-types";
  import readXlsxFile from "read-excel-file";
  import { toast } from "react-toastify";
  
  
  const AddBulkCallFrequencyMappingForm = () => {
    const { request, setError } = useApiPost();
    const [isLoading, setIsLoading] = useState(false);
    const { projectId } = useContext(AppContext);
    const [rcIdsWithError, setRcIdsWithError] = useState<any[]>([]);
    const [dataError, setDataError] = useState<string>();
    const [newEntries, setNewEntries] = useState<any[]>([]);
  
    let navigate = useNavigate();
  
    const handleComeBack = () => {
      navigate("/admin/outlets");
    };
  
    const [loading, setLoading] = useState<boolean>(false);
  
    const handleUploadBatchFile = async (e: any) => {
      const file = e.target.files[0];
      await readXlsxFile(file).then(async (rows: any) => {
        let filteredData: any = [];
        const encounteredCombinations = new Set();
  
        rows.forEach((item: any, index: number) => {
          if (index > 0) {
            const rc_id = item[0]?.toString().trim();
            const callType = item[1]?.toString().trim();
            const frequency = item[2]?.toString().trim();
            const sheetDataRow: SheetDataSetBulkFrequencyCall = {
              rc_id,
              callType,
              frequency,
            };
            const combination =
              rc_id +
              callType +
              frequency;
            if (!encounteredCombinations.has(combination)) {
              filteredData.push(sheetDataRow);
              encounteredCombinations.add(combination);
            }
          }
        });
        if (filteredData.length > 0) {
          setNewEntries(filteredData);
        }
      });
    };
 
    const sendForm = async (e: FormEvent) => {
    
    };
  
    if (
      loading ||
      isLoading
    ) {
      return <p>Loading...</p>;
    }

    const notifyError = () => {
      toast.error("Please choose excel file", {
        position: "bottom-center"
      });
    };
  
    const notifySuccess = (message: string) => {
      toast.info(message, {
        position: "bottom-center"
      });
    };
  
    const handleSave = async () => {
      if (!projectId) {
        return;
      }
      if (newEntries.length === 0) {
        notifyError();
        return;
      }
      setIsLoading(true);
      const response = await request("/office/outlets/callTypeFrequency-import", "POST", {
        project_id: projectId,
        callFrequency: newEntries,
      });
      setIsLoading(false);
      if (response?.failedRcIds?.length > 0) {
        let rcIds: any = [];
        let dataErrorMessage = '';
        response?.failedRcIds && response?.failedRcIds.map((item: any, index: number) => {
          rcIds.push(item?.rc_id);
          if (dataErrorMessage === '') {
            dataErrorMessage = 'RC ID: ' + item?.rc_id + ' Error: ' + item?.error;
          } else {
            dataErrorMessage = dataErrorMessage + '\n' + 'RC ID: ' + item?.rc_id + ' Error: ' + item?.error;
          }
        })
  
        setDataError(dataErrorMessage);
        setRcIdsWithError(rcIds);
      } else {
        notifySuccess(response?.message);
        handleComeBack();
        setDataError('')
      }
    }
  
    return (
      <>
        <Typography.Title>Bulk Call Frequency Mapping:</Typography.Title>
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          onFinish={sendForm}
        >
              <Table
                rowKey="order"
                style={{
                  fontSize: "12px",
                }}
                pagination={{
                  pageSize: 10,
                }}
                rowClassName="outletRowTableClass"
                dataSource={newEntries}
                columns={[
                  {
                    title: "Retail Cloud ID",
                    dataIndex: "rc_id",
                    key: "rc_id",
                    render: (_, { rc_id }, index) => {
                      var color = "red"
                      return (
                        <>
                          {rcIdsWithError && rcIdsWithError?.length > 0 && rcIdsWithError.includes(rc_id) ? 
                          <Tag color={color}>
                            {rc_id}
                          </Tag> : 
                          <div>
                            {rc_id}
                          </div>}
                        </>
                      )
                    }
                  },
                  {
                    title: "Call Type",
                    dataIndex: "callType",
                    key: "callType",
                  },
                  {
                    title: "Frequency",
                    dataIndex: "frequency",
                    key: "frequency",
                  },
                ]}
              />
              <>
                <div>
                  <input
                    type="file"
                    id="input"
                    accept=".xlsx, .xls"
                    onChange={(e) => {
                      handleUploadBatchFile(e);
                      setNewEntries([]);
                      setRcIdsWithError([]);
                      setDataError('');
                    }}
                  />
                </div>
                {dataError && dataError !=='' && <p style={{ color: "red", whiteSpace: 'break-spaces' }}>{dataError}</p>}
                <Button
                  type="primary"
                  style={{ margin: 10 }}
                  onClick={handleComeBack}
                >
                  Go back
                </Button>
  
                <Button type="primary" style={{ margin: 10 }} onClick={handleSave}>
                  Save
                </Button>
              </>
        </Form>
      </>
    );
  };
  
  export default AddBulkCallFrequencyMappingForm;
  