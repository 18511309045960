import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { DateTime } from "luxon";
import moment from "moment";

// Common type for 'dayjs' Date objects
export type TDate = dayjs.Dayjs | null;

export const getEntryDates = () => {
  let minDate = "";

  const now = new Date();
  const currentDay = now.getDate();
  const currentMonth = now.getMonth() + 1;
  const currentYear = now.getFullYear();
  const today = `${currentYear}-${currentMonth
    .toString()
    .padStart(2, "0")}-${currentDay.toString().padStart(2, "0")}`;

  const prevMonth = currentMonth === 1 ? 12 : currentMonth - 1;
  const prevYear = currentMonth === 1 ? currentYear - 1 : currentYear;
  const lastDayOfTheMonth = new Date(currentYear, currentMonth, 0).getDate();
  const isPreviousMonthActive = currentDay <= 10;
  const maxDate = `${currentYear}-${currentMonth
    .toString()
    .padStart(2, "0")}-${lastDayOfTheMonth}`;
  if (!isPreviousMonthActive) {
    minDate = `${currentYear}-${currentMonth.toString().padStart(2, "0")}-01`;
  } else {
    minDate = `${prevYear}-${prevMonth.toString().padStart(2, "0")}-01`;
  }
  return { today, minDate, maxDate };
};

export const getValidHours = (hours: number) => {
  if (hours > 24) {
    return 24;
  }
  if (hours < 0) {
    return 0;
  }
  return hours;
};

export const getValidMinutes = (minutes: number) => {
  if (minutes > 59) {
    return 59;
  }
  if (minutes < 0) {
    return 0;
  }
  return minutes;
};

export const convertStringToDate = (string: string) => {
  const result = new Date(string);
  if (isNaN(result.getDate())) {
    return null;
  }
  return result;
};

export const isToday = (date: string) => {
  const formattedDate = convertStringToDate(date);
  const today = new Date();
  return (
    formattedDate?.getDate() === today.getDate() &&
    formattedDate.getMonth() === today.getMonth() &&
    formattedDate.getFullYear() === today.getFullYear()
  );
};

export const isSelectedDay = (date: string, selectedDate: string) => {
  const formattedSelectedDate = convertStringToDate(selectedDate);
  const formattedDate = convertStringToDate(date);
  return (
    formattedDate?.getDate() === formattedSelectedDate?.getDate() &&
    formattedDate?.getMonth() === formattedSelectedDate?.getMonth() &&
    formattedDate?.getFullYear() === formattedSelectedDate?.getFullYear()
  );
};
export const isSelectedMonthYear = (
  year: number,
  month: number,
  selectedDate: string
) => {
  const formattedSelectedDate = convertStringToDate(selectedDate);
  const formattedDate = convertStringToDate(selectedDate);
  return (
    formattedDate?.getDate() === formattedSelectedDate?.getDate() &&
    formattedDate?.getMonth() === formattedSelectedDate?.getMonth() &&
    formattedDate?.getFullYear() === formattedSelectedDate?.getFullYear()
  );
};

export const getMonthFormatted = (date: Date) => {
  let minDate = "";

  const now = new Date();
  const currentDay = now.getDate();
  const currentMonth = now.getMonth() + 1;
  const currentYear = now.getFullYear();
  const today = `${currentYear}-${currentMonth
    .toString()
    .padStart(2, "0")}-${currentDay.toString().padStart(2, "0")}`;

  const prevMonth = currentMonth === 1 ? 12 : currentMonth - 1;
  const prevYear = currentMonth === 1 ? currentYear - 1 : currentYear;
  const lastDayOfTheMonth = new Date(currentYear, currentMonth, 0).getDate();
  const isPreviousMonthActive = currentDay <= 10;
  const maxDate = `${currentYear}-${currentMonth
    .toString()
    .padStart(2, "0")}-${lastDayOfTheMonth}`;
  if (!isPreviousMonthActive) {
    minDate = `${currentYear}-${currentMonth.toString().padStart(2, "0")}-01`;
  } else {
    minDate = `${prevYear}-${prevMonth.toString().padStart(2, "0")}-01`;
  }
  return { today, minDate, maxDate };
};

export const getWeekDays = (dateString: string) => {
  const date = new Date(dateString);
  const currentDay = date.getDate();
  const currentMonth = date.getMonth() + 1;
  const currentYear = date.getFullYear();
  const monday = DateTime.local(currentYear, currentMonth, currentDay)
    .startOf("week")
    .toISODate();
  let weekDays = [];

  for (let index = 0; index < 7; index++) {
    const nextDay = new Date(monday);
    nextDay.setDate(nextDay.getDate() + index);

    weekDays.push({
      dayName: getDayName(nextDay),
      date: nextDay,
    });
  }
  return weekDays;
};

function getDayName(date: Date) {
  // DateTime.local(2017, 5, 25).weekNumber
  return date.toLocaleDateString("en-US", { weekday: "long" });
}

export const getFormattedTimeFromMinutes = (
  minutes: number,
  decimals = false
) => {
  if (minutes <= 0) {
    return "0";
  }

  const hours = minutes / 60;
  const remainingMinutes = minutes % 60;
  if (decimals) {
    return (minutes / 60).toFixed(2);
  } else {
    return `${Math.floor(hours)}h and ${remainingMinutes}m`;
  }
};

export const getMinutesFromStringTime = (time: string) => {
  const [hoursStr, minutesStr] = time.split(":");
  if (!hoursStr || !minutesStr) {
  }
  const hours = Number(hoursStr);
  const totalMinutes = Number(minutesStr) + hours * 60;

  return totalMinutes;
};

export const getMinutesDiffrence = (storedTime: string) => {
  const date1: Date = new Date(storedTime); // Replace with your first date
  const date2: Date = new Date(); // Use the current date and time as the second date

  // Calculate the time difference in milliseconds
  const timeDifferenceInMilliseconds: number =
    date2?.getTime() - date1?.getTime();

  // Convert milliseconds to minutes
  const timeDifferenceInMinutes: number = Math.floor(
    timeDifferenceInMilliseconds / (1000 * 60)
  );
  return timeDifferenceInMinutes;
};

export const dStringToMin = (dateTimeString: string) =>
  getMinutesFromStringTime(moment(dateTimeString).format("HH:mm"));

/**
 * The function handles selecting a start date.
 * @param date the date object passed to the function.
 * @returns the formatted date with start time as 00:00 SAST (using the UTC plugin).
 */
export function handleStartDate(date: TDate) {
  if (date === null) return;

  // use UTC plugin
  dayjs.extend(utc);
  // return the timestamp as 00:00:00 for SAST
  return date.subtract(1, "day").utc().hour(22).minute(0).second(0).toDate();
}

/**
 * The function handles selecting an end date.
 * @param date the date object passed to the function.
 * @returns  he formatted date with end time as 23:59 SAST (using the UTC plugin).
 */
export function handleEndDate(date: TDate) {
  if (date === null) return;

  // use UTC plugin
  dayjs.extend(utc);
  // return the timestamp as 23:59:59 for SAST
  return date.utc().hour(21).minute(59).second(59).toDate();
}

/**
 * The function handles selecting a start date.
 * @param date the date object passed to the function.
 * @returns the formatted date with the startOf passed date's time.
 */
export function handleStartDateToDateOnly(date: TDate) {
  if (date === null) return;
  return moment(date.startOf("date").toDate()).format("YYYY-MM-DD")
}

/**
 * The function handles selecting an end date.
 * @param date the date object passed to the function.
 * @returns the formatted date with the endOf passed date's time.
 */
export function handleEndDateToDateOnly(date: TDate) {
  if (date === null) return;
  return moment(date.endOf("date").toDate()).format("YYYY-MM-DD")
}
