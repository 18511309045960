import { useContext, useEffect, useRef, useState } from "react";
import { Form, Input, Button, Typography, Collapse, Modal } from "antd";
import { AppContext } from "../../../../App";
import { instance } from "../../../../api/common-api";
import useApiPost from "../../../../hooks/useApiPost";

interface MediaItem {
  name: string;
  imgSrc: string;
  type: string;
}

interface IPromotionItem {
  id: string;
  name: string;
  description: string;
  media: MediaItem;
}

interface ContentManagementProps {
  promotions: IPromotionItem[];
  innerBanner: MediaItem;
}
interface SingleMediaDetails {
  index: number;
  name: string;
}

const ContentManagement = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFiles] = useState<FileList>();
  const ref = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedDocumentType, setSelectedDocumentType] = useState<string>("");
  const { projectId } = useContext(AppContext);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [secondaryText, setSecondaryText] = useState<string>("");
  const [contentManagement, setContentManagement] = useState<[]>([]);
  const [successPlaybookContent, setSuccessPlaybookContent] = useState<[]>([]);
  const [innerBannerContent, setInnerBannerContent] = useState<any>(undefined);
  const [promotionsContent, setPromotionsContent] = useState<IPromotionItem[]>(
    []
  );
  const { request, setError } = useApiPost();

  const [mediaDetails, setMediaDetails] = useState<SingleMediaDetails[]>();

  const fetchAll = async () => {
    // const res = await getAllData(projectId);
    const res = await request("/office/content-management", "POST", {
      projectId,
    });

    setContentManagement(res);
    setSuccessPlaybookContent(
      res.filter((x: any) => x.type === "success-playbook")
    );
    const innerBanners = res.filter((x: any) => x.type === "inner-banner");
    if (innerBanners.length > 0) {
      setInnerBannerContent(innerBanners[0]);
    }
    const promotions = res.filter((x: any) => x.type === "promotions");
    setPromotionsContent(promotions);
  };

  useEffect(() => {
    fetchAll();
  }, []);

  const onChangeSingleFile = (e: any) => {
    setFiles(e.target.files);
    // console.log({ f: e.target.files });
    const mediaDetails: SingleMediaDetails[] = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const fileNameWithoutExtension = e.target.files[i].name.split(".")[0];
      mediaDetails.push({
        index: i,
        name: fileNameWithoutExtension,
      });
    }
    setMediaDetails(mediaDetails);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setFiles(undefined);
    setSelectedDocumentType("");
    setDescription("");
    setTitle("");
    setSecondaryText("");
  };

  const handleInnerBanner = async (e: any) => {
    const formData = new FormData();

    formData.append("documentType", selectedDocumentType);
    formData.append("projectId", projectId);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("secondaryText", secondaryText);

    await uploadForm(formData);
  };

  const handleFiles = async (e: any) => {
    if (!file) {
      return;
    }
    const formData = new FormData();

    for (let i = 0; i < file.length; i++) {
      formData.append("files", file[i]);
    }

    formData.append("documentType", selectedDocumentType);
    formData.append("projectId", projectId);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("secondaryText", secondaryText);

    await uploadForm(formData);
  };

  const uploadForm = async (formData: FormData) =>
    await request("/office/content-management/upload", "POST", formData, true);

  const uploadFile = async (e: any) => {
    setIsLoading(true);
    e.preventDefault();

    if (selectedDocumentType === "inner-banner") {
      await handleInnerBanner(e);
    } else {
      await handleFiles(e);
    }

    await fetchAll();
    setIsLoading(false);
    setIsModalOpen(false);
    setDescription("");
    setSecondaryText("");
    setTitle("");
    setFiles(undefined);
    return;
  };

  const handleAddNew = async (selectedDocumentType: string) => {
    setSelectedDocumentType(selectedDocumentType);
    setIsModalOpen(true);
  };

  const handleDelete = async (id: string) => {
    // await deleteItem(id);
    await request(`/office/content-management/delete/${id}`, "POST");
    await fetchAll();
  };

  const handleDeleteInnerBanner = async () => {
    // await deleteInnerBanner(projectId);
    await request(`/office/content-management/delete-inner-banner`, "POST", {
      projectId,
    });
    setInnerBannerContent(undefined);
    await fetchAll();
  };

  const canSubmit =
    selectedDocumentType === "inner-banner" ? !!title && !!description : !!file;

  return (
    <>
      <Typography.Title>Content Management</Typography.Title>
      <Collapse defaultActiveKey={["3"]}>
        <Collapse.Panel header="Promotions" key="1">
          <>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {promotionsContent.map((e: any, index: number) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: 300,
                    marginBottom: 20,
                    alignItems: "center",
                    border: "1px solid #ccc",
                  }}
                >
                  <img
                    style={{ width: 200, height: 200, objectFit: "contain" }}
                    src={e.link}
                    alt=""
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: 10,
                    }}
                  >
                    <Typography.Text
                      style={{ fontWeight: "bold", fontSize: 18 }}
                    >
                      {e.titleText}
                    </Typography.Text>
                    <Typography.Text
                      style={{ textAlign: "justify", fontSize: 16 }}
                    >
                      {e.descriptionText}
                    </Typography.Text>
                  </div>
                  <div>
                    <Button type="primary" onClick={() => handleDelete(e.id)}>
                      Delete
                    </Button>
                  </div>
                </div>
              ))}
            </div>
            <Button type="primary" onClick={() => handleAddNew("promotions")}>
              Add New
            </Button>
          </>
        </Collapse.Panel>
        <Collapse.Panel header="Inner Banner" key="2">
          <Typography.Title level={2}>Inner Banner</Typography.Title>
          <>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {innerBannerContent && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 20,
                    }}
                  >
                    <Typography.Title level={4}>
                      {`Title: ${innerBannerContent?.titleText}`}
                    </Typography.Title>
                    <Typography.Text>
                      {`Description: ${innerBannerContent?.descriptionText}`}
                    </Typography.Text>
                    <Typography.Text>
                      {`Secondary Text: ${innerBannerContent?.secondaryText}`}
                    </Typography.Text>
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Button
                    type="primary"
                    onClick={() => handleAddNew("inner-banner")}
                    style={{ marginRight: 10 }}
                  >
                    {innerBannerContent ? "Edit" : "Add New"}
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => handleDeleteInnerBanner()}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          </>
        </Collapse.Panel>
        <Collapse.Panel header="Success Playbook" key="3">
          <Typography.Title level={2}>Success Playbook</Typography.Title>
          <>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {successPlaybookContent.map((e: any, index: number) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: 300,
                    marginBottom: 20,
                    alignItems: "center",
                    border: "1px solid #ccc",
                  }}
                >
                  <img
                    style={{ width: 200, height: 200, objectFit: "contain" }}
                    src={e.link}
                    alt=""
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: 10,
                    }}
                  >
                    <Typography.Text
                      style={{ fontWeight: "bold", fontSize: 18 }}
                    >
                      {e.titleText}
                    </Typography.Text>
                    <Typography.Text
                      style={{ textAlign: "justify", fontSize: 16 }}
                    >
                      {e.descriptionText}
                    </Typography.Text>
                  </div>
                  <div>
                    <Button type="primary" onClick={() => handleDelete(e.id)}>
                      Delete
                    </Button>
                  </div>
                </div>
              ))}
            </div>
            <Button
              type="primary"
              onClick={() => handleAddNew("success-playbook")}
            >
              Add New
            </Button>
          </>
        </Collapse.Panel>
      </Collapse>

      <Modal
        title={`Provide details`}
        open={isModalOpen}
        centered
        closable={false}
        width={1000}
        footer={[]}
        //   <Button key="back" onClick={handleCancel}>
        //     Cancel
        //   </Button>,

        //   <Button
        //     key="submit"
        //     type="primary"
        //     onClick={handleApprove}
        //     // disabled={!isCreateAvailable}
        //   >
        //     Approve
        //   </Button>,
        // ]}
      >
        <div>
          <form
            encType="multipart/form-data"
            method="post"
            action="#"
            onSubmit={uploadFile}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Form.Item label="Title">
                <Input
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                />
              </Form.Item>
              <Form.Item label="Description">
                <Input
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                />
              </Form.Item>
              {selectedDocumentType === "inner-banner" && (
                <Form.Item label="Secondary text">
                  <Input
                    onChange={(e) => setSecondaryText(e.target.value)}
                    value={secondaryText}
                  />
                </Form.Item>
              )}
              {selectedDocumentType !== "inner-banner" && (
                <input
                  ref={ref}
                  type="file"
                  name="file"
                  onChange={onChangeSingleFile}
                  disabled={file && file?.length > 0}
                  accept="image/*"
                />
              )}
              {/* <input
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Title"
              />
              <input
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Description"
              /> */}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Button type="primary" onClick={handleCancel}>
                Close
              </Button>
              <Button type="primary" htmlType="submit" disabled={!canSubmit}>
                Confirm
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default ContentManagement;
