import { useEffect, useState } from "react";
import styled from "styled-components";
import useApiPost from "../../../../hooks/useApiPost";

interface Role {
  id: string;
  name: string;
}

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 50px;
  border: 1px solid black;
  text-align: center;
`;

interface AccessMap {
  [key: string]: {
    [key: string]: {
      action: string;
      isAllowed: boolean;
      module: string;
    }[];
  };
}

const isEnabled = (
  action: string,
  role: string,
  module: string,
  accessMap: AccessMap
) => {
  const maybeAccess = accessMap[role]?.modules;

  if (!maybeAccess) return false;

  const filteredActions = maybeAccess?.filter(
    (access) => access.action === action && access.module === module
  );

  if (filteredActions.length === 0) return false;

  const isAllowed = filteredActions[0]?.isAllowed;

  return isAllowed;
};

const AccessPage = () => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [modules, setModules] = useState<string[]>([]);
  const [accessMap, setAccessMap] = useState<AccessMap>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { request } = useApiPost();

  const toggleRoleAccess = async (
    action: string,
    role_id: string,
    module: string,
    isAllowed: boolean
  ) => {
    await request(`/office/access/update`, "POST", {
      action,
      role_id,
      module,
      isAllowed,
    });
    fetchRoleData();
  };

  const fetchRoleData = async () => {
    setIsLoading(true);
    const response = await request(`/office/access`);

    const { accessMap, moduleOptions } = response;

    const roles: Role[] = Object.entries(accessMap).map(([key, value]) => ({
      id: (value as { role_id: string }).role_id,
      name: key,
    })).sort((a, b) => a.name.localeCompare(b.name));

    setAccessMap(accessMap);
    setModules(moduleOptions);
    setRoles(roles);

    setIsLoading(false);
  };

  useEffect(() => {
    fetchRoleData();
  }, []);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div>
        <div>
          <Cell />
          {modules.map((module, index) => (
            <Cell key={index}>{module}</Cell>
          ))}
        </div>
      </div>
      <div>
        <div
          style={{
            display: "flex",
          }}
        >
          {roles.map((role, index) => (
            <div key={index}>
              <Cell key={role.id}>{role.name}</Cell>
              {modules.map((module, index) => (
                <Cell key={index}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span>View</span>
                      <input
                        type="checkbox"
                        checked={isEnabled(
                          "view",
                          role.name,
                          module,
                          accessMap
                        )}
                        onChange={(e) => {
                          toggleRoleAccess(
                            "view",
                            role.id,
                            module,
                            e.target.checked
                          );
                        }}
                      />
                    </div>
                  </div>
                </Cell>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AccessPage;
