import { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Typography, Switch } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../../App";
import useApiPost from "../../../../hooks/useApiPost";

const TimesheetWorkTypesForm = () => {
  const { projectId: project_id } = useContext(AppContext);
  const { request, setError } = useApiPost();

  const { workTypeId } = useParams<{ workTypeId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const navigate = useNavigate();
  const headerText = workTypeId ? "Edit Work Type" : "Add Work Type";

  const fetchWorkTypesData = async () => {
    setIsLoading(true);
    if (!workTypeId) {
      setIsLoading(false);
      return;
    }
    const { timesheetWorkType } = await request(
      "/office/timesheets/workTypes/details",
      "POST",
      {
        id: workTypeId,
      }
    );

    if (!timesheetWorkType) {
      navigate(`/admin`);
      return;
    }
    const { name, is_active: isActive } = timesheetWorkType;
    setName(name);
    setIsActive(isActive);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchWorkTypesData();
  }, [workTypeId]);

  const handleComeBack = () => navigate("/admin/timesheets-worktypes");

  const handleInsert = async () => {
    try {
      const res = await request("/office/timesheets/workTypes/insert", "POST", {
        name,
        is_active: isActive,
        project_id,
      });
      if (res.error) {
        alert(res.error);
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      if (!workTypeId) return;

      await request("/office/timesheets/workTypes/update", "POST", {
        id: workTypeId,
        isActive,
      });
      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Form labelCol={{ span: 4 }}>
        <Form.Item label="Name">
          {workTypeId ? (
            <div>{name}</div>
          ) : (
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          )}
        </Form.Item>
        <Form.Item label="Is active" valuePropName="isActive" name="isActive">
          <Switch defaultChecked={isActive} onChange={(e) => setIsActive(e)} />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            onClick={workTypeId ? handleUpdate : handleInsert}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default TimesheetWorkTypesForm;
