import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    Form,
    Button,
    Typography,
    DatePicker,
    Table,
    Modal,
    Input,
    Switch,
} from "antd";
import useApiPost from "../../../../hooks/useApiPost";
import { AppContext } from "../../../../App";


interface DataType {
    retailCloudId: string;
    topicName: string;
    difficultyLevel: string;
    topicLevelStatus: string;
}

const AxonifyDetail = () => {

    const navigate = useNavigate();
    const appContext = useContext(AppContext);
    const { request } = useApiPost();
    const { axonyId } = useParams<{ axonyId: string }>();
    const { projectId } = appContext;
    const [name, setName] = useState<any>(null);
    const [validFrom, setValidFrom] = useState<any>(null);
    const [validTo, setvalidTo] = useState<any>(null);
    const [isActive, setisActive] = useState<any>(null);
    const [axonifyDetail, setAxonifyDetail] = useState<DataType[]>([]);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {

        try {
            const getAllAxonify = async () => {
                setIsLoading(true);
                const axonifyList = await request(`/office/axonify/details/${axonyId}`, "POST", {
                    projectId
                });

                setName(axonifyList?.data?.name)
                setvalidTo(new Date(axonifyList?.data?.validTo).toDateString())
                setValidFrom(new Date(axonifyList.data.validFrom).toDateString())
                setisActive(axonifyList?.data?.isActive)
                setAxonifyDetail(axonifyList?.data?.details)

                setIsLoading(false);
            };
            getAllAxonify();
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }

    }, [axonyId]);

    const updateAxonify = async () => {
        if (!axonyId || !validFrom || !validTo) {
            return;
        }

        await request("/office/axonify/toggleActive", "POST", {
            isActive,
            projectId,
            axonifyId: axonyId,
        });
        navigate(`/admin/axonify`);
    };

    if (isLoading) {
        return <p>Loading...</p>;
    }


    return (<>
        <Typography.Title>{name}</Typography.Title>

        <div>
            <hr />
            <div>
                {validFrom} - {validTo}
            </div>
            <hr />
        </div>

        <Table
            rowKey="order"
            style={{
                fontSize: "12px",
            }}
            pagination={{
                pageSize: 1000,
            }}
            dataSource={axonifyDetail}
            columns={[
                {
                    title: "Retail Cloud ID",
                    dataIndex: "retailCloudId",
                    key: "retailCloudId",
                },
                {
                    title: "Topic Name",
                    dataIndex: "topicName",
                    key: "topicName",
                },
                {
                    title: "Difficulty Level",
                    dataIndex: "difficultyLevel",
                    key: "difficultyLevel",
                },
                {
                    title: "Topic Level Status",
                    dataIndex: "topicLevelStatus",
                    key: "topicLevelStatus",
                },

            ]}
        />

        <Form.Item label="Is Active" valuePropName="isActive" name="isActive">
            <Switch checked={isActive} onChange={(e) => setisActive(e)} />
        </Form.Item>

        <Button
            type="primary"
            style={{ margin: 10 }}
            onClick={updateAxonify}
        >
            Save
        </Button>

    </>)

}

export default AxonifyDetail;