import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Typography } from "antd";
import { AppContext } from "../../../../App";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import * as S from "./kt-modules-public.styled";
import useApiPost from "../../../../hooks/useApiPost";
dayjs.extend(customParseFormat);

const KTModulePublicQuestionnaire = () => {
  const { projectId } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const [title, setTitle] = useState<string>("");
  const { moduleId } = useParams<{ moduleId: string }>();
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [score, setScore] = useState<number>(0);
  const { request, setError, noAuthRequest } = useApiPost();

  const [knowledgeTransferDetails, setKnowledgeTransferDetails] = useState<
    any[]
  >([]);

  const getAllData = async () => {
    setIsLoading(true);
    if (!moduleId) return;
    const data = await noAuthRequest(
      `/public/questionnaire/details/${moduleId}`,
      "POST"
    );

    if (data.message && data.message === "Already finished") {
      setIsCompleted(true);
      setScore(data.score);
      setIsLoading(false);
      return;
    }
    console.log("data", data);
    setTitle(data?.name || "");
    setKnowledgeTransferDetails(data?.questions || []);
    setIsLoading(false);
  };

  const handleSubmit = async () => {
    if (!moduleId) return;
    const responses = knowledgeTransferDetails.map((q) => ({
      questionId: q.qID,
      answerId: q.answerPayload.selectedOption,
    }));

    setIsLoading(true);
    await noAuthRequest("/public/questionnaire/submit-public-questionnaire", "POST", {
      responses,
      module_id: moduleId,
    });
    await getAllData();
    setIsLoading(false);
  };

  useEffect(() => {
    getAllData();
  }, []);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isCompleted) {
    return (
      <S.Wrapper>
        <Typography.Title>{`Welcome to ${title} Questionnaire!`}</Typography.Title>
        <div>
          <h3 style={{ textAlign: "center" }}>
            {`You have already completed this questionnaire. Your score is ${score.toFixed(
              2
            )}%`}
          </h3>
        </div>
      </S.Wrapper>
    );
  }

  const isAllQuestionsAnswered =
    knowledgeTransferDetails.every(
      (question) => question.answerPayload.selectedOption
    ) && knowledgeTransferDetails.length > 0;

  return (
    <S.Wrapper>
      <Typography.Title>{`Welcome to ${title} Questionnaire!`}</Typography.Title>
      {moduleId && knowledgeTransferDetails.length > 0 && (
        <div>
          <h3 style={{ textAlign: "center" }}>
            Please, fill out questions below and submit your answers.
          </h3>
          {knowledgeTransferDetails
            .sort((a, b) => a.sequence - b.sequence)
            .map((q, index: number) => (
              <div key={q.qID}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h4>{`(${index + 1}) ${q.questionText}`}</h4>
                    {q.answerPayload.options
                      .sort(
                        (a: any, b: any) =>
                          a.responseSequence - b.responseSequence
                      )
                      .map((o: any) => (
                        <div
                          key={o.answerId}
                          style={{
                            display: "flex",
                            marginBottom: "10px",
                          }}
                        >
                          <Button
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "8px",
                              border: `1px solid ${
                                knowledgeTransferDetails[index].answerPayload
                                  .selectedOption === o.answerId
                                  ? "#1890ff"
                                  : "#d9d9d9"
                              }`,
                              background: `${
                                knowledgeTransferDetails[index].answerPayload
                                  .selectedOption === o.answerId
                                  ? "#1890ff"
                                  : "#fff"
                              }`,
                              color: `${
                                knowledgeTransferDetails[index].answerPayload
                                  .selectedOption === o.answerId
                                  ? "#fff"
                                  : "#000"
                              }`,
                              borderRadius: "5px",
                            }}
                            onClick={() => {
                              const newKnowledgeTransferDetails = [
                                ...knowledgeTransferDetails,
                              ];
                              newKnowledgeTransferDetails[index] = {
                                ...newKnowledgeTransferDetails[index],
                                answerPayload: {
                                  ...newKnowledgeTransferDetails[index]
                                    .answerPayload,
                                  selectedOption: o.answerId,
                                },
                              };
                              setKnowledgeTransferDetails(
                                newKnowledgeTransferDetails
                              );
                            }}
                          >
                            {o.label}
                          </Button>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="primary"
          style={{ marginTop: "20px" }}
          onClick={handleSubmit}
          disabled={!isAllQuestionsAnswered}
        >
          Submit
        </Button>
      </div>
    </S.Wrapper>
  );
};

export default KTModulePublicQuestionnaire;
