import React from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Collapse,
  Cascader,
} from "antd";
import { DefaultOptionType } from "antd/lib/cascader";
import * as S from "../../components/Admin/admin-dashboard/cluster-specialists/ClusterSpecialists.styled";
import { toast } from "react-toastify";
const { Panel } = Collapse;
interface ClusterSpecialistsQuestion {
  orderNumber: number;
  text: string;
  type: string; // "radio" | "text" | "bool";
  maxScore: number;
  isLocal: boolean;
  options: {
    score: number;
    value: string;
    nextQuestion: number | null;
    isTriggeringRedFlag: boolean;
    emailRecipient?: string;
    redFlagCategory?: any;
    orderNumber: number;
    refQuestions: number[];
    redFlagReferencedQuestions: number[] | null;
  }[];
}

interface QuestionFormCommonProps {
  questions: ClusterSpecialistsQuestion[];
  setQuestions: Function;
  deleteQuestion: Function;
  treeData: any[];
  from: "AddDraft" | "EditDraft";
  draftId: any;
}
// from is using for the screen name like Add from strategic audit and Edit daft from main screen
const QuestionFormCommon: React.FC<QuestionFormCommonProps> = ({
  questions,
  setQuestions,
  treeData,
  from,
  draftId,
  deleteQuestion,
}) => {
  const onChange = (key: string | string[]) => {
    console.log("keykeykey", key);
  };

  const availableOptions = questions.map((question, index) => ({
    value: index,
    label: `Question ${index + 1}`,
  }));

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1
    );

  const generateRadio = (index: number) => (
    <div>
      {questions[index].options.map((option, optionIndex) => {
        return (
          <S.OptionBox key={`option_${optionIndex}`}>
            <Form.Item label="Option">
              <Input
                type="text"
                value={option.value}
                disabled={!questions[index]?.isLocal}
                onChange={(e) => {
                  setQuestions((questions: ClusterSpecialistsQuestion[]) => {
                    return questions.map((question, questionIndex) => {
                      if (questionIndex === index) {
                        return {
                          ...question,
                          options: question.options.map(
                            (option, optionIndex2) => {
                              if (optionIndex === optionIndex2) {
                                return {
                                  ...option,
                                  value: e.target.value,
                                };
                              }
                              return option;
                            }
                          ),
                        };
                      }
                      return question;
                    });
                  });
                }}
                required
              />
            </Form.Item>
            <Form.Item label="Score">
              <Input
                type="number"
                value={option.score}
                disabled={!questions[index]?.isLocal}
                onChange={(e) => {
                  setQuestions((questions: ClusterSpecialistsQuestion[]) => {
                    return questions.map((question, questionIndex) => {
                      if (questionIndex === index) {
                        return {
                          ...question,
                          options: question.options.map(
                            (option, optionIndex2) => {
                              if (optionIndex === optionIndex2) {
                                return {
                                  ...option,
                                  score: Number(e.target.value),
                                };
                              }
                              return option;
                            }
                          ),
                        };
                      }
                      return question;
                    });
                  });
                }}
              />
            </Form.Item>
            {availableOptions.length > index + 1 && (
              <Form.Item label="Next Question">
                <Select
                  disabled={!questions[index]?.isLocal}
                  options={availableOptions
                    .filter((option) => option.value > index)
                    .concat({
                      value: -1,
                      label: "End",
                    })}
                  value={
                    !questions[index]?.isLocal
                      ? option.nextQuestion
                      : option.nextQuestion == 0
                      ? option.nextQuestion
                      : undefined
                  }
                  onChange={(value) => {
                    setQuestions((questions: ClusterSpecialistsQuestion[]) => {
                      return questions.map((question, questionIndex) => {
                        if (questionIndex === index) {
                          return {
                            ...question,
                            options: question.options.map(
                              (option, optionIndex2) => {
                                if (optionIndex === optionIndex2) {
                                  return {
                                    ...option,
                                    nextQuestion: value,
                                  };
                                }
                                return option;
                              }
                            ),
                          };
                        }
                        return question;
                      });
                    });
                  }}
                />
              </Form.Item>
            )}
            <Form.Item label="Red Flag">
              <Input
                type="checkbox"
                checked={option.isTriggeringRedFlag}
                disabled={!questions[index]?.isLocal}
                onChange={(e) => {
                  setQuestions((questions: ClusterSpecialistsQuestion[]) => {
                    return questions.map((question, questionIndex) => {
                      if (questionIndex === index) {
                        return {
                          ...question,
                          options: question.options.map(
                            (option, optionIndex2) => {
                              if (optionIndex === optionIndex2) {
                                return {
                                  ...option,
                                  isTriggeringRedFlag: e.target.checked,
                                  redFlagCategory: "",
                                  refQuestions: [],
                                  emailRecipient: "",
                                };
                              }
                              return option;
                            }
                          ),
                        };
                      }
                      return question;
                    });
                  });
                }}
              />
            </Form.Item>
            {option.isTriggeringRedFlag && (
              <>
                <Form.Item label={"refQuestions"}>
                  <Select
                    mode="multiple"
                    maxLength={2}
                    options={availableOptions}
                    allowClear
                    disabled={!questions[index]?.isLocal}
                    value={
                      option?.refQuestions || option?.redFlagReferencedQuestions
                    }
                    onChange={(value) => {
                      setQuestions(
                        (questions: ClusterSpecialistsQuestion[]) => {
                          return questions.map((question, questionIndex) => {
                            if (questionIndex === index) {
                              return {
                                ...question,
                                options: question.options.map(
                                  (option, optionIndex2) => {
                                    if (optionIndex === optionIndex2) {
                                      return {
                                        ...option,
                                        refQuestions: value,
                                      };
                                    }
                                    return option;
                                  }
                                ),
                              };
                            }
                            return question;
                          });
                        }
                      );
                    }}
                  />
                </Form.Item>
                <Form.Item label="RFX Category">
                  {!questions[index]?.isLocal ? (
                    <Cascader
                      showSearch={{ filter }}
                      options={treeData}
                      expandTrigger="hover"
                      changeOnSelect
                      disabled={!questions[index]?.isLocal}
                      value={option?.redFlagCategory}
                      placeholder="Select RF Category"
                      onChange={(val) => {
                        if (val.length > 0) {
                          setQuestions(
                            (questions: ClusterSpecialistsQuestion[]) => {
                              return questions.map(
                                (question, questionIndex) => {
                                  if (questionIndex === index) {
                                    return {
                                      ...question,
                                      options: question.options.map(
                                        (option, optionIndex2) => {
                                          if (optionIndex === optionIndex2) {
                                            return {
                                              ...option,
                                              redFlagCategory:
                                                val[val.length - 1],
                                            };
                                          }
                                          return option;
                                        }
                                      ),
                                    };
                                  }
                                  return question;
                                }
                              );
                            }
                          );
                        }
                      }}
                    />
                  ) : (
                    <Cascader
                      showSearch={{ filter }}
                      options={treeData}
                      expandTrigger="hover"
                      changeOnSelect
                      disabled={!questions[index]?.isLocal}
                      placeholder="Select RF Category"
                      onChange={(val) => {
                        if (val.length > 0) {
                          setQuestions(
                            (questions: ClusterSpecialistsQuestion[]) => {
                              return questions.map(
                                (question, questionIndex) => {
                                  if (questionIndex === index) {
                                    return {
                                      ...question,
                                      options: question.options.map(
                                        (option, optionIndex2) => {
                                          if (optionIndex === optionIndex2) {
                                            return {
                                              ...option,
                                              redFlagCategory:
                                                val[val.length - 1],
                                            };
                                          }
                                          return option;
                                        }
                                      ),
                                    };
                                  }
                                  return question;
                                }
                              );
                            }
                          );
                        }
                      }}
                    />
                  )}
                </Form.Item>
                <Form.Item label="Email Recipient">
                  <Input
                    type="text"
                    value={option.emailRecipient}
                    disabled={
                      option.redFlagCategory === "" ||
                      !questions[index]?.isLocal
                    }
                    onChange={(e) => {
                      setQuestions(
                        (questions: ClusterSpecialistsQuestion[]) => {
                          return questions.map((question, questionIndex) => {
                            if (questionIndex === index) {
                              return {
                                ...question,
                                options: question.options.map(
                                  (option, optionIndex2) => {
                                    if (optionIndex === optionIndex2) {
                                      return {
                                        ...option,
                                        emailRecipient: e.target.value,
                                      };
                                    }
                                    return option;
                                  }
                                ),
                              };
                            }
                            return question;
                          });
                        }
                      );
                    }}
                  />
                </Form.Item>
              </>
            )}
            {questions[index]?.isLocal && (
              <Button
                type="primary"
                danger
                style={{ maxWidth: "100px" }}
                onClick={() => {
                  setQuestions((questions: ClusterSpecialistsQuestion[]) => {
                    return questions.map((question, questionIndex) => {
                      if (questionIndex === index) {
                        return {
                          ...question,
                          options: question.options.filter(
                            (option, optionIndex2) =>
                              optionIndex !== optionIndex2
                          ),
                        };
                      }
                      return question;
                    });
                  });
                }}
              >
                Delete
              </Button>
            )}
          </S.OptionBox>
        );})}
      {questions[index]?.isLocal && (
        <Button
          type="primary"
          onClick={() => {
            setQuestions((questions: ClusterSpecialistsQuestion[]) => {
              return questions.map((question, questionIndex) => {
                if (questionIndex === index) {
                  return {
                    ...question,
                    options: [
                      ...question.options,
                      {
                        value: "",
                        nextQuestion: null,
                        isTriggeringRedFlag: false,
                        redFlagCategory: "",
                        orderNumber: question.options.length + 1,
                        score: 1,
                        refQuestions: [],
                        emailRecipient: "",
                      },
                    ],
                  };
                }
                return question;
              });
            });
          }}
        >
          Add Option
        </Button>
      )}
    </div>
  );

  const generateYesNoOptions = (index: number) => (
    <div style={{ display: "flex" }}>
      <S.Column>
        <Form.Item label="Yes" style={{ width: 400 }}>
          <Select
            disabled={!questions[index]?.isLocal}
            options={availableOptions
              .filter((option) => option.value > index)
              .concat({
                value: -1,
                label: "End",
              })}
            allowClear
            value={questions[index].options[0].nextQuestion}
            onChange={(value) => {
              setQuestions((questions: ClusterSpecialistsQuestion[]) => {
                return questions.map((question, questionIndex) => {
                  if (questionIndex === index) {
                    return {
                      ...question,
                      options: [
                        {
                          ...question.options[0],
                          nextQuestion: value,
                        },
                        question.options[1],
                      ],
                    };
                  }
                  return question;
                });
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Red Flag">
          <Input
            type="checkbox"
            name={`questions[${index}].options[0].isTriggeringRedFlag`}
            checked={questions[index].options[0].isTriggeringRedFlag}
            disabled={!questions[index]?.isLocal}
            onChange={(e) => {
              setQuestions((questions: ClusterSpecialistsQuestion[]) => {
                return questions.map((question, questionIndex) => {
                  if (questionIndex === index) {
                    return {
                      ...question,
                      options: [
                        {
                          ...question.options[0],
                          isTriggeringRedFlag: e.target.checked,
                          redFlagCategory: "",
                          refQuestions: [],
                          emailRecipient: "",
                        },
                        question.options[1],
                      ],
                    };
                  }
                  return question;
                });
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Score">
          <Input
            name={`questions[${index}].options[0].score`}
            checked={questions[index].options[0].score === 1}
            value={questions[index].options[0].score}
            onChange={(e) => {
              setQuestions((questions: ClusterSpecialistsQuestion[]) => {
                return questions.map((question, questionIndex) => {
                  if (questionIndex === index) {
                    return {
                      ...question,
                      options: [
                        {
                          ...question.options[0],
                          score: parseInt(e.target.value),
                        },
                        question.options[1],
                      ],
                    };
                  }
                  return question;
                });
              });
            }}
          />
        </Form.Item>
        {questions[index].options[0].isTriggeringRedFlag && (
          <>
            <Form.Item label="refQuestions">
              <Select
                mode="multiple"
                maxLength={2}
                options={availableOptions}
                allowClear
                value={questions[index].options[0].refQuestions}
                onChange={(value) => {
                  setQuestions((questions: ClusterSpecialistsQuestion[]) => {
                    return questions.map((question, questionIndex) => {
                      if (questionIndex === index) {
                        return {
                          ...question,
                          options: [
                            {
                              ...question.options[0],
                              refQuestions: value,
                            },
                            question.options[1],
                          ],
                        };
                      }
                      return question;
                    });
                  });
                }}
              />
            </Form.Item>
            <Form.Item label="RF category">
              <Cascader
                showSearch={{ filter }}
                options={treeData}
                expandTrigger="hover"
                changeOnSelect
                placeholder="Select RF Category"
                onChange={(val) => {
                  if (val.length > 0) {
                    setQuestions((questions: ClusterSpecialistsQuestion[]) => {
                      return questions.map((question, questionIndex) => {
                        if (questionIndex === index) {
                          return {
                            ...question,
                            options: [
                              {
                                ...question.options[0],
                                redFlagCategory: val[val.length - 1],
                              },
                              question.options[1],
                            ],
                          };
                        }
                        return question;
                      });
                    });
                  }
                }}
              />
            </Form.Item>
            <Form.Item label="Email Recipient">
              <Input
                name={`questions[${index}].options[0].emailRecipient`}
                value={questions[index].options[0].emailRecipient}
                disabled={questions[index].options[0].redFlagCategory === ""}
                onChange={(e) => {
                  setQuestions((questions: ClusterSpecialistsQuestion[]) => {
                    return questions.map((question, questionIndex) => {
                      if (questionIndex === index) {
                        return {
                          ...question,
                          options: [
                            {
                              ...question.options[0],
                              emailRecipient: e.target.value,
                            },

                            question.options[1],
                          ],
                        };
                      }
                      return question;
                    });
                  });
                }}
              />
            </Form.Item>
          </>
        )}
      </S.Column>
      <S.Column>
        <Form.Item label="No" style={{ width: 400 }}>
          <Select
            options={availableOptions
              .filter((option) => option.value > index)
              .concat({
                value: -1,
                label: "End",
              })}
            value={questions[index].options[1].nextQuestion}
            allowClear
            onChange={(value) => {
              setQuestions((questions: ClusterSpecialistsQuestion[]) => {
                return questions.map((question, questionIndex) => {
                  if (questionIndex === index) {
                    return {
                      ...question,
                      options: [
                        question.options[0],
                        {
                          ...question.options[1],
                          nextQuestion: value,
                        },
                      ],
                    };
                  }
                  return question;
                });
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Red Flag">
          <Input
            type="checkbox"
            checked={questions[index].options[1].isTriggeringRedFlag}
            onChange={(e) => {
              setQuestions((questions: ClusterSpecialistsQuestion[]) => {
                return questions.map((question, questionIndex) => {
                  if (questionIndex === index) {
                    return {
                      ...question,
                      options: [
                        question.options[0],
                        {
                          ...question.options[1],
                          isTriggeringRedFlag: e.target.checked,
                          redFlagCategory: "",
                          refQuestions: [],
                          emailRecipient: "",
                        },
                      ],
                    };
                  }
                  return question;
                });
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Score">
          <Input
            type="number"
            name={`questions[${index}].options[1].score`}
            value={questions[index].options[1].score}
            onChange={(e) => {
              setQuestions((questions: ClusterSpecialistsQuestion[]) => {
                return questions.map((question, questionIndex) => {
                  if (questionIndex === index) {
                    return {
                      ...question,
                      options: [
                        question.options[0],
                        {
                          ...question.options[1],
                          score: Number(e.target.value),
                        },
                      ],
                    };
                  }
                  return question;
                });
              });
            }}
          />
        </Form.Item>
        {questions[index].options[1].isTriggeringRedFlag && (
          <>
            <Form.Item label="refQuestions">
              <Select
                mode="multiple"
                maxLength={2}
                options={availableOptions}
                allowClear
                value={questions[index].options[1].refQuestions}
                onChange={(value) => {
                  setQuestions((questions: ClusterSpecialistsQuestion[]) => {
                    return questions.map((question, questionIndex) => {
                      if (questionIndex === index) {
                        return {
                          ...question,
                          options: [
                            question.options[0],
                            {
                              ...question.options[1],
                              refQuestions: value,
                            },
                          ],
                        };
                      }
                      return question;
                    });
                  });
                }}
              />
            </Form.Item>
            <Form.Item label="RF Category">
              <Cascader
                showSearch={{ filter }}
                options={treeData}
                expandTrigger="hover"
                changeOnSelect
                placeholder="Select RF Category"
                onChange={(val) => {
                  if (val.length > 0) {
                    setQuestions((questions: ClusterSpecialistsQuestion[]) => {
                      return questions.map((question, questionIndex) => {
                        if (questionIndex === index) {
                          return {
                            ...question,
                            options: [
                              question.options[0],
                              {
                                ...question.options[1],
                                redFlagCategory: val[val.length - 1],
                              },
                            ],
                          };
                        }
                        return question;
                      });
                    });
                  }
                }}
              />
            </Form.Item>
            <Form.Item label="Email Recipient">
              <Input
                name={`questions[${index}].options[1].emailRecipient`}
                value={questions[index].options[1].emailRecipient}
                disabled={questions[index].options[1].redFlagCategory === ""}
                onChange={(e) => {
                  setQuestions((questions: ClusterSpecialistsQuestion[]) => {
                    return questions.map((question, questionIndex) => {
                      if (questionIndex === index) {
                        return {
                          ...question,
                          options: [
                            question.options[0],
                            {
                              ...question.options[1],
                              emailRecipient: e.target.value,
                            },
                          ],
                        };
                      }
                      return question;
                    });
                  });
                }}
              />
            </Form.Item>
          </>
        )}
      </S.Column>
    </div>
  );

  return (
    <Collapse
      bordered={false}
      style={{ padding: 0 }}
      defaultActiveKey={["1", "0"]}
      onChange={onChange}
    >
      {questions.map((question, index) => (
        <Panel
          header={`(${index + 1}) ${questions[index].text}`}
          key={`${index}`}
        >
          <S.QuestionBox key={`box_${index}`}>
            <S.HeaderRow>
              <S.Label>Type:</S.Label>
              <Form.Item
                style={{ width: 150, paddingRight: 10, marginBottom: 12 }}
              >
                <Select
                  value={question.type}
                  options={[
                    { value: "radio", label: "Radio" },
                    { value: "text", label: "Text" },
                    { value: "bool", label: "Yes/No" },
                  ]}
                  disabled={!questions[index]?.isLocal}
                  onChange={(value) => {
                    setQuestions((questions: ClusterSpecialistsQuestion[]) => {
                      const newQuestions = [...questions];
                      newQuestions[index].type = value;
                      if (value === "bool") {
                        newQuestions[index].options = [
                          {
                            value: "Yes",
                            nextQuestion: null,
                            isTriggeringRedFlag: false,
                            orderNumber: 1,
                            score: 1,
                            refQuestions: [],
                            emailRecipient: "",
                            redFlagReferencedQuestions: null,
                          },
                          {
                            value: "No",
                            nextQuestion: null,
                            isTriggeringRedFlag: false,
                            orderNumber: 2,
                            score: 0,
                            refQuestions: [],
                            emailRecipient: "",
                            redFlagReferencedQuestions: null,
                          },
                        ];
                      }
                      return newQuestions;
                    });
                  }}
                />
              </Form.Item>
              <S.Label>Question:</S.Label>
              <Form.Item
                style={{
                  width: "100%",
                  paddingRight: 10,
                  marginBottom: 12,
                }}
              >
                <Input
                  type="text"
                  name={`questions[${index}].text`}
                  required
                  value={question.text}
                  disabled={!questions[index]?.isLocal}
                  onChange={(e) => {
                    setQuestions((questions: ClusterSpecialistsQuestion[]) => {
                      return questions.map((question, questionIndex) => {
                        if (questionIndex === index) {
                          return {
                            ...question,
                            text: e.target.value,
                          };
                        }
                        return question;
                      });
                    });
                  }}
                />
              </Form.Item>
            </S.HeaderRow>
            <div>
              {question.type === "bool" && generateYesNoOptions(index)}
              {question.type === "radio" && generateRadio(index)}
            </div>
            <Button
              type="primary"
              danger
              style={{ maxWidth: "200px", marginTop: 10, margin: "auto" }}
              onClick={() => {
                if (questions?.length === 1){
                  toast.info(
                    "Atleast 1 question is needed for strategic audit",
                    {
                      position: "bottom-center",
                    }
                  );         
                  return
                }
                  setQuestions((questions: ClusterSpecialistsQuestion[]) => {
                    return questions.filter(
                      (question, questionIndex) => questionIndex !== index
                    );
                  });
                if (!questions[index]?.isLocal) {
                 deleteQuestion(questions[index]);
                }
              }}
            >
              Delete question
            </Button>
          </S.QuestionBox>
        </Panel>
      ))}
    </Collapse>
  );
};
export default QuestionFormCommon;
