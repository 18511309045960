import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Input, Button, Typography, Switch, Select } from "antd";
import { FormEvent } from "react";
import { EMPTY_ROLE } from "../../../../utils/role";
import { AppContext } from "../../../../App";
import useApiPost from "../../../../hooks/useApiPost";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AddRoleForm = () => {
  const { roleId } = useParams<{ roleId: string }>();
  const { projectId } = useContext(AppContext);
  const [initialActive, setInitialActive] = useState<boolean>(false);
  const [newRole_id, setNewRole_id] = useState("");
  const headerText = roleId ? "Edit Role" : "Add Role";
  const [allRoles, setAllRoles] = useState<[]>([]);
  const { request, setError } = useApiPost();

  const [form, setForm] = useState(EMPTY_ROLE);
  let navigate = useNavigate();

  useEffect(() => {
    const getAllRoles = async () => {
      if (!projectId) {
        return;
      }
      const data = await request("/office/roles", "POST", {
        projectId: projectId,
      });
      data
        .filter((e: any) => e.id !== roleId && e.isActive)
        .map((e: any, index: number) => ({
          label: e.description,
          value: e.id,
        }));
      setAllRoles(data);
    };
    getAllRoles();
  }, [projectId]);

  useEffect(() => {
    (async () => {
      if (!roleId) return;
      // const { role, isActive } = await getRoleDetails(roleId);
      const { role, isActive } = await request(
        "/office/roles/details",
        "POST",
        {
          role_id: roleId,
        }
      );
      setInitialActive(role.isActive);
      setForm(role);
    })();
  }, [roleId]);

  const handleComeBack = () => {
    navigate("/admin/roles");
  };

  const [loading, setLoading] = useState<boolean>(false);

  const updateForm = (key: string, value: any) => {
    setForm((form) => ({
      ...form,
      [key]: value,
    }));
  };

  const sendForm = async (e: FormEvent) => {
    setLoading(true);

    try {
      if (roleId) {
        await request("/office/roles/update", "POST", {
          role_id: roleId,
          name: form.description,
          isActive: form.isActive,
          newRole_id,
        });
      } else {
        await request("/office/roles/insert", "POST", {
          projectId,
          name: form.description,
          isActive: form.isActive,
        });
      }

      setLoading(false);
      handleComeBack();
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={sendForm}
      >
        <Form.Item label="Name">
          <Input
            type="text"
            value={form.description}
            onChange={(e) => updateForm("description", e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Is active" name="isActive">
          <Switch
            defaultChecked={form.isActive}
            checked={form.isActive}
            onChange={(e) => updateForm("isActive", e)}
          />
        </Form.Item>
        {roleId && initialActive && !form.isActive && (
          <>
            <div>
              <p>Reassign users to a new role:</p>
            </div>
            <Form.Item label="New role" hasFeedback name="newRole">
              <Select
                options={allRoles}
                onChange={(_value, e: any) => {
                  setNewRole_id(e.value);
                }}
              />
            </Form.Item>
          </>
        )}
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button type="primary" htmlType="submit">
            {roleId ? "Update" : "Add"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddRoleForm;
