import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Space,
  Table,
  Button,
  Select,
  Input,
  InputRef,
  DatePicker,
  Modal,
} from "antd";
import { AppContext } from "../../../../App";
import { IOption } from "../types";
// import "antd/dist/antd.css";
import { today, getLocalTimeZone, Time } from "@internationalized/date";
import CalendarComponent from "../../../Calendar/Calendar";
import { Provider, defaultTheme } from "@adobe/react-spectrum";
import { TimeField } from "@adobe/react-spectrum";
import type { ColumnsType, ColumnType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { FilterConfirmProps } from "antd/es/table/interface";
import dayjs from "dayjs";
import useApiPost from "../../../../hooks/useApiPost";

interface DataType {
  id: string;
  key: string;
  user: any;
  callType: string;
  outletType: string;
  planned_at: any;
  status: string;
  assignedTo: string;
  outletName: string;
}

interface CalLTypeDataType {
  id: string;
  key: string;
  name: string;
  role_id?: any;
}

interface OutletDataType {
  id: string;
  key: string;
  name: string;
  photo: string;
  address1: string;
}
type DataIndex = keyof DataType;

const dateToStart = new Date();
dateToStart.setHours(0, 0, 0, 0);
const dateFromEnd = new Date();
dateFromEnd.setHours(23, 59, 59, 999);

const CallsList = () => {
  const appContext = useContext(AppContext);
  const { request, setError } = useApiPost();

  const { projectId } = appContext;
  const [isLoading, setIsLoading] = useState(false);
  const [allCalls, setAllCalls] = useState<DataType[]>([]);
  // const [allCallTypes, setAllCallTypes] = useState<CalLTypeDataType[]>([]);
  // const [allOutlets, setAllOutlets] = useState<OutletDataType[]>([]);
  const [isOnlyActiveVisible, setIsOnlyActiveVisible] = useState(true);
  const [userOptions, setUserOptions] = useState<IOption[]>([]);
  const [startDate, setStartDate] = useState<any>(dayjs(dateToStart));
  const [endDate, setEndDate] = useState<any>(dayjs(dateFromEnd));
  const [reassignTarget, setReassignTarget] = useState<IOption | null>(null);
  const [time, setTime] = useState(new Time(10, 0));
  const [date, setDate] = useState(today(getLocalTimeZone()));

  const [selectedAction, setSelectedAction] = useState<
    "Delete One" | "Delete Many" | "Reassign Many" | "Reschedule Many"
  >();

  const handleSelectDate = (event: any) => {
    setDate(event);
  };

  const [dateFrom, setDateFrom] = useState(dateToStart);
  const [dateTo, setDateTo] = useState(dateFromEnd);
  const [dateWithTime, setDateWithTime] = useState<any>(null);

  const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRows(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRows,
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({
      disabled: record.status !== "Open",
    }),
  };

  const getDiary = async () => {
    if (!projectId) {
      return;
    }
    const body = {
      isActiveProjects: isOnlyActiveVisible,
      dateFrom,
      dateTo,
    };

    if (endDate < startDate) {
      return alert("End date must be greater than start date");
    }

    const dayDifference = endDate.diff(startDate, "day");

    if (dayDifference > 14) {
      return alert("Date range must be less than 14 days");
    }

    if (startDate) {
      body.dateFrom = startDate.toDate();
    }
    if (endDate) {
      body.dateTo = endDate.toDate();
    }

    const data = await request(`/office/calls/diary/${projectId}`, "POST", {
      ...body,
    });
    setAllCalls(data);
  };

  useEffect(() => {
    setIsLoading(true);
    getDiary();
  }, [projectId]);

  useEffect(() => {
    const fetchAssigness = async () => {
      setIsLoading(true);
      const { assigness } = await request(
        `/office/calls/options/assignees`,
        "POST",
        {
          projectId,
        }
      );
      setUserOptions(assigness);
      setIsLoading(false);
    };
    fetchAssigness();
  }, []);

  // const getCallTypeName = (id: string) => {
  //   const getCallTypeName = allCallTypes.find((x) => x.id === id);
  //   return <>{getCallTypeName?.name}</>;
  // };

  // const getOutletName = (id: string) => {
  //   const getOutletName = allOutlets.find((x) => x.id === id);
  //   return <>{getOutletName?.name}</>;
  // };

  let navigate = useNavigate();

  const location = useLocation();
  const handleTo = () => {
    const first = location.pathname.split("/")[1];
    navigate(`/${first}/call/new/`);
  };

  const handleUpdate = async (id: string) => {
    const first = location.pathname.split("/")[1];
    navigate(`/${first}/call/view/${id}`);
  };

  const handleGoToUserDiary = (id: string) => {
    const first = location.pathname.split("/")[1];
    navigate(`/${first}/diary/${id}`);
  };

  const handleSelectReassignTarget = async (id: string) => {
    const getUser = userOptions.find((x) => x.value === id);
    if (!getUser) {
      return;
    }
    setReassignTarget(getUser);
  };

  const selectDelete = async (id: string) => {
    setSelectedAction("Delete One");
    setSelectedRows([id]);
    setIsModalOpen(true);
  };

  const handleDelete = async (id: string) => {
    await request(`/office/calls/${id}`, "DELETE");
    const data = allCalls.filter((e) => e.id !== id);
    setAllCalls(data);
  };
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };
  const searchInput = useRef<InputRef>(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: any) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const exists = record[dataIndex] ? record[dataIndex] : false;

      if (!exists) {
        return false;
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        //@ts-ignore
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = (props: any): ColumnsType<DataType> => [
    {
      title: "Assigned to",
      dataIndex: "assignedTo",
      key: "assignedTo",
      ...getColumnSearchProps("assignedTo"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Call Type",
      dataIndex: "callType",
      key: "callType",
      ...getColumnSearchProps("callType"),
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: "Outlet",
      dataIndex: "outletName",
      key: "outletName",
      ...getColumnSearchProps("outletName"),
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ...getColumnSearchProps("status"),
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: "Date",
      dataIndex: "plannedCallDate",
      key: "plannedCallDate",
      render: (date: any) => (
        <>
          <div>{`${new Date(date).toLocaleString()}`}</div>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space size="middle" key={record.id}>
          <Button
            type="primary"
            danger
            onClick={() => props.onDelete(record.id)}
            disabled={record.status !== "Open" && record.status !== "Busy"}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];
  
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (date && time) {
      const { hour, minute } = time;
      let selectedDateWithTime = new Date(
        date.toDate(getLocalTimeZone()).setHours(hour, minute)
      );
      setDateWithTime(selectedDateWithTime);
    }
  }, [date, time]);

  const confirmAction = async () => {
    if (selectedAction === "Delete One") {
      if (!selectedRows[0]) return;
      await handleDelete(selectedRows[0].toString());
    } else if (selectedAction === "Delete Many") {
      await Promise.all(
        selectedRows.map(async (row) => await handleDelete(row.toString()))
      );

      const data = allCalls.filter((e) => !selectedRows.includes(e.id));
      setAllCalls(data);
    } else if (selectedAction === "Reassign Many") {
      setIsLoading(true);

      await request("/office/calls/reassign", "POST", {
        projectId,
        plannedCallIds: selectedRows.map((row) => row.toString()),
        assigneeId: reassignTarget?.value,
      });

      await getDiary();
      setIsLoading(false);
    } else if (selectedAction === "Reschedule Many") {
      setIsLoading(true);

      await request("/office/calls/reschedule", "POST", {
        projectId,
        plannedCallIds: selectedRows.map((row) => row.toString()),
        dateWithTime,
      });

      await getDiary();
      setIsLoading(false);
    }
    setSelectedRows([]);
    setIsModalOpen(false);
  };

  const modalText = () => {
    if (selectedAction === "Delete One") {
      return "Are you sure you want to delete this call? This action cannot be undone.";
    } else if (selectedAction === "Delete Many") {
      return "Are you sure you want to delete these calls? This action cannot be undone.";
    } else if (selectedAction === "Reassign Many") {
      if (!reassignTarget) {
        return "Please select assignee";
      }
      return `Are you sure you want to reassign these calls to ${reassignTarget?.label}?`;
    } else if (selectedAction === "Reschedule Many") {
      return `Are you sure you want to reschedule these calls?`;
    }
  };

  const modalTitle = () => {
    if (selectedAction === "Delete One") {
      return "Delete call";
    } else if (selectedAction === "Delete Many") {
      return "Delete calls";
    } else if (selectedAction === "Reassign Many") {
      return "Reassign calls";
    } else if (selectedAction === "Reschedule Many") {
      return "Reschedule calls";
    }
  };

  const handleSelectDelete = async () => {
    setSelectedAction("Delete Many");
    setIsModalOpen(true);
  };

  const handleSelectReassign = async () => {
    setSelectedAction("Reassign Many");
    setIsModalOpen(true);
  };

  const handleSelectReschedule = async () => {
    setSelectedAction("Reschedule Many");
    setIsModalOpen(true);
  };

  return (
    <>
      <Modal
        title={modalTitle()}
        open={isModalOpen}
        onOk={confirmAction}
        onCancel={handleModalCancel}
        centered
        footer={[
          <Button key="back" onClick={handleModalCancel}>
            Back
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={confirmAction}
            disabled={selectedAction === "Reassign Many" && !reassignTarget}
          >
            Confirm
          </Button>,
        ]}
      >
        <div>{modalText()}</div>
        {selectedAction === "Reassign Many" && (
          <div>
            <div style={{ marginTop: "20px" }}>Select assignee</div>
            <Select
              loading={isLoading}
              options={userOptions}
              style={{ width: 200 }}
              // defaultValue={formOptionValues.role_id}
              onChange={(_value, e) => {
                handleSelectReassignTarget(_value);
              }}
            />
          </div>
        )}
        {selectedAction === "Reschedule Many" && (
          <Provider theme={defaultTheme} locale={"en-EN"} height={"100%"}>
            {selectedRows?.length == 1 && <TimeField
              hourCycle={24}
              hideTimeZone
              value={time}
              onChange={(e: any) => setTime(e)}
              aria-label="Pick time"
              defaultValue={undefined}
            />}
            <CalendarComponent
              defaultValue={date}
              onChange={(e: any) => handleSelectDate(e)}
            />
          </Provider>
        )}
      </Modal>
      <div
        style={{
          display: "flex",
          marginBottom: "20px",
          alignItems: "flex-end",
        }}
      >
        <div>
          <div>Start Date:</div>
          <DatePicker
            allowClear={false}
            value={startDate}
            onChange={(date, dateString) => {
              let newDate = dayjs(dateString);
              newDate = newDate.hour(2).minute(0).second(0);
              setStartDate(dayjs(newDate));
            }}
          />
        </div>
        <div style={{ marginLeft: "20px" }}>
          <div>End Date:</div>
          <DatePicker
            allowClear={false}
            value={endDate}
            onChange={(date, dateString) => {
              let newDate = dayjs(dateString);
              newDate = newDate.hour(23).minute(59).second(59);
              setEndDate(dayjs(newDate));
            }}
          />
        </div>
        <div style={{ marginLeft: "20px" }}>
          <Button type="primary" onClick={() => getDiary()}>
            Refresh
          </Button>
        </div>
      </div>
      <Table
        rowSelection={rowSelection}
        size="small"
        columns={columns({
          onUpdate: handleUpdate,
          onDelete: selectDelete,
        })}
        dataSource={allCalls}
        rowKey="id"
        pagination={{
          pageSize: 20,
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <div>
          Select assignee
          <Select
            loading={isLoading}
            options={userOptions}
            style={{ width: 200 }}
            onChange={(_value, e) => {
              handleGoToUserDiary(_value);
            }}
          />
          <Button
            type="primary"
            onClick={handleTo}
            style={{ marginLeft: "20px" }}
          >
            Add calls
          </Button>
        </div>
        <div>
          <Button
            type="primary"
            danger
            onClick={handleSelectDelete}
            disabled={selectedRows.length === 0}
            style={{ marginRight: "10px" }}
          >
            Delete selected
          </Button>

          <Button
            type="primary"
            onClick={handleSelectReassign}
            disabled={selectedRows.length === 0}
            style={{ marginRight: "10px" }}
          >
            Reassign selected
          </Button>
          <Button
            type="primary"
            onClick={handleSelectReschedule}
            disabled={selectedRows.length === 0}
          >
            Reschedule selected
          </Button>
        </div>
      </div>
    </>
  );
};

export default CallsList;
