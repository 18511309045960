import { Button } from "antd";
import * as S from "./AndtButton.styles";

export enum ANDT_BUTTON_TYPES {
  primary = "primary",
  ghost = "ghost",
  dashed = "dashed",
  link = "link",
  text = "text",
  default = "default",
}

interface IAndtButton {
  type: ANDT_BUTTON_TYPES;
  isDisabled: boolean;
  onClick: () => void;
  text: string;
}

const AndtButton = ({ type, isDisabled, onClick, text }: IAndtButton) => {
  return (
    <S.Wrapper>
      <Button type={type} disabled={isDisabled} onClick={onClick}>
        {text}
      </Button>
    </S.Wrapper>
  );
};

export default AndtButton;
