import styled from "styled-components";

export const EventsList = styled.ul`
  list-style: none;
  li {
    div {
      div {
        .ant-card-body {
          text-align: right;
        }
      }
    }
  }
`;
