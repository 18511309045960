import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  width: 75%;
`;

export const Label = styled.label`
  font-weight: 700;
  padding-bottom: 6px;
`;
