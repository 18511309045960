import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// import "antd/dist/antd.css";
import { Space, Table, Button, Switch, InputRef, Input, Modal } from "antd";
// import {
//   deleteOutlet,
//   getAllOutletsData,
//   rejectSubmission,
// } from "../../../../api/office/outlet";
import type { ColumnsType, ColumnType } from "antd/es/table";
import type { FilterConfirmProps } from "antd/es/table/interface";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { AppContext } from "../../../../App";
import useApiPost from "../../../../hooks/useApiPost";

interface DataType {
  id: string;
  key: string;
  name: string;
  photo: string;
  address1: string;
  created_at: Date;
  isVerified: boolean;
  userFriendlyCounter: string;
}

type DataIndex = keyof DataType;

// interface ColumnType {
//   onUpdate: (id: string) => void;
//   onDelete: (id: string) => void;
// }

const OutletsList = () => {
  const { projectId } = useContext(AppContext);
  const [isShowOnlyVerified, setIsShowOnlyVerified] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const { request, setError } = useApiPost();

  const columns = (props: any): ColumnsType<DataType> => [
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      render: (val) => (
        <div>
          <Switch defaultChecked={val} disabled />
        </div>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <div>{new Date(text).toLocaleDateString()}</div>,
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <div>{text}</div>,
      ...getColumnSearchProps("name"),
    },
    {
      title: "Retail Cloud ID",
      dataIndex: "userFriendlyCounter",
      key: "userFriendlyCounter",
      ...getColumnSearchProps("userFriendlyCounter"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Telephone",
      dataIndex: "telephoneNumber",
      key: "telephoneNumber",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space size="middle" key={record.id}>
          <Button
            type={record.isVerified ? "primary" : "dashed"}
            onClick={() => props.onUpdate(record.id)}
          >
            {record.isVerified ? "Update" : "Verify"}
          </Button>
          <Button
            type="primary"
            danger
            onClick={() => props.onDelete(record.id, record.isVerified)}
            disabled={isDeleting}
          >
            {record.isVerified ? "Delete" : "Reject"}
          </Button>
        </Space>
      ),
    },
  ];

  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: any) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          {/* <Button
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
        >
          close
        </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        //@ts-ignore
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [allOutlets, setAllOutlets] = useState<DataType[]>([]);
  let navigate = useNavigate();

  const location = useLocation();
  const handleTo = () => {
    const first = location.pathname.split("/")[1];
    navigate(`/${first}/outlet/new`);
  };

  const handleBulkMapping = () => {
    const first = location.pathname.split("/")[1];
    navigate(`/${first}/outlet/bulkMapping`);
  };
  const handleCFBulkMapping = () => {
    const first = location.pathname.split("/")[1];
    navigate(`/${first}/outlet/bulkCallFrequencyMapping`);
  };
  useEffect(() => {
    const getAllOutlets = async () => {
      setIsLoading(true);
      const outlets = await request("/office/outlets/all", "POST", {
        projectId,
      });
      const data = outlets.map((e: any, index: number) => ({
        ...e,
        key: index,
      }));
      setAllOutlets(data);
      setIsLoading(false);
    };
    getAllOutlets();
  }, []);

  const handleUpdate = (id: string) => {
    const first = location.pathname.split("/")[1];
    navigate(`/${first}/outlet/view/${id}`);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    const selectedOutlet = allOutlets.find((e) => e.id === selectedId);

    if (!selectedOutlet) return;

    const { isVerified } = selectedOutlet;

    setIsDeleting(true);

    if (isVerified) {
      await request("/office/outlets/delete-outlet", "POST", {
        outletId: selectedId,
      });
    } else {
      await request("/office/outlets/reject-submission", "POST", {
        projectId,
        outletId: selectedId,
      });
    }

    setAllOutlets(allOutlets.filter((e) => e.id !== selectedId));
    setIsLoading(false);
    setIsDeleting(false);
  };

  const handleToggleVerified = () => {
    setIsShowOnlyVerified(!isShowOnlyVerified);
  };

  const handleConfirmDelete = () => {
    setIsModalOpen(false);
    handleDelete();
    setAllOutlets(allOutlets.filter((item: any) => item.id !== selectedId));
    setSelectedId("");
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    setSelectedId("");
  };

  const handleSelectDelete = async (id: string) => {
    setSelectedId(id);
    setIsModalOpen(true);
  };

  return (
    <div>
      <h1>Outlets</h1>
      <Modal
        title="Confirmation"
        open={isModalOpen}
        onOk={handleConfirmDelete}
        onCancel={handleModalCancel}
        centered
        footer={[
          <Button key="back" onClick={handleModalCancel}>
            Back
          </Button>,
          <Button key="submit" type="primary" onClick={handleConfirmDelete}>
            Confirm
          </Button>,
        ]}
      >
        <div>
          Are you sure you want to delete this item? This action cannot be
          undone.
        </div>
      </Modal>
      <div>
        <div
          style={{
            paddingBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Switch
            checkedChildren="All"
            unCheckedChildren="Non verified"
            defaultChecked
            onChange={() => handleToggleVerified()}
          />
          {/* <Button type="primary" onClick={() => getDiary()}>
            Refresh
          </Button> */}
        </div>
      </div>
      <Table
        columns={columns({
          onUpdate: handleUpdate,
          onDelete: handleSelectDelete,
        })}
        dataSource={
          isShowOnlyVerified
            ? allOutlets.filter((e) => !e.isVerified)
            : allOutlets
        }
        rowKey="key"
        pagination={{
          defaultPageSize: 25,
        }}
        loading={isLoading}
        rowClassName={(record, index) =>
          record.isVerified ? "" : "not-verified"
        }
      />

      <Button type="primary" onClick={handleTo}>
        Add
      </Button>
      <Button type="primary" style={{ margin: 10 }} onClick={handleBulkMapping}>
        Bulk Mapping
      </Button>
      <Button type="primary" style={{ margin: 0 }} onClick={handleCFBulkMapping}>
        Bulk Call Frequency Mapping
      </Button>
    </div>
  );
};

export default OutletsList;
