import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import "antd/dist/antd.css";
import { Col, Row, Form, Button, Typography, Select } from "antd";
import { FormEvent } from "react";
import { EMPTY_CALL } from "../../../../utils/call";
import CalendarComponent from "../../../Calendar/Calendar";
import { today, getLocalTimeZone } from "@internationalized/date";
import useApiPost from "../../../../hooks/useApiPost";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const UpdateCall = () => {
  const { callId } = useParams<{ callId: string }>();
  const { request, setError } = useApiPost();

  useEffect(() => {
    (async () => {
      const updateCall = await request(`/call/${callId}`);
      setForm(updateCall);
    })();
  }, [callId]);
  const [form, setForm] = useState(EMPTY_CALL);
  const [, setIsLoading] = useState(false);
  const [allCallTypes, setAllCallTypes] = useState<any[]>([]);
  const [allOutlets, setAllOutlets] = useState<any[]>([]);
  const [date, setDate] = useState(today(getLocalTimeZone()));

  let navigate = useNavigate();

  const [allUsers, setAllUsers] = useState<any[]>([]);

  useEffect(() => {
    const getAllUsers = async () => {
      const res = await request("/user/all");
      const data = res.map((e: any, index: number) => ({
        ...e,
        key: index,
      }));
      setAllUsers(data);
    };
    const getAllCallType = async () => {
      const res = await request("/call-type/all");
      const data = res.map((e: any, index: number) => ({
        ...e,
        key: index,
      }));
      setAllCallTypes(data);
    };
    const getAllOutlets = async () => {
      const res = await request("/outlets/all");
      const data = res.map((e: any, index: number) => ({
        ...e,
        key: index,
      }));
      setAllOutlets(data);
    };
    setIsLoading(true);
    getAllUsers();
    getAllCallType();
    getAllOutlets();
    setIsLoading(false);
  }, []);

  const handleComeBack = () => {
    navigate("/admin/calls");
  };

  const handleAddAnotherCallType = () => {
    setResultInfo(null);
    setForm(EMPTY_CALL);
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [resultInfo, setResultInfo] = useState<string | null>(null);

  const updateForm = (key: string, value: any) => {
    setForm((form) => ({
      ...form,
      [key]: value,
    }));
  };

  const handleSelectDate = (event: any) => {
    updateForm("planned_at", event.toDate());
    setDate(event);
  };

  const sendForm = async (e: FormEvent) => {
    setLoading(true);
    try {
      await request(`/call/${callId}/`, "PATCH", {
        ...form,
      })
      setLoading(false);
      setResultInfo(`The call has been updated.`);
      handleComeBack();
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (resultInfo !== null) {
    return (
      <>
        <Row justify="space-between">
          <Col span={18} push={4}>
            <Typography.Title>
              A new call has been successfully added to the database.
            </Typography.Title>
            <Button
              type="primary"
              style={{ margin: 10 }}
              onClick={handleAddAnotherCallType}
            >
              Add another one
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  const callTypeOptions = allCallTypes.map((a) => ({
    value: a.id,
    label: a.name,
  }));

  const outletOptions = allOutlets.map((a) => ({ value: a.id, label: a.name }));

  const userOptions = allUsers.map((a) => ({
    value: a.id,
    label: `${a.name} ${a.surname}`,
  }));

  const getUserName = (id: string) => {
    const getUser = allUsers.find((x) => x.id === id);
    return getUser?.name;
  };
  return (
    <>
      <Typography.Title>Update call:</Typography.Title>
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={sendForm}
      >
        <Form.Item label="User">
          <Select
            onChange={(e: any) => updateForm("user", e)}
            options={userOptions}
            defaultValue={getUserName(form.assigned_to)}
          />
        </Form.Item>
        <Form.Item label="Call type">
          <Select
            onChange={(e: any) => updateForm("callType", e)}
            options={callTypeOptions}
          />
        </Form.Item>
        <Form.Item label="Outlet">
          <Select
            onChange={(e: any) => updateForm("outlet", e)}
            options={outletOptions}
          />
        </Form.Item>
        <Form.Item label="When">
          <CalendarComponent
            defaultValue={date}
            onChange={(e: any) => handleSelectDate(e)}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
          <Button
            type="primary"
            style={{ margin: 10 }}
            onClick={handleComeBack}
          >
            MENU
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default UpdateCall;
