import styled from "styled-components";
import { Link } from "react-router-dom";

export const Main = styled.div`
  display: flex;
  height: 100%;
  /* min-height: 100vh; */
  flex-direction: column;
`;

export const HeaderLink = styled.div`
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 700;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(30 41 59);
  padding: 1rem;
  color: white;
`;

export const MainContainer = styled.div`
  display: flex;
  height: 100%;
  background-color: rgb(255 255 255);
`;

export const NavContainer = styled.div`
  height: 100%;
  border-right-width: 1px;
  background-color: rgb(249 250 251);
`;

export const NavLink = styled(Link)`
  display: block;
  padding: 1rem;
  /* font-size: 1.25rem; */
  line-height: 1.75rem;
  color: rgb(59 130 246);
`;

export const SwitchContainer = styled.div`
  flex: 1 1 0%;
  display: flex;
  justify-content: center;
  padding-top: 2rem;

  @media (min-width: 768px) {
  padding-top: 0;
    align-items: center;
  }
`;
