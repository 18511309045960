export const EMPTY_OUTLET: any = {
  name: "",
  photo: "",
  address1: "",
  outletType_id: "",
  outletState_id: "",
  chain_id: "",
  masterChain_id: "",
  city_id: "",
  channel_id: "",
  hierarchy_id: "",
  clientOutletName: "",
  streetNumber: "",
  streetAddress: "",
  shopNumber_id: "",
  mall: "",
  latitude: "",
  longitude: "",
  storeCode: "",
  telephoneNumber: "",
  alternativeNumber: "",
  postalAddress: "",
  postalCode: "",
  outletPhoto: "",
  openingDate: "",
  outletBarcode: "",
  isActive: false,
  isVerified: false,
  clientOutletAccount: "",
  clientOutletCode: "",
  users_id: [],
  callTypes_id: [],
  callType_id: [],
  generatorStatus_id: "",
  outletGrade_id: "",
  callFrequencies: {}
};
