import styled from 'styled-components'

export const SideFiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0 16px;
  box-sizing: border-box;
`

export const SideFiltersHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid #d9d9d9;
`

export const SideFiltersTitle = styled.h3`
  margin: 0;
  font-weight: 700;
  font-size: 18px;
`

export const SideFiltersContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 16px 0;
  box-sizing: border-box;

  & > * {
    margin-bottom: 16px;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`