import styled from "styled-components";

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 36px;
  max-width: 1000px;
  margin: 0 auto;
`;

export const StyledImage = styled.img`
  border-radius: 8px;
  max-width: 100%;
  height: auto;
`;