import { Button, Form, Input, Typography } from "antd";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../../App";
import useApiPost from "../../../../../hooks/useApiPost";
const { TextArea } = Input;

const EMPTY_EVENT = {
  text: "",
  id: "",
  transaction_id: "",
};

const EventEdit = () => {
  const { projectId } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(EMPTY_EVENT);
  const { request, setError } = useApiPost();

  useEffect(() => {
    const getAllRoles = async () => {
      if (!projectId) {
        return;
      }
      setIsLoading(true);
      // const eventDetails = await getEventDetails(projectId);
      const eventDetails = await request(
        `/office/events/heads-up/${projectId}`
      );
      setForm(eventDetails);
      setIsLoading(false);
    };
    getAllRoles();
  }, []);

  const updateEvent = async () => {
    // await updateEventDetails(form.transaction_id, form.text);
    await request("/office/events/heads-up", "POST", {
      transaction_id: form.transaction_id,
      text: form.text,
    });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Typography.Title>Heads up Message</Typography.Title>
      <Form labelCol={{ span: 4 }} name="text">
        <Form.Item label="Text">
          <TextArea
            value={form.text}
            onChange={(e) => setForm({ ...form, text: e.target.value })}
          />
        </Form.Item>
        <Button type="primary" onClick={() => updateEvent()}>
          Save
        </Button>
      </Form>
    </>
  );
};
export default EventEdit;
