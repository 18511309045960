import { IDataEntry } from "../../components/TVDashboard/Dashboard";
import { instance } from "../common-api";

export const getAllData = async (
  projectId: string,
  selectedGeography: string,
  // selectedYear: number,
  // selectedMonth: number
  startDate: Date,
  endDate: Date,
  chain?: string
): Promise<any> => {
  const res = await instance.post(`v1/office/control-centre/all`, {
    projectId,
    selectedGeography,
    // selectedYear,
    // selectedMonth,
    startDate,
    endDate,
    chain,
  });
  return res.data;
};

export const getDashboardStocksRegional = async (
  projectId: string,
  selectedChain: string,
  // selectedYear: number,
  // selectedMonth: number
  startDate: Date,
  endDate: Date,
  locations?: any,
  products?: any,
  selectedRow?: string,
): Promise<any> => {
  let dataParams: any = {
      projectId,
    }
    if(selectedChain){
      dataParams = {...dataParams, selectedChain}
    }
    if(startDate){
      dataParams = {...dataParams, startDate}
    }
    if(endDate){
      dataParams = {...dataParams, endDate}
    }
    if(locations?.length > 0){
      dataParams = {...dataParams, locations}
    }
    if(products?.length > 0){
      dataParams = {...dataParams, products}
    }
    if(selectedRow){
      dataParams = {...dataParams, selectedRow}
    }
    
  const res = await instance.post(
    `v1/office/control-centre/dashboard-stocks-regional`,dataParams
  );
  return res.data;
};

export const getDashboardStocksChain = async (
  projectId: string,
  selectedRegion: string,
  // selectedYear: number,
  // selectedMonth: number,
  startDate: Date,
  endDate: Date,
  chain: string,
  locations?: any,
  products?: any,
  selectedRow?: string,
): Promise<any> => {
  let dataParams: any = {
      projectId,
    }
    if(selectedRegion){
      dataParams = {...dataParams, selectedRegion}
    }
    if(startDate){
      dataParams = {...dataParams, startDate}
    }
    if(endDate){
      dataParams = {...dataParams, endDate}
    }
   if(chain){
      dataParams = {...dataParams, chain}
    }
   if(locations?.length > 0){
      dataParams = {...dataParams, locations}
    }
    if(products?.length > 0){
      dataParams = {...dataParams, products}
    }
   if(selectedRow){
      dataParams = {...dataParams, selectedRow}
    }
   
  const res = await instance.post(
    `v1/office/control-centre/dashboard-stocks-chain`,dataParams
  );
  return res.data;
};

export const getPlaybookData = async (projectId: string): Promise<any> => {
  const res = await instance.post(`v1/office/control-centre/success-playbook`, {
    projectId,
  });

  return res.data;
};

export const getStarterPacks = async (
  projectId: string,
  selectedRegion: string,
  // selectedYear: number,
  // selectedMonth: number
  startDate: Date,
  endDate: Date,
  chain: string,
  products: string[]
): Promise<any> => {
  
   let dataParams: any = {
      projectId,
    }
    if(selectedRegion){
      dataParams = {...dataParams, selectedRegion}
    }
    if(startDate){
      dataParams = {...dataParams, startDate}
    }
    if(endDate){
      dataParams = {...dataParams, endDate}
    }
    if(chain){
      dataParams = {...dataParams, chain}
    }
    if(products?.length > 0){
      dataParams = {...dataParams, products}
    }
  const res = await instance.post(`v1/office/control-centre/starter-packs`,dataParams);
  return res.data;
};
