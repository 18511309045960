import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Typography,
  Select,
  Cascader,
  Tag,
  DatePicker,
  Switch,
} from "antd";
import { FormEvent } from "react";

import { AppContext } from "../../../../App";
import { DefaultOptionType } from "antd/lib/select";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ImportInfoTable from "../common/ImportInfoTable";
import readXlsxFile from "read-excel-file";
import templateImg from "../../../../assets/images/outlet-research-template-example.png";
import useApiPost from "../../../../hooks/useApiPost";

dayjs.extend(customParseFormat);

const columns = [
  {
    title: "In progress",
    column: "In progress",
  },
];
const description =
  "Below you wil find a list of fields and their corresponding excel columns. Please make sure that the values are correct before importing the file.";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
export const EMPTY_RESEARCH = {
  id: "",
  name: "",
  description: "",
  validTo: null,
  validFrom: null,
  chain_id: [],
  callType_id: [],
  outletType_id: [],
  userRoles_id: [],
  outletState_id: [],
  hierarchy_id: [] as any,
};

const OutletResearchForm = () => {
  const { projectId } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const { researchId } = useParams<{ researchId: string }>();
  const headerText = researchId
    ? "Edit Outlet Research"
    : "Add Outlet Research";
  const [isActive, setIsActive] = useState<any>(false);
  const { request, setError } = useApiPost();

  const [form, setForm] = useState(EMPTY_RESEARCH);
  const [allCallTypes, setAllCallTypes] = useState<any[]>([]);
  const [chains, setChains] = useState<any[]>([]);
  const [outletTypes, setOutletTypes] = useState<any[]>([]);
  const [outletStates, setOutletStates] = useState<any[]>([]);
  const [userRoles, setUserRoles] = useState<any[]>([]);
  const [treeData, setTreeData] = useState<any[]>([]);
  const [regionText, setRegionText] = useState<string[]>([]);

  const [formOptionValues, setFormOptionValues] = useState({
    outletType_id: null,
  });

  let navigate = useNavigate();
  // const [research, setResearch] = useState<any>(EMPTY_RESEARCH);
  const [outletResearchDetails, setOutletResearchDetails] = useState<any[]>([]);

  const handleComeBack = () => {
    navigate("/admin/outlet-research");
  };

  const handleUploadBatchFile = async (e: any) => {
    const file = e.target.files[0];
    const data: any[] = [];

    await readXlsxFile(file, { sheet: 2 }).then(async (rows) => {
      rows.map((row, index) => {
        if (index > 0) {
          const chain = row[0]?.toString().trim();
          const outletType = row[1]?.toString().trim();
          // headers
          if (chain === "Chain" && outletType === "Outlet Type") {
            return;
          }
          const category = row[6]?.toString().trim();
          const questionText = row[7]?.toString().trim();
          const questionSequence = row[8]?.toString().trim();
          const questionType = row[9]?.toString().trim();
          const responseText = row[12]?.toString().trim();
          const responseSequence = row[13]?.toString().trim();

          const item: any = {
            category,
            questionText,
            questionSequence: parseInt(questionSequence),
            questionType,
            responseText,
            responseSequence: parseInt(responseSequence),
          };

          data.push(item);
        }
      });

      console.log({ data });

      const researchQuestions: any[] = [];
      data.map((item) => {
        if (
          researchQuestions.some(
            (t) => t.questionSequence === item.questionSequence
          )
        ) {
          const question = researchQuestions.find(
            (t) => t.questionSequence === item.questionSequence
          );
          if (question) {
            question.responses.push({
              responseText: item.responseText,
              responseSequence: item.responseSequence,
            });
          }
        } else {
          researchQuestions.push({
            questionText: item.questionText,
            category: item.category,
            questionSequence: item.questionSequence,
            questionType: item.questionType,
            responses: [
              {
                responseText: item.responseText,
                responseSequence: item.responseSequence,
              },
            ],
          });
        }
      });
      if (researchQuestions.length > 0) {
        const properQuestions = researchQuestions.filter(
          (question) => !!question.questionText
        );
        await validateBasket(properQuestions);
      }
    });
  };

  const fetchData = async () => {
    const options = await request("/office/outlet-research/options", "POST", {
      projectId,
    });

    const {
      callTypes,
      chains,
      outletTypes,
      userRoles,
      outletStates,
      geography,
    } = options;

    setAllCallTypes(callTypes || []);
    setChains(chains || []);
    setOutletTypes(outletTypes || []);
    setUserRoles(userRoles || []);
    setOutletStates(outletStates || []);
    setTreeData(geography || []);
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const getAllData = async () => {
      if (!researchId) return;
      setIsLoading(true);
      const data = await request(`/office/outlet-research/${researchId}`);
      setOutletResearchDetails(data?.questions || []);
      setForm({
        ...form,
        ...data,
        validFrom: dayjs(data.startDate || new Date()),
        validTo: dayjs(data.endDate || new Date()),
        callType_id: data.callType_id
          ? data.callType_id.map((u: any) => u.value)
          : [],
        chain_id: data.chains,
        outletType_id: data.outletType_id
          ? data.outletType_id.map((u: any) => u.value)
          : [],
        userRoles_id: data.userRoles_id
          ? data.userRoles_id.map((u: any) => u.value)
          : [],
        outletState_id: data.outletState_id
          ? data.outletState_id.map((u: any) => u.value)
          : [],
        hierarchy_id: data.region_id
        ? data.region_id.map((u: any) => u.value)
        : [],
      });
      setIsActive(data.isActive);
      setRegionText(data.connectedRegionsText);

      // const data = outlets.map((e: any, index: number) => ({
      //   ...e,
      //   key: index,
      // }));
      setIsLoading(false);
    };
    getAllData();
  }, []);
  // const [loading, setLoading] = useState<boolean>(false);
  const [resultInfo, setResultInfo] = useState<string | null>(null);

  const updateForm = (key: string, value: any) => {
    setForm((form) => ({
      ...form,
      [key]: value,
    }));
  };

  const onChange = (key: string, value: any) => {
    const lastValue = Array.isArray(value) ? value[value.length - 1] : value;
    handleOptionChange(key, { value: lastValue });
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1
    );
  const handleOptionChange = (key: string, event: any) => {
    setFormOptionValues({
      ...formOptionValues,
      [key]: event.value,
    });
  };
  const sendForm = async (e: FormEvent) => {
    setIsLoading(true);

    try {
      const allVals: string[] = form.hierarchy_id.reduce(
        (acc: string | any[], val: any) => acc.concat(val),
        []
      );
      const uniqueRegionIds = allVals.filter((v, i, a) => a.indexOf(v) === i);

      const newData = {
        id: researchId,
        chains: form.chain_id,
        isActive,
        projectId,
        description: form.description,
        name: form.name,
        startDate: form.validFrom,
        endDate: form.validTo,
        userRoles_id: form.userRoles_id,
        hierarchy_id: uniqueRegionIds,
        outletType_id: form.outletType_id,
        outletState_id: form.outletState_id,
        callType_id: form.callType_id,
      };

      await request(`/office/outlet-research/update`, "POST", {
        ...newData,
      });

      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };
  const displayRender = (labels: string[]) => labels[labels.length - 1];

  if (
    isLoading ||
    outletTypes.length === 0 ||
    outletStates.length === 0 ||
    treeData.length === 0 ||
    allCallTypes.length === 0 ||
    userRoles.length === 0 ||
    chains.length === 0
  ) {
    return <p>Loading...</p>;
  }

  const validateBasket = async (data: any) => {
    setIsLoading(true);
    await request("/office/outlet-research/insert", "POST", {
      newData: data,
      projectId,
      startDate: form.validFrom || new Date(),
      endDate: form.validTo || new Date(),
      name: form.name,
    });
    alert("Outlet Research import successful");
    handleComeBack();
    setIsLoading(false);
  };

  if (!researchId) {
    return (
      <>
        <Typography.Title>{headerText}</Typography.Title>
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          // onFinish={onFinish}
        >
          <Form.Item label="Name" required>
            <Input
              type="text"
              value={form.name}
              onChange={(e) => updateForm("name", e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="Start"
            // name="startDate"
            // getValueProps={(i: any) => ({ value: moment(i) })}
            required
          >
            <DatePicker
              allowClear={false}
              value={form.validFrom}
              // onChange={(e) => setStartDate(e?.toDate())}
              onChange={(e: any) => updateForm("validFrom", e)}
            />
          </Form.Item>
          <Form.Item
            label="End"
            // name="endDate"
            // getValueProps={(i: any) => ({ value: moment(i) })}
            required
            rules={[
              {
                required: true,
                message: "Please select a form end",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  const start = new Date(getFieldValue("startDate"));
                  const end = new Date(value);

                  if (start > end) {
                    return Promise.reject(
                      "End date must be greater than start date"
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <DatePicker
              allowClear={false}
              value={form.validTo}
              // onChange={(e) => setEndDate(e?.toDate())}
              onChange={(e: any) => updateForm("validTo", e)}
            />
          </Form.Item>

          <ImportInfoTable
            columns={columns}
            description={description}
            imgSrc={templateImg}
          />
          <div>
            <input
              type="file"
              id="input"
              disabled={isLoading || !projectId || !form.name}
              onChange={(e) => handleUploadBatchFile(e)} //handleUploadBatchFile(e)}
            />
          </div>
          {/* {entriesExist && (
          <Button type="primary" onClick={validateBasket}>
            Validate basket
          </Button>
        )}
        <Button type="primary" style={{ margin: 10 }} onClick={handleComeBack}>
          Go back
        </Button> */}

          {/* <Modal
          title="Basic Modal"
          open={isConfirmModalOpen}
          centered
          width={1000}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              type="primary"
              onClick={handleOk}
              // disabled={!isCreateAvailable}
            >
              Submit
            </Button>,
          ]}
        >
          <p
            style={{
              textAlign: "center",
            }}
          >
            Your basket is almost ready!
          </p>
          {/* list newly created products */}
          {/* </Form.Item> */}
        </Form>
      </>
    );
  }

  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Form
        // form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={sendForm}
      >
        <Form.Item label="Name">
          <Input
            type="text"
            value={form.name}
            onChange={(e) => updateForm("name", e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="Start"
          // name="startDate"
          // getValueProps={(i: any) => ({ value: moment(i) })}
          required
        >
          <DatePicker
            allowClear={false}
            value={form.validFrom}
            // onChange={(e) => setStartDate(e?.toDate())}
            onChange={(e: any) => updateForm("validFrom", e)}
          />
        </Form.Item>
        <Form.Item
          label="End"
          // name="endDate"
          // getValueProps={(i: any) => ({ value: moment(i) })}
          required
          rules={[
            {
              required: true,
              message: "Please select a form end",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const start = new Date(getFieldValue("startDate"));
                const end = new Date(value);

                if (start > end) {
                  return Promise.reject(
                    "End date must be greater than start date"
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <DatePicker
            allowClear={false}
            value={form.validTo}
            // onChange={(e) => setEndDate(e?.toDate())}
            onChange={(e: any) => updateForm("validTo", e)}
          />
        </Form.Item>
        <Form.Item label="Description">
          <Input
            type="text"
            value={form.description}
            onChange={(e) => updateForm("description", e.target.value)}
          />
        </Form.Item>

        <Form.Item label="Call Types">
          <Select
            mode="multiple"
            options={allCallTypes}
            value={form.callType_id}
            onChange={(e) => updateForm("callType_id", e)}
          />
        </Form.Item>
        <Form.Item label="Chains">
          <Select
            mode="multiple"
            options={chains}
            value={form.chain_id}
            onChange={(e) => updateForm("chain_id", e)}
          />
        </Form.Item>
        <Form.Item label="Outlet Type">
          <Select
            loading={isLoading}
            options={outletTypes}
            value={form.outletType_id}
            mode="multiple"
            onChange={(e) => {
              updateForm("outletType_id", e);
            }}
            allowClear
          />
        </Form.Item>
        <Form.Item label="Outlet State">
          <Select
            loading={isLoading}
            options={outletStates}
            value={form.outletState_id}
            mode="multiple"
            onChange={(e) => {
              updateForm("outletState_id", e);
            }}
            allowClear
          />
        </Form.Item>
        <Form.Item label="User Roles">
          <Select
            loading={isLoading}
            options={userRoles}
            value={form.userRoles_id}
            mode="multiple"
            onChange={(e) => {
              updateForm("userRoles_id", e);
            }}
            allowClear
          />
        </Form.Item>
        <Form.Item label="Region" name="hierarchy_id">
          <Cascader
            multiple
            placeholder="Please select new regions"
            options={treeData}
            expandTrigger="hover"
            displayRender={displayRender}
            // defaultValue={[getLabel(form.hierarchy_id || "")]}
            // defaultValue={['75e6e6f8-479a-4a43-858a-da6b61ff21c5', 'd795d0ad-05d0-45e5-999a-fd1d02a750d1']}
            onChange={(val, e) => {
              setForm({
                ...form,
                hierarchy_id: val,
              });
              onChange("hierarchy_id", val);
            }}
            showSearch={{ filter }}
            changeOnSelect
          />
        </Form.Item>
        {regionText && regionText.length > 0 && (
          <Form.Item label="Current Regions">
            {regionText.map((t, i) => (
              <Tag key={i}>{t}</Tag>
            ))}
          </Form.Item>
        )}
        {researchId && (
          <Form.Item label="Is Active" valuePropName="isActive" name="isActive">
            <Switch checked={isActive} onChange={(e) => setIsActive(e)} />
          </Form.Item>
        )}
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button type="primary" htmlType="submit">
            {researchId ? "Update" : "Create"}
          </Button>
        </Form.Item>

        {researchId && outletResearchDetails.length > 0 && (
          <div>
            <h3 style={{ textAlign: "center" }}>Questions</h3>
            {outletResearchDetails
              .sort((a, b) => a.sequence - b.sequence)
              .map((q, index: number) => (
                <div key={q.qID}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <h4>{`(${index + 1}) ${q.questionText}`}</h4>
                      {q.type === "S" &&
                        q.answerPayload.options
                          .sort((a: any, b: any) => a.value - b.value)
                          .map((o: any) => (
                            <div
                              key={o.answerId}
                              style={{
                                display: "flex",
                                marginBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  padding: "8px",
                                  border: `1px solid green`,
                                  borderRadius: "5px",
                                }}
                              >
                                {o.label}
                              </div>
                            </div>
                          ))}
                      {q.type === "N" && <div>Numeric answer</div>}
                      {q.type === "T" && <div>Text answer</div>}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </Form>
    </>
  );
};

export default OutletResearchForm;
