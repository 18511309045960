import React, { useContext, useEffect, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  MarkerClusterer,
  InfoWindow,
  HeatmapLayer
} from "@react-google-maps/api";
// import { getAllOutletsDataForMaps } from "../../../../api/office/outlet";
import * as S from "./maps.style";
import { Select } from "antd";
import { Button, Drawer } from "antd";
import { AppContext } from "../../../../App";
import { getMarkerIcon } from "./utils";
import useApiPost from "../../../../hooks/useApiPost";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: -26.7024,
  lng: 27.8148,
};

function Maps() {
  const { projectId } = useContext(AppContext);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
  });
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const { request, setError } = useApiPost();

  const [map, setMap] = React.useState(null);
  const [allOutlets, setAllOutlets] = useState<any[]>([]);
  const [allOutletsData, setAllOutletsData] = useState<any[]>([]);
  const [uniqueChains, setUniqueChains] = useState<any[]>([]);
  const [visibleMarkers, setVisibleMarkers] = useState<any[]>([]);
  const [uniqueAreas, setUniqueAreas] = useState<any[]>([]);
  const [uniqueRegions, setUniqueRegions] = useState<any[]>([]);
  const [uniqueDivisions, setUniqueDivisions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedChains, setSelectedChains] = useState<any[]>([]);
  const [selectedAreas, setSelectedAreas] = useState<any[]>([]);
  const [selectedRegions, setSelectedRegions] = useState<any[]>([]);
  const [selectedDivisions, setSelectedDivisions] = useState<any[]>([]);

  const [selectedChainsTemp, setSelectedChainsTemp] = useState<any[]>([]);
  const [selectedAreasTemp, setSelectedAreasTemp] = useState<any[]>([]);
  const [selectedRegionsTemp, setSelectedRegionsTemp] = useState<any[]>([]);
  const [selectedDivisionsTemp, setSelectedDivisionsTemp] = useState<any[]>([]);
  const [selectedOutlet, setSelectedOutlet] = useState<any>(null);

  const onClose = () => {
    setSelectedAreasTemp([]);
    setSelectedChainsTemp([]);
    setSelectedDivisionsTemp([]);
    setSelectedRegionsTemp([]);
    setOpen(false);
  };

  useEffect(() => {
    const getAllOutlets = async () => {
      setIsLoading(true);
      const {
        entries,
        chainOptions,
        areaOptions,
        regionOptions,
        divisionOptions,
      } = await request("/office/outlets/all-map", "POST", {
        projectId});
      // = await getAllOutletsDataForMaps(projectId);
      setUniqueChains(chainOptions);
      setUniqueAreas(areaOptions);
      setUniqueRegions(regionOptions);
      setUniqueDivisions(divisionOptions);
      setAllOutletsData(entries);

      const initOutlets = entries
        .filter((e: any) => e.latitude && e.longitude)
        .map((e: any) => ({
          lat: Number(e.latitude),
          lng: Number(e.longitude),
          chain: e.chain,
        }));

      setVisibleMarkers(initOutlets);
      const data = entries.map((e: any, index: number) => ({
        ...e,
        key: index,
      }));
      setAllOutlets(data);
      setIsLoading(false);
    };
    getAllOutlets();
  }, []);
  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  // const allOutletMarkers = allOutlets
  //   .filter((e) => e.latitude && e.longitude)
  //   .map((e) => ({
  //     lat: Number(e.latitude),
  //     lng: Number(e.longitude),
  // }));

  // console.log({ allOutletMarkers });

  const options = {
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m", // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder,
    gridSize: 60,
  };

  useEffect(() => {
    const allOutletMarkers = allOutlets
      .filter((e) => e.latitude && e.longitude)
      .filter((e) => {
        if (selectedChains.length > 0) {
          if (selectedChains.some((c) => c.value === e.chain)) {
            return selectedChains.some((c) => c.value === e.chain);
          } else {
            return false;
          }
          // return selectedChains.some(c => c.value === e.chain);
        }
        return true;
      })
      .filter((e) => {
        if (selectedAreas.length > 0) {
          if (selectedAreas.some((c) => c.value === e.area)) {
            return selectedAreas.some((c) => c.value === e.area);
          } else {
            return false;
          }
          // return selectedChains.some(c => c.value === e.chain);
        }
        return true;
      })
      .filter((e) => {
        if (selectedRegions.length > 0) {
          if (selectedRegions.some((c) => c.value === e.region)) {
            return selectedRegions.some((c) => c.value === e.region);
          } else {
            return false;
          }
          // return selectedChains.some(c => c.value === e.chain);
        }
        return true;
      })
      .filter((e) => {
        if (selectedDivisions.length > 0) {
          if (selectedDivisions.some((c) => c.value === e.division)) {
            return selectedDivisions.some((c) => c.value === e.division);
          } else {
            return false;
          }

          // return selectedChains.some(c => c.value === e.chain);
        }
        return true;
      })
      .map((e) => ({
        lat: Number(e.latitude),
        lng: Number(e.longitude),
        chain: e.chain,
      }));

    setVisibleMarkers(allOutletMarkers);
  }, [selectedChains, selectedAreas, selectedRegions, selectedDivisions]);

  const onApplyFilter = () => {
    setSelectedChains(selectedChainsTemp);
    setSelectedAreas(selectedAreasTemp);
    setSelectedRegions(selectedRegionsTemp);
    setSelectedDivisions(selectedDivisionsTemp);
    setOpen(false);
  };

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker: any) => {
    // if (marker === activeMarker) {
    //   return;
    // }
    // setActiveMarker(marker);
  };

  if (isLoading) return <div>Loading...</div>;

  return isLoaded ? (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div
        style={{
          marginBottom: "10px",
          textAlign: "right",
        }}
      >
        <Button type="primary" onClick={showDrawer}>
          Filters
        </Button>
      </div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        // zoom={1}
        onUnmount={onUnmount}
        options={{
          zoom: 6,
        }}
        key={visibleMarkers.length}
      >
        {visibleMarkers.map(({ lat, lng, chain }, index) => (
          <Marker
            key={index}
            position={{ lat, lng }}
            // // clusterer={clusterer}
            // onClick={() => handleActiveMarker(index)}
            icon={{
              url: getMarkerIcon(chain), // pass your image here
              // anchor: iconAnchor
            }}
          ></Marker>
        ))}
        {/* <MarkerClusterer options={options}>
          {(clusterer) =>
            /// @ts-ignore
            visibleMarkers.map((location, index) => (
              <Marker
                key={index}
                position={location}
                clusterer={clusterer}
                onClick={() => handleActiveMarker(index)}
              >
                {activeMarker === index ? (
                  <InfoWindow  onCloseClick={() => setActiveMarker(null)}>
                    <div>test</div>
                  </InfoWindow>
                ) : null}
              </Marker>
            ))
          } 
        </MarkerClusterer>*/}
      </GoogleMap>
      <Drawer title="Close" placement="right" onClose={onClose} open={open}>
        <S.SideFiltersWrapper>
          <S.SideFiltersHeader>Filters</S.SideFiltersHeader>
          <Select
            style={{ marginTop: "10px" }}
            loading={isLoading}
            placeholder="Select Chain"
            options={uniqueChains}
            mode="multiple"
            onChange={(_value, e) => {
              setSelectedChainsTemp(e);
            }}
          />
          <Select
            style={{ marginTop: "10px" }}
            loading={isLoading}
            placeholder="Select Area"
            options={uniqueAreas}
            mode="multiple"
            onChange={(_value, e) => {
              setSelectedAreasTemp(e);
            }}
          />
          <Select
            style={{ marginTop: "10px" }}
            loading={isLoading}
            placeholder="Select Region"
            options={uniqueRegions}
            mode="multiple"
            onChange={(_value, e) => {
              setSelectedRegionsTemp(e);
            }}
          />
          <Select
            style={{ marginTop: "10px" }}
            loading={isLoading}
            placeholder="Select Division"
            options={uniqueDivisions}
            mode="multiple"
            onChange={(_value, e) => {
              setSelectedDivisionsTemp(e);
            }}
          />

          <div style={{ marginTop: "10px", textAlign: "center" }}>
            <Button type="primary" onClick={onApplyFilter}>
              Apply
            </Button>
          </div>
        </S.SideFiltersWrapper>
      </Drawer>
    </div>
  ) : (
    <></>
  );
}

export default React.memo(Maps);
