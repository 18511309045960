import { Calendar } from "@adobe/react-spectrum";
import * as S from "./Calendar.styles";

interface ICalendar {
  defaultValue: any;
  onChange: any;
}

const CalendarComponent = ({defaultValue, onChange}: ICalendar) => {
  return (
    <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
    <S.CalendarContainer>
      <Calendar
        // aria-label="Event date"
        aria-label="Date (controlled)"
        onChange={onChange}
        // maxValue={today(getLocalTimeZone())}
        defaultValue={defaultValue}
      />
    </S.CalendarContainer>
    </div>
  );
};

export default CalendarComponent;
