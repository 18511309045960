import styled from "styled-components";

export const LegendContainer = styled.div`
  margin-bottom: 20px;
`;

export const LegendContainerWrap = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
`;

export const LegendItemRow = styled.div`
  display: flex;
  align-items: center;
`;
export const LegendItemRowWrap = styled(LegendItemRow)`
  width: 50%;
`;

export const LegendItem = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  background-color: ${({ color }) => color};
  margin-right: 4px;
`;
