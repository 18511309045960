import { useContext, useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Cascader,
  Switch,
  Select,
} from "antd";
import { AppContext } from "../../../../App";
import { DataType, IOption, Product } from "../types";
import { DefaultOptionType } from "antd/lib/select";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EMPTY_PRODUCT } from "../../../../utils/product";
import { Collapse } from "antd";
import useApiPost from "../../../../hooks/useApiPost";
const { Panel } = Collapse;

interface ICategory {
  value: string;
  label: string;
  parent_id: string;
  children?: ICategory[];
}

const ProductDetailsPage = () => {
  const { productId } = useParams<{ productId: string }>();
  const { projectId } = useContext(AppContext);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isDeal, setIsDeal] = useState<boolean>(false);
  const [isTest, setIsTest] = useState<boolean>(false);
  const { request, setError } = useApiPost();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const headerText = productId ? "Edit Product" : "Add Product";
  const [initValues, setInitValues] = useState<Product>(EMPTY_PRODUCT);
  const [manufacturerOptions, setManufacturerOptions] = useState<IOption[]>([]);
  const [treeData, setTreeData] = useState<DataType[]>([]);
  const location = useLocation();
  const [outletType, setOutletType] = useState<string>();
  const first = location.pathname.split("/")[1];
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");

  const [formOptionValues, setFormOptionValues] = useState({
    manufacturer_id: null,
    category_id: null,
  });

  const getData = async () => {
    setIsLoading(true);
    const categories = await request(
      "/office/products/categories/all",
      "POST",
      {
        project_id: projectId,
      }
    );

    setCategories(categories);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const fetchProductData = async () => {
    if (!productId) {
      return;
    }
    setIsLoading(true);

    const prod = await request(`/office/products/${productId}`);

    setSelectedCategoryId(prod.category_id);
    setIsDeal(prod.isDeal);
    setIsActive(prod.isActive);
    setIsTest(prod.isTest);

    setInitValues({
      ...prod,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchProductData();
  }, [productId]);

  useEffect(() => {
    form.resetFields(["outletType"])
    setOutletType("")
  }, [projectId]);

  useEffect(() => {
    form.setFieldsValue(initValues);
  }, [initValues, productId]);

  const handleComeBack = () => {
    navigate(`/${first}/products`);
  };

  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true);
      const options = await request(
        "/office/products/options/creation/product",
        "POST",
        {
          projectId,
        }
      );

      const { manufacturers } = options;

      setManufacturerOptions(manufacturers);
      setTreeData(manufacturers);
      setIsLoading(false);
    };
    fetchAllData();
  }, [projectId, productId]);

  const handleInsert = async (values: any) => {
    try {
      await request("/office/products/insert", "POST", {
        ...values,
        outletType,
        projectId,
        isActive,
        isDeal,
        isTest,
        category_id: selectedCategoryId,
      });

      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (values: any) => {
    try {
      await request("/office/products/update", "POST", {
        ...values,
        outletType,
        projectId,
        isActive,
        isDeal,
        isTest,
        category_id: selectedCategoryId,
      });

      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  const onFinish = async (values: any) => {
    setIsLoading(true);

    let submitData = {
      ...values,
      ...formOptionValues,
    };

    if (selectedCategoryId) {
      submitData.category_id = selectedCategoryId;
    }

    if (productId) {
      handleUpdate({
        ...submitData,
        productId,
      });
    } else {
      handleInsert(submitData);
    }
  };

  const handleOptionChange = (key: string, event: any) => {
    setFormOptionValues({
      ...formOptionValues,
      [key]: event.value,
    });
  };
  const displayRender = (labels: string[]) => labels[labels.length - 1];
  const getLabel = (id: string) => {
    let label = "";
    const findLabel = (data: DataType[]) => {
      data.forEach((item) => {
        if (item.id === id) {
          label = item.title;
        } else if (item.children && item.children.length > 0) {
          findLabel(item.children);
        }
      });
    };
    findLabel(treeData);
    return label;
  };

  const getLabelCategory = (value: string) => {
    let label = "";
    const findLabel = (data: any[]) => {
      data.forEach((item) => {
        const children = item.children || [];
        if (item.value === value) {
          label = item.label;
        } else if (children && children.length > 0) {
          findLabel(children);
        }
      });
    };
    findLabel(categories);
    return label;
  };

  const onChange = (key: string, value: any) => {
    const lastValue = Array.isArray(value) ? value[value.length - 1] : value;
    handleOptionChange(key, { value: lastValue });
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1
    );

  const outletTypeOptions = [
    { label: "Pre-Paid", value: "Pre-Paid" },
    { label: "Post-Paid", value: "Post-Paid" },
  ];

  const outletTypeOptionsForDemo = [
    { label: "Kiosk", value: "Kiosk" }
  ];

  if (
    isLoading ||
    manufacturerOptions.length === 0 ||
    treeData.length === 0 ||
    categories.length === 0
  ) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Form
        form={form}
        labelCol={{ span: 2 }}
        wrapperCol={{ span: 14 }}
        onFinish={onFinish}
        initialValues={initValues}
      >
        <Collapse defaultActiveKey={["1", "2"]}>
          <Panel header="Master data" key="1">
            <Form.Item label="Description" name="description">
              <Input />
            </Form.Item>
            <Form.Item label="ShortDescription" name="shortDescription">
              <Input />
            </Form.Item>
            <Form.Item label="EAN" name="EAN">
              <Input />
            </Form.Item>
            <Form.Item label="Code" name="manufacturerCode">
              <Input />
            </Form.Item>
            <Form.Item label="Size" name="size">
              <Input type="number" min={1} max={10000} />
            </Form.Item>
            <Form.Item label="Manufacturer">
              <Cascader
                options={treeData}
                expandTrigger="hover"
                displayRender={displayRender}
                defaultValue={[
                  getLabel(formOptionValues.manufacturer_id || ""),
                ]}
                onChange={(val) => onChange("manufacturer_id", val)}
                showSearch={{ filter }}
                changeOnSelect
              />
            </Form.Item>
            {/* <Form.Item label="imageURL" name="imageURL">
          <Input />
        </Form.Item>
        <Form.Item label="Is image processed" valuePropName="isImageProcessed" name="isImageProcessed">
          <Switch defaultChecked={initValues.isImageProcessed} />
        </Form.Item> */}
            <Form.Item label="Is deal" name="isDeal">
              <Switch checked={isDeal} onChange={(e) => setIsDeal(e)} />
            </Form.Item>
            <Form.Item label="Is active" name="isActive">
              <Switch checked={isActive} onChange={(e) => setIsActive(e)} />
            </Form.Item>
            <Form.Item label="Test item" name="isTest">
              <Switch checked={isTest} onChange={(e) => setIsTest(e)} />
            </Form.Item>
          </Panel>
          <Panel header="Project Data" key="2">
            <Form.Item label="Outlet Type" name="outletType">
              <Select
                options={(projectId == "6e234132-5a12-4d1b-9759-17530e47b670" || projectId == "b974688d-1028-4a73-978a-efb9b0b2f583") ? outletTypeOptionsForDemo : outletTypeOptions}
                disabled={isLoading}
                onChange={(e) => setOutletType(e)}
                value={outletType}
              />
            </Form.Item>
            <Form.Item label="Category">
              <Cascader
                style={{ width: "100%" }}
                showSearch={{ filter }}
                options={categories}
                expandTrigger="hover"
                changeOnSelect
                defaultValue={[getLabelCategory(selectedCategoryId || "")]}
                onChange={(value) => {
                  const lastValue = Array.isArray(value)
                    ? value[value.length - 1]
                    : value;
                  if (lastValue) {
                    setSelectedCategoryId(lastValue.toString());
                  } else {
                    setSelectedCategoryId("");
                  }
                }}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Panel>
        </Collapse>
      </Form>
    </>
  );
};

export default ProductDetailsPage;
