// import { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
// import { getUserDetails } from "../api/office/user";
import { useAuth } from "../Auth";
// import Cookies from "universal-cookie";

const WithPrivateRoute = ({ children, requiredRole }: any) => {
  const user = useAuth();
  // const [loading, setIsLoading] = useState<boolean>(true);
  // const cookies = new Cookies();
  // const cookieInfo = cookies.get("RC20");

  // useEffect(() => {
  //   const fetchAllDetails = async () => {
  //     await getUserDetails({
  //       uid: user?.currentUser?.uid,
  //       email: user?.currentUser?.email,
  //     });
  //   };

  //   if (!user.role) {
  //     fetchAllDetails();
  //   }
  // }, [user.role]);

  // if (!cookieInfo) {
  //   return <Navigate to="/login" />;
  // }

  // const { role: userRole } = cookieInfo;
  // console.log({ userRole, requiredRole });

  // if (!userRole) {
  //   return <Navigate to="/login" />;
  // }

  // if (userRole !== "admin" && userRole !== requiredRole) {
  //   return <Navigate to="/" />;
  // }

  if (user) {
    return <Outlet />;
  }

  console.log("this is the private route logout")

  return <Navigate to="/login" />;
};

export default WithPrivateRoute;
