export const EMPTY_CALL_TYPE = {
    id: '',
    name: '',
    description: '',
    role_id: []
}


export const EMPTY_CALL = {
    callType: '',
    outlet: '',
    planned_at: '',
    assigned_to: ''
}

interface IAddFormalTraining {
  startDate: string;
  type: string;
  user_id: string;
  module_id: string;
  venue_id: string;
  outlet_id: string;
  status: string;
  project_id: string;
}

export const EMPTY_FORMAL_TRAINING = {
    startDate: '',
    eventType: '',
    user_id: '',
    module_id: '',
    venue_id: '',
    outlet_id:'',
    status: '',
    project_id: '',
    planned_at: '',
    newDelegates_id: [],
}