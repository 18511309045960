import { useState, useCallback, useContext } from "react";
import { instance } from "../api/common-api";
import authCtx from "../store/auth/AuthContextProvider";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import { getMinutesDiffrence } from "../utils/dateTime";

// Defining API Version type
type API_VERSION =  "v1" | "v2"

const useApiPost = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { authState, globalLogOutDispatch, globalTokenDispatch } =
    useContext(authCtx);

  const request = useCallback(
    async (
      endpoint: string,
      method: "POST" | "GET" | "DELETE" | "PATCH" = "GET",
      data?: any,
      isMultipart?: boolean,
      apiVersion?: API_VERSION
    ) => {
      const TokenTime = localStorage.getItem("TokenTime");
      const _apiVersion = apiVersion ?? "v1";
      if (!endpoint.includes(_apiVersion)) {
        endpoint = _apiVersion + endpoint;
      }
      setLoading(true);

      const getMinutesFromLastSession = getMinutesDiffrence(TokenTime || "");
      console.log("TokenTime", TokenTime);
      console.log("getMinutesFromLastSession", getMinutesFromLastSession);
      /* FOR SECURITY PURPOSE AFTER 3 HOURS IF IS IN IDLE MODE THEN IT WILL LOGOUT ITSELF */
      if (getMinutesFromLastSession >= 240) {
        globalLogOutDispatch();
      } else if (getMinutesFromLastSession >= 55) {
        /* API CALL AFTER 55 MINUTES TO KEEP SESSION MAINTAINED */
        const auth = getAuth();
        let apiResPromise = new Promise(function (apiResolve, apiReject) {
          onAuthStateChanged(auth, async (user) => {
            const response = await handleGetToken(
              user,
              endpoint,
              method,
              data,
              isMultipart,
              apiVersion
            );
            if (response) {
              apiResolve(response); // when successful
            } else {
              apiReject(response); // when error
            }
          });
        });

        //Using promise for handling existing api call and return back response after session token
        return await apiResPromise
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      } else {
        const AuthToken = localStorage.getItem("AuthToken") || "";
        if (authState && authState.isLoggedIn && AuthToken) {
          instance.defaults.headers.common["Authorization"] = AuthToken;
        }

        try {
          let res = undefined;
          // NOTE: If user is logged in, insert the auth token into request headers for authorization
          if (authState && authState.isLoggedIn) {
            if (method === "DELETE") {
              res = await instance.delete(endpoint); //, headers);
            } else if (method === "PATCH") {
              res = await instance.patch(endpoint, data); //, headers);
            } else if (method === "POST") {
              if (isMultipart) {
                res = await instance.post(endpoint, data, {
                  headers: {
                    Authorization: AuthToken || "",
                    "Content-Type": "multipart/form-data",
                  },
                });
              } else {
                res = await instance.post(endpoint, {
                  ...data,
                  idToken: AuthToken || "",
                });
              }
            } else {
              res = await instance.get(endpoint); //, headers);
            }
          } else {
            const endpoint = `${_apiVersion}/office/auth/sessionLogin` 
            res = await instance.post(endpoint);
          }
          setLoading(false);
          return res.data;
        } catch (error: any) {
          console.log({ error });
          // NOTE: If it's unauthorized error, then we will auto log user out
          if (error && error?.response?.status === 403) {
            console.log("Unauthorized logout");
            globalLogOutDispatch();
          }
          setLoading(false);
          if(error?.response?.data) {
            return error.response.data;
          } else {
            return error
          }
        }
      }
    },
    [authState.isLoggedIn, globalLogOutDispatch]
  );

  const noAuthRequest = async (
    endpoint: string,
    method: "POST" | "GET" | "DELETE" | "PATCH" = "GET",
    data?: any,
    apiVersion?: API_VERSION
  ) => {
    const _apiVersion = apiVersion ?? "v1";
    if (!endpoint.includes(_apiVersion)) {
      endpoint = _apiVersion + endpoint;
    }
    try {
      const res = await instance(endpoint, {
        method,
        data,
      });

      return res.data;
    } catch (error: any) {
     if (error.response) {
       return error.response.data;
     } else if (error.request) {
       console.log(error.request);
     } else {
       console.log("Error", error.message);
     }
     console.log(error);
    }
  };

  /* THIS FUNCTION WILL TAKE TOKEN AS DIRECT PARAMETER */
  const handleGetToken = async (
    user: User | null,
    endpoint: string,
    method: "POST" | "GET" | "DELETE" | "PATCH" = "GET",
    dataFromreqesut?: any,
    isMultipart?: boolean,
    apiVersion?: API_VERSION
  ) => {
    const _apiVersion = apiVersion ?? "v1";
    if (!endpoint.includes(_apiVersion)) {
      endpoint = _apiVersion + endpoint;
    }
    if (user) {
      const token = await user.getIdToken(true);
      localStorage.setItem("AuthToken", `${token}`);
      instance.defaults.headers.common["Authorization"] = token;
      try {
        const authResponse = await requestApiCallForSessionTimeout(
          token,
          "/office/auth/sessionLogin",
          "POST"
        );
        const currentDate = new Date();
        localStorage.setItem("TokenTime", `${currentDate}`);
        const {
          user: data,
          access,
          projectAccess,
          passwordAgeInDays,
          forcePassNotify,
          passNotify,
          name
        } = authResponse;

        /* Storing Token in Redux Store */
        globalTokenDispatch({
          uid: "-none1-" + token,
          email: data?.email,
          role: data?.role,
          access,
          token,
          projectAccess,
          passwordAgeInDays,
          forcePassNotify,
          passNotify,
          name
        });

        /* CALL API AGAIN FOR EXISITING PARAMS */
        return await requestApiCallForSessionTimeout(
          token,
          endpoint,
          method,
          dataFromreqesut,
          isMultipart,
          apiVersion
        );
      } catch (error) {
        globalLogOutDispatch();
      }
    } else {
      console.log("user is logged out");
      globalLogOutDispatch();
      setLoading(false);
    }
  };

  const requestApiCallForSessionTimeout = useCallback(
    async (
      token: string,
      endpoint: string,
      method: "POST" | "GET" | "DELETE" | "PATCH" = "GET",
      data?: any,
      isMultipart?: boolean,
      apiVersion?: API_VERSION
    ) => {
      const _apiVersion = apiVersion ?? "v1";
      if (!endpoint.includes(_apiVersion)) {
        endpoint = _apiVersion + endpoint;
      }
      if (authState && authState.isLoggedIn && authState.token) {
        instance.defaults.headers.common["Authorization"] = token;
      }

      try {
        let res = undefined;
        // NOTE: If user is logged in, insert the auth token into request headers for authorization
        if (authState && authState.isLoggedIn) {
          if (method === "DELETE") {
            res = await instance.delete(endpoint); //, headers);
          } else if (method === "PATCH") {
            res = await instance.patch(endpoint, data); //, headers);
          } else if (method === "POST") {
            if (isMultipart) {
              res = await instance.post(endpoint, data, {
                headers: {
                  Authorization: token,
                  "Content-Type": "multipart/form-data",
                },
              });
            } else {
              res = await instance.post(
                endpoint,
                {
                  ...data,
                  idToken: token,
                }
                // headers
              );
            }
          } else {
            res = await instance.get(endpoint);
          }
        } else {
          res = await instance.post(`/office/auth/sessionLogin`);
        }
        return res.data;
      } catch (error: any) {
        console.log({ error });
        if (error && error?.response?.status === 403) {
          console.log("Unauthorized logout");
          globalLogOutDispatch();
        }
      }
      setLoading(false);
    },
    []
  );

  return {
    loading,
    error,
    request,
    setError,
    noAuthRequest,
  };
};

export default useApiPost;