import { useContext, useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Select,
  DatePicker,
  Switch,
} from "antd";
// import {
//   getMediaById,
//   updateMedia,
//   // updateStrategicAudits,
// } from "../../../../api/office/media-files";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../../App";
import { DefaultOptionType } from "antd/lib/cascader";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { instance } from "../../../../api/common-api";
import TextArea from "antd/es/input/TextArea";
import useApiPost from "../../../../hooks/useApiPost";
dayjs.extend(customParseFormat);

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const EMPTY: any = {
  validTo: null,
  validFrom: null,
  chain_id: [],
  name: "",
  isActive: false,
};
const MediaEditForm = () => {
  const appContext = useContext(AppContext);
  const { projectId } = appContext;
  const [file, setFiles] = useState<FileList>();
  const { mediaId } = useParams<{ mediaId: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();

  const onChangeSingleFile = (e: any) => {
    setFiles(e.target.files);
  };
  const [form, setForm] = useState(EMPTY);
  const [errorMessage, setErrorMessage] = useState<string>();
  const ref = useRef<HTMLInputElement>(null);
  const { request, setError } = useApiPost();

  const [chainsProjectSpecific, setChainsProjectSpecific] = useState<any[]>([]);
  const [selectedOutletType, setSelectedOutletType] = useState<string>();
  const first = location.pathname.split("/")[1];

  const humanReadanbleFileSize = (size: number) => {
    let i = Math.floor(Math.log(size) / Math.log(1024));
    const value = (size / Math.pow(1024, i)).toFixed(2);

    return value + " " + ["B", "kB", "MB", "GB", "TB"][i];
  };
  useEffect(() => {
    if (!mediaId) {
      return;
    }

    const fetchData = async () => {
      // const data = await getMediaById(mediaId, projectId);
      const data = await request(`/office/media/details/${mediaId}`, "POST", {
        projectId,
      });

      setChainsProjectSpecific(data.chains);

      setForm({
        validTo: data.validFrom ? dayjs(data.validTo) : null,
        validFrom: data.validFrom ? dayjs(data.validFrom) : null,
        chain_id: data.chain_id,
        name: data.name,
        isActive: data.isActive,
        mediaType: data.mediaType,
        description: data.description,
        fileName: data.fileName,
      });
      setSelectedOutletType(data.outletType);
    };
    fetchData();
  }, [mediaId]);
  let navigate = useNavigate();

  const handleComeBack = () => {
    navigate(`/${first}/media-files`);
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1
    );

  const updateForm = (key: string, value: any) => {
    setForm((form: any) => ({
      ...form,
      [key]: value,
    }));
  };

  const [formOptionValues, setFormOptionValues] = useState({
    chain_id: null,
  });

  const submitForm = async () => {
    const data = {
      ...form,
      outletType: selectedOutletType,
    };

    if (mediaId) {
      // await updateMedia(mediaId, data);
      await request(`/office/media/update/${mediaId}`, "POST", {
        ...data,
      });
      handleComeBack();
    }
  };

  const isSmartStart = form.mediaType === "Smart Start";

  const uploadFile = async (e: any) => {
    if (!mediaId) {
      setErrorMessage("Please select files");
      return;
    }
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    if (!file) {
      setErrorMessage("Please select files");
      return;
    }

    if (!form.mediaType) {
      setErrorMessage("Please select document type");
      setLoading(false);
      return;
    }

    for (let i = 0; i < file.length; i++) {
      formData.append("files", file[i]);
    }

    formData.append("mediaId", mediaId);

    await request("/office/media/re-upload", "POST", formData, true);
    setLoading(false);
    navigate(`/${first}/media-files`);
    return;
  };
  const outletTypeOptions = [
    { label: "Pre-Paid", value: "Pre-Paid" },
    { label: "Post-Paid", value: "Post-Paid" },
  ];

  if (loading) return <div>Loading...</div>;

  return (
    <>
      <Typography.Title>{`Media file edit - ${form.fileName}`}</Typography.Title>
      <Form labelCol={{ span: 4 }} layout="horizontal">
        <Form.Item label="Name">
          <Input
            type="text"
            name="name"
            required
            value={form.name}
            onChange={(e) => updateForm("name", e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Outlet Type">
          <Select
            options={outletTypeOptions}
            onChange={(e) => setSelectedOutletType(e)}
            value={selectedOutletType}
          />
        </Form.Item>
        <Form.Item label="Media Type">
          {form.mediaType}
          {/* <Input
            type="text"
            name="mediaType"
            disabled
            required
            value={form.mediaType}
            onChange={(e) => updateForm("mediaType", e)}
          /> */}
        </Form.Item>
        <Form.Item label="Description">
          <TextArea
            rows={5}
            name="description"
            required
            value={form.description}
            onChange={(e) => updateForm("description", e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="Start"
          // name="startDate"
          // getValueProps={(i: any) => ({ value: moment(i) })}
          required
        >
          <DatePicker
            allowClear={false}
            value={form.validFrom}
            // onChange={(e) => setStartDate(e?.toDate())}
            onChange={(e: any) => updateForm("validFrom", e)}
          />
        </Form.Item>
        <Form.Item
          label="End"
          // name="endDate"
          // getValueProps={(i: any) => ({ value: moment(i) })}
          required
          rules={[
            {
              required: true,
              message: "Please select a form end",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const start = new Date(getFieldValue("startDate"));
                const end = new Date(value);

                if (start > end) {
                  return Promise.reject(
                    "End date must be greater than start date"
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <DatePicker
            allowClear={false}
            value={form.validTo}
            // onChange={(e) => setEndDate(e?.toDate())}
            onChange={(e: any) => updateForm("validTo", e)}
          />
        </Form.Item>
        {!isSmartStart && (
          <Form.Item
            label="Chain"
            hasFeedback
            rules={[
              { required: true, message: "State is required to proceed!" },
            ]}
          >
            <Select
              loading={loading}
              options={chainsProjectSpecific}
              value={form.chain_id}
              mode="multiple"
              onChange={(e) => {
                updateForm("chain_id", e);
              }}
            />
          </Form.Item>
        )}
        <Form.Item label="Is active" name="isActive">
          <Switch
            defaultChecked={form.isActive}
            checked={form.isActive}
            onChange={(e) => updateForm("isActive", e)}
          />
        </Form.Item>

        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button type="primary" onClick={() => submitForm()}>
            Submit
          </Button>
        </Form.Item>
      </Form>

      <div>
        <form
          encType="multipart/form-data"
          method="post"
          action="#"
          onSubmit={uploadFile}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <div>
              <div>Upload new file:</div>
              <input
                ref={ref}
                type="file"
                name="file"
                onChange={onChangeSingleFile}
                disabled={loading}
              />
            </div>
          </div>
          <br />
          <div>
            {file &&
              Array.from(file).map((e) => (
                <div key={e.name}>
                  {e.name} - {humanReadanbleFileSize(e.size)}
                </div>
              ))}
          </div>

          <p
            style={{
              color: "red",
            }}
          >
            {errorMessage}
          </p>
          <Button
            key="back"
            type="primary"
            htmlType="submit"
            disabled={!file || loading}
          >
            Upload
          </Button>
        </form>
      </div>
      <p
        style={{
          color: "red",
        }}
      >
        {errorMessage}
      </p>
    </>
  );
};

export default MediaEditForm;
