import { useEffect, useState } from "react";
import { Space, Table, Button, Switch } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import useApiPost from "../../../../hooks/useApiPost";

interface DataType {
  id: string;
  isActive: boolean;
  name: string;
}

interface ColumnType {
  onUpdate: (id: string) => void;
  onDelete: (id: string) => void;
}
const columns = (props: ColumnType): ColumnsType<DataType> => [
  {
    title: "Active",
    dataIndex: "isActive",
    key: "isActive",
    render: (val) => (
      <div>
        <Switch defaultChecked={val} />
      </div>
    ),
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    render: (_, record) => (
      <Space size="middle" key={record.id}>
        <Button type="primary" onClick={() => props.onUpdate(record.id)}>
          Update
        </Button>
        <Button type="primary" danger onClick={() => props.onDelete(record.id)}>
          Delete
        </Button>
      </Space>
    ),
  },
];

const RegionsPage = () => {
  const { request, setError } = useApiPost();

  const [loading, setLoading] = useState<boolean>(false);
  let navigate = useNavigate();
  const [allRegions, setAllRegions] = useState<DataType[]>([]);

  useEffect(() => {
    const getAllRegions = async () => {
      setLoading(true);
      try {
        const {regions} = await request("/office/regions");
        setAllRegions(regions);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    getAllRegions();
  }, []);

  const handleUpdate = async (id: string) => {
    navigate(`/admin/region/view/${id}`);
  };

  const handleDelete = async (id: string) => {
    await request(`/regions/${id}`, "DELETE");
  };

  const handleTo = () => {
    navigate("/admin/region/new");
  };

  return (
    <div>
      <>
        <Table
          columns={columns({
            onUpdate: handleUpdate,
            onDelete: handleDelete,
          })}
          dataSource={allRegions}
          rowKey="key"
          size="small"
          pagination={{ pageSize: 20 }}
        />

        <Button type="primary" onClick={handleTo}>
          Add
        </Button>
      </>
    </div>
  );
};

export default RegionsPage;
