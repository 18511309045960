import { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Typography, Switch } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useApiPost from "../../../../hooks/useApiPost";
import { AppContext } from "../../../../App";

const GradingForm = () => {
  const { gradingId } = useParams<{ gradingId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const navigate = useNavigate();

  const appContext = useContext(AppContext);
  const { projectId } = appContext;

  const headerText = gradingId ? "Update Grading" : "Add Grading";
  const { request, setError } = useApiPost();

  const fetchGradingData = async () => {
    setIsLoading(true);
    if (!gradingId) {
      setIsLoading(false);
      return;
    }
  
    const res = await request("/office/gradings/all", "POST", {
      projectId,
      gradingId: gradingId,
    });
    if (!res) {
      navigate(`/admin`);
      return;
    }
    const { maybeGradings } = res ;
    const { levelLabel, levelDescription, isActive } = maybeGradings?.[0];

    setName(levelLabel);
    setDescription(levelDescription);
    setIsActive(isActive);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchGradingData();
  }, [gradingId]);

  const handleComeBack = () => navigate("/admin/grading");

  const handleInsert = async () => {
    if (!name.trim()) {
      toast.error('Please fill required field name', {
        position: "bottom-center"
      });
      return 
    }
    else if (name.length > 255) {
      toast.error('Name should not be greater then 255 characters', {
        position: "bottom-center"
      });
      return 
    } else  if (!description.trim()) {
      toast.error('Please fill required field description', {
        position: "bottom-center"
      });
      return 
    }
    else if (description.length > 255) {
      toast.error('Description should not be greater then 255 characters', {
        position: "bottom-center"
      });
      return 
    }
  
    try {
      const res = await request("/office/gradings/insert", "POST", {
        project_id: projectId,
        gradingName: name,
        gradingDescription: description,
        isActive: isActive,
      });
     
      setIsLoading(false);
      if(res?.status === 200 || res?.status === 201 ) {
        toast.success(res?.message, {
          position: "bottom-center"
        });
        handleComeBack();
      } else {
        toast.error(res?.message, {
          position: "bottom-center"
        });
      if (res.error) {
        setIsLoading(false);
        return;
      }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      if (!gradingId) return;
      const res = await request("/office/gradings/toggleActive", "POST", {
        gradingId: gradingId,
        isActive: isActive,
      });
      setIsLoading(false);
      if(res?.status === 200 || res?.status === 201 ) {
        toast.success(res?.message, {
          position: "bottom-center"
        });
        handleComeBack();
      } else {
        toast.error(res?.message, {
          position: "bottom-center"
        });
      if (res.error) {
        setIsLoading(false);
        return;
      }
      }
    } finally {
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Form labelCol={{ span: 4 }}>
        <Form.Item label="Name" required>
          {gradingId ? (
            <div>{name}</div>
          ) : (
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          )}
        </Form.Item>
        <Form.Item label="Description" required>
          {gradingId ? (
            <div>{description}</div>
          ) : (
            <Input value={description} onChange={(e) => setDescription(e.target.value)} />
          )}
        </Form.Item>
        <Form.Item label="Is active" valuePropName="isActive" name="isActive">
          <Switch defaultChecked={isActive} onChange={(e) => setIsActive(e)} />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            onClick={gradingId ? handleUpdate : handleInsert}
          >
            {gradingId ? 'Update' : 'Submit'}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default GradingForm;