import { useEffect, useState } from "react";
import { Form, Input, Button, Typography, Switch } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import useApiPost from "../../../../hooks/useApiPost";

const SystemRoleForm = () => {
  const { roleId } = useParams<{ roleId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [name, setName] = useState<string>("");
  const navigate = useNavigate();
  const headerText = roleId ? "Edit Role" : "Add Role";
  const { request, setError } = useApiPost();

  const fetchRoleData = async () => {
    setIsLoading(true);
    if (!roleId) {
      setIsLoading(false);
      return;
    }
    const details = await request(`/office/access/roles/details`, "POST", {
      id: roleId,
    });

    const { role } = details;

    if (!role) {
      navigate(`/admin`);
      return;
    }
    const { name } = role;
    setName(name);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchRoleData();
  }, [roleId]);

  const handleComeBack = () => navigate("/admin/system-roles");

  const handleInsert = async () => {
    try {
      const res = await request("/office/access/roles/insert", "POST", {
        name,
      });
      if (res.error) {
        alert(res.error);
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      if (!roleId) return;
      await request("/office/access/roles/update", "POST", {
        id: roleId,
        name,
      });
      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Form labelCol={{ span: 4 }}>
        <Form.Item label="Name">
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            onClick={roleId ? handleUpdate : handleInsert}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default SystemRoleForm;
