import { instance } from "../../../../../api/common-api";
import moment from "moment";

export const generateTicketsReport = async (): Promise<any> => {
  const res = await instance.post(`v1/office/reports/tickets`);
  return res.data;
};

export const generateAxonifyReports = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any
  ): Promise<any> => {
  const res = await instance.post(`v1/office/reports/axonifyReport`, {
  projectId,
  startDate,
  endDate,
  chainId,
  regionId
  });
  
  return res.data;
  };
  

export const generateTimeSheetsReports = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any
): Promise<any> => {
  const res = await instance.post(`v1/office/reports/timesheets`, {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });
  return res.data;
};

export const generateSKUReport = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any,
  request: any
): Promise<any> =>
  await request(`/office/reports/sku`, "POST", {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });

export const generateSurveyAnswersReports = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any,
  request: any
): Promise<any> =>
  await request(`/office/reports/survey`, "POST", {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });

export const generateSurveyReports = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any,
  request: any
): Promise<any> =>
  await request(`/office/reports/survey-summarised`, "POST", {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });

export const generatePOSReport = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any,
  request: any
): Promise<any> =>
  await request(`/office/reports/pos`, "POST", {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });
export const generateStocksReport = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any,
  request: any
): Promise<any> =>
  await request(`/office/reports/stocks`, "POST", {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });
export const generateRedFlagReport = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any,
  request: any
): Promise<any> =>
  await request(`/office/reports/red-flags`, "POST", {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });

export const generateAddOnQueueReport = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any,
  request: any
): Promise<any> =>
  await request(`/office/reports/general/add-on-queue`, "POST", {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });

export const generateGeneralOutletsReport = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any,
  request: any
): Promise<any> =>
  await request(`/office/reports/general/outlets`, "POST", {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });

export const generateGeneralDelegatesReport = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any,
  request: any
): Promise<any> =>
  await request(`/office/reports/general/delegates`, "POST",  {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });

export const generateCallsCompletedReport = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any,
  request: any
): Promise<any> =>
  await request(`/office/reports/completed-visits`, "POST", {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });

export const generateCallDiariesReport = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any,
  request: any
): Promise<any> =>
  await request(`/office/reports/call-diaries`, "POST", {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });

export const generateUserListReport = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any,
  request: any
): Promise<any> =>
  await request(`/office/reports/general/users`, "POST", {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });

export const getPhotoAuditsReport = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any,
  request: any
): Promise<any> =>
  await request(`/office/reports/photo-audits`, "POST", {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });

export const generateScorecardsReport = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any,
  request: any
): Promise<any> =>
  await request(`/office/reports/scorecards`, "POST", {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });

export const generateScorecardsAnswersReport = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any,
  request: any
): Promise<any> =>
  await request(`/office/reports/scorecards-answers`, "POST", {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });

export const generateOutletResearchReport = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any,
  request: any
): Promise<any> =>
  await request(`/office/reports/outlet-research`, "POST", {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });

export const generateOutletResearchSummarisedReport = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any,
  request: any
): Promise<any> =>
  await request(`/office/reports/outlet-research-summarised`, "POST", {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });

export const generateStrategicAuditReport = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any,
  request: any
): Promise<any> =>
  await request(`/office/reports/strategic-audit`, "POST", {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });

export const generateStrategicAuditSummarisedReport = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any,
  request: any
): Promise<any> =>
  await request(`/office/reports/strategic-audit-summarised`, "POST", {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });

export const generateActiveScorecardsReport = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any,
  request: any
): Promise<any> =>
  await request(`/office/reports/all-active-questionnaires`, "POST", {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });

export const generateFormalTrainingReport = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any,
  request: any
): Promise<any> =>
  await request(`/office/reports/formal-training-attendance`, "POST", {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });

export const generateProductsReport = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any,
  request: any
): Promise<any> =>
  await request(`/office/reports/products`, "POST", {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });

export const generateCustomerInteractionsReport = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any,
  request: any
): Promise<any> =>
  await request(`/office/reports/customer-interaction`, "POST", {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });

export const generateKnowledgeTransferReport = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any,
  request: any
): Promise<any> =>
  await request(`/office/reports/knowledge-transfers`, "POST", {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });

export const generateKnowledgeTransferSummarisedReport = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any,
  request: any
): Promise<any> =>
  await request(`/office/reports/knowledge-transfers-summarised`, "POST", {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });

export const generateDropOffQueReports = async (
  projectId: string,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  chainId: any,
  regionId: any,
  request: any
): Promise<any> =>
  await request(`/office/reports/drop-off-queue`, "POST", {
    projectId,
    startDate,
    endDate,
    chainId,
    regionId
  });

  export const generateOutletDropOffReports = async (
    projectId: string,
    startDate: moment.Moment | null,
    endDate: moment.Moment | null,
    chainId: any,
    regionId: any,
    request: any
  ): Promise<any> =>
    await request(`/office/reports/dropoff-outlets`, "POST", {
      projectId,
      startDate,
      endDate,
      chainId,
      regionId
    });
   
