import { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Typography, Switch, Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import useApiPost from "../../../../hooks/useApiPost";
import { AppContext } from "../../../../App";

interface IChannel {
  id: string;
  name: string;
}

const ChainsForm = () => {
  const { chainId } = useParams<{ chainId: string }>();
  const { projectId } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const navigate = useNavigate();
  const headerText = chainId ? "Edit chain" : "Add chain";
  const [chains, setChains] = useState<any[]>([]);
  const { request, setError } = useApiPost();
  const [channel, setChannel] = useState<IChannel>();
  const [channelsOptions, setChannelsOptions] = useState<any[]>([]);
  const [newChannelId, setNewChannelId] = useState<string>("");

  const fetchChannelsOptions = async () => {
    setIsLoading(true);
    const { channelsOptions } = await request(
      `/office/locations/channels/options`,
      "POST",
      {
        projectId,
      }
    );
    setChannelsOptions(channelsOptions);
    setIsLoading(false);
  };

  const fetchchainData = async () => {
    setIsLoading(true);
    if (!chainId) {
      setIsLoading(false);
      return;
    }
    const { chain } = await request(
      `/office/locations/chains/details`,
      "POST",
      {
        id: chainId,
        projectId,
      }
    );
    if (!chain) {
      navigate(`/admin`);
      return;
    }
    setChannel({
      id: chain.channelId,
      name: chain.channel,
    });
    setChannelsOptions(chain?.channelsOptions);
    const { name, isActive, chains = [] } = chain;
    setChains(chains);
    setName(name);
    setIsActive(isActive);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchChannelsOptions();
  }, [projectId]);

  useEffect(() => {
    fetchchainData();
  }, [chainId]);

  const handleComeBack = () => navigate("/admin/chains");

  const handleInsert = async () => {
    try {
      // const res = await addNewchain(name, isActive);
      const res = await request("/office/locations/chains/insert", "POST", {
        name,
        isActive,
        projectId,
        channelId: newChannelId || channel?.id,
      });
      if (res.error) {
        alert(res.error);
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      if (!chainId) return;
      // await updatechain(chainId, isActive, name);
      const res = await request("/office/locations/chains/update", "POST", {
        id: chainId,
        isActive,
        name,
        channelId: newChannelId || channel?.id,
        projectId,
      });
      if (res.error) {
        alert(res.error);
        if (!isActive) {
          setIsActive(true);
        }
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Form labelCol={{ span: 4 }}>
        <Form.Item label="Name">
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        </Form.Item>

        <Form.Item label="Is active" valuePropName="isActive" name="isActive">
          <Switch checked={isActive} onChange={(e) => setIsActive(e)} />
        </Form.Item>

        {channel && (
          <div
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              marginBottom: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>Connected to channel {channel.name}</div>
            <Button
              type="primary"
              onClick={() => {
                navigate(`/admin/channel/edit/${channel.id}`);
              }}
            >
              View Channel
            </Button>
          </div>
        )}
        <Form.Item label="Change channel" name="channelId">
          <Select
            // style={{ marginTop: "10/px" }}
            loading={isLoading}
            placeholder="Select"
            options={channelsOptions}
            onChange={(_value, e) => {
              setNewChannelId(_value);
            }}
            showSearch
            filterOption={(input, option) =>
              (option?.label?.toString() ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            onClick={chainId ? handleUpdate : handleInsert}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ChainsForm;
