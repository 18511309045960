import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Space, Table, Button, Input, Switch, InputRef, Modal } from "antd";
import type { ColumnsType, ColumnType } from "antd/es/table";
import { AppContext } from "../../../../App";
import type { FilterConfirmProps } from "antd/es/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
// import {
//   getGPSCorrections,
//   submitGPSCorrectionResponse,
// } from "../../../../api/office/outlet";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { getGPSCorrectionMarkerColor } from "../maps/utils";
import useApiPost from "../../../../hooks/useApiPost";
export interface DataType {
  id: string;
  key: string;
  name: string;
  phone_number: string;
  email: string;
  firstName: string;
  lastName: string;
  storeName: string;
  userName: string;
  storeLatitude: string;
  storeLongitude: string;
  gpsLatitude: string;
  gpsLongitude: string;
  retailCloudID: string;
}

type DataIndex = keyof DataType;

const center = {
  lat: -26.7024,
  lng: 27.8148,
};

const containerStyle = {
  width: "100%",
  height: "100%",
};

const GPSCorrectionsPage = () => {
  const { projectId } = useContext(AppContext);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
  });
  const { request, setError } = useApiPost();

  const [isLoading, setIsLoading] = useState(false);
  const [GPSCorrections, setAllGPSCorrections] = useState<DataType[]>([]);
  const navigate = useNavigate();
  const handleTo = () => navigate("/admin/outlets");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [selectedCorrection, setSelectedCorrection] =
    useState<DataType | null>();
  const [visibleMarkers, setVisibleMarkers] = useState<any[]>([]);

  const columns = (props: any): ColumnsType<DataType> => [
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <div>{new Date(text).toLocaleDateString()}</div>,
    },
    {
      title: "Store Name",
      dataIndex: "storeName",
      ...getColumnSearchProps("storeName"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "retailCloudID",
      dataIndex: "retailCloudID",
      ...getColumnSearchProps("retailCloudID"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Submitted By",
      dataIndex: "userName",
      ...getColumnSearchProps("userName"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <Space size="middle" key={record.id}>
          <Button type="primary" onClick={() => props.onUpdate(record.id)}>
            View
          </Button>
          {/* <Button
            type="primary"
            danger
            onClick={() => props.onDelete(record.id)}
          >
            Reject
          </Button> */}
        </Space>
      ),
    },
  ];
  const searchInput = useRef<InputRef>(null);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: any) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const exists = record[dataIndex] ? record[dataIndex] : false;

      if (!exists) {
        return false;
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        //@ts-ignore
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  useEffect(() => {
    const getAllDelegates = async () => {
      setIsLoading(true);
      if (!projectId) {
        return;
      }
      // const users = await getUsers(projectId);
      // const corrections = await getGPSCorrections();
      const corrections = await request(
        "/office/outlets/gps-corrections",
        "POST"
      );
      setAllGPSCorrections(corrections);
      setIsLoading(false);
    };
    getAllDelegates();
  }, [projectId]);

  const handleConfirm = async (id: string) => {
    const foundGPSCorrection = GPSCorrections.find(
      (correction) => correction.id === id
    );
    if (!foundGPSCorrection) {
      return;
    }
    setSelectedCorrection(foundGPSCorrection);
    const { storeLatitude, storeLongitude, gpsLatitude, gpsLongitude } =
      foundGPSCorrection;
    const storeCoordinates = {
      lat: storeLatitude,
      lng: storeLongitude,
    };
    const gpsCoordinates = {
      lat: gpsLatitude,
      lng: gpsLongitude,
    };
    setIsConfirmModalOpen(true);

    setVisibleMarkers([storeCoordinates, gpsCoordinates]);
    // await submitTransferDelegateResponse(id, true);
  };

  const handleReject = async () => {
    if (!selectedCorrection) {
      return;
    }
    // await submitGPSCorrectionResponse(selectedCorrection?.id, false);
    await request("/office/outlets/gps-correction/response", "POST", {
      correctionId: selectedCorrection?.id,
      isConfirmed: false,
    });
    setAllGPSCorrections(
      GPSCorrections.filter(
        (correction) => correction.id !== selectedCorrection?.id
      )
    );
    setIsConfirmModalOpen(false);
    setSelectedCorrection(null);
  };

  const handleCancel = () => {
    setIsConfirmModalOpen(false);
    setSelectedCorrection(null);
  };

  const handleApprove = async () => {
    if (!selectedCorrection) {
      return;
    }
    // await submitGPSCorrectionResponse(selectedCorrection?.id, true);
    await request("/office/outlets/gps-correction/response", "POST", {
      correctionId: selectedCorrection?.id,
      isConfirmed: true,
    });
    setAllGPSCorrections(
      GPSCorrections.filter(
        (correction) => correction.id !== selectedCorrection?.id
      )
    );
    setIsConfirmModalOpen(false);
    setSelectedCorrection(null);
  };
  const [map, setMap] = useState<any>(null);

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const handleOnLoad = (map: any) => {
    console.log({ map });
    if (map) {
      const bounds = new google.maps.LatLngBounds();
      visibleMarkers.forEach((marker) => {
        console.log({ marker });
        bounds.extend(marker);
      });
      map.fitBounds(bounds);

      // map && map.fitBounds && map.fitBounds([
      //   [selectedCorrection?.storeLatitude, selectedCorrection?.storeLongitude],
      //   [selectedCorrection?.gpsLatitude, selectedCorrection?.gpsLongitude],
      // ]);
    }
  }; //, [map, selectedCorrection, visibleMarkers]);

  console.log("selectedCorrection", selectedCorrection);
  console.log({ visibleMarkers });
  return isLoaded ? (
    <>
      <Table
        loading={isLoading}
        key={projectId}
        columns={columns({
          onUpdate: handleConfirm,
          // onDelete: handleReject,
        })}
        dataSource={GPSCorrections}
        rowKey="id"
      />

      <Button type="primary" onClick={handleTo}>
        Add
      </Button>

      <Modal
        title={`GPS Correction For ${selectedCorrection?.storeName}`}
        open={isConfirmModalOpen}
        centered
        closable={false}
        width={1000}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="reject"
            color="red"
            type="primary"
            danger
            onClick={handleReject}
          >
            Reject
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleApprove}
            // disabled={!isCreateAvailable}
          >
            Approve
          </Button>,
        ]}
      >
        {isLoaded && (
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <div
              style={{
                textAlign: "center",
                height: "50vh",
              }}
            >
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                // zoom={1}
                onUnmount={onUnmount}
                options={{
                  zoom: 6,
                }}
                key={selectedCorrection?.id}
                onLoad={(map) => handleOnLoad(map)}
              >
                {visibleMarkers.map(({ lat, lng, chain }, index) => (
                  <Marker
                    key={index}
                    position={{ lat, lng }}
                    icon={{
                      url: getGPSCorrectionMarkerColor(index === 1),
                    }}
                    // label={index === 1 ? "Store" : "GPS"}
                  />
                ))}
              </GoogleMap>
            </div>
          </div>
        )}
        <div>
          Legend: <br />
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 20,
                height: 20,
                backgroundColor: "green",
                marginRight: 10,
              }}
            ></div>
            <div>New GPS location</div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 20,
                height: 20,
                backgroundColor: "red",
                marginRight: 10,
              }}
            ></div>
            <div>Old GPS location</div>
          </div>
        </div>
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default GPSCorrectionsPage;
