import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Space,
  Table,
  Button,
  Input,
  Switch,
  InputRef,
  Badge,
  Select,
} from "antd";
import type { ColumnsType, ColumnType } from "antd/es/table";
import { AppContext } from "../../../../App";
import type { FilterConfirmProps } from "antd/es/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import useApiPost from "../../../../hooks/useApiPost";
import { get } from "../../../../utils/api-calls";

interface ITicket {
  order: number;
  id: number;
  title: string;
  description: string;
  status: string;
  priority: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  assignedTo: string;
  projectId: number;
  project: string;
  comments: string[];
  alias: string;
}

type DataIndex = keyof ITicket;

const SupportPage = () => {
  const { request, setError, noAuthRequest } = useApiPost();
  const { projectId } = useContext(AppContext);
  const [projectOptions, setProjectOptions] = useState<any>([]);

  const [, setIsLoading] = useState(false);
  const [allTickets, setAllTickets] = useState<ITicket[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [isNonActiveVisible, setIsNonActiveVisible] = useState(true);
  const handleTo = () => {
    const first = location.pathname.split("/")[1];
    navigate(`/${first}/support/tickets/new`);
  };

  useEffect(() => {
    const fetchProjects = async () => {
      const options = await noAuthRequest("/office/projects/all?asOptions=true");
      setProjectOptions(options);
    };

    fetchProjects();
  }, []);

  const [selectedProjects, setSelectedProjects] = useState<any>([]);

  const columns = (props: any): ColumnsType<ITicket> => [
    {
      title: "#",
      dataIndex: "order",
      ...getColumnSearchProps("order"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Title",
      dataIndex: "title",
      ...getColumnSearchProps("title"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Description",
      dataIndex: "description",
      ...getColumnSearchProps("description"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      ...getColumnSearchProps("status"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Priority",
      dataIndex: "priority",
      ...getColumnSearchProps("priority"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Submitee",
      dataIndex: "alias",
      ...getColumnSearchProps("alias"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      render: (text) => <div>{`${new Date(text).toLocaleString()}`}</div>,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <Space size="middle" key={record.id}>
          <Button type="primary" onClick={() => props.onUpdate(record.id)}>
            View
          </Button>
        </Space>
      ),
    },
  ];
  const searchInput = useRef<InputRef>(null);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: any
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const [visibleTickets, setVisibleTickets] = useState<ITicket[]>([]);

  useEffect(() => {
    let visibleTickets = isNonActiveVisible
      ? allTickets.filter((ticket) => ticket.status !== "Resolved")
      : allTickets;

    if (selectedProjects.length === 0) {
      setVisibleTickets(visibleTickets);
      return;
    }

    setVisibleTickets(
      visibleTickets.filter(
        (ticket) =>
          !ticket.projectId ||
          selectedProjects.includes(ticket.projectId.toString())
      )
    );
  }, [selectedProjects, isNonActiveVisible]);

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<any> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: any) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const exists = record[dataIndex] ? record[dataIndex] : false;

      if (!exists) {
        return false;
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        //@ts-ignore
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const fetchTickets = async () => {
    setIsLoading(true);
    const { tickets } = await request(`/office/support/tickets`, "POST");
    setAllTickets(tickets);
    setVisibleTickets(
      tickets.filter((ticket: any) => ticket.status !== "Resolved")
    );
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTickets();
  }, []);

  const handleUpdate = async (id: string) => {
    const first = location.pathname.split("/")[1];
    navigate(`/${first}/support/tickets/${id}`);
  };

  const handleToggleActive = () => {
    setIsNonActiveVisible(!isNonActiveVisible);
  };

  const completedTickets = allTickets.filter(
    (ticket) => ticket.status === "Resolved"
  ).length;

  const inProgressTickets = allTickets.filter(
    (ticket) => ticket.status === "In Progress"
  ).length;

  const feedbackTickets = allTickets.filter(
    (ticket) =>
      ticket.status === "Feedback required" ||
      ticket.status === "Answer required"
  ).length;

  const remainingTickets = allTickets.filter(
    (ticket) =>
      ticket.status !== "Resolved" &&
      ticket.status !== "In Progress" &&
      ticket.status !== "Feedback required" &&
      ticket.status !== "Answer required"
  ).length;

  const handleChange = (value: string) => {
    setSelectedProjects(value);
  };

  return (
    <>
      <div>
        <div
          style={{
            paddingBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Switch
              checkedChildren="Unresolved only"
              unCheckedChildren="Unresolved and closed"
              defaultChecked
              onChange={() => handleToggleActive()}
              style={{
                marginRight: "10px",
              }}
            />
            <Select
              filterOption={(input, option) =>
                (option?.label?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              mode="multiple"
              showSearch={true}
              value={selectedProjects}
              style={{ width: 300 }}
              onChange={handleChange}
              options={projectOptions}
            />
          </div>
          <div>
            <Badge
              count={remainingTickets}
              showZero
              color="red"
              style={{
                marginRight: "10px",
              }}
            />
            <Badge
              count={inProgressTickets}
              showZero
              color="#147ffa"
              style={{
                marginRight: "10px",
              }}
            />
            <Badge
              count={feedbackTickets}
              showZero
              color="#faad14"
              style={{
                marginRight: "10px",
              }}
            />
            <Badge count={completedTickets} showZero color="#52c41a" />
          </div>
        </div>
      </div>
      <Table
        key={projectId}
        columns={columns({
          onUpdate: handleUpdate,
        })}
        dataSource={visibleTickets}
        rowKey="id"
        size="small"
        pagination={{ pageSize: 20 }}
        rowClassName={(record, index) => {
          if (
            record.status === "Feedback required" ||
            record.status === "Answer required"
          ) {
            return "feedback";
          }
          if (record.status === "Resolved") {
            return "completed";
          }
          return "";
        }}
      />

      <Button type="primary" onClick={handleTo}>
        Add
      </Button>
    </>
  );
};

export default SupportPage;
