import { Reducer } from "react";
import { AuthAction } from "./authActions";

export interface AuthState {
  isLoggedIn: boolean;
  access: string[];
  projectAccess: string[];
  isLoading?: boolean;
  authToken?: string;
  userId?: string;
  name?: string;
  email?: string;
  role?: string;
  token?: string;
  forcePassNotify?: boolean;
  passNotify?: boolean;
}

export const defaultAuthState: AuthState = {
  isLoggedIn: false,
  isLoading: true,
  access: [],
  projectAccess: [],
  name: ''
};

const authReducer: Reducer<AuthState, AuthAction> = (state, action) => {
  if (action.type === "LOG_IN") {
    localStorage.setItem("user", JSON.stringify(action.payload));
    return {
      ...state,
      isLoading: false,
      isLoggedIn: true,
      email: action.payload.email,
      uid: action.payload.uid,
      role: action.payload.role,
      token: action.payload.token,
      access: action.payload.access,
      projectAccess: action.payload.projectAccess,
      passwordAgeInDays: action.payload.passwordAgeInDays,
      forcePassNotify: action.payload.forcePassNotify,
      passNotify: action.payload.passNotify,
      name: action.payload.name
    };
  }
  if (action.type === "UPDATE_TOKEN") {
    localStorage.setItem("user", JSON.stringify(action.payload));
    return {
      ...state,
      uid: action.payload.uid,
      token: action.payload.token,
      passwordAgeInDays: action.payload.passwordAgeInDays,
      forcePassNotify: action.payload.forcePassNotify,
      passNotify: action.payload.passNotify,
    };
  }

  if (action.type === "LOG_OUT") {
    localStorage.removeItem("user");
    return { ...defaultAuthState, isLoading: false };
  }

  if (action.type === "SET_LOADING") {
    return {
      ...state,
      isLoading: action.payload
    }
  }
  return defaultAuthState;
};

export default authReducer;
