// import "antd/dist/antd.css";
import { Form, Input, Button, Typography, Select, Switch } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../../App";
import useApiPost from "../../../../hooks/useApiPost";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const EMPTY_CALLTYPE = {
  id: "",
  name: "",
  description: "",
  feature_id: [],
  userRoles_id: [],
  chain_id: [],
  isActive: false,
  isRemote: false,
  isPlannable: false,
};

const UpdateCallType = () => {
  const { request, setError } = useApiPost();

  const { projectId } = useContext(AppContext);
  const { callTypeId } = useParams<{ callTypeId: string }>();
  const navigate = useNavigate();
  const [userRoles, setUserRoles] = useState<any[]>([]);
  const [chains, setChains] = useState<any[]>([]);

  const headerText = callTypeId ? "Edit Call Type" : "Add Call Type";
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initValues, setInitValues] = useState<any>({});
  const [features, setFeatures] = useState<any[]>([]);
  const [form, setForm] = useState(EMPTY_CALLTYPE);

  const fetchCallTypeOptions = async () => {
    const { features, userRoles, chains } = await request(
      "/office/call-types/options",
      "POST",
      {
        projectId,
      }
    );
    setFeatures(features);
    setChains(chains);
    setUserRoles(userRoles);
  };

  const fetchCallTypeData = async () => {
    if (!callTypeId) {
      return;
    }
    setIsLoading(true);

    const data = await request(
      `/office/call-types/details/${callTypeId}`,
      "POST"
    );

    const { callType } = data;
    setForm({
      ...callType,
      feature_id: callType.feature_id
        ? callType.feature_id.map((u: any) => u.value)
        : [],
      chain_id: callType.chain_id
        ? callType.chain_id.map((u: any) => u.value)
        : [],
      userRoles_id: callType.userRoles_id
        ? callType.userRoles_id.map((u: any) => u.value)
        : [],
    });
    setInitValues({
      ...callType,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchCallTypeData();
  }, [callTypeId]);

  useEffect(() => {
    fetchCallTypeOptions();
  }, []);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  const handleInsert = async (values: any) => {
    try {
      await request("/office/call-types/insert", "POST", {
        ...values,
        projectId,
        callTypeId,
      });
      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (values: any) => {
    try {
      await request("/office/call-types/update", "POST", {
        ...values,
        projectId,
        callTypeId,
      });
      // await updateCallType({ ...values, callTypeId });
      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  const onFinish = async () => {
    setIsLoading(true);

    const submitData = {
      ...form,
      project_id: projectId,
    };

    if (callTypeId) {
      handleUpdate({
        ...submitData,
      });
    } else {
      handleInsert({ ...submitData });
    }
  };

  const handleComeBack = () => {
    navigate("/admin/call-types");
  };

  const updateForm = (key: string, value: any) => {
    setForm((form) => ({
      ...form,
      [key]: value,
    }));
  };
  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        // onFinish={onFinish}
        // form={form}
      >
        <Form.Item label="Name">
          <Input
            type="text"
            value={form.name}
            onChange={(e) => updateForm("name", e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Description">
          <Input
            type="text"
            value={form.description}
            onChange={(e) => updateForm("description", e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Is active" name="isActive">
          <Switch
            defaultChecked={form.isActive}
            onChange={(e) => updateForm("isActive", e)}
          />
        </Form.Item>
        <Form.Item label="Is Plannable" name="isPlannable">
          <Switch
            defaultChecked={form.isPlannable}
            onChange={(e) => updateForm("isPlannable", e)}
          />
        </Form.Item>
        <Form.Item label="Is Remote" name="isRemote">
          <Switch
            defaultChecked={form.isRemote}
            onChange={(e) => updateForm("isRemote", e)}
          />
        </Form.Item>

        <Form.Item label="Chains">
          <Select
            mode="multiple"
            options={chains}
            value={form.chain_id}
            onChange={(e) => updateForm("chain_id", e)}
          />
        </Form.Item>
        <Form.Item label="User Roles">
          <Select
            loading={isLoading}
            options={userRoles}
            value={form.userRoles_id}
            mode="multiple"
            onChange={(e) => {
              updateForm("userRoles_id", e);
            }}
            allowClear
          />
        </Form.Item>
        <Form.Item label="Features">
          <Select
            mode="multiple"
            options={features}
            value={form.feature_id}
            onChange={(e) => updateForm("feature_id", e)}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button
            type="primary"
            style={{ margin: 10 }}
            onClick={handleComeBack}
          >
            Go back
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              onFinish();
            }}
          >
            Update
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default UpdateCallType;
