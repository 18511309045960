import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import "antd/dist/antd.css";
import { Space, Table, Button, Switch } from "antd";
import type { ColumnsType } from "antd/es/table";
import { AppContext } from "../../../../App";
import useApiPost from "../../../../hooks/useApiPost";

interface DataType {
  id: string;
  key: string;
  name: string;
}

interface ColumnType {
  onUpdate: (id: string) => void;
  onDelete: (id: string) => void;
}

const columns = (props: ColumnType): ColumnsType<DataType> => [
  {
    title: "Active",
    dataIndex: "isActive",
    key: "isActive",
    render: (val) => (
      <div>
        <Switch defaultChecked={val} disabled />
      </div>
    ),
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text) => <div>{text}</div>,
  },
  {
    title: "Start Date",
    dataIndex: "startDate",
    key: "startDate",
    render: (text) => <div>{new Date(text).toLocaleDateString()}</div>,
  },
  {
    title: "End Date",
    dataIndex: "endDate",
    key: "endDate",
    render: (text) => <div>{new Date(text).toLocaleDateString()}</div>,
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    render: (_, record) => (
      <Space size="middle" key={record.id}>
        <Button type="primary" onClick={() => props.onUpdate(record.id)}>
          Edit
        </Button>
        {/* <Button type="primary" danger onClick={() => props.onDelete(record.id)}>
          Delete
        </Button> */}
      </Space>
    ),
  },
];

const CyclesPage = () => {
  const appContext = useContext(AppContext);
  const { request, setError } = useApiPost();

  const { projectId } = appContext;
  const [, setIsLoading] = useState(false);
  const [allCycles, setAllCycles] = useState<DataType[]>([]);
  let navigate = useNavigate();

  const handleTo = () => {
    navigate("/admin/cycle/new");
  };

  useEffect(() => {
    const getAllCycles = async () => {
      setIsLoading(true);

      const res = await request("/office/cycles", "POST", {
        projectId,
      });

      const data = res.map((e: any, index: number) => ({
        ...e,
        key: index,
      }));

      setAllCycles(data);
      setIsLoading(false);
    };
    getAllCycles();
  }, []);

  const handleUpdate = async (id: string) => {
    navigate(`/admin/cycle/edit/${id}`);
  };

  const handleDelete = async (id: string) => {
    await request(`/cycle/${id}`, "DELETE");
  };

  return (
    <>
      <Table
        columns={columns({
          onUpdate: handleUpdate,
          onDelete: handleDelete,
        })}
        dataSource={allCycles}
        rowKey="key"
      />

      <Button type="primary" onClick={handleTo}>
        Add
      </Button>
    </>
  );
};

export default CyclesPage;
