import { useState, useEffect, useContext } from "react";
import { Button, Typography, Cascader, Input, Form, Modal } from "antd";
import { AppContext } from "../../../../App";
import { DefaultOptionType } from "antd/lib/select";
import useApiPost from "../../../../hooks/useApiPost";
import { toast } from "react-toastify";

const GeographicalHierarchyHome = () => {
  const { projectId } = useContext(AppContext);
  const { request, setError } = useApiPost();

  const [selectedCountry, setselectedCountry] = useState<string>("");
  const [selectedCounty, setselectedCounty] = useState<string>("");
  const [selectedRegion, setselectedRegion] = useState<string>("");
  const [selectedCluster, setselectedCluster] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [regionsData, setregionsData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState<string>("");
  const [heirarchyLevel, setheirarchyLevel] = useState<number>(0);
  const [heirarchyId, setheirarchyId] = useState<string>("");

  const isCountryActive = selectedCountry?.length > 0;
  const isRegionActive = selectedRegion?.length > 0;
  const isCountyActive = selectedCounty?.length > 0;
  const isClusterActive = selectedCluster?.length > 0;
  
  const isAddingEnabled = isCountryActive && !isClusterActive;
  const isDeleteEnabled = isCountryActive || isRegionActive || isCountyActive || isClusterActive;
  const isSubmitDisabled = name?.length > 0;

  useEffect(() => {
    fetchData();
  }, [projectId]);

  const fetchData = async () => {
    resetState()
    setIsLoading(true);
    const resp = await request("/office/regions", "POST", {
      projectId,
    });
    if(resp){
      setregionsData(resp?.data?.regions);
    }
    
    setIsLoading(false);
  };
  
  const onCreateNew = async () => {
    if(name.length < 3){
      Modal.error({
        title: "Error",
        content:
        `Please enter the valid ${getAddText()?.toLowerCase()} name.`,
      });
      return
    }
    setIsLoading(true);
    const resp = await request("/office/geo-hierarchy/add", "POST", {
      project_id: projectId,
      parent_id: heirarchyId,
      level: heirarchyLevel,
      levelLabel: name
    });
    if(resp?.status == 200){
      toast.info(resp?.message, {
        position: "bottom-center",
      });
      fetchData();
    } else {
      toast.info(resp?.message, {
        position: "bottom-center",
      });
    }
    setIsLoading(false);
  };

  const resetState = () => {
    setselectedCounty("")
    setselectedRegion("")
    setselectedCluster("")
    setselectedCountry("")
    setName("")
    setheirarchyLevel(0)
    setheirarchyId("")
  }

  const onDelete = async () => {
    setIsLoading(true);
    const resp = await request("/office/geo-hierarchy/delete", "POST", {
      project_id: projectId,
      id: heirarchyId,
      level: heirarchyLevel - 1
    });
    if (resp?.status == 200) {
      toast.info(resp?.message, {
        position: "bottom-center",
      });
      fetchData();
    } else {
      toast.info(resp?.message, {
        position: "bottom-center",
      });
    }
    resetState();
    setIsLoading(false);
  };

  const onChange = (val: any) => {
    if (!val) {
      setselectedCountry("");
      setselectedRegion("");
      setselectedCounty("");
      setselectedCluster("");
      return;
    }
    setselectedCountry(val[0]);
    setselectedRegion(val[1]);
    setselectedCounty(val[2]);
    setselectedCluster(val[3]);
    setheirarchyLevel(val.length+1)
    setheirarchyId(val[val.length-1])
  };

  const filter = (input: string, path: DefaultOptionType[]) =>
    path.some(
      ({ label }: any) => label.toLowerCase().indexOf(input.toLowerCase()) > -1
    );
    // country -> region -> county -> cluster
  const getAddText = () => {
    if (isCountryActive && !isRegionActive) {
      return "Region";
    }
    if (isCountryActive && isRegionActive && !isCountyActive) {
      return "County";
    }
    if (isCountryActive && isRegionActive && isCountyActive) {
      return "Cluster"; 
    }
  };

  const handleModalCancel = () => setIsModalOpen(false);
  const handleConfirmDelete = async () => {
    await fetchData();
    setIsModalOpen(false);
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <div style={{ maxWidth: 600 }}>
      <Modal
        title="Confirmation"
        open={isModalOpen}
        onOk={handleConfirmDelete}
        onCancel={handleModalCancel}
        centered
        footer={[
          <Button key="back" onClick={handleModalCancel}>
            Back
          </Button>,
          <Button
            key="submit"
            type="primary"
            danger
            onClick={handleConfirmDelete}
          >
            Confirm Delete
          </Button>,
        ]}
      >
        <div>
          Are you sure you want to delete this item? This action cannot be
          undone.
        </div>
      </Modal>
      <Typography.Title>Geographical Hierarchy</Typography.Title>
      <Form>
        <Form.Item label="Select item" name="flags">
          <Cascader
            showSearch={{ filter }}
            options={regionsData}
            expandTrigger="hover"
            onChange={(val) => onChange(val)}
            changeOnSelect
          />
        </Form.Item>
        {isAddingEnabled && (
          <Form.Item label={`New ${getAddText()}`} name="name">
            <Input
              style={{ width: 400 }}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Item>
        )}
      </Form>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: 260,
        }}
      >
        <Button
          type="primary"
          onClick={() => onCreateNew()}
          disabled={!isAddingEnabled && !isSubmitDisabled}
        >
          ADD
        </Button>
        <Button
          type="primary"
          danger
          onClick={() => onDelete()}
          disabled={!isDeleteEnabled ||  heirarchyLevel == 2}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

export default GeographicalHierarchyHome;