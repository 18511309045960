
import { Button, Space } from 'antd'
import React from 'react'

function DropOffViewButton({
    onCancel=()=>{},
    onReject=()=>{},
    onApprove=()=>{},
    cancel='Cancel',
    reject='Reject',
    approve='Approve'
}) {
  return (
    <Space size="middle">
    <Button
      type={ "default"}
      onClick={onCancel}
    >
     {cancel}
    </Button>
    <Button
      type="primary"
      danger
      onClick={onReject}
      // disabled={isDeleting}
    >
     {reject}
    </Button>
    <Button
      type={ "primary" }
      onClick={onApprove}
    >
      {approve}
    </Button>
  </Space>
  )
}

export default DropOffViewButton