import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// import "antd/dist/antd.css";
import { Space, Table, Button, Switch, InputRef, Input } from "antd";
import { AppContext } from "../../../../App";
import { DataType } from "./pos-basket-types";
import type { FilterConfirmProps } from "antd/es/table/interface";
import type { ColumnsType, ColumnType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import useApiPost from "../../../../hooks/useApiPost";

type DataIndex = keyof DataType;

const PosBasketList = () => {
  const searchInput = useRef<InputRef>(null);
  const { request, setError } = useApiPost();

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: any) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const exists = record[dataIndex] ? record[dataIndex] : false;

      if (!exists) {
        return false;
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        //@ts-ignore
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const appContext = useContext(AppContext);
  const { projectId } = appContext;

  useEffect(() => {
    console.log({ projectId });
  }, [projectId]);

  const [isLoading, setIsLoading] = useState(false);
  const [baskets, setBaskets] = useState<DataType[]>([]);

  const dateToStart = new Date();
  dateToStart.setHours(0, 0, 0, 0);
  const dateFromEnd = new Date();
  dateFromEnd.setHours(23, 59, 59, 999);

  const getData = async () => {
    if (!projectId) {
      return;
    }
    const baskets = await request("/office/pos-baskets/list", "POST", {
      projectId,
    });

    baskets.map((basket: DataType) => {
      basket.startDate = new Date(basket.startDate).toLocaleDateString();
      basket.endDate = new Date(basket.endDate).toLocaleDateString();
    });

    setBaskets(baskets);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  let navigate = useNavigate();

  const handleTo = () => {
    navigate("/admin/pos-basket/new");
  };

  const handleUpdate = async (id: string) => {
    navigate(`/admin/pos-basket/view/${id}`);
  };

  const handleDelete = async (id: string) => {
    await request(`/office/pos-baskets/${id}`, "DELETE");
    const filteredBaskets = baskets.filter((basket) => basket.id !== id);
    setBaskets(filteredBaskets);
  };
  const columns = (props: any): ColumnsType<DataType> => [
    {
      title: "Active",
      dataIndex: "isActive",
      render: (val: any) => (
        <div>
          <Switch defaultChecked={val} disabled />
        </div>
      ),
    },
    {
      title: "Start",
      dataIndex: "startDate",
      key: "startDate",
      ...getColumnSearchProps("startDate"),
      render: (date: any) => (
        <>
          <div>{date}</div>
        </>
      ),
    },
    {
      title: "End",
      dataIndex: "endDate",
      key: "endDate",
      ...getColumnSearchProps("endDate"),
      render: (date: any) => (
        <>
          <div>{date}</div>
        </>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ...getColumnSearchProps("description"),
      render: (text: any) => (
        <>
          <div style={{ textAlign: "center" }}>{text ? text : "-"}</div>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space size="middle" key={record.basketId}>
          <Button type="primary" onClick={() => props.onUpdate(record.id)}>
            Details
          </Button>
          {/* <Button
            type="primary"
            danger
            onClick={() => props.onDelete(record.id)}
          >
            Delete
          </Button> */}
        </Space>
      ),
    },
  ];

  // const handleUploadBatchFile = async (e: any) => {
  //   const file = e.target.files[0];
  //   const data: any[] = [];
  //   await readXlsxFile(file).then(async (rows) => {
  //     rows.map((row, index) => {
  //       if (index > 0) {
  //         const chain = row[0]?.toString().trim();
  //         const outletType = row[1]?.toString().trim();
  //         // headers
  //         if (chain === "Chain" && outletType === "Outlet Type") {
  //           return;
  //         }
  //         const productName = row[2]?.toString().trim();
  //         const productCode = row[3]?.toString().trim();
  //         const MSH = row[4]?.toString().trim();
  //         const RSP = row[5]?.toString().trim();
  //         const startDate = row[6]?.toString().trim();
  //         const endDate = row[7]?.toString().trim();

  //         const item: SheetDataSet = {
  //           chain,
  //           outletType,
  //           productName,
  //           productCode,
  //           MSH: parseInt(MSH),
  //           RSP: parseFloat(RSP),
  //           startDate,
  //           endDate,
  //         };

  //         data.push(item);

  //         // if(serialNumber !== serialNumber2) {
  //         //   if(!serialNumber2) {
  //         //     return;
  //         //   }
  //         //   data.push({
  //         //     ...item,
  //         //     serialNumber: serialNumber2
  //         //   });
  //         // }
  //       }
  //     });
  //     console.log({ data });
  //     if(data.length > 0){
  //       await validateBasket(data);
  //     }
  //     setNewData(data);
  //   });
  // };
  return (
    <>
      {/* <div>
        <div
          style={{
            paddingBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Switch
            checkedChildren="Active"
            unCheckedChildren="All"
            defaultChecked
            onChange={() => setIsOnlyActiveVisible(!isOnlyActiveVisible)}
          />
          <Button type="primary" onClick={() => getData()}>
            Refresh
          </Button>
        </div>
      </div> */}
      <Table
        columns={columns({
          onUpdate: handleUpdate,
          onDelete: handleDelete,
        })}
        dataSource={baskets}
        rowKey="key"
      />

      <Button type="primary" onClick={handleTo}>
        Add
      </Button>
    </>
  );
};

export default PosBasketList;
