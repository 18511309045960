import { useContext, useEffect, useState } from "react";
import { Button, Input, Modal, Select } from "antd";
import { AppContext } from "../../../../App";
import { FaDotCircle } from "react-icons/fa";
import * as S from "./interaction-settings-pages.styled";
import useApiPost from "../../../../hooks/useApiPost";

interface ICategoryOption {
  id: string;
  text: string;
}

interface InteractionSetting {
  id: string;
  type: string;
  options: ICategoryOption[];
}

const InteractionSettingsPage = () => {
  const { projectId } = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [newCategoryName, setNewCategoryName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState<InteractionSetting[]>([]);
  const [actionType, setActionType] = useState<"add" | "edit">();
  const [editOptionId, setEditOptionId] = useState<string>("");
  const [editSettingId, setEditSettingId] = useState<string>("");
  const [deleteText, setDeleteText] = useState<string>("");
  const { request, setError } = useApiPost();

  const getData = async () => {
    const { settings } = await request("/office/interaction-settings", "POST", {
      project_id: projectId,
    });
    setSettings(settings);
  };

  useEffect(() => {
    getData();
  }, []);

  const handeAddCategory = () => {
    setActionType("add");
    setIsModalOpen(true);
    setNewCategoryName("");
    setSelectedType("");
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    setSelectedType("");
    setNewCategoryName("");
    setDeleteText("");
    setEditOptionId("");
    setEditSettingId("");
  };

  const handleEditOption = (optionId: string) => {
    setActionType("edit");
    setIsModalOpen(true);
    setEditOptionId(optionId);
  };

  const handleEditSetting = (settingId: string) => {
    setActionType("edit");
    setIsModalOpen(true);
    setEditSettingId(settingId);
  };

  const AddCategory = {
    title: "Add Entry",
    content: (
      <div>
        <div>Select interaction type to add option</div>
        <Select
          allowClear
          style={{ width: "100%" }}
          options={settings.map(({ type, id }) => ({
            label: type,
            value: id,
          }))}
          value={selectedType}
          onChange={(value) => {
            setSelectedType(value);
          }}
          key="id"
        />
        <div>Name</div>
        <Input
          value={newCategoryName}
          onChange={(e) => setNewCategoryName(e.target.value)}
        />
      </div>
    ),
    onsubmit: async () => {
      setIsLoading(true);
      if (selectedType) {
        await request("/office/interaction-settings/insert/option", "POST", {
          project_id: projectId,
          interactionSetting_id: selectedType,
          text: newCategoryName,
        });
      } else {
        await request("/office/interaction-settings/insert", "POST", {
          project_id: projectId,
          interactionType: newCategoryName,
        });
      }
      setNewCategoryName("");
      await getData();
      setIsLoading(false);
      setIsModalOpen(false);
      setDeleteText("");
      setEditOptionId("");
      setEditSettingId("");
    },
    ondelete: async () => {
      setIsLoading(true);
      await getData();
      setIsLoading(false);
      setIsModalOpen(false);
      setDeleteText("");
      setNewCategoryName("");
      setEditOptionId("");
      setEditSettingId("");
    },
  };

  const EditSettings = {
    title: "Information",
    content: (
      <div>
        <div>{`Do you want to delete "${deleteText}" ${
          editOptionId ? "option?" : "setting and all options?"
        }`}</div>
      </div>
    ),
    onsubmit: async () => {
      setIsLoading(true);
      setNewCategoryName("");
      await getData();
      setIsLoading(false);
      setIsModalOpen(false);
      setDeleteText("");
      setEditOptionId("");
      setEditSettingId("");
    },
    ondelete: async () => {
      setIsLoading(true);
      if (editOptionId) {
        await request("/office/interaction-settings/delete/option", "POST", {
          project_id: projectId,
          option_id: editOptionId,
        });
      } else {
        await request("/office/interaction-settings/delete/setting", "POST", {
          project_id: projectId,
          setting_it: editSettingId,
        });
      }
      await getData();
      setIsLoading(false);
      setIsModalOpen(false);
      setDeleteText("");
      setNewCategoryName("");
      setEditOptionId("");
      setEditSettingId("");
    },
  };

  const getContent = () => {
    switch (actionType) {
      case "add":
        return AddCategory;
      case "edit":
        return EditSettings;
      default:
        return AddCategory;
    }
  };

  const currentContent = getContent();
  const isDeleteButtonVisible = actionType === "edit";
  const isSaveButtonVisible = actionType === "add";
  const confirmButtonText = actionType === "add" ? "Add" : "Save";
  const isSaveCategoryActive = newCategoryName.length > 0;
  const isSaveSubcategoryActive = true;
  const isSaveActive =
    actionType === "add" ? isSaveCategoryActive : isSaveSubcategoryActive;

  const renderCategoriesChildren = (options: ICategoryOption[]) => {
    return (
      <S.SubCategoriesWrapper>
        {options.map((option, index) => (
          <>
            <S.Row key={index}>
              <FaDotCircle size={8} />
              <S.CategoryName
                key={option.text}
                onClick={() => {
                  handleEditOption(option.id);
                  setDeleteText(option.text);
                }}
              >
                {option.text}
              </S.CategoryName>
            </S.Row>
          </>
        ))}
      </S.SubCategoriesWrapper>
    );
  };

  return (
    <div>
      <Modal
        title={currentContent.title}
        open={isModalOpen}
        onCancel={handleModalCancel}
        centered
        footer={[
          isDeleteButtonVisible ? (
            <Button
              key="delete"
              type="primary"
              danger
              onClick={() => currentContent.ondelete()}
            >
              Delete
            </Button>
          ) : null,
          <Button key="back" onClick={handleModalCancel}>
            Back
          </Button>,
          isSaveButtonVisible ? (
            <Button
              key="submit"
              type="primary"
              onClick={() => currentContent.onsubmit()}
              disabled={!isSaveActive}
            >
              {confirmButtonText}
            </Button>
          ) : null,
        ]}
      >
        {currentContent.content}
      </Modal>
      <h1>Interaction Settings</h1>
      <S.CategoriesWrapper>
        {settings.map((setting, index) => (
          <>
            <S.Row key={index}>
              <FaDotCircle size={8} />
              <S.CategoryName
                onClick={() => {
                  handleEditSetting(setting.id);
                  setDeleteText(setting.type);
                }}
              >
                {setting.type}
              </S.CategoryName>
            </S.Row>
            {renderCategoriesChildren(setting.options)}
          </>
        ))}
      </S.CategoriesWrapper>
      <S.Actions>
        <Button type="primary" onClick={handeAddCategory} loading={isLoading}>
          Add
        </Button>
      </S.Actions>
    </div>
  );
};

export default InteractionSettingsPage;
