import { useEffect, useState } from "react";
import { Form, Input, Button, Typography, Switch } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import useApiPost from "../../../../hooks/useApiPost";
import { toast } from "react-toastify";


const ClientForm = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(true);
  const navigate = useNavigate();
  const headerText = clientId ? "Edit Client" : "Add Client";
  const { request } = useApiPost();

  useEffect(() => {
    fetchClientData();
  }, [clientId]);

  const handleComeBack = () => navigate("/admin/clients");

  const fetchClientData = async () => {
    setIsLoading(true);
    if (!clientId) {
      setIsLoading(false);
      return;
    }
    const { data } = await request(`/office/clients/${clientId}`, "GET", null, undefined, "v2");
    if (!data) {
      navigate(`/admin`);
      return;
    }    
    const { name, isActive } = data;
    setName(name);
    setIsActive(isActive);
    setIsLoading(false);
  };

  

  const handleInsert = async () => {
    try {
      let pattern = new RegExp(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/)
      if (!name.trim()) return alert("Please enter client name!")
      if (!pattern.test(name)) return alert("Only Characters & Numbers allowed")
      const res = await request("/office/clients/add", "POST", {
        name,
        isActive,
        accountNumber: "0"
      }, undefined, "v2");
      setIsLoading(false);
      if (res.error) {
        alert(res.error);
        return;
      }
      handleComeBack();
      toast.info("Client has been added successfully.", {
        position: "top-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      if (!clientId) return;
     let res =  await request("/office/clients/edit", "PATCH", {
        clientId,
        isActive,
      }, undefined, "v2");
      setIsLoading(false);
      handleComeBack();
      if (res?.status == 400) {
        return toast.info(res?.message, {
          position: "top-right",
        });
      }
      toast.info("Client has been updated successfully.", {
        position: "top-right",
      });
    } finally {
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Form labelCol={{ span: 4 }}>
        <Form.Item className="form-item" label="Name" rules={[
          {
            required: true,
            message: 'Please input client name!',
          },
          {
            pattern: new RegExp(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/),
            message: 'Only Characters & Numbers Allowed'
        }]}>
          {clientId ? (
            <div>{name}</div>
          ) : (
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          )}
        </Form.Item>
        <Form.Item label="Is active" valuePropName="isActive" name="isActive">
          <Switch defaultChecked={isActive} onChange={(e) => setIsActive(e)} />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            onClick={clientId ? handleUpdate : handleInsert}
          >Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ClientForm;
