import { createContext, useContext, useEffect, useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import auth from "../src/config/firebase";

export const AuthContext = createContext<any>({});
export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProviderOld = ({ children }: any) => {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const login = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user: any) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    error,
    setError,
    login,
    // logout,
  };

  return (
    <AuthContext.Provider
      value={{
        ...value,
      }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};
