import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Button, Typography, DatePicker, Table, Modal } from "antd";
import { DataType } from "../types";
import moment from "moment";
import { AppContext } from "../../../../App";
import readXlsxFile from "read-excel-file";
import { SheetDataSet } from "./sku-basket-types";
import useApiPost from "../../../../hooks/useApiPost";
import { handleEndDate, handleStartDate } from "../../../../utils/dateTime";

const SkuBasketForm = () => {
  const [form] = Form.useForm();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { request, setError } = useApiPost();

  const showModal = () => {
    setIsConfirmModalOpen(true);
  };

  const handleOk = () => {
    setIsConfirmModalOpen(false);
  };

  const handleCancel = () => {
    setIsConfirmModalOpen(false);
  };

  const appContext = useContext(AppContext);
  const { basketId } = useParams<{ basketId: string }>();
  const [chains, setChains] = useState<any[]>([]);
  const [channels, setChannels] = useState<any[]>([]);
  const [treeData, setTreeData] = useState<DataType[]>([]);
  const [initValues, setInitValues] = useState<any>({});

  const [products, setProducts] = useState<any[]>([]);
  const [newData, setNewData] = useState<any[]>([]);
  const [newEntries, setNewEntries] = useState<any[]>([]);
  const [entriesToValidate, setEntriesToValidate] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const [formOptionValues, setFormOptionValues] = useState({
    outletType_id: null,
  });

  const { projectId } = appContext;
  const [isLoading, setIsLoading] = useState(false);
  const headerText = basketId ? "Edit SKU Basket" : "Import SKU Basket";
  const navigate = useNavigate();

  const handleComeBack = () => {
    navigate("/admin/sku-baskets");
  };

  const fetchBasketData = async () => {
    setIsLoading(true);
    if (!basketId) {
      return;
    }
    const { basket, products } = await request(
      `/office/sku-baskets/details/${basketId}`,
      "POST",
      {
        projectId,
      }
    );
    setProducts(products);
    setFormOptionValues({
      outletType_id: basket.outletType_id,
      // channel_id: userData.channel_id,
      // chain_id: userData.chain_id,
    });

    setInitValues({
      ...basket,
      // ...formOptionValues,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    form.setFieldsValue(initValues);
  }, [initValues, basketId]);

  useEffect(() => {
    fetchBasketData();
  }, [basketId]);

  useEffect(() => {
    const fetchOptions = async () => {
      setIsLoading(true);
      const { outletTypes, channels, chains } = await request(
        "/office/sku-baskets/options/creation/basket",
        "POST",
        {
          projectId,
        }
      )
      setChains(chains);
      setChannels(channels);
      setTreeData(outletTypes);
      setIsLoading(false);
    };
    fetchOptions();
  }, [projectId]);

  const displayRender = (labels: string[]) => labels[labels.length - 1];

  const handleOptionChange = (key: string, event: any) => {
    setFormOptionValues({
      ...formOptionValues,
      [key]: event.value,
    });
  };

  const getLabel = (id: string) => {
    let label = "";
    const findLabel = (data: DataType[]) => {
      data.forEach((item) => {
        if (item.id === id) {
          label = item.title;
        } else if (item.children && item.children.length > 0) {
          findLabel(item.children);
        }
      });
    };
    findLabel(treeData);
    return label;
  };
  const onChange = (key: string, value: any) => {
    const lastValue = Array.isArray(value) ? value[value.length - 1] : value;
    handleOptionChange(key, { value: lastValue });
  };
  if (isLoading || !chains || chains.length === 0) {
    return <p>Loading...</p>;
  }

  const insertBasket = async (data: any) => {
    const { newResults, entriesToValidate } = await request(
      "/office/sku-baskets/validate",
      "POST",
      {
        basketData: data,
        projectId,
        startDate,
        endDate,
      }
    );
    console.log({ newResults, entriesToValidate });
    setNewEntries(newResults);
    setEntriesToValidate(entriesToValidate);
    setIsConfirmModalOpen(true);
  };

  const validateBasket = async () => {
    if (entriesToValidate.length === 0) {
      return;
    }

    await request("/office/sku-baskets/confirm-validation", "POST", {
      entries: entriesToValidate,
    });
    // )
    // await confirmBasketValidation({ entries: entriesToValidate });

    navigate("/admin/sku-baskets");
  };

  const handleUploadBatchFile = async (e: any) => {
    const file = e.target.files[0];
    const data: any[] = [];

    await readXlsxFile(file).then(async (rows) => {
      rows.map((row, index) => {
        if (index > 1) {
          const chain = row[0]?.toString().trim();
          const outletType = row[1]?.toString().trim();
          // headers
          if (chain === "Chain" && outletType === "Outlet Type") {
            return;
          }
          const productName = row[2]?.toString().trim();
          const productCode = row[3]?.toString().trim();
          const MSH = row[4]?.toString().trim();
          const dealDescription = row[5]?.toString().trim();
          let RSP = row[6]?.toString().trim();
          const startDate = row[7]?.toString().trim();
          const endDate = row[8]?.toString().trim();

          RSP = RSP === "N/A" ? "0" : RSP;
          const item: SheetDataSet = {
            chain,
            outletType,
            productName,
            productCode,
            MSH: parseFloat(MSH),
            RSP: parseFloat(RSP),
            startDate,
            endDate,
            dealDescription,
          };

          data.push(item);
        }
      });
      console.log(data);
      if (data.length > 0) {
        await insertBasket(data);
      }
      // setNewData(data);
    });
  };

  const handleUploadBatchForPricing = async (e: any) => {
    const file = e.target.files[0];
    const data: any[] = [];
    await readXlsxFile(file).then(async (rows) => {
      rows.map((row, index) => {
        if (index > 1) {
          const productCode = row[3]?.toString().trim();
          const MSH = row[4]?.toString().trim();
          const RSP = row[6]?.toString().trim();
          const item: any = {
            productCode,
            MSH: parseInt(MSH),
            RSP: parseFloat(RSP),
          };

          data.push(item);
        }
      });
      if (data.length > 0) {
        // await updatePricings(data);
        await request("/office/sku-baskets/update-price", "POST", {
          pricings: data,
        });
      }
      // setNewData(data);
    });
  };

  const entriesExist = newEntries.length > 0;
  const newProductsLength = newEntries.filter((t) => t.isNewProduct).length;
  const newOutletsLength = newEntries.filter((t) => t.isNewOutlet).length;
  const missingChains = newEntries.filter((t) => !t.chain_id);
  const missingChainLength = missingChains.length;
  const isFileUploadDisabled = !startDate || !endDate;

  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={() => {}}
      >
        {/* <Form.Item label="Chain" name="chain_id">
          <Select
            onChange={(e: any) => updateForm("chain_id", e)}
            options={chains}
          />
        </Form.Item>
        <Form.Item label="Channel" name="channel_id">
          <Select
            onChange={(e: any) => updateForm("channel_id", e)}
            options={channels}
          />
        </Form.Item>
        <Form.Item label="Outlet Type">
          <Cascader
            options={treeData}
            expandTrigger="hover"
            displayRender={displayRender}
            defaultValue={[getLabel(formOptionValues.outletType_id || "")]}
            onChange={(val) => onChange("outletType_id", val)}
            showSearch={{ filter }}
            changeOnSelect
          />
        </Form.Item> */}

        <div>
          <Form.Item
            label="Start"
            name="startDate"
            // getValueProps={(i: any) => ({ value: moment(i) })}
            required
          >
            <DatePicker
              allowClear={false}
              onChange={(e) => setStartDate(handleStartDate(e))}
            />
          </Form.Item>
          <Form.Item
            label="End"
            name="endDate"
            // getValueProps={(i: any) => ({ value: moment(i) })}
            required
            rules={[
              {
                required: true,
                message: "Please select a form end",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  const start = new Date(getFieldValue("startDate"));
                  const end = new Date(value);

                  if (start > end) {
                    return Promise.reject(
                      "End date must be greater than start date"
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <DatePicker
              allowClear={false}
              onChange={(e) => setEndDate(handleEndDate(e))}
            />
          </Form.Item>
        </div>
        {/* <Form.Item label="Products"> */}
        <Table
          rowKey="id"
          style={{
            fontSize: "12px",
          }}
          rowClassName={(record, index) => {
            if (record.chain_id && record.outletType_id && record.product_id) {
              return "success";
            }
            return "error";
          }}
          pagination={{
            pageSize: 1000,
          }}
          dataSource={newEntries}
          columns={[
            // {
            //   title: "Order",
            //   dataIndex: "order",
            //   key: "order",
            // },
            {
              title: "Chain",
              dataIndex: "chain",
              key: "chain",
            },
            // {
            //   title: "Chain ID",
            //   dataIndex: "chain_id",
            //   key: "chain_id",
            // },
            {
              title: "Outlet Type",
              dataIndex: "outletType",
              key: "outletType",
            },
            // {
            //   title: "Outlet Type ID",
            //   dataIndex: "outletType_id",
            //   key: "outletType_id",
            // },
            {
              title: "Product Code",
              dataIndex: "productCode",
              key: "productCode",
            },
            {
              title: "Product Name",
              dataIndex: "productName",
              key: "productName",
            },
            // {
            //   title: "Product ID",
            //   dataIndex: "product_id",
            //   key: "product_id",
            // },
            {
              title: "RSP",
              dataIndex: "RSP",
              key: "RSP",
            },
            {
              title: "MSH",
              dataIndex: "MSH",
              key: "MSH",
            },
            {
              title: "Start Date",
              dataIndex: "startDate",
              key: "startDate",
              render: (text) => (
                <div>{new Date(text).toLocaleDateString()}</div>
              ),
            },
            {
              title: "End Date",
              dataIndex: "endDate",
              key: "endDate",
              render: (text) => (
                <div>{new Date(text).toLocaleDateString()}</div>
              ),
            },
            // {
            //   title: "Action",
            //   key: "action",

            //   render: (text: any, record: any) => (
            //     <Space size="middle">
            //       <Button
            //         type="primary"
            //         danger
            //         // onClick={() => handleDelete(record)}
            //       >
            //         Delete
            //       </Button>
            //     </Space>
            //   ),
            // },
          ]}
        />
        {/* </Form.Item> */}

        {/* <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}> */}
        {/* <Button type="primary" htmlType="submit">
            ADD
          </Button> */}
        {/* <div>
          <Upload customRequest={dummyRequest} onChange={(e) => handleUploadBatchFile(e)}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </div> */}
        <div
          style={{
            padding: "8px 10px 0px",
          }}
        >
          <input
            type="file"
            id="input"
            accept=".xlsx, .xls"
            disabled={isFileUploadDisabled}
            onChange={(e) => handleUploadBatchFile(e)} // handleUploadBatchForPricing(e)}//handleUploadBatchFile(e)}
          />
        </div>
        {entriesExist && (
          <Button type="primary" onClick={validateBasket}>
            Confirm all entries
          </Button>
        )}
        <Button type="primary" style={{ margin: 10 }} onClick={handleComeBack}>
          Go back
        </Button>

        <Modal
          title="Information"
          open={isConfirmModalOpen}
          centered
          width={800}
          footer={[
            // <Button key="back" onClick={handleCancel}>
            //   Cancel
            // </Button>,
            <Button
              type="primary"
              onClick={handleOk}
              // disabled={!isCreateAvailable}
            >
              OK
            </Button>,
          ]}
        >
          <p
            style={{
              textAlign: "center",
            }}
          >
            Your data has been validated.
          </p>
          {/* list newly created products */}
          {newProductsLength > 0 && (
            <div>
              <p>
                {newProductsLength} new products were added based on missing
                product codes
              </p>
              <Table
                dataSource={newEntries.filter((t) => t.isNew)}
                rowKey="id"
                columns={[
                  {
                    title: "Product Code",
                    dataIndex: "productCode",
                    key: "productCode",
                  },
                  {
                    title: "Product Name",
                    dataIndex: "productName",
                    key: "productName",
                  },
                  {
                    title: "Product ID",
                    dataIndex: "product_id",
                    key: "product_id",
                  },
                ]}
              />
            </div>
          )}
          {missingChainLength > 0 && (
            <>
              <p>{`System found ${missingChainLength} missing chains:`}</p>
              {missingChains &&
                missingChains.map(({ chain }, index) => (
                  <div key={index} style={{ fontWeight: 800 }}>
                    {chain}
                  </div>
                ))}
              <div
                style={{
                  paddingTop: 10,
                  textAlign: "center",
                  color: "red",
                  fontWeight: 800,
                }}
              >{`Please verify the import sheet for spellling mistakes or add missing outlets and try again`}</div>
            </>
          )}
        </Modal>
        {/* </Form.Item> */}
      </Form>
    </>
  );
};

export default SkuBasketForm;
