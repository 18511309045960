import { useEffect, useState } from "react";
import { Button, Select, Input, Form, Typography, Table } from "antd";
import { useParams } from "react-router-dom";
import FormItem from "antd/es/form/FormItem";
import useApiPost from "../../../../hooks/useApiPost";

interface DataType {
  id: string;
  key: string;
  assignee: string;
  text: string;
  photo: string;
  category: string;
  outletName: string;
  age: number;
  status: string;
  assigneeID: string;
}

interface IComment {
  comment: string;
  date: string;
  user: string;
}

const RedFlagsEdit = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<DataType>();
  const [comment, setComment] = useState<string>("");
  const [assignees, setAssignees] = useState<any[]>([]);
  const [selectedAssignee, setSelectedAssignee] = useState<string>("");
  const [commentsHistory, setCommentsHistory] = useState<IComment[]>([]);
  const { id } = useParams<{ id: string }>();
  const { assigneeID } = data ? data : { assigneeID: "" };
  const { request, setError } = useApiPost();

  useEffect(() => {
    const getAssignees = async () => {
      setIsLoading(true);
      if (assigneeID) {
        const data = await request(`/office/red-flags/assignees/${assigneeID}`);
        setAssignees(data);
        setIsLoading(false);
      }
    };
    getAssignees();
  }, [assigneeID]);

  useEffect(() => {
    const getRedFlagDetails = async () => {
      setIsLoading(true);
      const data = await request(`/office/red-flags/${id}`);
      setData(data);
      setIsLoading(false);
    };
    getRedFlagDetails();
  }, []);

  const closeRedFlag = async () => {
    await request(`/office/red-flags/${id}`, "POST", {
      senseId: id,
      comment,
      userId: null,
    });
  };

  const commentRedFlag = async () => {
    await request(`/office/red-flags/comment/${id}`, "POST", {
      senseId: id,
      comment,
      userId: null,
    });

    getCommentsHistory();
  };

  useEffect(() => {
    getCommentsHistory();
  }, []);

  const getCommentsHistory = async () => {
    if (!id) {
      return;
    }
    const comms = await request(`/office/red-flags/history/${id}`);
    setCommentsHistory(comms);
  };

  const reassign = async () => {
    await request(`/office/red-flags/reassign/${id}`, "POST", {
      sense_id: id,
      newUser_id: selectedAssignee,
      currentUser_id: assigneeID,
    });
    getCommentsHistory();
  };

  const assigneeList =
    assignees &&
    assignees.length > 0 &&
    assignees.map((a: any) => ({ value: a.assigneeID, label: a.assignee }));

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (!data) {
    return <div>Data not found</div>;
  }

  const isActionSubmitDisabled = !comment; // || data.status === "Closed";
  const isActionInputDisabled = data.status === "Closed";

  const isReassignSubmitDisabled = !selectedAssignee || !comment;
  const isReassignSelecttDisabled = !comment;

  const isCloseFlagDisabled = !comment || data.status === "Closed";

  const handleSetAssignee = (value: string) => {
    setSelectedAssignee(value);
  };

  const onFinish = async (values: any) => {
    // setIsLoading(true);
    // console.log({ values, formOptionValues });
    // const submitData = {
    //   ...values,
    //   ...formOptionValues,
    //   project_id: projectId,
    // };
    // if (basketId) {
    //   handleUpdate({
    //     ...submitData,
    //   });
    // } else {
    //   handleInsert(submitData);
    // }
    // setIsLoading(false);
  };

  return (
    <>
      <Typography.Title>Edit Red flag</Typography.Title>
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={onFinish}
      >
        <Form.Item label="Assignee">{data.assignee}</Form.Item>
        <Form.Item label="Text">
          <div>{data.text}</div>
        </Form.Item>
        {data.photo && (
          <Form.Item label="Photo">
            <div>
              <img
                src={data.photo}
                style={{
                  maxWidth: 300,
                  border: "1px solid grey",
                  borderRadius: 8,
                }}
              />
            </div>
          </Form.Item>
        )}
        <Form.Item label="Category">{data.category}</Form.Item>
        <Form.Item label="Outlet">{data.outletName}</Form.Item>
        <Form.Item label="Age">{data.age}</Form.Item>
        <Form.Item label="Status">{data.status}</Form.Item>
        <Form.Item label="Comment">
          <Input
            type="textarea"
            name="comment"
            id="comment"
            value={comment}
            maxLength={100}
            onChange={(e: any) => setComment(e.target.value)}
            disabled={isActionInputDisabled}
          />
        </Form.Item>
        <Button onClick={commentRedFlag} disabled={isActionSubmitDisabled}>
          Comment
        </Button>
        {/* </div> */}
        {/* <div > */}
        <FormItem label="Reassign">
          <Select
            options={assigneeList || []}
            style={{ width: "100%", maxWidth: "200px" }}
            onChange={handleSetAssignee}
            disabled={isReassignSelecttDisabled}
          />
        </FormItem>
        <FormItem>
          <Button onClick={reassign} disabled={isReassignSubmitDisabled}>
            Reassign
          </Button>
        </FormItem>
        <Button onClick={closeRedFlag} disabled={isCloseFlagDisabled}>
          Close red flag
        </Button>
        <div>
          <Typography.Title>Comments history</Typography.Title>
          <Table
            columns={[
              {
                title: "User",
                dataIndex: "user",
                key: "user",
                render: (text) => <div>{text}</div>,
              },
              {
                title: "Comment",
                dataIndex: "comment",
                key: "comment",
                render: (text) => <div>{text}</div>,
              },
              {
                title: "Date",
                dataIndex: "date",
                key: "user",
                render: (text) => (
                  <div>{`${new Date(text).toDateString()}`}</div>
                ),
              },
            ]}
            dataSource={commentsHistory}
            pagination={false}
          />
        </div>
      </Form>
    </>
  );
};

export default RedFlagsEdit;
