import { useState, useContext } from "react";
import { useParams } from "react-router-dom";
// import "antd/dist/antd.css";
import { Form, Typography } from "antd";
import { DataType } from "../types";
import { AppContext } from "../../../../App";
import readXlsxFile from "read-excel-file";
import { SheetDataSet2 } from "./diary-import.types";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ImportInfoTable from "../common/ImportInfoTable";
import useApiPost from "../../../../hooks/useApiPost";
import templateImg from "../../../../assets/images/updated-diary-import-template.png";

dayjs.extend(customParseFormat);
const columns: any = [
  // {
  //   title: "(A) Date",
  //   column: "A",
  // },
  // {
  //   title: "(B) Retail Cloud ID",
  //   column: "B",
  // },
  // {
  //   title: "(C) Call Type",
  //   column: "C",
  // },
  // {
  //   title: "(D) Email",
  //   column: "D",
  // },
];
const description =
  "Below you wil find a list of fields and their corresponding excel columns. Please make sure that the values are correct before importing the file.";

const DiaryImportPage = () => {
  const { scorecardId } = useParams<{ scorecardId: string }>();
  const appContext = useContext(AppContext);
  const { projectId } = appContext;
  const [isLoading, setIsLoading] = useState(false);
  const headerText = scorecardId ? `Edit Call Diaries` : "Import Call Diaries";
  const { request, setError } = useApiPost();

  const handleUploadBatchFile = async (e: any) => {
    setIsLoading(true);
    const file = e.target.files[0];
    const diariesData: any[] = [];
    await readXlsxFile(file, { sheet: 1 }).then(async (rows) => {
      rows.map((row, index) => {
        if (index > 0) {
          const date = row[0]?.toString().trim();
          const retailCloudId = row[1]?.toString().trim();
          const callType = row[2]?.toString().trim();
          const time = row[3]?.toString().trim();
          const email = row[4]?.toString().trim();

          const item: SheetDataSet2 = {
            date,
            retailCloudId,
            callType,
            email,
            time,
          };
          diariesData.push(item);
        }
      });
    });

    await request("/office/pos-baskets/diaries", "POST", {
      diariesData,
      projectId,
    });

    setIsLoading(false);

    alert("Diary import successful");
  };

  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Form
        // form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={undefined}
      >
        <ImportInfoTable
          columns={columns}
          description={description}
          imgSrc={templateImg}
        />
        <div>
          <input
            type="file"
            id="input"
            onChange={(e) => handleUploadBatchFile(e)}
            disabled={isLoading}
          />
        </div>
      </Form>
    </>
  );
};

export default DiaryImportPage;
