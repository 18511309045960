import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// import "antd/dist/antd.css";
import { Space, Table, Button, Switch } from "antd";
import type { ColumnsType } from "antd/es/table";
import { AppContext } from "../../../../App";
import useApiPost from "../../../../hooks/useApiPost";

interface DataType {
  id: string;
  key: string;
  name: string;
}

interface ColumnType {
  onUpdate: (id: string) => void;
  onDelete: (id: string) => void;
}

const columns = (props: ColumnType): ColumnsType<DataType> => [
  {
    title: "Active",
    dataIndex: "isActive",
    render: (val) => (
      <div>
        <Switch defaultChecked={val} disabled />
      </div>
    ),
  },
  {
    title: "Name",
    dataIndex: "description",
    key: "description",
    render: (text) => <div>{text}</div>,
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    render: (_, record) => (
      <Space size="middle" key={record.id}>
        <Button type="primary" onClick={() => props.onUpdate(record.id)}>
          Update
        </Button>
      </Space>
    ),
  },
];

const RolesList = () => {
  const appContext = useContext(AppContext);
  const { request, setError } = useApiPost();

  const { projectId } = appContext;

  const [, setIsLoading] = useState(false);
  const [allRoles, setAllRoles] = useState<DataType[]>([]);
  let navigate = useNavigate();

  const handleTo = () => {
    navigate("/admin/role/new");
  };

  useEffect(() => {
    const getAllRoles = async () => {
      setIsLoading(true);
      if (!projectId) {
        return;
      }

      const res = await request("/office/roles", "POST", {
        projectId,
      });

      const data = res.map((e: any, index: number) => ({
        ...e,
        key: index,
      }));

      setAllRoles(data);
      setIsLoading(false);
    };
    getAllRoles();
  }, [projectId]);

  const handleUpdate = async (id: string) => {
    navigate(`/admin/role/view/${id}`);
  };

  const handleDelete = async (id: string) => {
    await request(`/roles/${id}`, "DELETE");
  };

  return (
    <>
      <Table
        size="small"
        columns={columns({
          onUpdate: handleUpdate,
          onDelete: handleDelete,
        })}
        dataSource={allRoles}
        rowKey="key"
      />

      <Button type="primary" onClick={handleTo}>
        Add
      </Button>
    </>
  );
};

export default RolesList;
