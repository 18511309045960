import { useState, useContext } from "react";
import { useParams } from "react-router-dom";
// import "antd/dist/antd.css";
import { Alert, Button, Form, Typography } from "antd";
// import { DataType } from "../types";
import { AppContext } from "../../../../App";
import readXlsxFile from "read-excel-file";
// import { SheetDataSet2 } from "./diary-import.types";
// import { insertDiary } from "../../../../api/office/scorecards";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ImportInfoTable from "../common/ImportInfoTable";
import templateImg from "../../../../assets/images/outlets-import-template.png";
import useApiPost from "../../../../hooks/useApiPost";
// import { importNewOutlets } from "../../../../api/office/outlet";

// Outlet Name	Client Outlet Name	City (Select from dropdown first check if Suburb is not loaded)	Mall Name (If applicable)	Shop Number (If applicable)	Street Number	Street Address	Latitude (-)	Longtitude (+)	Telephone Number	Client Outlet Code	Outlet Chain (Select from dropdown)
dayjs.extend(customParseFormat);
const columns = [
  {
    title: "(A) Outlet Name",
    column: "A",
  },
  {
    title: "(B) Client Outlet Name",
    column: "B",
  },
  {
    title: "(C) City",
    column: "C",
  },
  {
    title: "(D) Mall Name",
    column: "D",
  },
  {
    title: "(E) Shop Number",
    column: "E",
  },
  {
    title: "(F) Street Number",
    column: "F",
  },
  {
    title: "(G) Street Address",
    column: "G",
  },
  {
    title: "(H) Latitude",
    column: "H",
  },
  {
    title: "(I) Longtitude",
    column: "I",
  },
  {
    title: "(J) Telephone Number",
    column: "J",
  },
  {
    title: "(K) Client Outlet Code",
    column: "K",
  },
  {
    title: "(L) Outlet Chain",
    column: "L",
  },
];

const description =
  "Below you wil find a list of fields and their corresponding excel columns. Please make sure that the values are correct before importing the file.";

interface errorTypes {
  value: string;
  error: string;
}

const OutletImportPage = () => {
  const { scorecardId } = useParams<{ scorecardId: string }>();
  const appContext = useContext(AppContext);
  const { projectId } = appContext;
  const [isLoading, setIsLoading] = useState(false);
  const [importData, setImportData] = useState<any[]>([]);
  const [errors, setErrors] = useState<errorTypes[]>([]);
  const { request, setError } = useApiPost();

  const handleUploadBatchFile = async (e: any) => {
    setIsLoading(true);
    const file = e.target.files[0];
    await readXlsxFile(file).then(async (rows) => {
      console.log({ rows });
      rows.map((row, index) => {
        if (index > 0) {
          const outletName = row[0]?.toString().trim();
          const clientOutletName = row[1]?.toString().trim();
          const cityName = row[2]?.toString().trim();
          const mallName = row[3]?.toString().trim();
          const shopNumber = row[4]?.toString().trim();
          const streetNumber = row[5]?.toString().trim();
          const streetAddress = row[6]?.toString().trim();
          const latitude = row[7]?.toString().trim();
          const longitude = row[8]?.toString().trim();
          const telephoneNumber = row[9]?.toString().trim();
          const clientOutletCode = row[10]?.toString().trim();
          const outletChainName = row[11]?.toString().trim();

          const item: any = {
            name: outletName,
            clientOutletName,
            cityName,
            mall: mallName,
            shopNumber_id: shopNumber,
            streetNumber,
            streetAddress,
            latitude,
            longitude,
            telephoneNumber,
            clientOutletCode,
            outletChainName,
            projectId,
          };
          console.log({ item });
          importData.push(item);
        }
      });
    });

    // const res = await importNewOutlets({ outlets: importData, projectId });
    const res = await request("/office/outlets/outlets-import", "POST", {
      data: { outlets: importData, projectId },
    });
    if (res.errors.length) {
      setErrors(res.errors);
    }

    setIsLoading(false);
  };

  const handleUpload = async () => {
    console.log({ importData });
    // const result =
    //   selectedTargetType === "Targets"
    //     ? await insertTargets(
    //         dataToUpload,
    //         projectId,
    //         selectedMonth,
    //         selectedYear,
    //         selectedOutletType
    //       )
    //     : await insertActuals(
    //         dataToUpload,
    //         projectId,
    //         selectedMonth,
    //         selectedYear,
    //         selectedOutletType
    //       );

    // const { notExistingOutlets } = result;

    // if (notExistingOutlets.filter((e: any) => !!e).length) {
    //   setNotExistingOutlets(notExistingOutlets.filter((e: any) => !!e));
    // } else {
    //   alert("Data uploaded successfully");
    // }

    // getAllStatus(projectId);
  };

  return (
    <>
      <Typography.Title>Import new outlets</Typography.Title>
      {errors.length > 0 && (
        <Alert
          message="Please make sure that the excel file is in the correct format before uploading it."
          type="warning"
          showIcon
          description={
            errors.map((e) => (
              <div>
                <b>{e.value}</b> - {e.error}
              </div>
            )) || ""
          }
        />
      )}
      <Form
        // form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={undefined}
      >
        <ImportInfoTable
          columns={columns}
          description={description}
          imgSrc={templateImg}
        />
        <div>
          <input
            type="file"
            id="input"
            onChange={(e) => handleUploadBatchFile(e)}
            disabled={isLoading}
          />
        </div>
        <div
          style={{
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Button
            disabled={isLoading}
            loading={isLoading}
            onClick={() => handleUpload()}
          >
            Confirm
          </Button>
        </div>
      </Form>
    </>
  );
};

export default OutletImportPage;
