import styled from "styled-components";


export const SequenceContainer = styled.div`
  border-radius: 50%;
  border: 1px solid black;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:black;
  color: white;
`

export const SequenceValue = styled.div`
  font-weight: 800;
`

export const QuestionBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  /* border: 1px black solid;
  border-radius: 8px; */
  padding: 10px;
  margin-bottom: 10px;
`;

export const OptionBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  border: 1px #0000002b solid;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex: 1;

  .ant-form-item {
    width: 100%;
    
    .and-form-item-row {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  flex: 1;
  height: 30px;
`;

export const Label = styled.span`
  margin-bottom: 12px;
  padding-right: 10px;
`