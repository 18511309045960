import { useContext, useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Select,
  InputRef,
  Space,
  DatePicker,
  Spin,
  Modal,
} from "antd";
import { EMPTY_USER } from "../../../../utils/user";
import { AppContext } from "../../../../App";
import { DefaultOptionType } from "antd/lib/select";
import { useNavigate, useParams } from "react-router-dom";
import Table, { ColumnsType, ColumnType } from "antd/es/table";
import {
  SearchOutlined,
  LoadingOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import { FilterConfirmProps } from "antd/es/table/interface";
import Highlighter from "react-highlight-words";
import { IOption, User } from "../DiaryManagement/types";
import moment from "moment";
import * as S from "./StoreFinder.style";
import useApiPost from "../../../../hooks/useApiPost";
import { handleStartDate } from "../../../../utils/dateTime";

const getDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  unit: "K" | "N"
) => {
  var radlat1 = (Math.PI * lat1) / 180;
  var radlat2 = (Math.PI * lat2) / 180;
  var theta = lon1 - lon2;
  var radtheta = (Math.PI * theta) / 180;
  var dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit == "K") {
    dist = dist * 1.609344;
  }
  if (unit == "N") {
    dist = dist * 0.8684;
  }
  return dist;
};

interface DataType {
  id: string;
  key: string;
  name: string;
  photo: string;
  address1: string;
  distance: number;
  channel: string;
  chain: string;
  county: string;
  dealerId: string;
  outletType: string;
  latitude: string;
  longitude: string;
  visitId: string;
}

interface AlreadyScheduledCalls {
  [key: string]: {
    status: string;
    plannedCallStart: string;
    callType_id: string;
    customer_id: string;
    plannedCallId: string;
    visitId: string;
  }[];
}

type DataIndex = keyof DataType;
// import "antd/dist/antd.css";

const DiaryManagement = () => {
  const { userId } = useParams<{ userId: string }>();
  const { projectId } = useContext(AppContext);
  const searchInput = useRef<InputRef>(null);
  const [allOutlets, setAllOutlets] = useState<DataType[]>([]);
  const visibleOutlets = allOutlets.filter((outlet) => outlet.id !== userId);
  const [isCheckingGPS, setIsCheckingGPS] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeRow, setActiveRow] = useState<DataType | null>(null);
  const { request, setError } = useApiPost();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [currentLocation, setCurrentLocation] = useState<any>({
    latitude: 0,
    longitude: 0,
  });
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [startDate, setStartDate] = useState<any>(null);
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  useEffect(() => {
    if (currentLocation.latitude === 0 || currentLocation.longitude === 0) {
      const getPosition = (position: any) => {
        setIsCheckingGPS(false);
        setCurrentLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
        // const [currentLocation, setCurrentLocation] = useState<any>({
        // console.log(position.coords.latitude, position.coords.longitude);
      };
      const error = (err: any) => {
        console.log(err);
        setIsCheckingGPS(false);
      };
      if (navigator.geolocation) {
        setIsCheckingGPS(true);
        navigator.geolocation.getCurrentPosition(getPosition, error, {
          timeout: 10000,
        });
      }
      // navigator.geolocation.getCurrentPosition((position) => {
      //   console.log("position, shieet", position)
      //   setCurrentLocation({
      //     latitude: position.coords.latitude,
      //     longitude: position.coords.longitude,
      //   });
      // });
    }
  }, [allOutlets]);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: any) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        //@ts-ignore
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = (props: any): ColumnsType<DataType> => [
    {
      title: "Business Site",
      dataIndex: "name",
      key: "name",
      render: (text) => <div>{text}</div>,
      ...getColumnSearchProps("name"),
    },
    {
      title: "Distance",
      dataIndex: "distance",
      key: "distance",
      render: (text) => {
        return isCheckingGPS ? (
          <Spin />
        ) : (
          <div>{text ? text.toFixed(2) : 0} km</div>
        );
      },
    },
    {
      title: "County",
      dataIndex: "county",
      key: "county",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Chain",
      dataIndex: "chain",
      key: "chain",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Channel",
      dataIndex: "channel",
      key: "channel",
      render: (text) => <div>{text}</div>,
    },
    // {
    //   title: "Telephone",
    //   dataIndex: "telephoneNumber",
    //   key: "telephoneNumber",
    //   render: (text) => <div>{text}</div>,
    // },
    // {
    //   title: "Photo",
    //   dataIndex: "photo",
    //   key: "photo",
    // },
    // {
    //   title: "address1",
    //   dataIndex: "address1",
    //   key: "address1",
    // },
    {
      title: "Status",
      dataIndex: "isPlanned",
      key: "isPlanned",
      render: (_, record) => {
        const isPlanned = isCallAlreadyPlanned(record) && record.distance > 0;
        const isCheckedIn = isCallCheckedIn(record);
        const showPulsar = isPlanned || isCheckedIn;

        return (
          <Space size="middle" key={record.id}>
            {/* <Button
              disabled={
                startDate === null ||
                formOptionValues.callType_id === null ||
                formOptionValues.cycle_id === null
              }
              type="primary"
              onClick={() => props.onUpdate(record.id)}
            ></Button> */}
            {showPulsar && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {isCheckedIn ? <S.Pulsar isOnline={true} /> : <S.RedDot />}
              </div>
            )}

            {/* <Button type="primary" danger onClick={() => props.onDelete(record.id)}>
            Delete
          </Button> */}
          </Space>
        );
      },
    },
  ];
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userRolesOptions, setUserRolesOptions] = useState<IOption[]>([]);
  const [userNetworkOptions, setUserNetworkOptions] = useState<IOption[]>([]);
  const [cycles, setCycles] = useState<IOption[]>([]);
  const [callTypes, setCallTypes] = useState<IOption[]>([]);
  const [alreadyScheduledCalls, setAlreadyScheduledCalls] =
    useState<AlreadyScheduledCalls>({});
  const [treeData, setTreeData] = useState<DataType[]>([]);
  const navigate = useNavigate();
  const headerText = "Store Finder";

  const [formOptionValues, setFormOptionValues] = useState({
    hierarchy_id: null,
    contactNetwork_id: null,
    role_id: null,
    cycle_id: null,
    callType_id: null,
  });

  const [initValues, setInitValues] = useState<User>(EMPTY_USER);

  const fetchUserData = async () => {
    setIsLoading(true);
    if (!userId) {
      return;
    }
    const userData = await request(`/office/users/${userId}`, "POST", {
      projectId,
    })
    if (!userData) {
      navigate(`/admin`);
      return;
    }
    const { hierarchy_id, contactNetwork_id, role_id } = userData;
    // setFormOptionValues({
    //   hierarchy_id,
    //   contactNetwork_id,
    //   role_id,
    //   cycle_id,
    //   callType_id,
    // });

    setInitValues({
      ...userData,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchUserData();
  }, [userId, projectId]);

  useEffect(() => {
    form.setFieldsValue(initValues);
  }, [initValues, userId]);

  const handleComeBack = () => navigate("/admin/users");

  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true);
      const { callTypes, cycles, outlets, allPlannedCalls } = await request(
        "/office/training/options",
        "POST",
        {
          projectId,
        }
      );
      if (currentLocation.latitude !== 0 && currentLocation.longitude !== 0) {
        outlets.map((outlet: any, index: number) => {
          outlet.key = index;
          outlet.distance = getDistance(
            outlet.latitude,
            outlet.longitude,
            currentLocation.latitude,
            currentLocation.longitude,
            "K"
          );
        });
      }

      setAlreadyScheduledCalls(allPlannedCalls);
      setCycles(cycles);
      setCallTypes(callTypes);
      setAllOutlets(outlets);

      setIsLoading(false);
    };
    fetchAllData();
  }, [projectId]);

  useEffect(() => {
    allOutlets.forEach((outlet: any) => {
      outlet.key = outlet.name;
      outlet.distance = getDistance(
        outlet.latitude,
        outlet.longitude,
        currentLocation.latitude,
        currentLocation.longitude,
        "K"
      );
    });
  }, [currentLocation]);

  const handleInsert = async (values: any) => {
    try {
      // await insertUser(values);
      await request("/office/users/insert", "POST", {
        ...values,
      });
      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  const isCallAlreadyPlanned = (row: DataType) => {
    if (
      !alreadyScheduledCalls[row.id] ||
      !alreadyScheduledCalls[row.id].length
    ) {
      return false;
    }

    const isPlanned = alreadyScheduledCalls[row.id].find(
      (plannedCall) =>
        plannedCall.status === "Open" &&
        // plannedCall.callType_id === formOptionValues.callType_id &&
        // plannedCall.customer_id === row.id &&
        moment(plannedCall.plannedCallStart, "YYYY-MM-DD").isSame(
          new Date(),
          "day"
        )
    );
    return isPlanned ? true : false;
  };

  const isCallCheckedIn = (row: DataType) => {
    if (
      !alreadyScheduledCalls[row.id] ||
      !alreadyScheduledCalls[row.id].length
    ) {
      return false;
    }

    const isCheckedIn = alreadyScheduledCalls[row.id].find(
      (plannedCall) =>
        plannedCall.status === "Busy" &&
        // plannedCall.callType_id === formOptionValues.callType_id &&
        // plannedCall.customer_id === row.id &&
        moment(plannedCall.plannedCallStart, "YYYY-MM-DD").isSame(
          new Date(),
          "day"
        )
    );
    return isCheckedIn ? true : false;
  };
  const handleUpdate = async (values: any) => {
    try {
      // await updateUser(values);
      // setIsLoading(false);
      // handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  const onFinish = async (values: any) => {
    setIsLoading(true);

    const submitData = {
      ...values,
      ...formOptionValues,
      project_id: projectId,
    };

    if (userId) {
      handleUpdate({
        ...submitData,
        userId,
      });
    } else {
      handleInsert(submitData);
    }
  };

  const handleOptionChange = (key: string, event: any) => {
    setFormOptionValues({
      ...formOptionValues,
      [key]: event.value,
    });
  };

  // if (isLoading)  || userRolesOptions.length === 0) {
  //   return <p>Loading...</p>;
  // }

  const displayRender = (labels: string[]) => labels[labels.length - 1];
  const onChange = (key: string, value: any) => {
    const lastValue = Array.isArray(value) ? value[value.length - 1] : value;
    handleOptionChange(key, { value: lastValue });
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1
    );

  // const getLabel = (id: string) => {
  //   let label = "";
  //   const findLabel = (data: DataType[]) => {
  //     data.forEach((item) => {
  //       if (item.id === id) {
  //         label = item.title;
  //       } else if (item.children && item.children.length > 0) {
  //         findLabel(item.children);
  //       }
  //     });
  //   };
  //   findLabel(treeData);
  //   return label;
  // };

  // const isInsertDisabled = hierarchy_id === null || role_id === null;

  useEffect(() => {
    if (currentLocation.latitude !== 0 && currentLocation.longitude !== 0) {
      const outlets = [...allOutlets];
      outlets.forEach((outlet: any, index) => {
        outlet.key = index;
        outlet.distance = getDistance(
          outlet.latitude,
          outlet.longitude,
          currentLocation.latitude,
          currentLocation.longitude,
          "K"
        );
      });
      outlets.sort((a: any, b: any) => a.distance - b.distance);
      setAllOutlets(outlets);
    }
  }, [currentLocation]);
  useEffect(() => {
    setIsCheckingGPS(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(getPosition, error, {
        timeout: 10000,
      });
    }
    function getPosition(position: any) {
      setIsCheckingGPS(false);
      setCurrentLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
      // const [currentLocation, setCurrentLocation] = useState<any>({
      // console.log(position.coords.latitude, position.coords.longitude);
    }
    function error(err: any) {
      setIsCheckingGPS(false);
      console.log(err);
    }
  }, []);
  // const isGPSLoading = currentLocation.latitude === 0;

  const handleCheckGPS = () => {
    setIsCheckingGPS(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(getPosition, error, {
        timeout: 10000,
      });
    }
    function getPosition(position: any) {
      setIsCheckingGPS(false);
      setCurrentLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
      // const [currentLocation, setCurrentLocation] = useState<any>({
      // console.log(position.coords.latitude, position.coords.longitude);
    }
    function error(err: any) {
      setIsCheckingGPS(false);
      console.log(err);
    }
  };

  const handleCheckIn = async () => {
    if (!activeRow) {
      return;
    }

    if (
      !alreadyScheduledCalls[activeRow.id] ||
      !alreadyScheduledCalls[activeRow.id].length
    ) {
      return false;
    }

    const maybePlanned = alreadyScheduledCalls[activeRow.id].find(
      (plannedCall) =>
        plannedCall.status === "Open" &&
        // plannedCall.callType_id === formOptionValues.callType_id &&
        // plannedCall.customer_id === row.id &&
        moment(plannedCall.plannedCallStart, "YYYY-MM-DD").isSame(
          new Date(),
          "day"
        )
    );

    if (!maybePlanned) {
      return false;
    }
    const res = await request("/office/training/check-in", "POST", {
      plannedCallId: maybePlanned.plannedCallId,
    });

    if (res.status === 200) {
      const newVisitId = res.data.visitId;

      navigate(`/training/call-details/${newVisitId}`);
      // redirect to training/call-details/:visitId

      // const newPlannedCall = newPlannedCalls.find(
      //   (plannedCall) => plannedCall.plannedCallId === maybePlanned.plannedCallId
      // );
      // if (newPlannedCall) {
      //   newPlannedCall.status = "In Progress";
      //   // newPlannedCall.visitId = newVisitId;
      // }
      // setAlreadyScheduledCalls({
      //   ...alreadyScheduledCalls,
      //   [activeRow.id]: newPlannedCalls,
      // });
    }
  };

  const isModalAlreadyPlannedCall =
    activeRow && isCallAlreadyPlanned(activeRow) && activeRow.distance > 0;

  const isModalCheckIn = activeRow && isCallCheckedIn(activeRow);
  // const isCheckedIn = isCallCheckedIn(record);

  const handleGoToCall = () => {
    if (!activeRow) {
      return;
    }

    const maybeBusyCall = alreadyScheduledCalls[activeRow.id].find(
      (plannedCall) =>
        plannedCall.status === "Busy" &&
        // plannedCall.callType_id === formOptionValues.callType_id &&
        // plannedCall.customer_id === row.id &&
        moment(plannedCall.plannedCallStart, "YYYY-MM-DD").isSame(
          new Date(),
          "day"
        )
    );

    if (maybeBusyCall) {
      navigate(`/training/call-details/${maybeBusyCall.visitId}`);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography.Title>{headerText}</Typography.Title>
        <Button
          style={{
            margin: "22px 0 0px 20px",
          }}
          icon={isCheckingGPS ? <LoadingOutlined /> : <EnvironmentOutlined />}
          onClick={handleCheckGPS}
        >
          Check GPS
        </Button>
      </div>
      <Form
        form={form}
        labelCol={{ span: 4 }}
        // wrapperCol={{ span: 10 }}
        onFinish={onFinish}
        initialValues={initValues}
      >
        <Form.Item
          label="Call Type"
          rules={[
            { required: true, message: "Call type is required to proceed!" },
          ]}
        >
          <Select
            loading={isLoading}
            options={callTypes}
            defaultValue={formOptionValues.callType_id}
            onChange={(_value, e) => {
              handleOptionChange("callType_id", e);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Cycle"
          hasFeedback
          name="cycle_id"
          rules={[{ required: true, message: "Cycle is required to proceed!" }]}
        >
          <Select
            loading={isLoading}
            options={cycles}
            defaultValue={formOptionValues.cycle_id}
            onChange={(_value, e) => {
              handleOptionChange("cycle_id", e);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Date"
          name="startDate"
          // getValueProps={(i: any) => ({ value: moment(i) })}
          required
        >
          <DatePicker
            allowClear={false}
            onChange={(e) => setStartDate(handleStartDate(e))}
          />
        </Form.Item>
        <Table
          columns={columns({
            onUpdate: handleUpdate,
            // onDelete: handleDelete,
          })}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                setActiveRow(record);
                setIsModalOpen(true);
              }, // click row
            };
          }}
          dataSource={allOutlets}
          rowKey="id"
          pagination={{
            defaultPageSize: 25,
          }}
          loading={isLoading}
        />
        <Modal
          title="Outlet Details"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Return
            </Button>,
            // !isModalAlreadyPlannedCall ? (
            // <>
            <Button
              disabled={!isModalCheckIn}
              key="submit"
              type="primary"
              onClick={handleGoToCall}
            >
              Go to call
            </Button>,
            <Button
              disabled={!!!isModalAlreadyPlannedCall}
              key="check-in"
              type="primary"
              onClick={handleCheckIn}
            >
              Check In
            </Button>,
            // ) : null,
          ]}
        >
          <p>Outlet name: {activeRow?.name}</p>
          <p>Channel: {activeRow?.channel} </p>
          <p>Chain: {activeRow?.chain}</p>
          {activeRow?.dealerId && <p>Dealer ID: {activeRow?.dealerId}</p>}
          {activeRow?.outletType && <p>Outlet Type: {activeRow?.outletType}</p>}
          <p>Project: National Chains</p>
          <p>Latitude: {activeRow?.latitude}</p>
          <p>Longitude: {activeRow?.longitude}</p>
        </Modal>
      </Form>
    </>
  );
};

export default DiaryManagement;
