import { useContext, useState } from "react";
import { Form, Input, Button, Typography, Collapse } from "antd";
import { FormEvent } from "react";
import { AppContext } from "../../../../App";
import { useNavigate } from "react-router-dom";
import useApiPost from "../../../../hooks/useApiPost";
import * as S from "./kt-modules.styled";

const { Panel } = Collapse;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

interface ClusterSpecialistsInput {
  name: string;
  questions: KTModuleQuestion[];
  isFormal: boolean;
}

interface KTModuleQuestion {
  questionOrder: number;
  question: string;
  type: string;
  responses: {
    score: number;
    response: string;
    sequence: number;
  }[];
}

const KTModulesCreation = () => {
  const { request, setError } = useApiPost();
  const appContext = useContext(AppContext);
  const { projectId } = appContext;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState<KTModuleQuestion[]>([]);
  const [isFormal, setIsFormal] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const navigate = useNavigate();

  const sendForm = async (e: FormEvent) => {
    setLoading(true);

    try {
      const input: ClusterSpecialistsInput = {
        name,
        questions,
        isFormal,
      };

      const isOnlyOneAnswerCorrectPerQuestion = questions.every((question) => {
        const correctAnswers = question.responses.filter(
          (response) => response.score === 1
        );
        return correctAnswers.length === 1;
      });

      if (!isOnlyOneAnswerCorrectPerQuestion) {
        alert(
          "Please make sure that there is only one correct answer per question"
        );
        return;
      }

      await request("/office/kt-modules/insert", "POST", {
        KTModuleData: input,
        projectId,
      });
      navigate(`/admin/knowledge-transfer`);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  const handleAddNewQuestion = () => {
    setQuestions((questions) => [
      ...questions,
      {
        questionOrder: questions.length + 1,
        question: "",
        type: "radio",
        responses: [
          {
            response: "",
            sequence: 1,
            score: 0,
          },
          {
            response: "",
            sequence: 2,
            score: 0,
          },
          {
            response: "",
            sequence: 3,
            score: 0,
          },
        ],
      },
    ]);
  };

  const generateRadio = (index: number) => (
    <div>
      {questions[index].responses.map((option, optionIndex) => (
        <S.OptionBox key={`option_${optionIndex}`}>
          <Form.Item label="Option">
            <Input
              type="text"
              value={option.response}
              onChange={(e) => {
                setQuestions((questions) => {
                  return questions.map((question, questionIndex) => {
                    if (questionIndex === index) {
                      return {
                        ...question,
                        responses: question.responses.map(
                          (option, optionIndex2) => {
                            if (optionIndex === optionIndex2) {
                              return {
                                ...option,
                                response: e.target.value,
                              };
                            }
                            return option;
                          }
                        ),
                      };
                    }
                    return question;
                  });
                });
              }}
              required
            />
          </Form.Item>
          <Form.Item label="Score">
            <Input
              type="number"
              value={option.score}
              onChange={(e) => {
                setQuestions((questions) => {
                  return questions.map((question, questionIndex) => {
                    if (questionIndex === index) {
                      return {
                        ...question,
                        responses: question.responses.map(
                          (option, optionIndex2) => {
                            if (optionIndex === optionIndex2) {
                              return {
                                ...option,
                                score: parseInt(e.target.value),
                              };
                            }
                            return option;
                          }
                        ),
                      };
                    }
                    return question;
                  });
                });
              }}
              required
            />
          </Form.Item>
          <Button
            type="primary"
            danger
            onClick={() => {
              setQuestions((questions) => {
                return questions.map((question, questionIndex) => {
                  if (questionIndex === index) {
                    return {
                      ...question,
                      responses: question.responses.filter(
                        (option, optionIndex2) => optionIndex !== optionIndex2
                      ),
                    };
                  }
                  return question;
                });
              });
            }}
            style={{ maxWidth: 100, marginLeft: "auto" }}
          >
            Delete
          </Button>
        </S.OptionBox>
      ))}
      <Button
        type="primary"
        onClick={() => {
          setQuestions((questions) => {
            return questions.map((question, questionIndex) => {
              if (questionIndex === index) {
                return {
                  ...question,
                  responses: [
                    ...question.responses,
                    {
                      response: "",
                      sequence: question.responses.length + 1,
                      score: 1,
                    },
                  ],
                };
              }
              return question;
            });
          });
        }}
      >
        Add Option
      </Button>
    </div>
  );

  return (
    <>
      <Typography.Title>KT Module Creation</Typography.Title>
      <Form
        labelCol={{ span: 4 }}
        // wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={sendForm}
        form={form}
      >
        <Form.Item label="Is Formal">
          <Input
            type="checkbox"
            style={{
              maxWidth: 40,
            }}
            onChange={(e) => {
              setIsFormal(e.target.checked);
            }}
          />
        </Form.Item>
        <Form.Item label="Name">
          <Input
            type="text"
            name="name"
            required
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Form.Item>
        <Collapse bordered={false} style={{ padding: 0 }} defaultActiveKey={[]}>
          {questions.map((question, index) => (
            <Panel
              header={`(${index + 1}) ${questions[index].question}`}
              key={`${index}`}
            >
              <S.QuestionBox key={`box_${index}`}>
                <S.HeaderRow>
                  <S.Label>Question:</S.Label>
                  <Form.Item
                    style={{
                      width: "100%",
                      paddingRight: 10,
                      marginBottom: 12,
                    }}
                  >
                    <Input
                      type="text"
                      name={`questions[${index}].text`}
                      required
                      value={question.question}
                      onChange={(e) => {
                        setQuestions((questions) => {
                          return questions.map((question, questionIndex) => {
                            if (questionIndex === index) {
                              return {
                                ...question,
                                question: e.target.value,
                              };
                            }
                            return question;
                          });
                        });
                      }}
                    />
                  </Form.Item>
                </S.HeaderRow>
                <div>{generateRadio(index)}</div>
              </S.QuestionBox>
            </Panel>
          ))}
        </Collapse>
        <Button type="primary" onClick={() => handleAddNewQuestion()}>
          Add question
        </Button>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={questions.length === 0 || !name}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default KTModulesCreation;
