import ackermans from "../../../../assets/markers/ackermans.svg";
import black from "../../../../assets/markers/black.svg";
import checkers from "../../../../assets/markers/checkers.svg";
import dunns from "../../../../assets/markers/dunns.svg";
import edgars from "../../../../assets/markers/edgars.svg";
import fashion from "../../../../assets/markers/fashion.svg";
import game from "../../../../assets/markers/game.svg";
import hifi from "../../../../assets/markers/hifi.svg";
import house from "../../../../assets/markers/house.svg";
import istore from "../../../../assets/markers/istore.svg";
import pep from "../../../../assets/markers/pep.svg";
import pickAndPay from "../../../../assets/markers/pick&pay.svg";
import powercell from "../../../../assets/markers/powercell.svg";
import price from "../../../../assets/markers/price.svg";
import vodacom from "../../../../assets/markers/vodacom.svg";
import woolworths from "../../../../assets/markers/woolworths.svg";

// Ackermans, Ackermans Woman, Ackermans Connect = R= 0; G= 144, B = 81
// Pep, Pep Home, Pep Cell= R = 0, G = 150, B = 255
// Mr Price Home, Mr Price Cellular, Mr Price, Sheet Street = R = 112, G= 48, B = 160
// Edgars = R = 145, G= 145, B = 145
// ASJ, Exact, Foschini, Hi, Jet, Jet Cellular, Markhams, Sportscene, Sterns, The Fix, Totalsports = R = 0, G= 0, B = 0
// Checkers, Checkers Hyper, Food World, Knect, Shoprite, Usave = R = 255, G= 252, B = 121
// Dunns = R = 255, G= 147, B = 0
// Fashion World = R = 0, G= 250, B = 146
// Game, Makro = R = 255, G= 133, B = 255
// Hifi Corporation, Incredible Connection = R = 4, G= 51, B = 255
// House & Home, OK Furniture = R = 148, G= 82, B = 0
// iStore = R = 235, G= 235, B = 235
// Pick n Pay Clothing, Pick n Pay Family, Pick n Pay Hyper, Pick n Pay Super = R = 1, G= 25, B = 147
// Powercell = R = 148, G= 17, B = 0
// Vodacom Express, Vodacom Repairs, Vodacom Shop, Independent Dealer, Cellucity, Chatz= R = 255, G= 0, B = 0
// Woolworths = R = 215, G= 131, B = 255

export const getGPSCorrectionMarkerColor = (isNewLocation: boolean) => {
  if (isNewLocation) {
    return ackermans;
  } else {
    return vodacom;
  }
};

export const getMarkerColor = (brand: string) => {
  switch (brand) {
    case "Ackermans":
      return "rgb(0, 144, 81)";
    case "Ackermans Woman":
      return "rgb(0, 144, 81)";
    case "Ackermans Connect":
      return "rgb(0, 144, 81)";
    case "Pep":
      return "rgb(0, 150, 255)";
    case "Pep Home":
      return "rgb(0, 150, 255)";
    case "Pep Cell":
      return "rgb(0, 150, 255)";
    case "Mr Price Home":
      return "rgb(112, 48, 160)";
    case "Mr Price Cellular":
      return "rgb(112, 48, 160)";
    case "Mr Price":
      return "rgb(112, 48, 160)";
    case "Sheet Street":
      return "rgb(112, 48, 160)";
    case "Edgars":
      return "rgb(145, 145, 145)";
    case "ASJ":
      return "rgb(0, 0, 0)";
    case "Exact":
      return "rgb(0, 0, 0)";
    case "Foschini":
      return "rgb(0, 0, 0)";
    case "Hi":
      return "rgb(0, 0, 0)";
    case "Jet":
      return "rgb(0, 0, 0)";
    case "Jet Cellular":
      return "rgb(0, 0, 0)";
    case "Markhams":
      return "rgb(0, 0, 0)";
    case "Sportscene":
      return "rgb(0, 0, 0)";
    case "Sterns":
      return "rgb(0, 0, 0)";
    case "The Fix":
      return "rgb(0, 0, 0)";
    case "Totalsports":
      return "rgb(0, 0, 0)";
    case "Checkers":
      return "rgb(255, 252, 121)";
    case "Checkers Hyper":
      return "rgb(255, 252, 121)";
    case "Food World":
      return "rgb(255, 252, 121)";
    case "Knect":
      return "rgb(255, 252, 121)";
    case "Shoprite":
      return "rgb(255, 252, 121)";
    case "Usave":
      return "rgb(255, 252, 121)";
    case "Dunns":
      return "rgb(255, 147, 0)";
    case "Fashion World":
      return "rgb(0, 250, 146)";
    case "Game":
      return "rgb(255, 133, 255)";
    case "Makro":
      return "rgb(255, 133, 255)";
    case "Hifi Corporation":
      return "rgb(4, 51, 255)";
    case "Incredible Connection":
      return "rgb(4, 51, 255)";
    case "House & Home":
      return "rgb(148, 82, 0)";
    case "OK Furniture":
      return "rgb(148, 82, 0)";
    case "iStore":
      return "rgb(235, 235, 235)";
    case "Pick n Pay Clothing":
      return "rgb(1, 25, 147)";
    case "Pick n Pay Corporate":
      return "rgb(1, 25, 147)";
    case "Pick n Pay Market":
      return "rgb(1, 25, 147)";
    case "Pick n Pay Family":
      return "rgb(1, 25, 147)";
    case "Pick n Pay Hyper":
      return "rgb(1, 25, 147)";
    case "Pick n Pay Super":
      return "rgb(1, 25, 147)";
    case "Powercell":
      return "rgb(148, 17, 0)";
    case "Vodacom Express":
      return "rgb(255, 0, 0)";
    case "Vodacom Repairs":
      return "rgb(255, 0, 0)";
    case "Vodacom Shop":
      return "rgb(255, 0, 0)";
    case "Independent Dealer":
      return "rgb(255, 0, 0)";
    case "Cellucity":
      return "rgb(255, 0, 0)";
    case "Chatz":
      return "rgb(255, 0, 0)";
    case "Woolworths":
      return "rgb(215, 131, 255)";
    default:
      return "rgb(0, 0, 0)";
  }
};

export const getMarkerIcon = (brand: string) => {
  switch (brand) {
    case "Ackermans":
      return ackermans;
    case "Ackermans Woman":
      return ackermans;
    case "Ackermans Connect":
      return ackermans;
    case "Ackermans Connect ":
      return ackermans;
    case "PEP":
      return pep;
    case "PEP Market":
      return pep;
    case "PEP Home":
      return pep;
    case "PEP Cell":
      return pep;
    case "Mr Price Home":
      return price;
    case "Mr Price Cellular":
      return price;
    case "Mr Price":
      return price;
    case "Sheet Street":
      return price;
    case "Edgars":
      return edgars;
    case "ASJ":
      return black;
    case "Exact":
      return black;
    case "Foschini":
      return black;
    case "Hi":
      return black;
    case "Jet":
      return black;
    case "Jet Cellular":
      return black;
    case "Markhams":
      return black;
    case "Sportscene":
      return black;
    case "Sterns":
      return black;
    case "The Fix":
      return black;
    case "Totalsports":
      return black;
    case "Checkers":
      return checkers;
    case "Checkers Hyper":
      return checkers;
    case "Food World":
      return checkers;
    case "Knect":
      return checkers;
    case "Shoprite":
      return checkers;
    case "Usave":
      return checkers;
    case "Dunns":
      return dunns;
    case "Fashion World":
      return fashion;
    case "Game":
      return game;
    case "Makro":
      return game;
    case "Hifi Corporation":
      return hifi;
    case "Incredible Connection":
      return hifi;
    case "House & Home":
      return house;
    case "OK Furniture":
      return house;
    case "iStore":
      return istore;
    case "Pick n Pay Clothing":
      return pickAndPay;
    case "Pick n Pay Family":
      return pickAndPay;
    case "Pick n Pay Hyper":
      return pickAndPay;
    case "Pick n Pay Super":
      return pickAndPay;
    case "Pick n Pay Corporate":
      return pickAndPay;
    case "Pick n Pay Market":
      return pickAndPay;
    case "Powercell":
      return powercell;
    case "Vodacom Express":
      return vodacom;
    case "Vodacom Express Stores":
      return vodacom;
    case "Vodacom Repairs":
      return vodacom;
    case "Vodacom Repair Centre":
      return vodacom;
    case "Vodacom Shop":
      return vodacom;
    case "Independent Dealer":
      return vodacom;
    case "Cellucity":
      return vodacom;
    case "Chatz":
      return vodacom;
    case "Woolworths":
      return woolworths;
    default:
      return black;
  }
};
