import { Button, Radio, Select } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useApiPost from "../../../../hooks/useApiPost";

const CallDetailsPage = () => {
  const { visitId } = useParams<{ visitId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allModules, setAllModules] = useState<any[]>([]);
  const [delegates, setAllDelegates] = useState<any[]>([]);
  const [selectedModule, setSelectedModule] = useState<any>(null);
  const [selectedDelegate, setSelectedDelegate] = useState<any>(null);
  const [content, setContent] = useState<any>(null);
  const [answers, setAnswers] = useState<any>([]);
  const { request, setError } = useApiPost();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAllData = async () => {
      if (!visitId) {
        return;
      }
      setIsLoading(true);
      const { trainings, delegates } = await request(
        "/office/training/get-registry-options",
        "POST",
        {
          plannedCallId: visitId,
        }
      );

      setAllDelegates(delegates);
      setAllModules(trainings);
      setIsLoading(false);
    };
    fetchAllData();
  }, [visitId]);

  // console.log({ allModules, delegates });

  if (!visitId) {
    navigate("/admin/training");
  }

  const onStart = async () => {
    const content = await request(
      "/office/training/get-kt-module-content",
      "POST",
      {
        moduleId: selectedModule.value,
      }
    );

    setContent(content);
  };

  const handleSubmit = async () => {
    const responses: any[] = [];

    Object.keys(answers).forEach((key) => {
      const answerId = answers[key];
      const questionId = key;
      // const visitId = visitId;
      const delegateId = selectedDelegate.value;
      const moduleID = selectedModule.value;

      const data = { questionId, answerId, visitId, delegateId, moduleID };
      responses.push(data);
    });

    await Promise.all(
      responses.map(async (response) => {
        // await submitSingleKTAnswer(response);
        await request("/office/training/submit-single-answer", "POST", response);
      })
    );
  };

  // const handleChangeQuestion = (qID: string) => {
  //   // console.log(e.target.value);
  // };

  // console.log({ answers });
  const getQuestionnaire = () => {
    return (
      <div>
        <h1>Questionnaire</h1>
        <div>
          {content.map((value: any, index: number) => {
            return (
              <div key={index}>
                <h3>{value.questionText}</h3>
                <Radio.Group
                  // options={value.answerPayload.options}
                  onChange={(e) => {
                    // console.log(e.target.value);
                    setAnswers((prev: any) => {
                      return {
                        ...prev,
                        [value.qID]: e.target.value,
                      };
                    });
                  }}
                  value={answers && answers[value.qID]}
                >
                  {value.answerPayload.options.map(
                    (option: any, index: number) => {
                      return (
                        <Radio
                          key={`${index}_radio`}
                          value={option.id}
                          onClick={() => {
                            // console.log(option.value);
                            setAnswers((prev: any) => {
                              return {
                                ...prev,
                                [value.qID]: option.id,
                              };
                            });
                          }}
                        >
                          {option.label}
                        </Radio>
                      );
                    }
                  )}
                </Radio.Group>
              </div>
            );
          })}
        </div>
        <div>
          <Button type="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Select
        style={{ width: 300 }}
        loading={isLoading}
        options={allModules}
        // defaultValue={formOptionValues.callType_id}
        onChange={(_value, e) => {
          setSelectedModule(e);
        }}
      />
      <Select
        style={{ width: 300 }}
        loading={isLoading}
        options={delegates}
        // defaultValue={formOptionValues.callType_id}
        onChange={(_value, e) => {
          setSelectedDelegate(e);
        }}
        showSearch
      />
      <div>
        <Button
          type="primary"
          onClick={() => {
            onStart();
          }}
          disabled={!selectedModule || !selectedDelegate}
        >
          Start
        </Button>

        <div>{content && getQuestionnaire()}</div>
      </div>
    </div>
  );
};

export default CallDetailsPage;
