import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Form,
  Button,
  Typography,
  Select,
  Collapse,
  List,
  DatePicker,
} from "antd";
import { FormEvent } from "react";
import { EMPTY_FORMAL_TRAINING } from "../../../../utils/call";
import { getLocalTimeZone, Time } from "@internationalized/date";
import { TimeField } from "@adobe/react-spectrum";
import { AppContext } from "../../../../App";
import { IOption } from "../types";
import dayjs from "dayjs";
import * as S from "./FormalTraining.styled";
import { getAuth } from "firebase/auth";
import useApiPost from "../../../../hooks/useApiPost";
const { Panel } = Collapse;

const FormalTraining = () => {
  const auth = getAuth();
  const addedByEmail = auth.currentUser?.email || "not@defined.com";
  const appContext = useContext(AppContext);
  const { eventId } = useParams<{ eventId: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const { request, setError } = useApiPost();

  const { projectId } = appContext;
  const [form, setForm] = useState(EMPTY_FORMAL_TRAINING);
  const [date, setDate] = useState<any>(dayjs(new Date()));
  const [time, setTime] = useState(new Time(10, 0));
  const [facilitators, setFacilitators] = useState<IOption[]>([]);
  const [outlets, setOutlets] = useState<IOption[]>([]);
  const [venues, setVenues] = useState<IOption[]>([]);
  const [delegates, setDelegates] = useState<any[]>([]);
  const [initialDelegate_ids, setInitialDelegate_ids] = useState<any[]>([]);
  const [visibleDelegates, setVisibleDelegates] = useState<any[]>([]);
  const [delegateOptions, setDelegateOptions] = useState<IOption[]>([]);
  const [modules, setModules] = useState<IOption[]>([]);
  const eventTypeOptions = [
    { label: "Classroom", value: "Classroom" },
    { label: "Virtual", value: "Virtual" },
    { label: "In Store", value: "In Store" },
  ];

  const [attendees, setAttendees] = useState<any[]>([]);
  const [facilitatorName, setFacilitatorName] = useState("");
  const [moduleName, setModuleName] = useState("");

  useEffect(() => {
    if (date && time) {
      const { hour, minute } = time;
      let selectedDateWithTime = new Date(
        date.toDate(getLocalTimeZone()).setHours(hour, minute)
      );

      const formattedDate = dayjs(selectedDateWithTime).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      return updateForm("planned_at", formattedDate);
    }
  }, [date, time]);

  let navigate = useNavigate();

  const fetchAssigness = async () => {
    const data = await request(
      "/office/training/get-formal-trainings-options",
      "POST",
      {
        projectId,
      }
    );

    const { outlets, facilitators, venues, modules, delegates } = data;

    setOutlets(outlets);
    setFacilitators(facilitators);
    setVenues(venues);
    setModules(modules);
    setDelegates(delegates);

    const delegatesOptions = delegates.map((delegate: any) => ({
      label: `${delegate.firstName} ${delegate.lastName} ID: ${delegate.IDNumber}`,
      value: delegate.id,
    }));

    setDelegateOptions(delegatesOptions);
  };

  const setAttendeesList = (newAttendessList: any[]) => {
    const sortedList = newAttendessList.sort((a, b) => {
      const nameA = a.firstName.toUpperCase();
      const nameB = b.firstName.toUpperCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0
    }
    )
    setAttendees(sortedList);
  }

  const fetchDetails = async () => {
    if (!eventId) return;
    const eventDetails = await request(
      "/office/training/get-formal-trainings-details",
      "POST",
      {
        event_id: eventId,
      }
    );
    const { startDate, activeDelegates } = eventDetails;
    setInitialDelegate_ids(activeDelegates.map((a: any) => a.id));
    setAttendeesList(activeDelegates);
    setForm(eventDetails);
    setFacilitatorName(eventDetails.facilitator);
    setModuleName(eventDetails.name);
    const date = dayjs(startDate);
    setDate(date);
    const hours = new Date(startDate).getHours();
    const minutes = new Date(startDate).getMinutes();
    setTime(new Time(hours, minutes));
  };

  useEffect(() => {
    const fetchAll = async () => {
      setLoading(true);
      await fetchDetails();
      await fetchAssigness();
      setLoading(false);
    };

    fetchAll();
  }, [eventId]);

  // useEffect(() => {
  //   fetchAssigness();
  // }, []);

  const handleComeBack = () => {
    navigate("/admin/formal-trainings");
  };

  const updateForm = (key: string, value: any) => {
    setForm((form) => ({
      ...form,
      [key]: value,
    }));
  };

  const sendForm = async (values: FormEvent) => {
    const formattedDate = new Date(form.planned_at);
    formattedDate.setHours(time.hour, time.minute);

    updateForm("planned_at", formattedDate);

    if (form.eventType === "Classroom" && !form.venue_id && !form.outlet_id) {
      return alert("Please select either venue or outlet");
    }

    if (!form.module_id || !form.planned_at || !form.eventType) {
      return alert("Please fill all required fields");
    }

    try {
      if (eventId) {
        // await updateFormalTraining({
        //   ...form,
        //   type: form.eventType,
        //   planned_at: formattedDate,
        //   event_id: eventId,
        //   newDelegate_ids: attendees
        //     .filter((a) => !a.isLinked && !initialDelegate_ids.includes(a.id))
        //     .map((attendee) => attendee.id),
        //   removedDelegate_ids: initialDelegate_ids.filter(
        //     (id) => !attendees.find((a) => a.id === id)
        //   ),
        //   addedByEmail,
        // });

        await request("/office/training/update-formal-trainings", "POST", {
          ...form,
          type: form.eventType,
          planned_at: formattedDate,
          event_id: eventId,
          newDelegate_ids: attendees
            .filter((a) => !a.isLinked && !initialDelegate_ids.includes(a.id))
            .map((attendee) => attendee.id),
          removedDelegate_ids: initialDelegate_ids.filter(
            (id) => !attendees.find((a) => a.id === id)
          ),
          addedByEmail,
        });
        handleComeBack();
      } else {
        // addFormalTraining({
        //   ...form,
        //   delegate_ids: attendees.map((attendee) => attendee.id),
        //   planned_at: formattedDate,
        //   type: form.eventType,
        //   projectId,
        //   addedByEmail,
        // });

        await request("/office/training/add-formal-trainings", "POST", {
          ...form,
          delegate_ids: attendees.map((attendee) => attendee.id),
          planned_at: formattedDate,
          type: form.eventType,
          projectId,
          addedByEmail,
        });
        setLoading(false);
        // setResultInfo(`${data.name} added with ID ${data.id}.`);
        handleComeBack();
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  const handleMoveSelectedToAttendees = () => {
    const attendessToAdd = form.newDelegates_id.map((id: string) => {
      const delegate = delegates.find((delegate) => delegate.id === id);
      return {
        id: delegate?.id,
        firstName: delegate?.firstName,
        lastName: delegate?.lastName,
        email: delegate?.email,
        mobileNumber: delegate?.mobileNumber,
        IDNumber: delegate?.IDNumber,
      };
    });

    const newAttendessList = [...attendees, ...attendessToAdd];

    setAttendeesList(newAttendessList);

    updateForm("newDelegates_id", []);
  };

  const isClassRoom = form.eventType === "Classroom";
  const isInStore = form.eventType === "In Store";

  const handleRemoveAttendee = (id: string) => {
    const newAttendees = attendees.filter((attendee) => attendee.id !== id);
    setAttendeesList(newAttendees);

    const delegateToAdd = delegates.find((delegate) => delegate.id === id);
    if (delegateToAdd) {
      setVisibleDelegates([...visibleDelegates, delegateToAdd]);
    }
  };

  const canSubmit = form.module_id && form.planned_at && form.eventType;

  const isSetInPast = dayjs().isAfter(dayjs(date));
  return (
    <>
      <Typography.Title>Formal Training Event</Typography.Title>
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={sendForm}
      >
        <Collapse defaultActiveKey={[eventId ? "2" : "1"]}>
          <Panel header="Event Details" key="1">
            <Form.Item>
              {/* <CalendarComponent
                defaultValue={date}
                onChange={(e: any) => handleSelectDate(e)}
              /> */}
              {eventId ? (
                <div>
                  <div>Date: {new Date(date).toDateString()}</div>
                </div>
              ) : (
                <DatePicker
                  onChange={(date) => setDate(dayjs(date))}
                  value={form.startDate ? dayjs(form.startDate) : undefined}
                />
              )}
            </Form.Item>
            <Form.Item
              label="Call Time"
              hasFeedback
              required
              rules={[{ required: true, message: "Required to proceed!" }]}
            >
              <TimeField
                hourCycle={24}
                hideTimeZone
                value={time}
                onChange={(e: any) => setTime(e)}
                aria-label="Pick time"
                defaultValue={undefined}
              />
            </Form.Item>
            {/* {eventId ? (
              <div
                style={{
                  marginBottom: 20,
                }}
              >
                Facilitator: {facilitatorName}
              </div>
            ) : ( */}
              <Form.Item label="Facilitator" required>
                <Select
                  // showSearch
                  // loading={isLoading}
                  options={facilitators}
                  style={{ width: 200 }}
                  onChange={(e: any) => updateForm("user_id", e)}
                  value={form.user_id}
                  // defaultValue={selectedAssigneeId}
                />
              </Form.Item>
            {/* )} */}
            <Form.Item label="Event Type" required>
              <Select
                // showSearch
                // loading={isLoading}
                options={eventTypeOptions}
                style={{ width: 200 }}
                value={form.eventType}
                onChange={(e: any) => {
                  updateForm("venue_id", null);
                  updateForm("outlet_id", null);
                  updateForm("eventType", e);
                }}

                // defaultValue={selectedAssigneeId}
                // onChange={(_value, e) => {
                //   handleGoToUserDiary(_value);
                // }}
              />
            </Form.Item>
            {isInStore && (
              <>
                <Form.Item label="Outlet" hasFeedback>
                  <Select
                    onChange={(e: any) => {
                      updateForm("outlet_id", e);
                      updateForm("venue_id", null);
                    }}
                    options={outlets}
                    showSearch
                    value={form.outlet_id}
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </>
            )}
            {isClassRoom && (
              <>
                <Form.Item label="Venue" hasFeedback>
                  <Select
                    onChange={(e: any) => {
                      updateForm("venue_id", e);
                      updateForm("outlet_id", null);
                    }}
                    options={venues}
                    value={form.venue_id}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </>
            )}
            {eventId && isSetInPast ? (
              <div>
                <div>Module: {moduleName}</div>
              </div>
            ) : (
              <Form.Item label="Module" hasFeedback required>
                <Select
                  onChange={(e: any) => updateForm("module_id", e)}
                  options={modules}
                  showSearch
                  value={form.module_id}
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            )}

            {/* Selected: {day}/{month}/{year}, {hour}:{minute} */}
            {/* <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
              <Button type="primary" htmlType="submit" disabled={!!eventId}>
                {eventId ? "Update" : "Submit"}
              </Button>
              <Button
                type="primary"
                style={{ margin: 10 }}
                onClick={handleComeBack}
              >
                MENU
              </Button>
            </Form.Item> */}
          </Panel>
          {/* {!eventId && ( */}
          <Panel header="Event Attendees" key="2">
            <div>
              <Form.Item label="Delegate">
                <Select
                  options={delegateOptions}
                  // value={form.city_id}
                  onChange={(e) => updateForm("newDelegates_id", e)}
                  showSearch
                  value={form.newDelegates_id}
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  mode="multiple"
                />
                <Button
                  type="primary"
                  style={{
                    marginTop: "10px",
                  }}
                  onClick={() => {
                    handleMoveSelectedToAttendees();
                  }}
                >
                  Confirm
                </Button>
              </Form.Item>
            </div>
            <List
              size="small"
              bordered
              dataSource={attendees}
              // dataSource={allCallTypes.filter((item) =>
              //   form.callType_id.includes(item.value)
              // )}
              style={{
                margin: "0 auto",
                marginBottom: "20px",
              }}
              renderItem={(item: any) => (
                <List.Item key={item.email}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      width: "100%",
                      // backgroundColor: item.isLinked ? "#e6f7ff" : "",
                    }}
                  >
                    <div></div>
                    <S.Prop>
                      <S.BoldText>Name:</S.BoldText>
                      <S.Value>{`${item.firstName} ${item.lastName}`}</S.Value>
                    </S.Prop>
                    <S.Prop>
                      <S.BoldText>Phone Number:</S.BoldText>
                      <S.Value>{item.mobileNumber}</S.Value>
                    </S.Prop>
                    <S.Prop>
                      <S.BoldText>Email:</S.BoldText>
                      <S.Value>{item.email}</S.Value>
                    </S.Prop>
                    <S.Prop>
                      <S.BoldText>ID Number:</S.BoldText>
                      <S.Value>{item.IDNumber}</S.Value>
                    </S.Prop>
                  </div>
                  {/* <List.Item.Meta
                    avatar={<Avatar src={item.picture.large} />}
                    title={<a href="https://ant.design">{item.name.last}</a>}
                    description={item.email}
                  /> */}
                  <div>
                    <Button
                      type="primary"
                      style={{ margin: 10 }}
                      onClick={() => {
                        handleRemoveAttendee(item.id);
                      }}
                    >
                      Remove
                    </Button>
                  </div>
                </List.Item>
              )}
            />
          </Panel>
          {/* )} */}
        </Collapse>
        <Form.Item>
          <div
            style={{
              marginTop: "10px",
            }}
          >
            <Button type="primary" htmlType="submit" disabled={!canSubmit}>
              {eventId ? "Update" : "Submit"}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default FormalTraining;
