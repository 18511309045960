import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 36px;
`;

export const StyledImage = styled.img`
  border-radius: 8px;
  max-width: 164px;
  @media (min-width: 768px) {
    max-width: 256px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h3`
  margin: 0;
  font-weight: 700;
  font-size: 26px;
`;

export const Error = styled.span`
  color: red;
  min-height: 22px;
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

export const InputWithButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const ShowButton = styled.button`
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-radius: 18px;
  background-color: white;
  padding: 8px;
  font-weight: 700;
  margin-top: 10px;
`;

export const FormWrapper = styled.div`
  display: flex;
  width: 100%;
`;
