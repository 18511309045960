import { useContext, useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Modal,
  DatePicker,
  Collapse,
} from "antd";
import { AppContext } from "../../../../App";
import { instance } from "../../../../api/common-api";
// import {
//   getAllNewsfeed,
//   deleteNewsfeed,
//   updateNewsfeed,
// } from "../../../../api/office/newsfeed";
import * as S from "./NewsfeedPage.styled";
import moment from "moment";
import dayjs from "dayjs";
import useApiPost from "../../../../hooks/useApiPost";
import TextArea from "antd/es/input/TextArea";

interface SingleMediaDetails {
  index: number;
  name: string;
}

const NewsfeedPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFiles] = useState<FileList>();
  const ref = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { projectId } = useContext(AppContext);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [validFrom, setValidFrom] = useState<any>(null);
  const [validTo, setValidTo] = useState<any>(null);
  const [newsfeed, setNewsfeed] = useState<[]>([]);
  const [selectedFeed, setSelectedFeed] = useState<any>(undefined);
  const [modalKey, setModalKey] = useState<number>(0);
  const { request, setError } = useApiPost();

  const [mediaDetails, setMediaDetails] = useState<SingleMediaDetails[]>();

  const fetchAll = async () => {
    const res = await request("/office/newsfeed", "POST", {
      projectId,
    });
    setNewsfeed(res);
  };

  useEffect(() => {
    fetchAll();
  }, []);

  const onChangeSingleFile = (e: any) => {
    setFiles(e.target.files);
    const mediaDetails: SingleMediaDetails[] = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const fileNameWithoutExtension = e.target.files[i].name.split(".")[0];
      mediaDetails.push({
        index: i,
        name: fileNameWithoutExtension,
      });
    }
    setMediaDetails(mediaDetails);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setFiles(undefined);
    setDescription("");
    setTitle("");
  };

  const handleAdd = async () => {
    const formData = new FormData();

    if (file && file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        formData.append("files", file[i]);
      }
    }

    formData.append("projectId", projectId);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("validFrom", validFrom);
    formData.append("validTo", validTo);

    await uploadForm(formData);
  };

  const handleUpdateNewsfeed = async () => {
    await request(`/office/newsfeed/update/${selectedFeed.id}`, "POST", {
      projectId,
      title,
      description,
      validFrom,
      validTo,
    });
  };

  const uploadForm = async (formData: FormData) =>
    await request("/office/newsfeed/upload", "POST", formData, true);

  const uploadFile = async (e: any) => {
    setIsLoading(true);

    if (!selectedFeed) {
      await handleAdd();
    } else {
      await handleUpdateNewsfeed();
    }
    await fetchAll();

    setIsLoading(false);
    setIsModalOpen(false);
    setDescription("");
    setTitle("");
    setValidFrom(null);
    setValidTo(null);
    setFiles(undefined);
    setSelectedFeed(undefined);
    setModalKey(modalKey + 1);
    return;
  };

  const handleAddNew = () => {
    setSelectedFeed(null);
    setModalKey(modalKey + 1);
    setIsModalOpen(true);
  };

  const handleDelete = async (id: string) => {
    await request(`/office/newsfeed/delete/${id}`, "POST");
    await fetchAll();
  };

  const handleEdit = (e: any) => {
    setSelectedFeed(e);
    setTitle(e.title);
    setDescription(e.description);
    setValidFrom(e.validFrom);
    setValidTo(e.validTo);

    setModalKey(modalKey + 1);
    setIsModalOpen(true);
  };

  const isConfirmDisabled = !title || !validFrom;

  return (
    <>
      <Typography.Title>Newsfeed</Typography.Title>
      <S.Wrapper>
        {newsfeed.map((e: any, index: number) => (
          <Collapse
            key={index}
            style={{
              width: "100%",
            }}
          >
            <Collapse.Panel header={e.title} key={index}>
              <S.Container key={index}>
                {e.link && (
                  <img
                    style={{ width: 400, height: 400, objectFit: "contain" }}
                    src={e.link}
                    alt=""
                  />
                )}
                <S.TextBlock>
                  <Form labelCol={{ span: 4 }} layout="horizontal">
                    <Form.Item label="Title">
                      <Typography.Text
                        style={{ fontWeight: "bold", fontSize: 18 }}
                      >
                        {e.title}
                      </Typography.Text>
                    </Form.Item>
                    <Form.Item label="Body">
                      <TextArea
                        style={{ textAlign: "justify", fontSize: 16 }}
                        readOnly={true}
                        value={e.description}
                        autoSize={true}
                      />
                    </Form.Item>
                    <Form.Item label="Valid from">
                      <Typography.Text
                        style={{ textAlign: "justify", fontSize: 16 }}
                      >
                        {e.validFrom
                          ? moment(e.validFrom)?.local().format("DD-MM-YYYY")
                          : "Not set"}
                      </Typography.Text>
                    </Form.Item>
                    <Form.Item label="Valid to">
                      <Typography.Text
                        style={{ textAlign: "justify", fontSize: 16 }}
                      >
                        {e.validFrom
                          ? moment(e.validTo).local().format("DD-MM-YYYY")
                          : "Not set"}
                      </Typography.Text>
                    </Form.Item>
                  </Form>
                </S.TextBlock>
                <S.InnerActions>
                  <Button type="primary" onClick={() => handleEdit(e)}>
                    Edit
                  </Button>

                  <Button
                    type="primary"
                    danger
                    onClick={() => handleDelete(e.id)}
                  >
                    Delete
                  </Button>
                </S.InnerActions>
              </S.Container>
            </Collapse.Panel>
          </Collapse>
        ))}
      </S.Wrapper>
      <Button type="primary" onClick={() => handleAddNew()}>
        Add New
      </Button>
      <Modal
        title={`Provide details`}
        open={isModalOpen}
        centered
        closable={false}
        width={400}
        footer={[]}
        key={modalKey}
      >
        <div>
          <Form
            encType="multipart/form-data"
            method="post"
            action="#"
            // onFinish={}
            onFinish={uploadFile}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Form.Item label="Title" required>
                <Input
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                />
              </Form.Item>
              <Form.Item label="Description">
                <TextArea
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  rows={5}
                />
              </Form.Item>

              <Form.Item
                label="Valid from"
                name="validFrom"
                required
                valuePropName="validFrom"
              >
                <DatePicker
                  value={validFrom ? dayjs(validFrom) : null}
                  allowClear={false}
                  onChange={(e: any) => setValidFrom(e)}
                />
              </Form.Item>
              <Form.Item
                label="Expires on"
                name="validTo"
                valuePropName="validTo"
              >
                <DatePicker
                  value={validTo ? dayjs(validTo) : null}
                  allowClear={false}
                  onChange={(e: any) => setValidTo(e)}
                />
              </Form.Item>
              {!selectedFeed && (
                <input
                  ref={ref}
                  type="file"
                  name="file"
                  onChange={onChangeSingleFile}
                  disabled={file && file?.length > 0}
                  accept="image/*"
                />
              )}
            </div>
            <S.Actions>
              <Button
                type="primary"
                onClick={handleCancel}
                disabled={isLoading}
              >
                Close
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={isConfirmDisabled}
                loading={isLoading}
              >
                Confirm
              </Button>
            </S.Actions>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default NewsfeedPage;
