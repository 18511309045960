import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import "antd/dist/antd.css";
import { Space, Table, Button, Switch } from "antd";
import type { ColumnsType } from "antd/es/table";
import useApiPost from "../../../../hooks/useApiPost";

interface ColumnType {
  onUpdate: (id: string) => void;
  onDelete: (id: string) => void;
}

interface Project {
  id: string;
  description: string;
  isActive: string;
}

const columns = (props: ColumnType): ColumnsType<Project> => [
  {
    title: "Active",
    dataIndex: "isActive",
    key: "isActive",
    render: (val) => (
      <div>
        <Switch defaultChecked={val} />
      </div>
    ),
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (text) => <div>{text}</div>,
  },
  {
    title: "Objectives",
    dataIndex: "objectives",
    key: "objectives",
    render: (text) => <div>{text}</div>,
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    render: (_, record) => (
      <Space size="middle" key={record.id}>
        <Button type="primary" onClick={() => props.onUpdate(record.id)}>
          Edit
        </Button>
        <Button type="primary" danger onClick={() => props.onDelete(record.id)}>
          Delete
        </Button>
      </Space>
    ),
  },
];

const ProjectsPage = () => {
  const [, setIsLoading] = useState(false);
  const [allProjects, setAllProjects] = useState<Project[]>([]);
  const [visibleProjects, setVisibleProjects] = useState<Project[]>([]);
  const [isOnlyActiveVisible, setIsOnlyActiveVisible] = useState(true);
  let navigate = useNavigate();
  const { request, setError } = useApiPost();

  const handleTo = () => {
    navigate("/admin/projects/new");
  };

  useEffect(() => {
    const getAllProjects = async () => {
      setIsLoading(true);
      const res = await request("/office/projects/all");
      const data = res.map((e: any, index: number) => ({
        ...e,
        key: index,
      }));

      setAllProjects(data);
      setIsLoading(false);
    };
    getAllProjects();
  }, []);

  const handleDelete = async (id: string) => {
    await request(`/roles/${id}`, "DELETE");
  };
  const handleUpdate = async (id: string) => {
    navigate(`/admin/projects/view/${id}`);
  };

  useEffect(() => {
    if (isOnlyActiveVisible) {
      setVisibleProjects(allProjects.filter((e) => e.isActive));
    } else {
      setVisibleProjects(allProjects);
    }
  }, [isOnlyActiveVisible, allProjects]);


  return (
    <>
      <div style={{ paddingBottom: "20px" }}>
        <Switch
          checkedChildren="Active"
          unCheckedChildren="All"
          defaultChecked
          onChange={() => setIsOnlyActiveVisible(!isOnlyActiveVisible)}
        />
      </div>
      <Table
        columns={columns({
          onUpdate: handleUpdate,
          onDelete: handleDelete,
        })}
        dataSource={visibleProjects}
        rowKey="key"
      />

      <Button type="primary" onClick={handleTo}>
        Add
      </Button>
    </>
  );
};

export default ProjectsPage;
