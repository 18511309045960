export enum AuthActionEnum {
  LOG_IN = 'LOG_IN',
  LOG_OUT = 'LOG_OUT',
  UPDATE_TOKEN = 'UPDATE_TOKEN',
  SET_LOADING = 'SET_LOADING',
};

export type AuthAction =
  | {
      type: AuthActionEnum.LOG_IN;
      payload: {
        uid: string;
        email: string;
        // authToken: string;
        role: string;
        token: string;
        access: string[];
        projectAccess: string[];
        passwordAgeInDays: number;
        forcePassNotify: boolean;
        passNotify: boolean;
        name: string;
      };
    }
  | {
      type: AuthActionEnum.LOG_OUT;
      payload: null;
    }
  | {
      type: AuthActionEnum.UPDATE_TOKEN;
      payload: {
        uid: string;
        email: string;
        // authToken: string;
        role: string;
        token: string;
        access: string[];
        projectAccess: string[];
        passwordAgeInDays: number;
        forcePassNotify: boolean;
        passNotify: boolean;
      };
    }
  | {
      type: AuthActionEnum.SET_LOADING;
      payload: boolean;
    };

