import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Form,
  Button,
  Typography,
  Select,
  Cascader,
  DatePicker,
  Table,
  Modal,
  Input,
  Switch,
  Tag,
} from "antd";
import { DataType } from "../types";
import { DefaultOptionType } from "antd/lib/select";
import { AppContext } from "../../../../App";
import readXlsxFile from "read-excel-file";
import { SheetDataSet } from "./scorecards-types";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import templateImg from "../../../../assets/images/scorecards-template-example.png";
import ImportInfoTable from "../common/ImportInfoTable";
import useApiPost from "../../../../hooks/useApiPost";
dayjs.extend(customParseFormat);

const columns = [
  {
    title: "(A) Category Description",
    column: "A",
  },
  {
    title: "(B) Question",
    column: "B",
  },
  {
    title: "(C) Question Sequence",
    column: "C",
  },
  {
    title: "(D) Question Type",
    column: "D",
  },
  {
    title: "(E) Max Score",
    column: "E",
  },
  {
    title: "(F) Response",
    column: "F",
  },
  {
    title: "(G) Response Sequence",
    column: "G",
  },
  {
    title: "(H) Response Score",
    column: "H",
  },
  {
    title: "(I) Is Branching required",
    column: "I",
  },
  {
    title: "(J) Branch sequence number",
    column: "J",
  },
];
const description =
  "Below you will find a list of fields and their corresponding excel columns on the first sheet. Please make sure that the values are correct before importing the file.";

export const EMPTY = {
  id: "",
  name: "",
  chain_id: [],
  callType_id: [],
  outletTypes: [],
  validTo: null,
  validFrom: null,
  isActive: false,
  outletType_id: [],
  users_id: [],
  hierarchy_id: [] as any,
  outletState_id: [],
  userRoles_id: [],
};

const ScorecardsForm = () => {
  const [form, setForm] = useState(EMPTY);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isActive, setIsActive] = useState<any>(null);
  const [isSurvey, setIsSurvey] = useState<any>(false);
  const { scorecardId } = useParams<{ scorecardId: string }>();
  const [allCallTypes, setAllCallTypes] = useState<any[]>([]);
  const [regionText, setRegionText] = useState<string[]>([]);
  const { request, setError } = useApiPost();

  const updateForm = (key: string, value: any) => {
    setForm((form) => ({
      ...form,
      [key]: value,
    }));
  };

  const showModal = () => {
    setIsConfirmModalOpen(true);
  };

  const handleOk = () => {
    setIsConfirmModalOpen(false);
  };

  const handleCancel = () => {
    setIsConfirmModalOpen(false);
  };

  const appContext = useContext(AppContext);
  const { basketId } = useParams<{ basketId: string }>();
  const [chains, setChains] = useState<any[]>([]);
  const [channels, setChannels] = useState<any[]>([]);
  const [outletTypes, setOutletTypes] = useState<any[]>([]);
  const [outletStates, setOutletStates] = useState<any[]>([]);
  const [userRoles, setUserRoles] = useState<any[]>([]);
  const [treeData, setTreeData] = useState<DataType[]>([]);
  const [initValues, setInitValues] = useState<any>({});

  const [products, setProducts] = useState<any[]>([]);
  const [newData, setNewData] = useState<any[]>([]);
  const [newEntries, setNewEntries] = useState<any[]>([]);
  const [scorecardDetails, setScorecardDetails] = useState<any[]>([]);

  const [formOptionValues, setFormOptionValues] = useState({
    outletType_id: null,
  });

  const { projectId } = appContext;
  const [isLoading, setIsLoading] = useState(false);
  const headerText = scorecardId ? `Edit Scorecard` : "Import Scorecard";
  const navigate = useNavigate();

  const fetchData = async () => {
    const options = await request("/office/scorecards/options", "POST", {
      projectId,
    });


    const {
      callTypes,
      chains,
      outletTypes,
      userRoles,
      outletStates,
      geography,
    } = options;
    setUserRoles(userRoles);
    setAllCallTypes(callTypes);
    setOutletTypes(outletTypes);
    setOutletStates(outletStates);
    setChains(chains);
    setTreeData(geography);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const fetchBasketData = async () => {
    if (!scorecardId) {
      return;
    }
    setIsLoading(true);

    // const { scorecard, activeOutletTypes } = await getScorecardsDetails(
    //   scorecardId,
    //   projectId
    // );

    const { scorecard, activeOutletTypes } = await request(
      `/office/scorecards/details/${scorecardId}`,
      "POST",
      {
        projectId,
      }
    );
    setScorecardDetails(scorecard?.questions || []);

    const options = await request(`/office/scorecards/options`, "POST", {
      projectId,
    });
    const {
      callTypes,
      chains,
      outletTypes,
      userRoles,
      outletStates,
      geography,
    } = options;
    setAllCallTypes(callTypes);
    setOutletTypes(outletTypes);
    setChains(chains);
    setUserRoles(userRoles);
    setOutletStates(outletStates);
    setTreeData(geography);
    setForm({
      ...form,
      ...scorecard,
      // callType_id: scorecard.callTypes,
      // chain_id: scorecard.chains,
      validFrom: dayjs(scorecard.startDate),
      validTo: dayjs(scorecard.endDate),
      outletType_id: activeOutletTypes,
      userRoles_id: scorecard.userRoles_id
        ? scorecard.userRoles_id.map((u: any) => u.value)
        : [],
      callType_id: scorecard.callType_id
        ? scorecard.callType_id.map((u: any) => u.value)
        : [],
      outletState_id: scorecard.outletState_id
        ? scorecard.outletState_id.map((u: any) => u.value)
        : [],
      chain_id: scorecard.chain_id
        ? scorecard.chain_id.map((u: any) => u.value)
        : [],
      hierarchy_id: scorecard.region_id
        ? scorecard.region_id.map((u: any) => u.value)
        : [],
    });
    const { isActive, connectedRegionsText = [] } = scorecard;
    setRegionText(connectedRegionsText);
    setIsActive(isActive);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchBasketData();
  }, [basketId]);

  const displayRender = (labels: string[]) => labels[labels.length - 1];

  const handleOptionChange = (key: string, event: any) => {
    setFormOptionValues({
      ...formOptionValues,
      [key]: event.value,
    });
  };

  const getLabel = (id: string) => {
    let label = "";
    console.log({ id });
    const findLabel = (data: DataType[]) => {
      data.forEach((item) => {
        if (item.id === id) {
          label = item.title;
        } else if (item.children && item.children.length > 0) {
          findLabel(item.children);
        }
      });
    };
    findLabel(treeData);
    console.log({ label });
    return label;
  };
  const onChange = (key: string, value: any) => {
    const lastValue = Array.isArray(value) ? value[value.length - 1] : value;
    handleOptionChange(key, { value: lastValue });
  };
  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1
    );
  const handleUploadBatchFile = async (e: any) => {
    const file = e.target.files[0];
    const dataRows: any[] = [];
    await readXlsxFile(file, { sheet: 1 }).then(async (rows) => {
      rows.map((row, index) => {
        if (index > 0) {
          console.log({ row });
          // const chain = row[0]?.toString().trim();
          // const outletType = row[1]?.toString().trim();
          // headers
          // if (chain === "Chain" && outletType === "Outlet Type") {
          //   return;
          // }

          // const category = row[6]?.toString().trim();
          // const questionText = row[7]?.toString().trim();
          // const questionSequence = row[8]?.toString().trim();
          // const questionType = row[9]?.toString().trim();
          // const questionMaxScore = row[14]?.toString().trim();
          // const questionResponse = row[15]?.toString().trim();
          // const questionResponseSequence = row[16]?.toString().trim();
          // const questionResponseScore = row[17]?.toString().trim();
          // const isBranching = row[19]?.toString().trim();
          // const branchExitNumber = row[21]?.toString().trim();

          const category = row[0]?.toString().trim();
          const questionText = row[1]?.toString().trim();
          const questionSequence = row[2]?.toString().trim();
          const questionType = row[3]?.toString().trim();
          const questionMaxScore = row[4]?.toString().trim();
          const questionResponse = row[5]?.toString().trim();
          const questionResponseSequence = row[6]?.toString().trim();
          const questionResponseScore = row[7]?.toString().trim();
          const isBranching = row[8]?.toString().trim();
          const branchExitNumber = row[9]
            ?.toString()
            .trim()
            .toLocaleLowerCase();
          const item: SheetDataSet = {
            category,
            questionText,
            questionSequence: parseInt(questionSequence),
            questionType,
            questionMaxScore: parseInt(questionMaxScore),
            questionResponse,
            questionResponseSequence: parseInt(questionResponseSequence),
            questionResponseScore: parseInt(questionResponseScore),
            isBranching: isBranching === "Yes" ? true : false,
            // branchExitNumber: parseInt(branchExitNumber),
            branchExitNumber:
              branchExitNumber === "end" ? -1 : parseInt(branchExitNumber),
          };

          dataRows.push(item);
        }
      });

      console.log({ dataRows });
      const scorecardQuestions: any[] = [];
      dataRows.map((item) => {
        if (
          scorecardQuestions.some(
            (t) => t.questionSequence === item.questionSequence
          )
        ) {
          const question = scorecardQuestions.find(
            (t) => t.questionSequence === item.questionSequence
          );
          if (question) {
            question.responses.push({
              responseText: item.questionResponse,
              responseSequence: item.questionResponseSequence,
              responseScore: item.questionResponseScore,
              isBranching: item.isBranching,
              branchExitNumber: item.branchExitNumber,
            });
          }
        } else {
          scorecardQuestions.push({
            questionText: item.questionText,
            category: item.category,
            questionSequence: item.questionSequence,
            questionType: item.questionType,
            questionMaxScore: item.questionMaxScore,
            responses: [
              {
                responseText: item.questionResponse,
                responseSequence: item.questionResponseSequence,
                responseScore: item.questionResponseScore,
                isBranching: item.isBranching,
                branchExitNumber: item.branchExitNumber,
              },
            ],
          });
        }
      });

      console.log({ scorecardQuestions });
      const sanitizedQuestions = scorecardQuestions.filter(
        (t) => t.questionSequence > 0
      );
      console.log({
        sanitizedQuestions,
        projectId,
        a: form.validFrom ? form.validFrom : new Date().toString(), //startDate,
        b: form.validTo ? form.validTo : new Date().toString(), //startDate,
        c: form.name,
      });
      await request("/office/pos-baskets/scorecards", "POST", {
        scorecardData: sanitizedQuestions,
        projectId,
        startDate: form.validFrom ? form.validFrom : new Date().toString(),
        endDate: form.validTo ? form.validTo : new Date().toString(),
        name: form.name,
        isSurvey,
      });
      navigate("/admin/scorecards");
    });
  };

  const handleUploadBatchFile2 = async (e: any) => {
    const file = e.target.files[0];
    const diariesData: any[] = [];
    await readXlsxFile(file, { sheet: 1 }).then(async (rows) => {
      rows.map((row, index) => {
        if (index > 0) {
          // const chain = row[0]?.toString().trim();
          // const outletType = row[1]?.toString().trim();
          // headers
          // if (chain === "Chain" && outletType === "Outlet Type") {
          //   return;
          // }
          const date = row[0]?.toString().trim();
          const outletId = row[1]?.toString().trim();
          const chain = row[2]?.toString().trim();
          const outletName = row[3]?.toString().trim();
          const town = row[4]?.toString().trim();
          const callType = row[5]?.toString().trim();
          const userName = row[7]?.toString().trim();
          const region = row[9]?.toString().trim();

          const item: any = {
            // SheetDataSet2 = {
            date,
            outletId,
            chain,
            outletName,
            town,
            callType,
            userName,
            region,
          };

          diariesData.push(item);
        }
      });
    });

    await request("/office/pos-baskets/diaries", "POST", {
      diariesData,
      projectId,
    });
  };
  const entriesExist = newEntries.length > 0;

  const newProductsLength = newEntries.filter((t) => t.isNewProduct).length;
  const newOutletsLength = newEntries.filter((t) => t.isNewOutlet).length;
  const missingChains = newEntries.filter((t) => !t.chain_id);
  const missingChainLength = missingChains.length;

  const isCreateAvailable = missingChainLength === 0;

  const updateScorecard = async () => {
    if (!scorecardId || !form.validFrom || !form.validTo) {
      return;
    }

    const allVals: string[] = form.hierarchy_id.reduce(
      (acc: string | any[], val: any) => acc.concat(val),
      []
    );
    const uniqueRegionIds = allVals.filter((v, i, a) => a.indexOf(v) === i);

    await request("/office/scorecards/update", "POST", {
      isActive,
      projectId,
      scorecardId,
      name: form.name,
      startDate: form.validFrom,
      endDate: form.validTo,
      userRoles_id: form.userRoles_id,
      hierarchy_id: uniqueRegionIds,
      outletType_id: form.outletType_id,
      outletState_id: form.outletState_id,
      callType_id: form.callType_id,
      chain_id: form.chain_id,
    });

    navigate(`/admin/scorecards`);
  };

  if (
    isLoading ||
    outletTypes.length === 0 ||
    outletStates.length === 0 ||
    treeData.length === 0 ||
    allCallTypes.length === 0 ||
    userRoles.length === 0 ||
    chains.length === 0
  ) {
    return <p>Loading...</p>;
  }

  if (!scorecardId) {
    return (
      <>
        <Typography.Title>{headerText}</Typography.Title>
        <Form
          // form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          onFinish={undefined}
        >
          <div>
            <Form.Item
              label="Name"
              // name="name"
              // getValueProps={(i: any) => ({ value: moment(i) })}
              required
            >
              <Input
                onChange={(e) => updateForm("name", e.target.value)}
                value={form.name}
              />
            </Form.Item>
            <Form.Item
              label="Start"
              // name="startDate"
              // getValueProps={(i: any) => ({ value: moment(i) })}
              required
            >
              <DatePicker
                allowClear={false}
                value={form.validFrom}
                // onChange={(e) => setStartDate(e?.toDate())}
                onChange={(e: any) => updateForm("validFrom", e)}
              />
            </Form.Item>
            <Form.Item
              label="End"
              // name="endDate"
              // getValueProps={(i: any) => ({ value: moment(i) })}
              required
              rules={[
                {
                  required: true,
                  message: "Please select a form end",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    const start = new Date(getFieldValue("startDate"));
                    const end = new Date(value);

                    if (start > end) {
                      return Promise.reject(
                        "End date must be greater than start date"
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <DatePicker
                allowClear={false}
                value={form.validTo}
                // onChange={(e) => setEndDate(e?.toDate())}
                onChange={(e: any) => updateForm("validTo", e)}
              />
            </Form.Item>
            {!scorecardId && (
              <Form.Item
                label="Is a survey"
                valuePropName="isSurvey"
                name="isSurvey"
              >
                <Switch checked={isSurvey} onChange={(e) => setIsSurvey(e)} />
              </Form.Item>
            )}
          </div>
        </Form>
        <ImportInfoTable
          columns={columns}
          description={description}
          imgSrc={templateImg}
        />
        <div>
          <input
            type="file"
            id="input"
            onChange={(e) => handleUploadBatchFile(e)}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Form
        // form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={undefined}
      >
        <div>
          <Form.Item
            label="Name"
            // name="name"
            // getValueProps={(i: any) => ({ value: moment(i) })}
            required
          >
            <Input
              onChange={(e) => updateForm("name", e.target.value)}
              value={form.name}
            />
          </Form.Item>
          <Form.Item
            label="Start"
            // name="startDate"
            // getValueProps={(i: any) => ({ value: moment(i) })}
            required
          >
            <DatePicker
              allowClear={false}
              value={form.validFrom}
              // onChange={(e) => setStartDate(e?.toDate())}
              onChange={(e: any) => updateForm("validFrom", e)}
            />
          </Form.Item>
          <Form.Item
            label="End"
            // name="endDate"
            // getValueProps={(i: any) => ({ value: moment(i) })}
            required
            rules={[
              {
                required: true,
                message: "Please select a form end",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  const start = new Date(getFieldValue("startDate"));
                  const end = new Date(value);

                  if (start > end) {
                    return Promise.reject(
                      "End date must be greater than start date"
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <DatePicker
              allowClear={false}
              value={form.validTo}
              // onChange={(e) => setEndDate(e?.toDate())}
              onChange={(e: any) => updateForm("validTo", e)}
            />
          </Form.Item>
          {/* <Form.Item label="Call Types">
            <Select
              mode="multiple"
              options={allCallTypes}
              value={form.callType_id}
              onChange={(e) => updateForm("callType_id", e)}
              allowClear
            />
          </Form.Item> */}
          <Form.Item label="User Roles">
            <Select
              loading={isLoading}
              options={userRoles}
              value={form.userRoles_id}
              mode="multiple"
              onChange={(e) => {
                updateForm("userRoles_id", e);
              }}
              allowClear
            />
          </Form.Item>
          <Form.Item label="Region" name="hierarchy_id">
            <Cascader
              multiple
              placeholder="Please select new regions"
              options={treeData}
              expandTrigger="hover"
              displayRender={displayRender}
              // defaultValue={[getLabel(form.hierarchy_id || "")]}
              // defaultValue={['75e6e6f8-479a-4a43-858a-da6b61ff21c5', 'd795d0ad-05d0-45e5-999a-fd1d02a750d1']}
              onChange={(val, e) => {
                setForm({
                  ...form,
                  hierarchy_id: val,
                });
                onChange("hierarchy_id", val);
              }}
              showSearch={{ filter }}
              changeOnSelect
            />
          </Form.Item>
          {regionText && regionText.length > 0 && (
            <Form.Item label="Current Regions">
              {regionText.map((t, i) => (
                <Tag key={i}>{t}</Tag>
              ))}
            </Form.Item>
          )}
          <Form.Item label="Outlet Type">
            <Select
              loading={isLoading}
              options={outletTypes}
              value={form.outletType_id}
              mode="multiple"
              onChange={(e) => {
                updateForm("outletType_id", e);
              }}
              allowClear
            />
          </Form.Item>
          <Form.Item label="Outlet State">
            <Select
              loading={isLoading}
              options={outletStates}
              value={form.outletState_id}
              mode="multiple"
              onChange={(e) => {
                updateForm("outletState_id", e);
              }}
              allowClear
            />
          </Form.Item>
          <Form.Item label="Call Types">
            <Select
              mode="multiple"
              showSearch
              options={allCallTypes}
              value={form.callType_id}
              onChange={(e) => updateForm("callType_id", e)}
            />
          </Form.Item>
          <Form.Item label="Chains">
            <Select
              allowClear
              mode="multiple"
              options={chains}
              value={form.chain_id}
              onChange={(e) => updateForm("chain_id", e)}
            />
          </Form.Item>
        </div>
        {scorecardId && (
          <Form.Item label="Is Active" valuePropName="isActive" name="isActive">
            <Switch checked={isActive} onChange={(e) => setIsActive(e)} />
          </Form.Item>
        )}
        {/* <Form.Item label="Region" name="hierarchy_id">
          <Cascader
            options={treeData}
            expandTrigger="hover"
            displayRender={displayRender}
            defaultValue={[getLabel(form.hierarchy_id)]} //form.hierarchy_id || "")]}
            onChange={(val) => onChange("hierarchy_id", val)}
            showSearch={{ filter }}
            changeOnSelect
          />
        </Form.Item>
        <Form.Item
          label="Chain"
          hasFeedback
          rules={[{ required: true, message: "State is required to proceed!" }]}
        >
          <Select
            loading={isLoading}
            options={chains}
            value={form.chain_id}
            onChange={(_value, e) => {
              updateForm("chain_id", e.value);
            }}
          />
        </Form.Item>
        <Form.Item label="Outlet State">
          <Select
            showSearch
            options={[]}
            value={form.outletState_id}
            onChange={(e) => updateForm("outletState_id", e)}
          />
        </Form.Item>
        <Form.Item label="Call Types">
          <Select
            mode="multiple"
            showSearch
            options={allCallTypes}
            value={form.callType_id}
            onChange={(e) => updateForm("callType_id", e)}
          />
        </Form.Item> */}

        <Modal
          title="Basic Modal"
          open={isConfirmModalOpen}
          centered
          width={1000}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              type="primary"
              onClick={handleOk}
              disabled={!isCreateAvailable}
            >
              Submit
            </Button>,
          ]}
        >
          <p
            style={{
              textAlign: "center",
            }}
          >
            Your basket is almost ready!
          </p>
          {/* list newly created products */}
          {newProductsLength > 0 && (
            <div>
              <p>
                {newProductsLength} new products were added based on missing
                product codes
              </p>
              <Table
                dataSource={newEntries.filter((t) => t.isNew)}
                rowKey="order"
                columns={[
                  {
                    title: "Product Code",
                    dataIndex: "productCode",
                    key: "productCode",
                  },
                  {
                    title: "Product Name",
                    dataIndex: "productName",
                    key: "productName",
                  },
                  {
                    title: "Product ID",
                    dataIndex: "product_id",
                    key: "product_id",
                  },
                ]}
              />
            </div>
          )}
          {missingChainLength > 0 && (
            <>
              <p>{`System found ${missingChainLength} missing chains:`}</p>
              {missingChains &&
                missingChains.map(({ chain }, index) => (
                  <div key={index} style={{ fontWeight: 800 }}>
                    {chain}
                  </div>
                ))}
              <div
                style={{
                  paddingTop: 10,
                  textAlign: "center",
                  color: "red",
                  fontWeight: 800,
                }}
              >{`Please verify the import sheet for spellling mistakes or add missing outlets and try again`}</div>
            </>
          )}
        </Modal>
        {scorecardId && (
          <Button
            type="primary"
            style={{ margin: 10 }}
            onClick={updateScorecard}
          >
            Save
          </Button>
        )}
        {/* </Form.Item> */}

        {scorecardId && (
          <div>
            <h3 style={{ textAlign: "center" }}>Questions</h3>
            {scorecardDetails
              .sort((a, b) => a.sequence - b.sequence)
              .map((q, index: number) => (
                <div key={q.qID}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <h4>{`(${index + 1}) ${q.questionText}`}</h4>
                      {/* <Radio.Group
                      // options={q.answerPayload.options}
                      onChange={(e) => {
                        console.log(e.target.value);
                      }}
                      value={q.answerPayload.options[0].value}
                    >
                      {q.answerPayload.options.map((o: any, indexA: number) => (
                        <Radio value={o.value} key={`q_${index}_a_${indexA}`}>{o.label}</Radio>
                      ))}
                    </Radio.Group> */}
                      {q.answerPayload.options
                        .sort((a: any, b: any) => a.sequence - b.sequence)
                        .map((o: any) => (
                          <div
                            key={o.answerId}
                            style={{
                              display: "flex",
                              marginBottom: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "8px",
                                border: `1px solid ${
                                  o.value ? "green" : "red"
                                }`,
                                borderRadius: "5px",
                              }}
                            >
                              {o.label}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "8px",
                                fontWeight: 800,
                              }}
                            >
                              {`${o.value} pts`}
                            </div>
                            {o.branchExit && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: "8px",
                                  color: "#147af3",
                                }}
                              >
                                [Branching to{" "}
                                {o.branchExit === -1 ? "End" : o.branchExit}]
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </Form>
    </>
  );
};

export default ScorecardsForm;
