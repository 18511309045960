import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Space, Table, Button, Input, Switch, InputRef } from "antd";
import type { ColumnsType, ColumnType } from "antd/es/table";
import { AppContext } from "../../../../App";
import type { FilterConfirmProps } from "antd/es/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import useApiPost from "../../../../hooks/useApiPost";

interface DataType {
  retailCloudId: string;
  key: string;
  outletName: string;
  dropoffReason_id: string;
  dropoffReason: string;
  submitBy: string;
  createdAt: string;
  firstName: string;
  lastName: string;
}
type DataIndex = keyof DataType;

const DropOffPage = () => {
  const { request, setError } = useApiPost();
  const [isLoading, setIsLoading] = useState(false);
  const { projectId } = useContext(AppContext);
  const [requestList, setRequestList] = useState<DataType[]>([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const navigate = useNavigate();
  useEffect(() => {
    const getAllAxonify = async () => {
      setIsLoading(true);
      if (!projectId) {
        return;
      }
      const requestData = await request(`/office/dropoff/getDropOffRequest`, "POST", {
        projectId
      }, undefined, "v2");
      if(requestData?.status ==200){
        setRequestList(requestData.data)
      }
      
      setIsLoading(false);
    };
    getAllAxonify();
  }, [projectId]);

  const handleTo = () => {
   // const first = location.pathname.split("/")[1];
   // navigate(`/${first}/outlet-type/new`);
  };

  const handleUpdate = async (outletTypeId: string) => {
   // const first = location.pathname.split("/")[1];
   // navigate(`/${first}/outlet-type/edit/${outletTypeId}`);
  };


  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };


  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const onViewClick=(data:any)=>{
    const {outletId} = data;
    navigate(`/admin/dropoff/view/${outletId}`,{state:{isDropOff:true}})
  }

  const columns = (props: any): ColumnsType<DataType> => [
    {
      title: "Store Name",
      dataIndex: "outletName",
      ...getColumnSearchProps("outletName"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Retail Cloud ID",
      dataIndex: "retailCloudId",
      ...getColumnSearchProps("retailCloudId"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Reason",
      dataIndex: "dropoffReason",
     ...getColumnSearchProps("dropoffReason"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Submitted By",
      dataIndex: "firstName",
      // ...getColumnSearchProps("firstName"),
      render: (text, record) => <div>{(record?.firstName ? record?.firstName : "-" )+ " " + (record?.lastName ? record?.lastName : "" )}</div>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (text) => <div>{new Date(text).toLocaleDateString()}</div>,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <Space size="middle" key={record.retailCloudId}>
          <Button type="primary" onClick={() => onViewClick(record)}>
            View
          </Button>
        </Space>
      ),
    },
  ];

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: any) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const exists = record[dataIndex] ? record[dataIndex] : false;

      if (!exists) {
        return false;
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        //@ts-ignore
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });


  return (
    <>
      <Table
        size="small"
        key={projectId}
        columns={columns({
          onUpdate: handleUpdate,
        })}
        dataSource={requestList}
        rowKey="id"
        loading={isLoading}
        pagination={{ pageSize: 50 }}
      />
    </>
  );
  };

export default DropOffPage;