import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import "antd/dist/antd.css";
import { Space, Table, Button, Switch } from "antd";
import type { ColumnsType } from "antd/es/table";
import { AppContext } from "../../../../App";
import useApiPost from "../../../../hooks/useApiPost";

interface DataType {
  id: string;
  key: string;
  name: string;
  role_id?: any[];
}

interface ColumnType {
  onUpdate: (id: string) => void;
  onDelete: (id: string) => void;
}

const CallTypesList = () => {
  const appContext = useContext(AppContext);
  const { projectId } = appContext;
  let navigate = useNavigate();
  const { request, setError } = useApiPost();

  const [isLoading, setIsLoading] = useState(false);
  const [callTypes, setCallTypes] = useState<DataType[]>([]);

  useEffect(() => {
    console.log({ projectId });
  }, [projectId]);

  const fetchData = async () => {
    if (!projectId) {
      return;
    }
    const { callTypes } = await request("/office/call-types/all", "POST", {
      projectId,
    });

    setCallTypes(callTypes);
  };

  const handleUpdate = async (id: string) => {
    navigate(`/admin/call-type/view/${id}`);
  };

  const location = useLocation();
  const handleTo = () => {
    const first = location.pathname.split("/")[1];
    navigate(`/${first}/call-type/new/`);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [projectId]);

  const columns = (props: ColumnType): ColumnsType<DataType> => [
    {
      title: "Is Active",
      dataIndex: "isActive",
      key: "isActive",
      render: (val) => (
        <div>
          <Switch defaultChecked={val} disabled />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Is Plannable",
      dataIndex: "isPlannable",
      key: "isPlannable",
      render: (val) => (
        <div>
          <Switch defaultChecked={val} disabled />
        </div>
      ),
    },
    {
      title: "Is Remote",
      dataIndex: "isRemote",
      key: "isRemote",
      render: (val) => (
        <div>
          <Switch defaultChecked={val} disabled />
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space size="middle" key={record.id}>
          <Button type="primary" onClick={() => props.onUpdate(record.id)}>
            Update
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div>
        <div
          style={{
            paddingBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Switch
            checkedChildren="Active"
            unCheckedChildren="All"
            defaultChecked
            // onChange={() => setIsOnlyActiveVisible(!isOnlyActiveVisible)}
          />
          {/* <Button type="primary" onClick={() => getDiary()}>
            Refresh
          </Button> */}
        </div>
      </div>
      <Table
        columns={columns({
          onUpdate: handleUpdate,
          onDelete: () => console.log("delete"),
        })}
        dataSource={callTypes}
        rowKey="id"
      />
      <Button type="primary" onClick={handleTo}>
        Add calls
      </Button>
    </>
  );
};

export default CallTypesList;
