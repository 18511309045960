import { useState } from "react";
import { PieChart } from "react-minimal-pie-chart";

interface PieChartProps {
  data?: { title: string; value: number; color: string }[];
  title?: string;
}

const PaddedPieChart = (props: PieChartProps) => {
  const { data, title } = props;

  if (!data) return null;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "14px",
      }}
    >
      <div
        style={{
          marginBottom: "16px",
          fontSize: "16px",
          textAlign: "center",
        }}
      >
        {title}
      </div>
      <PieChart
        style={{
          maxWidth: "120px",
          maxHeight: "120px",
        }}
        labelPosition={75}
        label={({ x, y, dx, dy, dataEntry }) => (
          <text
            x={x}
            y={y}
            dx={dx}
            dy={dy}
            dominant-baseline="central"
            text-anchor="middle"
            // style={{
            //   fill: "#fff",
            //   pointerEvents: "none",
            //   fontSize: "4px",
            // }}
          >
            <tspan
              x={x}
              y={y}
              dx={dx}
              dy={dy}
              style={{ fontSize: "12px", fill: "white" }}
            >
              {`${Math.round(dataEntry.value)}%`}
            </tspan>
          </text>
        )}
        data={data}
        lineWidth={45}
        paddingAngle={5}
      ></PieChart>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
          marginTop: "16px",
        }}
      >
        {data.map((item, index) => (
          <div
          key={index}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <div
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: item.color,
                marginRight: "5px",
              }}
            ></div>
            <div
              style={{
                fontSize: "12px",
              }}
            >
              {item.title}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const PieChartComponent = (props: PieChartProps) => {
  const { data, title } = props;
  const [selected, setSelected] = useState(0);
  if (!data) return null;

  return (
    <div>
      <div
        style={{
          marginBottom: "16px",
          fontSize: "16px",
          textAlign: "center",
        }}
      >
        {title}
      </div>
      <div style={{ display: "flex" }}>
        <div>
          {data.map((item, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  backgroundColor: item.color,
                  marginRight: "5px",
                }}
              ></div>
              <div>{item.title}</div>
            </div>
          ))}
        </div>
        <PieChart
          label={({ x, y, dx, dy, dataEntry }) => (
            <text x={x} y={y} dx={dx} dy={dy} dominant-baseline="central">
              <tspan
                x={x}
                // y={y + 5}
                dx={dx}
                dy={dy}
                style={{ fontSize: "6px", fill: "black" }}
              >
                {`${Math.round(dataEntry.value)}`}
              </tspan>
            </text>
          )}
          labelStyle={{ fontSize: "10px", color: "white" }}
          data={data}
          style={{ width: "290px", padding: "20px" }}
          radius={40}
        />
      </div>
    </div>
  );
};

export { PieChartComponent, PaddedPieChart };
