import { useContext, useEffect, useState } from "react";
import { Button, Cascader, Input, Modal, Select } from "antd";
import { AppContext } from "../../../../App";
import { DefaultOptionType } from "antd/lib/select";
import { FaDotCircle } from "react-icons/fa";
import useApiPost from "../../../../hooks/useApiPost";
import * as S from "./product-settings-page.styled";

interface ICategory {
  value: string;
  label: string;
  parent_id: string;
  children?: ICategory[];
}

const ProductSettingsPage = () => {
  const { projectId } = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [newCategoryName, setNewCategoryName] = useState("");
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [actionType, setActionType] = useState<"add" | "edit">();
  const { request, setError } = useApiPost();

  const getData = async () => {
    const categories = await request(
      "/office/products/categories/all",
      "POST",
      {
        project_id: projectId,
      }
    );
    // const categories = await getAllCategories(projectId);
    setCategories(categories);
  };

  useEffect(() => {
    getData();
  }, []);

  const handeAddCategory = () => {
    setActionType("add");
    setIsModalOpen(true);
    setNewCategoryName("");
    setSelectedCategoryId("");
  };

  const onCategoryClick = (id: string, text: string) => {
    setActionType("edit");
    setNewCategoryName(text);
    setSelectedCategoryId(id);
    setIsModalOpen(true);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    setSelectedCategoryId("");
    setNewCategoryName("");
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1
    );

  const AddCategory = {
    title: "Add Category",
    content: (
      <div>
        <Cascader
          style={{ width: "100%" }}
          showSearch={{ filter }}
          options={categories}
          expandTrigger="hover"
          changeOnSelect
          onChange={(value) => {
            const lastValue = Array.isArray(value)
              ? value[value.length - 1]
              : value;
            setSelectedCategoryId(lastValue.toString());
          }}
        />
        <div>Subcategory Name</div>
        <Input
          value={newCategoryName}
          onChange={(e) => setNewCategoryName(e.target.value)}
        />
      </div>
    ),
    onsubmit: async () => {
      setIsLoading(true);
      await request("/office/products/categories/new", "POST", {
        project_id: projectId,
        text: newCategoryName,
        parent_id: selectedCategoryId,
      });
      setNewCategoryName("");
      await getData();
      setIsLoading(false);
      setIsModalOpen(false);
    },
    ondelete: async () => {
      setIsLoading(true);
      await request("/office/products/categories/delete", "POST", {
        id: selectedCategoryId,
      });
      await getData();
      setIsLoading(false);
      setIsModalOpen(false);
      setNewCategoryName("");
    },
  };

  const EditCategory = {
    title: "Edit Category",
    content: (
      <div>
        <div>Category Name</div>
        <Input
          value={newCategoryName}
          onChange={(e) => setNewCategoryName(e.target.value)}
        />
      </div>
    ),
    onsubmit: async () => {
      setIsLoading(true);
      await request("/office/products/categories/update", "POST", {
        id: selectedCategoryId,
        text: newCategoryName,
      });
      setNewCategoryName("");
      await getData();
      setIsLoading(false);
      setIsModalOpen(false);
    },
    ondelete: async () => {
      setIsLoading(true);
      await request("/office/products/categories/delete", "POST", {
        id: selectedCategoryId,
      });
      await getData();
      setIsLoading(false);
      setIsModalOpen(false);
      setNewCategoryName("");
    },
  };

  const getContent = () => {
    switch (actionType) {
      case "add":
        return AddCategory;
      case "edit":
        return EditCategory;
      default:
        return AddCategory;
    }
  };

  const currentContent = getContent();
  const isDeleteButtonVisible = actionType === "edit";
  const confirmButtonText = actionType === "add" ? "Add" : "Save";
  const isSaveCategoryActive = newCategoryName.length > 0;
  const isSaveSubcategoryActive =
    newCategoryName.length > 0 &&
    ((actionType === "add" && selectedCategoryId.length > 0) ||
      actionType === "edit");
  const isSaveActive =
    actionType === "add" ? isSaveCategoryActive : isSaveSubcategoryActive;

  const renderCategoriesChildren = (category: ICategory) => {
    const { children } = category;

    if (!children || children.length === 0) {
      return null;
    }

    return (
      <S.SubCategoriesWrapper>
        {children.map((subcategory, index) => (
          <>
            <S.Row key={index}>
              <FaDotCircle size={8} />
              <S.CategoryName
                key={subcategory.value}
                onClick={() =>
                  onCategoryClick(subcategory.value, subcategory.label)
                }
              >
                {subcategory.label}
              </S.CategoryName>
            </S.Row>
            {renderCategoriesChildren(subcategory)}
          </>
        ))}
      </S.SubCategoriesWrapper>
    );
  };

  return (
    <div>
      <Modal
        title={currentContent.title}
        open={isModalOpen}
        // onOk={handleConfirmDelete}
        onCancel={handleModalCancel}
        centered
        footer={[
          isDeleteButtonVisible ? (
            <Button
              key="delete"
              type="primary"
              danger
              onClick={() => currentContent.ondelete()}
            >
              Delete
            </Button>
          ) : null,
          <Button key="back" onClick={handleModalCancel}>
            Back
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => currentContent.onsubmit()}
            disabled={!isSaveActive}
          >
            {confirmButtonText}
          </Button>,
        ]}
      >
        {currentContent.content}
      </Modal>
      <h1>Product Categories</h1>
      <S.CategoriesWrapper>
        {categories.map((category, index) => (
          <>
            <S.Row key={index}>
              <FaDotCircle size={8} />
              <S.CategoryName
                onClick={() => onCategoryClick(category.value, category.label)}
              >
                {category.label}
              </S.CategoryName>
            </S.Row>
            {renderCategoriesChildren(category)}
          </>
        ))}
      </S.CategoriesWrapper>
      <S.Actions>
        <Button type="primary" onClick={handeAddCategory} loading={isLoading}>
          Add Category
        </Button>
      </S.Actions>
    </div>
  );
};

export default ProductSettingsPage;
