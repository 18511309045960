import { useState, useContext, useEffect } from "react";
import { Alert, Button, DatePicker, Form, Select, Typography } from "antd";
import { AppContext } from "../../../../App";
import readXlsxFile from "read-excel-file";
import { SheetDataSet } from "./target-import.types";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import useApiPost from "../../../../hooks/useApiPost";

dayjs.extend(customParseFormat);

const TargetsImportPage = () => {
  const { request, setError } = useApiPost();
  const appContext = useContext(AppContext);
  const { projectId } = appContext;
  const [isLoading, setIsLoading] = useState(false);
  const headerText = "Sales Targets Imports";
  const [selectedOutletType, setSelectedOutletType] = useState<string>();
  const [selectedTargetType, setSelectedTargetType] = useState<string>();
  const [alreadyUploadedData, setAlreadyUploadedData] = useState<any[]>([]);
  const [dataToUpload, setDataToUpload] = useState<any[]>([]);

  const [selectedMonth, setSelectedMonth] = useState<number>(0);
  const [selectedYear, setSelectedYear] = useState<number>(0);

  const [notExistingOutlets, setNotExistingOutlets] = useState<string[]>([]);

  const fetchAllStatusData = async () => {
    const { data } = await request(`/office/targets/status/${projectId}`);
    setAlreadyUploadedData(data);
  };

  useEffect(() => {
    if (projectId) {
      fetchAllStatusData();
    }
  }, [projectId]);

  const handleUploadBatchFile = async (e: any) => {
    setIsLoading(true);
    const file = e.target.files[0];
    const data: any[] = [];

    await readXlsxFile(file, { sheet: 1 }).then(async (rows) => {
      rows.map((row, index) => {
        if (index > 0) {
          const retailCloudID = Number(row[0]?.toString().trim());
          const target = Math.ceil(Number(row[1]));

          const item: SheetDataSet = {
            retailCloudID,
            target,
          };

          data.push(item);
        }
      });
    });

    setDataToUpload(data);
    setIsLoading(false);
  };

  const handleUpload = async () => {
    if (!selectedOutletType) {
      setIsLoading(false);
      return;
    }
    const result =
      selectedTargetType === "Targets"
        ? await request("/office/targets/goals/insert", "POST", {
            data: dataToUpload,
            projectId,
            month: selectedMonth,
            year: selectedYear,
            type: selectedOutletType,
          })
        : await request("/office/targets/actuals/insert", "POST", {
            data: dataToUpload,
            projectId,
            month: selectedMonth,
            year: selectedYear,
            type: selectedOutletType,
          });

    const { notExistingOutlets } = result;

    if (notExistingOutlets.filter((e: any) => !!e).length) {
      setNotExistingOutlets(notExistingOutlets.filter((e: any) => !!e));
    } else {
      alert("Data uploaded successfully");
    }

    await request(`/office/targets/status/${projectId}`)
  };

  const handleSelectDate = (date: any, dateString: string) => {
    const month = dayjs(dateString, "YYYY-MM").month() + 1;
    const year = dayjs(dateString, "YYYY-MM").year();
    setSelectedMonth(month);
    setSelectedYear(year);
  };

  const outletTypeOptions = [
    { label: "Pre-Paid", value: "Pre-Paid" },
    { label: "Post-Paid", value: "Post-Paid" },
  ];

  const targetTypeOptions = [
    { label: "Targets", value: "Targets" },
    { label: "Actuals", value: "Actuals" },
  ];

  const isSubmitDisabled = selectedMonth === 0 || selectedYear === 0;
  const data = [
    {
      month: 6,
      year: 2023,
      prepaid: true,
      postpaid: true,
    },
  ];

  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      {notExistingOutlets.length > 0 && (
        <Alert
          message="Warning"
          description={`The following outlets do not exist: ${notExistingOutlets.join(
            ", "
          )}.`}
          type="warning"
          showIcon
          style={{ marginBottom: 20 }}
        />
      )}
      <Form
        // form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
      >
        {/* <ImportInfoTable
          columns={columns}
          description={description}
          imgSrc={templateImg}
        /> */}
        {/* <Form.Item label="Date"> */}
        <div
          style={{
            marginBottom: "20px",
          }}
        >
          <DatePicker
            picker="month"
            // name="startDate"
            onChange={(date, dateString) => handleSelectDate(date, dateString)}
          />
        </div>
        <Form.Item label="Outlet Type">
          <Select
            options={outletTypeOptions}
            onChange={(e) => setSelectedOutletType(e)}
            value={selectedOutletType}
          />
        </Form.Item>
        <Form.Item label="Target Data">
          <Select
            options={targetTypeOptions}
            onChange={(e) => setSelectedTargetType(e)}
            value={selectedTargetType}
          />
        </Form.Item>
        {/* </Form.Item> */}
        <div>
          <input
            type="file"
            id="input"
            onChange={(e) => handleUploadBatchFile(e)}
            disabled={isLoading || isSubmitDisabled || !selectedOutletType}
          />
        </div>
        <div
          style={{
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Button
            disabled={
              isLoading ||
              isSubmitDisabled ||
              !selectedOutletType ||
              !dataToUpload.length
            }
            loading={isLoading}
            onClick={() => handleUpload()}
          >
            Confirm
          </Button>
        </div>
      </Form>
      <div>
        <div
          style={{
            fontWeight: "bold",
          }}
        >
          Already uploaded data
        </div>
        <div
          style={{
            maxWidth: "600px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "100px",
              }}
            >
              Date
            </div>
            <div
              style={{
                width: "100px",
              }}
            >
              Prepaid
            </div>
            <div
              style={{
                width: "100px",
              }}
            >
              Postpaid
            </div>
          </div>
          {alreadyUploadedData.map((item, index) => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: `${index === 0 ? " #aab7bd88" : ""}`,
              }}
            >
              <div
                style={{
                  width: "100px",
                }}
              >
                <Typography.Text>
                  {item.month}/{item.year}
                </Typography.Text>
              </div>
              <div
                style={{
                  width: "100px",
                }}
              >
                <Typography.Text>{item.prepaid ? "Yes" : "No"}</Typography.Text>
              </div>
              <div
                style={{
                  width: "100px",
                }}
              >
                <Typography.Text>
                  {item.postpaid ? "Yes" : "No"}
                </Typography.Text>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TargetsImportPage;
