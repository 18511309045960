import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Space, Table, Button, Input, Switch, InputRef } from "antd";
import type { ColumnsType, ColumnType } from "antd/es/table";
import { AppContext } from "../../../../App";
import type { FilterConfirmProps } from "antd/es/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import useApiPost from "../../../../hooks/useApiPost";

interface DataType {
  id: string;
  key: string;
  isActive: boolean;
  name: string;
  validFrom: string;
  validTo: string;
  uploadedDateAndTime: string;
}
type DataIndex = keyof DataType;

const AxonifyPage = () => {
  const { request, setError } = useApiPost();
  const { projectId } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [searchReset, setSearchReset] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [searchTextValidTo, setSearchTextValidTo] = useState("");
  const [searchTextValidFrom, setSearchTextValidFrom] = useState("");
  const [allAxonofies, setAllAxonofies] = useState<DataType[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const handleTo = () => {
    const first = location.pathname.split("/")[1];
    navigate(`/${first}/axonify/new`);
  };

  // const handleToDetail = (id: string)=> {
  //   const first = location.pathname.split("/")[1];
  //   //alert(first)
  //   navigate(`/${first}/axonify/detail:${id}`);
  // }

  const handleUpdate = async (id: string) => {
    const first = location.pathname.split("/")[1];
    navigate(`/${first}/axonify/detail/${id}`);
  };

  useEffect(() => {
    const getAllAxonify = async () => {
      setIsLoading(true);
      if (!projectId) {
        return;
      }
      const axonifyList = await request(`/office/axonify`, "POST", {
        projectId,
        // isActive: isActive ? 1 : 0,
        page: page,
        limit: limit,
        name: searchText,
        validFrom: searchTextValidFrom,
        validTo: searchTextValidTo,
      });

      setAllAxonofies(axonifyList?.data)
      setTotal(axonifyList?.total);
      setIsLoading(false);
    };
    getAllAxonify();
  }, [projectId, isActive, page, searchText, searchReset, searchTextValidFrom, searchTextValidTo]);

  const columns = (props: any): ColumnsType<DataType> => [
    {
      title: "Active",
      dataIndex: "isActive",
      render: (val: any) => (
        <div>
          <Switch defaultChecked={val} disabled />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      ...getColumnSearchProps("name", "Name"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Start date",
      dataIndex: "validFrom",
      ...getColumnSearchProps("validFrom", "Start Date (dd/mm/yyyy)"),
      render: (text) => <div>{new Date(text).toLocaleDateString()}</div>,
    },
    {
      title: "End date",
      dataIndex: "validTo",
      ...getColumnSearchProps("validTo", "End Date (dd/mm/yyyy)"),
      render: (text) => <div>{new Date(text).toLocaleDateString()}</div>,
    },
    {
      title: "Upload Date",
      dataIndex: "created_at",
      render: (text) => <div>{new Date(text).toLocaleDateString()}</div>,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <Space size="middle" key={record.id}>
          <Button type="primary" onClick={() => props.onUpdate(record.id)}>
            Details
          </Button>
        </Space>
      ),
    },
  ];
  const searchInput = useRef<InputRef>(null);

  const [searchedColumn, setSearchedColumn] = useState("");
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    if(dataIndex === 'validTo') {
    const regexddmmyyyy = /^(0[1-9]|[12][0-9]|3[01])[/ /.](0[1-9]|1[012])[/ /.](19|20)\d\d$/;
    if (regexddmmyyyy.test(selectedKeys[0])) {
      setSearchTextValidTo(selectedKeys[0]);
    } else {
      setSearchTextValidTo('');
    }
    } else if(dataIndex === 'validFrom') {
      const regexddmmyyyy = /^(0[1-9]|[12][0-9]|3[01])[/ /.](0[1-9]|1[012])[/ /.](19|20)\d\d$/;
      if (regexddmmyyyy.test(selectedKeys[0])) {
        setSearchTextValidFrom(selectedKeys[0]);
      } else {
        setSearchTextValidFrom('');
      }
    } else if(dataIndex === 'name') {
      setSearchText(selectedKeys[0]);
    }
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
    setSearchTextValidFrom("");
    setSearchTextValidTo("");
    setSearchReset(!searchReset);
  };
  const getColumnSearchProps = (
    dataIndex: DataIndex,
    placeHolder: string
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: any) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={placeHolder} 
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters)
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const exists = record[dataIndex] ? record[dataIndex] : false;

      if (!exists) {
        return false;
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        //@ts-ignore
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  useEffect(() => {
  }, [projectId]);



  // const handleToggleVerified = () => {
  //   setIsActive(!isActive)
  // };

  return (
    <>
    <div>
        {/* <div
          style={{
            paddingBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Switch
            checkedChildren="Active"
            unCheckedChildren="InActive"
            defaultChecked
            onChange={() => handleToggleVerified()}
          />
        </div> */}
      </div>
      <Table
        size="small"
        key={projectId}
        columns={columns({
          onUpdate: handleUpdate,
        })}
        dataSource={allAxonofies}
        rowKey="id"
        loading={isLoading}
        pagination={{ pageSize: limit,
          total: total,
          onChange: (page, pageSize) => {
            setPage(page);
          }, }}
      />
      <Button type="primary" onClick={handleTo}>
        Add
      </Button>
    </>
  );
};

export default AxonifyPage;
