import styled from "styled-components";

export const TicketBox = styled.div`
  margin-bottom: 10px;
  width: 70%;
`;

export const MesageBox = styled.div`
  border-radius: 12px;
  padding: 8px 16px;
`;

export const OutgoingTicketBox = styled(TicketBox)`
  margin-left: auto;
`;

export const IncomingTicketBox = styled(TicketBox)`
  margin-right: auto;
`;

export const OutgoingTicketMessage = styled(MesageBox)`
  border: 1px solid #1777ff;
  background-color: #eaf4ff;
  color: #1777ff;
`;

export const IncomingTicketMessage = styled(MesageBox)`
  border: 1px solid rgb(101 113 125);
  background-color: rgb(223 225 227);
  color: black;
`;

export const ChatBox = styled.div`
  max-height: 300px;
  overflow: auto;
`;

export const DownloadFile = styled.div`
  margin-top: 10px;
  color: black !important;
`;
