import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Surface,
  Legend,
  Symbols,
  Cell,
} from "recharts";
import * as S from "./BarChart.styled";
import { IDataEntry } from "../../TVDashboard/Dashboard";

interface BarChartComponentData {
  name: string;
  value: number;
  label: string;
  index: string;
  id: string;
}
interface BarChartComponentProps {
  data: BarChartComponentData[];
  color: string;
  title?: string;
  customHeight?: number;
  customWidth?: number;
  hideLegend?: boolean;
  setLabels90Degree?: boolean;
  type: string;
  handleBarClick?: (e: any) => void;
  selectedRow?: string;
  activeIds?: IDataEntry[];
}

const BarChartComponent = (props: BarChartComponentProps) => {
  const {
    data,
    color,
    title,
    customHeight = 280,
    customWidth = 300,
    hideLegend = false,
    setLabels90Degree = false,
    handleBarClick,
    selectedRow,
    type,
    activeIds,
  } = props;

  return (
    <div>
      <div
        style={{
          marginBottom: "16px",
          fontSize: "20px",
          textAlign: "center",
        }}
      >
        {title}
      </div>
      <ResponsiveContainer
        width="100%"
        height={customHeight}
        minWidth={customWidth}
      >
        <BarChart
          width={customWidth}
          height={customHeight}
          data={data}
          margin={{
            top: 15,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          style={{ overflow: "visible" }}
          onClick={(e) =>
            handleBarClick && handleBarClick({ ...e, type, selectedRow })
          }
        >
          {!hideLegend && (
            <XAxis
              dataKey="name"
              fontSize={10}
              style={{ fontSize: "10px" }}
              // tick={<CustomizedAxisTick />}
            />
          )}
          {hideLegend && (
            <XAxis
              dataKey="name"
              textAnchor="end"
              interval={0}
              angle={-90}
              scale={"auto"}
              height={200}
              style={{ fontSize: "10px" }}
            />
          )}
          {!hideLegend && <YAxis />}
          {hideLegend && (
            <YAxis
              textAnchor="end"
              interval={0}
              scale={"auto"}
              height={200}
              style={{ fontSize: "10px" }}
            />
          )}
          <Tooltip />
          {/* <Legend /> */}
          <Bar
            dataKey="value"
            fill={color}
            // width={10}
            barSize={hideLegend ? 20 : 30}
            label={{
              position: "top",
              fill: "black",
              fontStyle: "bold",
              fontSize: "12px",
              angle: setLabels90Degree ? -90 : 0,
            }}
          >
            {data.map((entry, index) => (
              <Cell
                fill={
                  activeIds &&
                  activeIds.length > 0 &&
                  activeIds.some((item) => item.id === entry.id && item.row === selectedRow)
                    ? "#ea3829"
                    : color
                }
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      {!hideLegend &&
        data &&
        (data.length < 6 ? (
          <S.LegendContainer>
            {data.map((item, index) => (
              <S.LegendItemRow key={index}>
                <S.LegendItem color={color} key={index} />
                <div>{`[${item.index}] ${item.label}`}</div>
              </S.LegendItemRow>
            ))}
          </S.LegendContainer>
        ) : (
          <S.LegendContainerWrap>
            {data.map((item, index) => (
              <S.LegendItemRowWrap key={index}>
                <S.LegendItem color={color} key={index} />
                <div>{`[${item.index}] ${item.label}`}</div>
              </S.LegendItemRowWrap>
            ))}
          </S.LegendContainerWrap>
        ))}
    </div>
  );
};

export default BarChartComponent;
