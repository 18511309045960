import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-bottom: 20px;
  align-items: center;
  border: 1px solid #ccc;
`;

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: 600px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const InnerActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 300px;
`;