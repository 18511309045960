import { useContext, useEffect, useRef, useState } from "react";
import { Space, Table, Button, Switch, Input, InputRef } from "antd";
import type { ColumnsType, ColumnType } from "antd/es/table";
import type { FilterConfirmProps } from "antd/es/table/interface";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../../../App";
import { FcExpired } from "react-icons/fc";
import useApiPost from "../../../../hooks/useApiPost";

interface DataType {
  id: string;
  isActive: boolean;
  internalProject_id: string;
  scorecardType_id: string;
  name: string;
  validFrom: string;
  validTo: string;
  description: string;
  isRequired: boolean;
  standard: number;
  version: string;
  created_at: string;
  key: number;
}
type DataIndex = keyof DataType;

const KnowledgeTransferPage = () => {
  const { request, setError } = useApiPost();

  const appContext = useContext(AppContext);
  const { projectId } = appContext;
  const [data, setData] = useState([]);
  useEffect(() => {
    const getAllKTs = async () => {
      const res = await request("/office/kt-modules", "POST", {
        projectId,
      });
      const data = res.map((e: any, index: number) => ({
        ...e,
        key: index,
      }));
      setData(data);
      // setIsLoading(false);
    };
    getAllKTs();
  }, []);
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  let navigate = useNavigate();

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: any) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        //@ts-ignore
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = (props: any): ColumnsType<DataType> => [
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      render: (val) => (
        <div>
          <Switch defaultChecked={val} disabled />
        </div>
      ),
    },
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
      render: (text) => <div>{text}</div>,
      ...getColumnSearchProps("name"),
    },
    {
      title: "Valid From",
      dataIndex: "validFrom",
      key: "validFrom",
      render: (date) => <div>{`${new Date(date).toDateString()}`}</div>,
    },
    {
      title: "Valid To",
      dataIndex: "validTo",
      key: "validTo",
      render: (date) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>{`${new Date(date).toDateString()}`}</div>
          {new Date(date) < new Date() && (
            <div
              style={{
                display: "flex",
              }}
            >
              <FcExpired size={20} />
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space size="middle" key={record.id}>
          <Button type="primary" onClick={() => props.onUpdate(record.id)}>
            Update
          </Button>
          {/* <Button
            type="primary"
            danger
            onClick={() => props.onDelete(record.id)}
          >
            Delete
          </Button> */}
        </Space>
      ),
    },
  ];

  const location = useLocation();

  const handleUpdate = async (id: string) => {
    const first = location.pathname.split("/")[1];
    navigate(`/${first}/knowledge-transfer/edit/${id}`);
  };

  const handleTo = () => {
    const first = location.pathname.split("/")[1];
    navigate(`/${first}/knowledge-transfer/new`);
  };

  return (
    <div>
      <h1>KT Modules</h1>
      <Table
        columns={columns({
          onUpdate: handleUpdate,
          onDelete: () => {},
        })}
        dataSource={data}
        rowKey="key"
      />

      <Button type="primary" onClick={handleTo}>
        Add
      </Button>
    </div>
  );
};

export default KnowledgeTransferPage;
