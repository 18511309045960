import { Button, DatePicker, Form, Input, Select, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useContext, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { instance } from "../../../../api/common-api";
import { AppContext } from "../../../../App";
import useApiPost from "../../../../hooks/useApiPost";

interface SingleMediaDetails {
  index: number;
  name: string;
}

const MediaUpload = () => {
  const [file, setFiles] = useState<FileList>();
  const [sucessMessage, setSucessMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [mediaDetails, setMediaDetails] = useState<SingleMediaDetails[]>();
  const { request, setError } = useApiPost();

  const [selectedDocumentType, setSelectedDocumentType] = useState<string>();
  const [selectedOutletType, setSelectedOutletType] =
    useState<string>("Post-paid");

  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  // const ref = useRef<HTMLInputElement>(null);
  const ref2 = useRef<HTMLInputElement>(null);
  const location = useLocation();

  const { projectId } = useContext(AppContext);
  const navigate = useNavigate();

  const first = location.pathname.split("/")[1];
  // useEffect(() => {
  //   if (ref.current !== null) {
  //     ref.current.setAttribute("directory", "");
  //     ref.current.setAttribute("webkitdirectory", "");
  //   }
  // }, [ref]);

  const onChangeMultipleFiles = (e: any) => {
    setFiles(e.target.files);
    const mediaDetails: SingleMediaDetails[] = [];
    // console.log({ f: e.target.files });
    for (let i = 0; i < e.target.files.length; i++) {
      const fileNameWithoutExtension = e.target.files[i].name.split(".")[0];
      mediaDetails.push({
        index: i,
        name: fileNameWithoutExtension,
      });
    }
    setMediaDetails(mediaDetails);
  };

  const onChangeSingleFile = (e: any) => {
    setFiles(e.target.files);
    // console.log({ f: e.target.files });
    const mediaDetails: SingleMediaDetails[] = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const fileNameWithoutExtension = e.target.files[i].name.split(".")[0];
      mediaDetails.push({
        index: i,
        name: fileNameWithoutExtension,
      });
    }
    setMediaDetails(mediaDetails);
  };

  const uploadFile = async (e: any) => {
    setIsLoading(true);
    e.preventDefault();
    const formData = new FormData();
    if (!file) {
      setErrorMessage("Please select files");
      return;
    }

    if (!selectedDocumentType) {
      setErrorMessage("Please select document type");
      setIsLoading(false);
      return;
    }

    for (let i = 0; i < file.length; i++) {
      // if (file[i]) {
      //   file[i].name = file[i].name.replace(/ /g, "_");
      formData.append("files", file[i]);
      // }
    }

    formData.append("documentType", selectedDocumentType);
    formData.append("outletType", selectedOutletType);
    formData.append("projectId", projectId);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    const safeMediaDetails = mediaDetails?.map((e) => {
      return { ...e, name: `${e.name}.pdf` };
    });

    formData.append("mediaDetails", JSON.stringify(safeMediaDetails));

    if (selectedDocumentType === "Smart Start") {
      if (!title || !description) {
        setErrorMessage("Please provide title and description");
        setIsLoading(false);
        return;
      }
    }
    formData.append("description", description || "");
    formData.append("title", title || "");

    await request("/office/media/upload", "POST", formData, true);
    setIsLoading(false);
    navigate(`/${first}/media-files`);
    return;
  };

  const humanReadanbleFileSize = (size: number) => {
    let i = Math.floor(Math.log(size) / Math.log(1024));
    const value = (size / Math.pow(1024, i)).toFixed(2);

    return value + " " + ["B", "kB", "MB", "GB", "TB"][i];
  };

  const options = [
    { label: "Campaigns", value: "Campaigns" },
    { label: "Channel Brief", value: "Channel Brief" },
    { label: "Cycle Brief", value: "Cycle Brief" },
    { label: "Deal Poster", value: "Deal Poster" },
    { label: "Memo / Cheat Sheet", value: "Memo / Cheat Sheet" },
    { label: "Planogram", value: "Planogram" },
    { label: "Smart Start", value: "Smart Start" },
  ];

  const outletTypeOptions = [
    { label: "Pre-Paid", value: "Pre-Paid" },
    { label: "Post-Paid", value: "Post-Paid" },
  ];

  const handleUpdateName = (index: number, name: string) => {
    const newMediaDetails = mediaDetails?.map((e) => {
      if (e.index === index) {
        return { ...e, name };
      }
      return e;
    });
    setMediaDetails(newMediaDetails);
  };

  return (
    <>
      <Typography.Title>Media Upload</Typography.Title>
      <Typography.Paragraph>
        Upload media files to the server. First select the folder then click on
        upload to confirm.
      </Typography.Paragraph>
      <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }}>
        <Form.Item label="Document Type">
          <Select
            options={options}
            disabled={isLoading}
            onChange={(e) => setSelectedDocumentType(e)}
          />
        </Form.Item>
        <Form.Item label="Outlet Type">
          <Select
            options={outletTypeOptions}
            disabled={isLoading}
            onChange={(e) => setSelectedOutletType(e)}
            defaultValue={selectedOutletType}
          />
        </Form.Item>
        {/* {selectedDocumentType === "Smart Start" && (
          <> */}
        <Form.Item label="Product Title">
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            disabled={isLoading}
          />
        </Form.Item>
        <Form.Item label="Product Description">
          <TextArea
            rows={5}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            disabled={isLoading}
          />
        </Form.Item>

        <Form.Item
          label="Start"
          // name="startDate"
          // getValueProps={(i: any) => ({ value: moment(i) })}
          required
        >
          <DatePicker
            allowClear={false}
            value={startDate}
            onChange={(e: any) => setStartDate(e)}
          />
        </Form.Item>
        <Form.Item
          label="End"
          // name="endDate"
          // getValueProps={(i: any) => ({ value: moment(i) })}
          required
          rules={[
            {
              required: true,
              message: "Please select a form end",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const start = new Date(getFieldValue("startDate"));
                const end = new Date(value);

                if (start > end) {
                  return Promise.reject(
                    "End date must be greater than start date"
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <DatePicker
            allowClear={false}
            value={endDate}
            onChange={(e: any) => setEndDate(e)}
          />
        </Form.Item>

        {/* </>
        )} */}
      </Form>
      <div>
        <form
          encType="multipart/form-data"
          method="post"
          action="#"
          onSubmit={uploadFile}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <div>
              <div>Upload folder:</div>
              <input
                // ref={ref}
                type="file"
                name="files"
                multiple
                onChange={onChangeMultipleFiles}
                disabled={isLoading || (file && file?.length > 0)}
                accept=".pdf"
              />
            </div>
            <br />
            <div>
              <div>Upload single file:</div>
              <input
                ref={ref2}
                type="file"
                name="file"
                onChange={onChangeSingleFile}
                disabled={isLoading || (file && file?.length > 0)}
                accept=".pdf"
              />
            </div>
          </div>
          <br />
          <div>
            {file &&
              Array.from(file).map((e, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: index % 2 === 0 ? "#b1b1b1" : "#e6e6e6",
                    padding: "2px 5px",
                  }}
                >
                  <div key={e.name}>
                    {e.name} - {humanReadanbleFileSize(e.size)}
                  </div>
                  <div>
                    <input
                      value={mediaDetails?.[index]?.name}
                      onChange={(e) => handleUpdateName(index, e.target.value)}
                    />
                  </div>
                </div>
              ))}
          </div>
          <p> {sucessMessage}</p>

          <p
            style={{
              color: "red",
            }}
          >
            {errorMessage}
          </p>
          <Button
            key="back"
            type="primary"
            htmlType="submit"
            disabled={!file || !selectedDocumentType || isLoading}
          >
            Upload
          </Button>
        </form>
      </div>
    </>
  );
};

export default MediaUpload;
