import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Typography,
  Select,
  DatePicker,
  Switch,
} from "antd";
import { FormEvent } from "react";
import { AppContext } from "../../../../App";
import { DefaultOptionType } from "antd/lib/select";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import templateImg from "../../../../assets/images/kt-module-template-example.png";
import readXlsxFile from "read-excel-file";
import ImportInfoTable from "../common/ImportInfoTable";
import useApiPost from "../../../../hooks/useApiPost";
import "../../../../App.css"

dayjs.extend(customParseFormat);

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
export const EMPTY_RESEARCH = {
  id: "",
  name: "",
  description: "",
  validTo: null,
  validFrom: null,
  chain_id: [],
  callType_id: [],
  outletType_id: [],
  userRoles_id: [],
  outletState_id: [],
  hierarchy_id: [] as any
};
const columns = [
  {
    title: "(B) Date",
    column: "B",
  },
  {
    title: "(C) Question Text",
    column: "C",
  },
  {
    title: "(G) Response 1",
    column: "G",
  },
  {
    title: "(I) Response 2",
    column: "I",
  },
  {
    title: "(K) Response 3",
    column: "K",
  },
  {
    title: "(M) Correct Answer",
    column: "M",
  },
];
const description =
  "Below you wil find a list of fields and their corresponding excel columns. Please make sure that the values are correct before importing the file.";

const KnowledgeTransferForm = () => {
  const { userId } = useParams<{ userId: string }>();
  const { projectId } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const { moduleId } = useParams<{ moduleId: string }>();
  const headerText = moduleId ? "Edit KT Module" : "Add Module";
  const { request, setError } = useApiPost();

  const [form, setForm] = useState(EMPTY_RESEARCH);
  const [allCallTypes, setAllCallTypes] = useState<any[]>([]);
  const [chains, setChains] = useState<any[]>([]);
  const [outletTypes, setOutletTypes] = useState<any[]>([]);
  const [outletStates, setOutletStates] = useState<any[]>([]);
  const [userRoles, setUserRoles] = useState<any[]>([]);
  const [treeData, setTreeData] = useState<any[]>([]);
  const [regionText, setRegionText] = useState<string[]>([]);
  const [selectedOutletType, setSelectedOutletType] = useState<string>();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isFormal, setIsFormal] = useState<boolean>(false);

  useEffect(() => {
  },[isActive])
  const [formOptionValues, setFormOptionValues] = useState({
    outletType_id: null,
  });

  let navigate = useNavigate();
  // const [research, setResearch] = useState<any>(EMPTY_RESEARCH);
  const [knowledgeTransferDetails, setKnowledgeTransferDetails] = useState<
    any[]
  >([]);

  const handleComeBack = () => {
    navigate("/admin/knowledge-transfer");
  };

  const fetchData = async () => {
    const options = await request("/office/outlet-research/options", "POST", {
      projectId,
    });

    const {
      callTypes,
      chains,
      outletTypes,
      userRoles,
      outletStates,
      geography,
    } = options;

    setAllCallTypes(callTypes || []);
    setChains(chains || []);
    setOutletTypes(outletTypes || []);
    setUserRoles(userRoles || []);
    setOutletStates(outletStates || []);
    setTreeData(geography || []);
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const getAllData = async () => {
      setIsLoading(true);
      if (!moduleId) return;
      // const data = await getKTModuleDetails(moduleId);
      const data = await request(
        `/office/kt-modules/details/${moduleId}`,
        "POST"
      );
      setKnowledgeTransferDetails(data?.questions || []);

      setIsActive(data?.isActive);
      setIsFormal(data?.externalModule_id === 'formal' ? true : false);

      setForm({
        ...form,
        ...data,
        isActive: data?.isActive,
        isFormal: data?.externalModule_id === 'formal' ? true : false,
        validFrom: dayjs(data.validFrom || new Date()),
        validTo: dayjs(data.validTo || new Date()),
        callType_id: data.callType_id
          ? data.callType_id.map((u: any) => u.value)
          : [],
        chain_id: data.chain_id ? data.chain_id.map((u: any) => u.value) : [],
        outletType_id: data.outletType_id
          ? data.outletType_id.map((u: any) => u.value)
          : [],
        userRoles_id: data.userRoles_id
          ? data.userRoles_id.map((u: any) => u.value)
          : [],
        outletState_id: data.outletState_id
          ? data.outletState_id.map((u: any) => u.value)
          : [],
      });
      setSelectedOutletType(data.outletType);
      setRegionText(data.connectedRegionsText);

      // const data = outlets.map((e: any, index: number) => ({
      //   ...e,
      //   key: index,
      // }));
      setIsLoading(false);
    };
    getAllData();
  }, []);
  const [loading, setLoading] = useState<boolean>(false);
  const [resultInfo, setResultInfo] = useState<string | null>(null);

  const updateForm = (key: string, value: any) => {
    setForm((form) => ({
      ...form,
      [key]: value,
    }));
  };

  const onChange = (key: string, value: any) => {
    const lastValue = Array.isArray(value) ? value[value.length - 1] : value;
    handleOptionChange(key, { value: lastValue });
  };

  const handleUploadKTModules = async (e: any) => {
    const file = e.target.files[0];
    const data: any[] = [];

    await readXlsxFile(file).then(async (rows) => {
      rows.map((row, index) => {
        if (index > 0) {
          const questionOrder = row[1]?.toString().trim();
          const question = row[2]?.toString().trim();
          const response1 = row[6]?.toString().trim();
          const response2 = row[8]?.toString().trim();
          const response3 = row[10]?.toString().trim();
          const answerId = row[12]?.toString().trim();
          const responses = [response1, response2, response3]
            .filter((t) => t)
            .map((t, index) => ({
              response: t,
              score: answerId === index.toString() ? 1 : 0,
              sequence: index + 1,
            }));
          const item: any = {
            questionOrder: parseInt(questionOrder),
            question,
            responses,
            answerId,
          };

          data.push(item);
        }
      });
    });
  };
  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1
    );
  const handleOptionChange = (key: string, event: any) => {
    setFormOptionValues({
      ...formOptionValues,
      [key]: event.value,
    });
  };
  const sendForm = async (e: FormEvent) => {
    setLoading(true);

    try {
      const allVals: string[] = form.hierarchy_id.reduce(
        (acc: string | any[], val: any) => acc.concat(val),
        []
      );
      const uniqueRegionIds = allVals.filter((v, i, a) => a.indexOf(v) === i);
      const uniqueRoles = form.userRoles_id.filter(
        (v, i, a) => a.indexOf(v) === i
      );

      const uniqueChains = form.chain_id.filter(
        (v, i, a) => a.indexOf(v) === i
      );

      const newData = {
        id: moduleId,
        chains_id: uniqueChains,
        isActive: isActive,
        externalModule_id: isFormal === true ? 'formal' : '',
        projectId,
        description: form.description,
        name: form.name,
        startDate: form.validFrom,
        endDate: form.validTo,
        userRoles_id: uniqueRoles,
        hierarchy_id: uniqueRegionIds,
        outletType_id: form.outletType_id,
        outletState_id: form.outletState_id,
        callType_id: form.callType_id,
        outletType: selectedOutletType,
      };

      if (!moduleId) {
        return alert("Please select a module");
      }
      await request(`/office/kt-modules/update/${moduleId}`, "POST", {
        ...newData,
      });
      // await updateKTModuleDetails(moduleId, newData);
      handleComeBack();
      // const res = await updateOutletResearchDetails(newData);
    } finally {
      setLoading(false);
    }
  };

  const outletTypeOptions = [
    { label: "Pre-Paid", value: "Pre-Paid" },
    { label: "Post-Paid", value: "Post-Paid" },
  ];

  if (
    loading ||
    outletTypes.length === 0 ||
    outletStates.length === 0 ||
    treeData.length === 0 ||
    allCallTypes.length === 0 ||
    userRoles.length === 0 ||
    chains.length === 0
  ) {
    return <p>Loading...</p>;
  }

  if (!moduleId)
    return (
      <>
        <Typography.Title>{headerText}</Typography.Title>
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
        // onFinish={onFinish}
        >
          <Form.Item label="Name">
            <Input
              type="text"
              value={form.name}
              onChange={(e) => updateForm("name", e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="Start"
            // name="startDate"
            // getValueProps={(i: any) => ({ value: moment(i) })}
            required
          >
            <DatePicker
              allowClear={false}
              value={form.validFrom}
              // onChange={(e) => setStartDate(e?.toDate())}
              onChange={(e: any) => updateForm("validFrom", e)}
            />
          </Form.Item>
          <Form.Item
            label="End"
            // name="endDate"
            // getValueProps={(i: any) => ({ value: moment(i) })}
            required
            rules={[
              {
                required: true,
                message: "Please select a form end",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  const start = new Date(getFieldValue("startDate"));
                  const end = new Date(value);

                  if (start > end) {
                    return Promise.reject(
                      "End date must be greater than start date"
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <DatePicker
              allowClear={false}
              value={form.validTo}
              // onChange={(e) => setEndDate(e?.toDate())}
              onChange={(e: any) => updateForm("validTo", e)}
            />
          </Form.Item>

          <ImportInfoTable
            columns={columns}
            description={description}
            imgSrc={templateImg}
          />
          <div>
            <input
              type="file"
              id="input"
              disabled={loading || !projectId || !form.name}
              onChange={(e) => handleUploadKTModules(e)} //handleUploadBatchFile(e)}
            />
          </div>
          {/* {entriesExist && (
          <Button type="primary" onClick={validateBasket}>
            Validate basket
          </Button>
        )}
        <Button type="primary" style={{ margin: 10 }} onClick={handleComeBack}>
          Go back
        </Button> */}

          {/* <Modal
          title="Basic Modal"
          open={isConfirmModalOpen}
          centered
          width={1000}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              type="primary"
              onClick={handleOk}
              // disabled={!isCreateAvailable}
            >
              Submit
            </Button>,
          ]}
        >
          <p
            style={{
              textAlign: "center",
            }}
          >
            Your basket is almost ready!
          </p>
          {/* list newly created products */}
          {/* </Form.Item> */}
        </Form>
      </>
    );

  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Form
        // form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={sendForm}
      >
        <Form.Item label="Name">
          <Input
            type="text"
            value={form.name}
            onChange={(e) => updateForm("name", e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="Start"
          // name="startDate"
          // getValueProps={(i: any) => ({ value: moment(i) })}
          required
        >
          <DatePicker
            allowClear={false}
            value={form.validFrom}
            // onChange={(e) => setStartDate(e?.toDate())}
            onChange={(e: any) => updateForm("validFrom", e)}
          />
        </Form.Item>
        <Form.Item
          label="End"
          // name="endDate"
          // getValueProps={(i: any) => ({ value: moment(i) })}
          required
          rules={[
            {
              required: true,
              message: "Please select a form end",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const start = new Date(getFieldValue("startDate"));
                const end = new Date(value);

                if (start > end) {
                  return Promise.reject(
                    "End date must be greater than start date"
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <DatePicker
            allowClear={false}
            value={form.validTo}
            // onChange={(e) => setEndDate(e?.toDate())}
            onChange={(e: any) => updateForm("validTo", e)}
          />
        </Form.Item>
        <Form.Item label="Outlet Type">
          <Select
            options={outletTypeOptions}
            onChange={(e) => setSelectedOutletType(e)}
            value={selectedOutletType}
          />
        </Form.Item>
        <Form.Item label="Description">
          <Input
            type="text"
            value={form.description}
            onChange={(e) => updateForm("description", e.target.value)}
          />
        </Form.Item>
        {/* <Form.Item label="Call Types">
          <Select
            mode="multiple"
            options={allCallTypes}
            value={form.callType_id}
            onChange={(e) => updateForm("callType_id", e)}
          />
        </Form.Item> */}
        <Form.Item label="Chains">
          <Select
            mode="multiple"
            options={chains}
            value={form.chain_id}
            onChange={(e) => updateForm("chain_id", e)}
          />
        </Form.Item>
        {/* <Form.Item label="Outlet Type">
          <Select
            loading={isLoading}
            options={outletTypes}
            value={form.outletType_id}
            mode="multiple"
            onChange={(e) => {
              updateForm("outletType_id", e);
            }}
            allowClear
          />
        </Form.Item> */}
        {/* <Form.Item label="Outlet State">
          <Select
            loading={isLoading}
            options={outletStates}
            value={form.outletState_id}
            mode="multiple"
            onChange={(e) => {
              updateForm("outletState_id", e);
            }}
            allowClear
          />
        </Form.Item> */}
        <Form.Item label="User Roles">
          <Select
            loading={isLoading}
            options={userRoles}
            value={form.userRoles_id}
            mode="multiple"
            onChange={(e) => {
              updateForm("userRoles_id", e);
            }}
            allowClear
          />
        </Form.Item>
        {/* <Form.Item label="Region" name="hierarchy_id">
          <Cascader
            multiple
            placeholder="Please select new regions"
            options={treeData}
            expandTrigger="hover"
            displayRender={displayRender}
            // defaultValue={[getLabel(form.hierarchy_id || "")]}
            // defaultValue={['75e6e6f8-479a-4a43-858a-da6b61ff21c5', 'd795d0ad-05d0-45e5-999a-fd1d02a750d1']}
            onChange={(val, e) => {
              setForm({
                ...form,
                hierarchy_id: val,
              });
              onChange("hierarchy_id", val);
            }}
            showSearch={{ filter }}
            changeOnSelect
          />
        </Form.Item>
        {regionText && regionText.length > 0 && (
          <Form.Item label="Current Regions">
            {regionText.map((t, i) => (
              <Tag key={i}>{t}</Tag>
            ))}
          </Form.Item>
        )} */}


        {/* <div>
          <div>
            {moduleId &&
            <Form.Item  label="Is Active" valuePropName="isActive" name="isActive" >
              <Switch
                checked={isActive}
                defaultChecked={isActive}
                onChange={(e) => { setIsActive(e) }}
              />
            </Form.Item>
          }
          </div>
          <div>
            {moduleId &&
            <Form.Item label="Is Formal" valuePropName="isFormal" name="isFormal" >
              <Switch
                checked={isFormal}
                defaultChecked={isFormal}
                onChange={(e) => { setIsFormal(e) }}
              />
            </Form.Item>
          }
          </div>
        </div> */}

        <div style={{display: "flex" , gap: "20px", marginLeft: "170px"}}>
          <label htmlFor="switchActiveBtn" style={{display: "flex" , gap: "10px"}}>
            Is Active:
            <Switch
              checked={isActive}
              id="switchActiveBtn"
              defaultChecked={isActive}
              onChange={(e) => { setIsActive(e) }}
            />
          </label>
          {!moduleId && <label htmlFor="switchFinishBtn" style={{display: "flex" , gap: "10px"}}>
            Is Formal:
            <Switch
              checked={isFormal}
              id="switchFinishBtn"
              defaultChecked={isFormal}
              onChange={(e) => { setIsFormal(e) }}
            />
          </label>
          }
          {moduleId && isFormal === true && <label htmlFor="switchFinishBtn" style={{display: "flex" , gap: "10px"}}>
            Is Formal:
            <Switch
              disabled={true}
              checked={isFormal}
              id="switchFinishBtn"
              defaultChecked={isFormal}
              onChange={(e) => { setIsFormal(e) }}
            />
          </label>
          }
        </div>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }} style={{marginTop: 10}}>
          <Button type="primary" htmlType="submit">
            {moduleId ? "Update" : "Create"}
          </Button>
        </Form.Item>

        {moduleId && knowledgeTransferDetails.length > 0 && (
          <div>
            <h3 style={{ textAlign: "center" }}>Questions</h3>
            {knowledgeTransferDetails
              .sort((a, b) => a.sequence - b.sequence)
              .map((q, index: number) => (
                <div key={q.qID}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <h4>{`(${index + 1}) ${q.questionText}`}</h4>
                      {q.answerPayload.options
                        .sort(
                          (a: any, b: any) =>
                            a.responseSequence - b.responseSequence
                        )
                        .map((o: any) => (
                          <div
                            key={o.answerId}
                            style={{
                              display: "flex",
                              marginBottom: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "8px",
                                border: `1px solid ${o.value ? "green" : "red"
                                  }`,
                                borderRadius: "5px",
                              }}
                            >
                              {o.label}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "8px",
                                fontWeight: 800,
                              }}
                            >
                              {`${o.value} pts`}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </Form>
    </>
  );
};

export default KnowledgeTransferForm;
