import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Form,
  Button,
  Typography,
  DatePicker,
  Table,
  Modal,
  Input,
  Switch,
} from "antd";
import { DataType } from "../types";
import { DefaultOptionType } from "antd/lib/select";
import { AppContext } from "../../../../App";
import readXlsxFile from "read-excel-file";
import { SheetDataSet } from "./pos-basket-types";
import useApiPost from "../../../../hooks/useApiPost";
import { handleEndDate, handleStartDate } from "../../../../utils/dateTime";

const PosBasketForm = () => {
  const [form] = Form.useForm();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [name, setName] = useState<any>(null);
  const [isActive, setIsActive] = useState<any>(null);
  const { request, setError } = useApiPost();

  const showModal = () => {
    setIsConfirmModalOpen(true);
  };

  const handleOk = () => {
    setIsConfirmModalOpen(false);
  };

  const handleCancel = () => {
    setIsConfirmModalOpen(false);
  };

  const appContext = useContext(AppContext);
  const { basketId } = useParams<{ basketId: string }>();
  const [chains, setChains] = useState<any[]>([]);
  const [treeData, setTreeData] = useState<DataType[]>([]);
  const [initValues, setInitValues] = useState<any>({});

  const [products, setProducts] = useState<any[]>([]);
  const [newData, setNewData] = useState<any[]>([]);
  const [newEntries, setNewEntries] = useState<any[]>([]);

  const [formOptionValues, setFormOptionValues] = useState({
    outletType_id: null,
  });

  const { projectId } = appContext;
  const [isLoading, setIsLoading] = useState(false);
  const headerText = basketId ? `${name} Basket details` : "Import POS Basket";
  const navigate = useNavigate();

  const handleComeBack = () => {
    navigate("/admin/sku-baskets");
  };

  const fetchBasketData = async () => {
    setIsLoading(true);
    if (!basketId) {
      return;
    }
    // const { basket, products } = await getPosBasketDetails(basketId, projectId);
    const { basket, products } = await request(
      `/office/pos-baskets/details/${basketId}`,
      "POST",
      {
        projectId,
      }
    );
    const { startDate, endDate, description, isActive } = basket;
    setNewEntries(products);
    setName(description);
    setIsActive(isActive);
    setStartDate(new Date(startDate).toDateString());
    setEndDate(new Date(endDate).toDateString());
    setProducts(products);
    setFormOptionValues({
      outletType_id: basket.outletType_id,
      // channel_id: userData.channel_id,
      // chain_id: userData.chain_id,
    });

    setInitValues({
      ...basket,
      // ...formOptionValues,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    form.setFieldsValue(initValues);
  }, [initValues, basketId]);

  useEffect(() => {
    fetchBasketData();
  }, [basketId]);

  useEffect(() => {
    const fetchOptions = async () => {
      setIsLoading(true);
      const { outletTypes, chains } = await request(
        "/office/sku-baskets/options/creation/basket",
        "POST",
        {
          projectId,
        }
      );
      setChains(chains);
      setTreeData(outletTypes);
      setIsLoading(false);
    };
    fetchOptions();
  }, [projectId]);

  const handleOptionChange = (key: string, event: any) => {
    setFormOptionValues({
      ...formOptionValues,
      [key]: event.value,
    });
  };

  const getLabel = (id: string) => {
    let label = "";
    console.log({ id });
    const findLabel = (data: DataType[]) => {
      data.forEach((item) => {
        if (item.id === id) {
          label = item.title;
        } else if (item.children && item.children.length > 0) {
          findLabel(item.children);
        }
      });
    };
    findLabel(treeData);
    console.log({ label });
    return label;
  };
  const onChange = (key: string, value: any) => {
    const lastValue = Array.isArray(value) ? value[value.length - 1] : value;
    handleOptionChange(key, { value: lastValue });
  };
  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1
    );
  const updateForm = (key: string, value: any) => {
    // setForm((form) => ({
    //   ...form,
    //   [key]: value,
    // }));
  };

  if (isLoading || !chains || chains.length === 0) {
    return <p>Loading...</p>;
  }

  const validateBasket = async (data: any) => {
    const entries = await request("/office/pos-baskets/validate", "POST", {
      basketData: data,
      projectId,
      startDate,
      endDate,
      name,
    });
    setNewEntries(entries);
    setIsConfirmModalOpen(true);

    navigate("/admin/pos-baskets");
  };

  const handleUploadBatchFile = async (e: any) => {
    const file = e.target.files[0];
    const data: any[] = [];
    await readXlsxFile(file).then(async (rows) => {
      rows.map((row, index) => {
        if (index > 0) {
          const chain = row[0]?.toString().trim();
          const manufacturerCode = row[1]?.toString().trim();
          // headers
          if (chain === "Chain" && manufacturerCode === "Element") {
            return;
          }
          const required = row[2]?.toString().trim();
          const comment = row[3]?.toString().trim();
          // const startDate = row[4]?.toString().trim();
          // const endDate = row[5]?.toString().trim();

          const item: SheetDataSet = {
            chain,
            manufacturerCode,
            required: parseInt(required),
            comment,
            startDate,
            endDate,
          };

          data.push(item);

          // if(serialNumber !== serialNumber2) {
          //   if(!serialNumber2) {
          //     return;
          //   }
          //   data.push({
          //     ...item,
          //     serialNumber: serialNumber2
          //   });
          // }
        }
      });
      if (data.length > 0) {
        await validateBasket(data);
      }
      setNewData(data);
    });
  };
  const entriesExist = newEntries.length > 0;

  const newProductsLength = newEntries.filter((t) => t.isNewProduct).length;
  const newOutletsLength = newEntries.filter((t) => t.isNewOutlet).length;
  const missingChains = newEntries.filter((t) => !t.chain_id);
  const missingChainLength = missingChains.length;
  const isCreateAvailable = missingChainLength === 0;
  const uniqueNewEntries = newEntries
    .filter(
      (t, index, self) =>
        index === self.findIndex((t2) => t2.product_id === t.product_id)
    )
    .sort((a, b) => a.manufacturerCode - b.manufacturerCode);

  const updatePosBasket = async () => {
    if (!basketId || !startDate || !endDate) {
      return;
    }

    await request("/office/pos-baskets/update", "POST", {
      isActive,
      projectId,
      campaignId: basketId,
    });
    navigate(`/admin/pos-baskets`);
  };
  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={undefined}
      >
        {basketId && (
          <div>
            <hr />
            <div>
              {startDate} - {endDate}
            </div>
            <hr />
          </div>
        )}
        {!basketId && (
          <div>
            <Form.Item
              label="Name"
              name="name"
              // getValueProps={(i: any) => ({ value: moment(i) })}
              required
            >
              <Input onChange={(e) => setName(e.target.value)} />
            </Form.Item>
            <Form.Item
              label="Start"
              name="startDate"
              // getValueProps={(i: any) => ({ value: moment(i) })}
              required
            >
              <DatePicker
                allowClear={false}
                onChange={(e) => setStartDate(handleStartDate(e))}
              />
            </Form.Item>
            <Form.Item
              label="End"
              name="endDate"
              // getValueProps={(i: any) => ({ value: moment(i) })}
              required
              rules={[
                {
                  required: true,
                  message: "Please select a form end",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    const start = new Date(getFieldValue("startDate"));
                    const end = new Date(value);

                    if (start > end) {
                      return Promise.reject(
                        "End date must be greater than start date"
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <DatePicker
                allowClear={false}
                onChange={(e) => setEndDate(handleEndDate(e))}
              />
            </Form.Item>
          </div>
        )}
        <Table
          rowKey="order"
          style={{
            fontSize: "12px",
          }}
          // rowClassName={(record, index) => {
          //   if (record.chain_id && record.outletType_id && record.product_id) {
          //     return "success";
          //   }
          //   return "error";
          // }}
          pagination={{
            pageSize: 1000,
          }}
          dataSource={newEntries}
          columns={[
            {
              title: "Chain",
              dataIndex: "chain",
              key: "chain",
            },
            {
              title: "Product Code",
              dataIndex: "manufacturerCode",
              key: "manufacturerCode",
            },
            {
              title: "MSH",
              dataIndex: "required",
              key: "required",
            },
            {
              title: "Comment",
              dataIndex: "comment",
              key: "comment",
            },
            // {
            //   title: "Action",
            //   key: "action",

            //   render: (text: any, record: any) => (
            //     <Space size="middle">
            //       <Button
            //         type="primary"
            //         danger
            //         // onClick={() => handleDelete(record)}
            //       >
            //         Delete
            //       </Button>
            //     </Space>
            //   ),
            // },
          ]}
        />
        {/* </Form.Item> */}

        {/* <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}> */}
        {/* <Button type="primary" htmlType="submit">
            ADD
          </Button> */}
        {basketId && (
          <Form.Item label="Is Active" valuePropName="isActive" name="isActive">
            <Switch checked={isActive} onChange={(e) => setIsActive(e)} />
          </Form.Item>
        )}
        {!basketId && (
          <>
            <div>
              <input
                type="file"
                id="input"
                onChange={(e) => handleUploadBatchFile(e)}
              />
            </div>
            {entriesExist && (
              <Button type="primary" onClick={validateBasket}>
                Validate basket
              </Button>
            )}
            <Button
              type="primary"
              style={{ margin: 10 }}
              onClick={handleComeBack}
            >
              Go back
            </Button>

            <Modal
              title="Information"
              open={false}
              centered
              width={1000}
              footer={[
                <Button key="back" onClick={handleCancel}>
                  Cancel
                </Button>,
                <Button
                  type="primary"
                  onClick={handleOk}
                  disabled={!isCreateAvailable}
                >
                  Submit
                </Button>,
              ]}
            >
              <p
                style={{
                  textAlign: "center",
                }}
              >
                Your basket is almost ready!
              </p>
              {/* list newly created products */}
              {newProductsLength > 0 && (
                <div>
                  <p>
                    {newProductsLength} new products were added based on missing
                    product codes
                  </p>
                  <Table
                    dataSource={uniqueNewEntries}
                    rowKey="order"
                    columns={[
                      {
                        title: "Code",
                        dataIndex: "manufacturerCode",
                        key: "manufacturerCode",
                      },
                    ]}
                  />
                </div>
              )}
              {missingChainLength > 0 && (
                <>
                  <p>{`System found ${missingChainLength} missing chains:`}</p>
                  {missingChains &&
                    missingChains.map(({ chain }, index) => (
                      <div key={index} style={{ fontWeight: 800 }}>
                        {chain}
                      </div>
                    ))}
                  <div
                    style={{
                      paddingTop: 10,
                      textAlign: "center",
                      fontWeight: 800,
                    }}
                  >{`Please verify the import sheet for spellling mistakes or add missing outlets and try again`}</div>
                </>
              )}
            </Modal>
          </>
        )}

        {basketId && (
          <Button
            type="primary"
            style={{ margin: 10 }}
            onClick={updatePosBasket}
          >
            Save
          </Button>
        )}
        {/* </Form.Item> */}
      </Form>
    </>
  );
};

export default PosBasketForm;
